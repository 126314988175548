import LineController from "../journeys/line_controller"
import { StraightConnector } from "@jsplumb/core"

export default class extends LineController {
  static values = {
    start: String,
    end: String,
    // A branch can be an edge value if it's the first branch
    // to the left of a Condition step
    edge: { type: Boolean, default: false },
  }

  // The straight line needs to be drawn from this element to the 'proxy point',
  // which gives the illusion that this element is connected to its parent.
  static targets = ["proxyPoint", "halo", "removedInput"]

  initialize() {
    this.kind = "branch"
    if(this.areWeTestingWithJest) return

    super.initialize()
  }

  disconnect() {
    if(this.areWeTestingWithJest) return

    this.instance.deleteConnection(this.startLine)
    this.instance.deleteConnection(this.endLine)

    super.disconnect()
  }

  connect() {
    super.connect()

    if(!this.areWeTestingWithJest) {
      if (this.edgeValue) {
        this.drawLinesForEdgeBranch()
      } else {
        this.drawLinesForInnerBranch()
      }
    }

    if(document.querySelector(`input[name='${this.element.id}[persisted]']`)) {
      document.querySelector(`input[name='${this.element.id}[persisted]']`)
        .disabled = false
    }
  }

  redraw({ detail }) {
    if (detail.target !== this.element.id) return

    this.instance.deleteConnection(this.endLine)

    this.endLine = this.draw(
      this.element.firstElementChild,
      document.getElementById(detail.replaceWith),
      {
        fromOptions: this.blankEndpoint(),
        toOptions: this.blankEndpoint("Top"),
      }
    )
  }

  redrawEndLineIfNeeded({ detail }) {
    if (
      detail.target === this.element.id ||
      detail.parent === this.element.id
    ) {
      this.instance.deleteConnection(this.endLine)

      if (this.edgeValue) {
        this.drawEndLineForEdgeBranch()
      } else {
        this.drawEndLineForInnerBranch()
      }
    }

    this.repositionLine()
  }

  repositionLine() {
    this.instance.repaintEverything()
  }

  onWindowSizeChange() {
    this.instance.repaintEverything()
  }

  invalidate({ dataset }) {
    if(dataset.target !== this.element.id) return

    this.haloTarget.classList.add("halo--error")
  }

  removeInvalidStatus() {
    this.haloTarget.classList.remove("halo--error")
  }

  remove() {
    this.element.remove()

    if (this.hasRemovedInputTarget) {
      this.removedInputTarget.disabled = false
      document
        .getElementById("drawing_container")
        .appendChild(this.removedInputTarget)
    }
  }

  // private

  drawLinesForEdgeBranch() {
    this.startLine = this.draw(
      this.startElement,
      this.element.firstElementChild,
      {
        fromOptions: this.blankEndpoint("Left"),
        toOptions: this.blankEndpoint("Top"),
      }
    )

    this.drawEndLineForEdgeBranch()
  }

  drawEndLineForEdgeBranch() {
    this.endLine = this.draw(this.element.firstElementChild, this.endElement, {
      fromOptions: this.blankEndpoint(),
      toOptions: this.blankEndpoint("Left"),
    })
  }

  drawLinesForInnerBranch() {
    this.startLine = this.draw(
      this.proxyPointTarget,
      this.element.firstElementChild,
      {
        fromOptions: this.blankEndpoint("Center"),
        toOptions: this.blankEndpoint("Top"),
        lineOptions: StraightConnector.type,
      }
    )

    this.drawEndLineForInnerBranch()
  }

  drawEndLineForInnerBranch() {
    this.endLine = this.draw(this.element.firstElementChild, this.endElement, {
      fromOptions: this.blankEndpoint(),
      toOptions: this.blankEndpoint(),
      lineOptions: {
        ...this.flowchartConnectorLineOptions,
        options: {
          ...this.flowchartConnectorLineOptions.options,
          cornerRadius: 0,
        },
      },
    })
  }

  get startElement() {
    return document.getElementById(this.startValue)
  }

  get endElement() {
    return document.getElementById(this.endValue)
  }
}
