import DragController from "../drag_controller"

export default class extends DragController {
  static values = {
    selectedInputTypes: Array,
  }

  static targets = [
    'item',
    'body',
    'focusLastItem',
    'captureNameInput'
  ]

  updateCaptureName({ dataset }) {
    this.captureNameInputTarget.value = dataset.value
  }

  end(event) {
    this.dispatch("drag-end", {
      target: event.item,
      detail: {
        oldIndex: event.oldIndex + 1,
        newIndex: event.newIndex + 1,
      },
    })
  }

  focus({ currentTarget }) {
    this.itemTargets.forEach((item, index) => {
      const body = this.bodyFor(item)

      if(item === currentTarget || item.contains(currentTarget)) {
        item.classList.add('link-primary')
        body.classList.remove('hidden')
        body.setAttribute('data-active', true)
      } else {
        item.classList.remove('link-primary')
        body.classList.add('hidden')
        body.removeAttribute('data-active')
      }
    })
  }

  saveSelectedInputTypes({ detail: inputType }) {
    this.selectedInputTypesValue = Array.from(new Set([...this.selectedInputTypesValue, inputType]))
  }

  updateSelectedInputTypes({ detail }) {
    const { replaced, with: inputType } = detail
    this.selectedInputTypesValue = Array.from(new Set([...this.selectedInputTypesValue.filter(type => type !== replaced), inputType]))
  }

  focusLastItemTargetConnected() {
    this.focusLastItemTarget.remove()

    this.itemTargets.forEach((item, index) => {
      const body = this.bodyFor(item)

      if(index !== this.itemTargets.length - 1) {
        item.classList.remove('link-primary')
        body.classList.add('hidden')
        body.removeAttribute('data-active')
      } else {
        body.setAttribute('data-active', true)

        this.dispatch('hide', {
          target: body.querySelector('[data-controller*="capture--inputs"]'),
          detail: this.selectedInputTypesValue
        })
      }

      this.enable(item)
    })
  }

  itemTargetConnected() {
    if(this.itemTargets.length === 1) {
      this.bodyTargets[0].setAttribute('data-active', true)

      setTimeout(() => {
        this.disable(this.itemTargets[0])
      })
    }
  }

  enable(item) {
    this.dispatch('enable', {
      target: item
    })
  }

  disable(item) {
    this.dispatch('disable', {
      target: item
    })
  }

  remove({ currentTarget }) {
    const item = this.itemTargets.find(item => item.contains(currentTarget))
    const body = this.bodyTargets.find(body => body.dataset.step === item.dataset.step)

    item.remove()
    body.remove()

    this.focus( { currentTarget: this.itemTargets[0] })
  }

  bodyFor(item) {
    return this.bodyTargets.find(body => body.dataset.step === item.dataset.step)
  }

  selectedInputTypesValueChanged() {
    window.selectedInputTypes = this.selectedInputTypesValue

    this.bodyTargets.forEach(body => {
      this.dispatch('hide', {
        target: body.querySelector('[data-controller*="capture--inputs"]'),
        detail: this.selectedInputTypesValue
      })
    })
  }
}
