import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    autoClose: Boolean,
    hideValue: Number,
  }

  connect() {
    if (this.autoCloseValue) {
      setTimeout(this.hide.bind(this), 4000)
      super.connect()
    }
  }

  hide() {
    this.element.classList.add("opacity-0")
    setTimeout(() => {
      this.element.classList.add("hidden")
    }, this.hideValue | 150)
  }
}
