import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    activeStepId: String,
  }

  static targets = [
    "mobileLayoutButton",
    "desktopLayoutButton",
    "bubbleButton",
    "buttonBackgroundColor",
    "buttonTextColor",
    "buttonAlignment",
    "overlayBackgroundColor",
  ]
  static classes = ["active"]

  initialize() {
    this.stepsCount = this.element.querySelectorAll(
      '[data-controller="popup--step--name"]'
    ).length
    this.activeLayout = "mobile"
  }

  displayMobileLayout() {
    if (this.bubbleVisible) {
      this.displayMobileBubble()
    }
    this.displayMobileLayoutForAllSteps()

    this.mobileLayoutButtonTarget.classList.add("text-tiger")
    this.desktopLayoutButtonTarget.classList.remove("text-tiger")

    this.activeLayout = "mobile"
  }

  displayDesktopLayout() {
    if (this.bubbleVisible) {
      this.displayDesktopBubble()
    }

    this.displayDesktopLayoutForAllSteps()

    this.desktopLayoutButtonTarget.classList.add("text-tiger")
    this.mobileLayoutButtonTarget.classList.remove("text-tiger")

    this.activeLayout = "desktop"
  }

  showStep({ currentTarget }) {
    this.bubbleVisible = false
    this.notifyStepToShow(currentTarget)
  }

  showBubble() {
    this.element.querySelectorAll("[data-step-id]").forEach((stepButton) => {
      stepButton.classList.remove(this.activeClass)
    })

    this.bubbleButtonTarget.classList.add(this.activeClass)
    this.dispatch("show-step", {
      target: document.documentElement,
      detail: "mobile_bubble",
    })

    if (this.activeLayout === "mobile") {
      this.displayMobileBubble()
    } else {
      this.displayDesktopBubble()
    }

    this.bubbleVisible = true
  }

  showBubbleStep() {
    this.bubbleButtonTarget.removeAttribute("hidden")
    this.bubbleButtonTarget.click()
  }

  hideBubbleStep() {
    this.bubbleButtonTarget.setAttribute("hidden", "")
    this.notifyStepToShow(
      this.element.querySelector("[data-step-id]:first-child")
    )
  }

  setButtonStyles({ detail: { state } }) {
    this.buttonStyle = state
    this.buttonBackgroundColorTarget.value = state.backgroundColor
    this.buttonTextColorTarget.value = state.textColor
    this.buttonAlignmentTarget.value = state.alignment
  }

  async setNewStepAsActive({ currentTarget }) {
    this.bubbleVisible = false
    this.element.querySelectorAll("[data-step-id]").forEach((stepButton) => {
      stepButton.classList.remove(this.activeClass)
    })

    await get(currentTarget.dataset.url, {
      responseKind: "turbo-stream",
      query: {
        step_count: ++this.stepsCount,
        mobile_layout: window.mobileLayout || "",
        desktop_layout: window.desktopLayout || "",
        active_layout: this.activeLayout,
        button_style: JSON.stringify(this.buttonStyle),
        overlay_background_color: document.querySelector(
          "input[name='popup[overlay][background_color]']"
        ).value,
      },
    })

    this.dispatch("hide-step", {
      target: document.documentElement,
    })
  }

  remove({ currentTarget }) {
    document.getElementById(currentTarget.dataset.step).remove()
    document.getElementById(currentTarget.dataset.stepNameId).remove()

    --this.stepsCount
    this.notifyStepToShow(
      this.element.querySelector("[data-step-id]:first-child")
    )
  }

  disableDesktopLayout() {
    this.mobileLayoutButtonTarget.disabled = false
    this.mobileLayoutButtonTarget.click()

    this.desktopLayoutButtonTarget.disabled = true
  }

  disableMobileLayout() {
    this.desktopLayoutButtonTarget.disabled = false
    this.desktopLayoutButtonTarget.click()

    this.mobileLayoutButtonTarget.disabled = true
  }

  enableBothMobileAndDesktopLayout() {
    this.mobileLayoutButtonTarget.disabled = false
    this.desktopLayoutButtonTarget.disabled = false
  }

  setOverlayBackgroundImage({ detail: color }) {
    this.overlayBackgroundColorTarget.value = color
  }

  // private

  notifyStepToShow(element) {
    this.hideMobileBubble()
    this.hideDesktopBubble()

    console.log(element.dataset)
    this.dispatch("show-step", {
      target: document.documentElement,
      detail: element.dataset.stepId,
    })

    this.bubbleButtonTarget.classList.remove(this.activeClass)
    this.activeStepIdValue = element.dataset.stepId

    this.element.querySelectorAll("[data-step-id]").forEach((stepButton) => {
      if (stepButton.dataset.stepId === this.activeStepIdValue) {
        stepButton.classList.add(this.activeClass)
      } else {
        stepButton.classList.remove(this.activeClass)
      }
    })
  }

  displayMobileLayoutForAllSteps() {
    this.dispatch("display:mobile-layout", {
      target: document.documentElement,
      detail: this.activeStepIdValue,
    })
  }

  displayDesktopLayoutForAllSteps() {
    this.dispatch("display:desktop-layout", {
      target: document.documentElement,
      detail: this.activeStepIdValue,
    })
  }

  displayMobileBubble() {
    this.hideDesktopBubble()

    document.querySelector("[data-bubble='mobile']").classList.remove("hidden")
  }

  hideMobileBubble() {
    document.querySelector("[data-bubble='mobile']").classList.add("hidden")
  }

  displayDesktopBubble() {
    this.hideMobileBubble()

    document.querySelector("[data-bubble='desktop']").classList.remove("hidden")
  }

  hideDesktopBubble() {
    document.querySelector("[data-bubble='desktop']").classList.add("hidden")
  }
}
