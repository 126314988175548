import { Controller } from "@hotwired/stimulus"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

class Tabs {
  constructor(config) {
    this.config = config
    this.intervalID = config
    this.init()
  }
  init() {
    if (!this.validate()) return

    this.openTabsOnClick()
    this.emptyLoader()
  }
  onEnter() {
    this.autoChange()
    if (this.config.loading) {
      this.loader()
    }
    if (this.config.loadingColor) {
      this.changeLoaderColor()
    }
  }
  validate() {
    if (!(this.config && this.config.el)) {
      console.error("Tabs: Please provide an el to initialize tabs")
      return false
    }
    if (!document.querySelector(this.config.el)) {
      console.error(`Tabs: Couldn't find an element with ID ${this.config.el}`)
      return false
    }
    if (typeof this.config.timer == "string") {
      console.error("Tabs: Timer option must be a number")
      return false
    }
    if (typeof this.config.autoplay == "string") {
      console.error("Tabs: autoplay option must be a boolean")
      return false
    }
    if (typeof this.config.loading == "string") {
      console.error("Tabs: loading option must be a boolean")
      return false
    }
    return true
  }
  getItems() {
    var mainContainer = document.querySelector(this.config.el)
    return {
      mainContainer,
      allTabs: mainContainer.querySelectorAll(".tabs-container [data-tab]"),
      allTabsContent: mainContainer.querySelectorAll(
        ".tabs-content-container .tab-content"
      ),
    }
  }
  openTabsOnClick() {
    for (let i = 0; i < this.getItems().allTabs.length; i++) {
      var that = this
      this.getItems().allTabs[i].addEventListener("click", function () {
        clearInterval(that.intervalID)
        var dataTab = this.dataset.tab
        that.contentChange(dataTab)
        if (that.config.loading) {
          that.loader()
        }
        that.autoChange()
      })
    }
  }
  contentChange(e) {
    var singleTabElement = this.getItems().mainContainer.querySelector(
      '[data-tab="' + e + '"]'
    )
    var singleTabContent = this.getItems().mainContainer.querySelectorAll(
      `[data-tab-content=${e}]`
    )

    this.getItems().allTabs.forEach(function (tab) {
      tab.classList.remove("active-tab")
    })

    this.getItems().allTabsContent.forEach(function (tab) {
      tab.classList.remove("active-tab")
    })

    singleTabContent.forEach(function (tab) {
      tab.classList.add("active-tab")
    })

    singleTabElement.classList.add("active-tab")
    // singleTabContent.classList.add("active-tab")
  }
  changeLoaderColor() {
    var that = this
    this.getItems().allTabs.forEach(function (tab) {
      tab.querySelectorAll(".tabs--loader").forEach(function (tabsLoader) {
        tabsLoader.style.background = that.config.loadingColor
      })
    })
  }
  emptyLoader() {
    var loaderEl = `<div class="tabs--loader-container w-1 h-full max-h-24 bg-lavender-light absolute left-0 lg:-left-6 rounded-full top-0"><div class="relative w-1 h-full"><div class="tabs--loader top-0 bg-tiger rounded-full w-1 h-1"></div></div></div>`

    if (!this.getItems().allTabs[0].querySelector(".tabs--loader-container")) {
      this.getItems().allTabs[0].innerHTML += loaderEl
    }
  }
  loader() {
    var timer = this.config.timer || 5000
    var loaderEl = `<div class="tabs--loader-container w-1 h-full max-h-24 bg-lavender-light absolute left-0 lg:-left-6 rounded-full top-0"><div class="relative w-1 h-full"><div class="tabs--loader top-0 bg-tiger rounded-full w-1" style="animation-duration: ${timer}ms"></div></div></div>`

    if (!this.getItems().allTabs[0].querySelector(".tabs--loader-container")) {
      this.getItems().allTabs[0].innerHTML += loaderEl
    }

    this.getItems().allTabs.forEach(function (item) {
      if (item.querySelector(".tabs--loader-container")) {
        item.querySelector(".tabs--loader-container").remove()
      }
      if (item.classList.contains("active-tab")) {
        item.style.position = "relative"
        item.innerHTML += loaderEl
      }
    })
  }
  autoChange() {
    if (this.config.autoplay == false) {
      return
    }
    var that = this
    var timer = this.config.timer || 5000
    var counter = 0

    this.getItems().allTabs.forEach(function (i, j) {
      if (i.classList.contains("active-tab")) {
        counter = j + 1
      }
    })

    this.intervalID = setInterval(function () {
      if (counter >= that.getItems().allTabs.length) {
        counter = 0
      }
      that.contentChange(that.getItems().allTabs[counter].dataset.tab)
      counter++
      if (that.config.loading) {
        that.loader()
      }
      if (that.config.loadingColor) {
        that.changeLoaderColor()
      }
    }, timer)
  }
}
export default class extends Controller {
  connect() {
    const tabs = new Tabs({
      el: "#tabs",
      loading: true,
      autoplay: true,
      timer: 5000,
    })

    this.scrollTrigger = ScrollTrigger.create({
      trigger: ".tabs-container",
      start: "center center",
      onEnter: ({ progress, direction, isActive }) => tabs.onEnter(),
    })
  }

  disconnect() {
    this.scrollTrigger.kill()
  }
}
