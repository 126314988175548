import { computePosition, autoUpdate, shift } from "@floating-ui/dom"

import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['active', 'hover']

  initialize() {
    super.initialize()

    this.onMouseMove = this.onMouseMove.bind(this)
  }

  connect() {
    super.connect()

    if(this.hasActiveTarget) {
      super.hide(this.activeTarget)
    }

    if(this.hasHoverTarget) {
      super.hide(this.hoverTarget)
    }

    document.addEventListener('mousemove', this.onMouseMove)
  }

  disconnect() {
    document.removeEventListener('mousemove', this.onMouseMove)
    super.disconnect()
  }

  onMouseMove(event) {
    window.mouseX = event.clientX
    window.mouseY = event.clientY
  }

  moveLineToTheElement({ detail }) {
    const elementToPositionTo = detail.element
    const elementHeight = elementToPositionTo.clientHeight

    this.activeElement = elementToPositionTo

    if(this.activeCleanup) {
      this.activeCleanup()
    }

    this.activeTarget.style.height = `${elementHeight}px`
    this.activeCleanup = this.float(this.activeTarget, {
      anchor: elementToPositionTo,
    })

    this.activeTarget.classList.remove('hidden')
  }

  hoverLineToTheElement({ detail }) {
    this.hover({ srcElement: detail.element })
  }

  hover({ srcElement: elementToHoverTo }) {
    const elementHeight = elementToHoverTo.clientHeight

    if(this.hoverCleanup) {
      this.hoverCleanup()
    }

    this.hoverTarget.style.height = `${elementHeight}px`
    this.hoverCleanup = this.float(this.hoverTarget, {
      anchor: elementToHoverTo,
    })

    this.hoverTarget.classList.remove('hidden')
  }

  blur({ detail }) {
    const { element } = detail

    if(this.activeElement !== element) {
      return
    }

    if(this.activeCleanup) {
      this.activeCleanup()
    }

    this.activeTarget.classList.add('hidden')
  }

  unhover() {
    if(this.hoverCleanup) {
      this.hoverCleanup()
    }

    this.hoverTarget.classList.add('hidden')
  }

  hide({ target }) {
    this.nextTick(() => {
      if(this.withinLine(target) && this.noActiveLine) {
        return this.manuallyHoverLine(target)
      }

      if(!this.withinLine(target)) {
        if(this.hasHoverTarget) {
          super.hide(this.hoverTarget)
        }

        if(this.hasActiveTarget) {
          super.hide(this.activeTarget)
        }

        if(this.activeCleanup) {
          this.activeCleanup()
        }

        if(this.hoverCleanup) {
          this.hoverCleanup()
        }

        this.activeElement = null
      }
    })
  }

  focus({ currentTarget }) {
    this.moveLineToTheElement({ detail: { element: currentTarget } })
  }

  disconnectFromActiveElement({ detail }) {
    const { id } = detail

    if(this.activeElement && this.activeElement.dataset.lineId === id) {
      this.hide({ target: this.activeElement })

      const newElement = document.querySelector(`[data-line-id="${id}"]`)

      if(!newElement) return

      if(newElement.querySelector('[data-error]')) {
        this.nextTick(() => {
          this.moveLineToTheElement({ detail: { element: newElement }})
        })
      } else {
        this.hover({
          srcElement: document.querySelector(`[data-line-id="${id}"]`)
        })
      }
    }
  }

  // private

  float(element, { anchor }) {
    return autoUpdate(anchor, element, () => {
      if(anchor === this.activeElement && !document.contains(anchor)) {
        this.activeTarget.classList.add('hidden')
        this.activeCleanup()

        const newElement = document.querySelector(`[data-line-id="${anchor.dataset.lineId}"]`)

        if(!newElement) return

        if(newElement.querySelector('[data-error]')) {
          this.moveLineToTheElement({ detail: { element: newElement }})
          this.hover({ srcElement: newElement })
        } else {
          this.hover({
            srcElement: document.querySelector(`[data-line-id="${anchor.dataset.lineId}"]`)
          })
        }

        return
      }

      computePosition(anchor, element, {
        placement: 'left',
        middleware: [
          shift(),
        ]
      }).then(({x, y}) => {
        Object.assign(element.style, {
          top: `${y}px`,
        });
      });
    })
  }

  manuallyHoverLine(target) {
    // If clicked within a line that is not active, hover it. This happens when a line's input is focused,
    // and the user clicks within the line that causes the input to lose focus, hence removing the active line.
    // In that case we need to hover the element again manually to invoke a mouseenter event.

    super.hide(this.activeTarget)

    if(this.activeCleanup) {
      this.activeCleanup()
    }

    this.hover({ srcElement: target.closest('.line') || target })
  }

  withinLine(target) {
    return target.closest('.line') || target.classList.contains('line')
  }

  get noActiveLine() {
    return !document.querySelector('[data-line-active]')
  }
}
