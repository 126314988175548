export const useRemove = (
  controller,
  { before = () => {}, afterRemove = () => {}, after = () => {}, stopEventPropagation = false } = {}
) => {
  Object.assign(controller, {
    remove(e) {
      if (stopEventPropagation) {
        e.preventDefault()
        e.stopImmediatePropagation()
      }

      before(e)
      this.element.remove()
      afterRemove(e)
      after(e)
    },
  })
}
