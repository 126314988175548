import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    action: String
  }

  static targets = [
    'recaptchaTokenInput',
  ]

  initialize() {
    this.storeCaptchaToken = this.storeCaptchaToken.bind(this)
    super.initialize()
  }

  connect() {
    super.connect()

    if(this.recaptchaTokenInputTarget.value) {
      return
    }

    this.grecapthaInitializationTimeout = setTimeout(() => {
      if(grecaptcha) {
        grecaptcha.ready(this.storeCaptchaToken)
        clearTimeout(this.grecapthaInitializationTimeout)
      }
    }, 500)
  }

  disconnect() {
    clearTimeout(this.grecapthaInitializationTimeout)
    super.disconnect()
  }

  async submit({ currentTarget }) {
    currentTarget.disabled = true

    if(!this.recaptchaTokenInputTarget.value) {
      await this.storeCaptchaToken()
    }

    this.element.requestSubmit()
  }

  // private

  async storeCaptchaToken() {
    const siteKey = this.valueOfMetaTag('recaptcha-site-key')
    const config = { action: this.actionValue }

    this.recaptchaTokenInputTarget.value = await grecaptcha.execute(siteKey, config)
  }
}
