import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["input", "templates", "templateIdInput", "notice"]

  inputValueChanged(e) {
    if(this.inputTarget.value.startsWith("/")) {
      this.dispatch("show", {
        detail: this.inputTarget.value.substring(1),
        target: this.templatesTarget
      })
    } else {
      this.dispatch("hide", {
        target: this.templatesTarget
      })
    }

    if(!this.templateIdInputTarget.value || !this.hasNoticeTarget) return

    if(this.templateContent && this.inputTarget.value.trim() !== this.templateContent.trim()) {
      this.show(this.noticeTarget)
    } else {
      this.hide(this.noticeTarget)
    }
  }

  saveTemplateId({ detail }) {
    if(!detail.id) return;

    this.templateIdInputTarget.value = detail.id
    this.templateContent = detail.body
  }

  onTargetTechnologyChanged({ detail }) {
    this.dispatch('technology-changed', {
      detail: detail,
      target: this.templatesTarget
    })
  }

  onEnter(e) {
    e.preventDefault()
    e.stopPropagation()

    this.dispatch('enter', {
      target: this.templatesTarget,
    })
  }

  hideTemplatesContainer() {
    console.log('hiding templates')
    this.dispatch("hide", {
      target: this.templatesTarget
    })
  }
}
