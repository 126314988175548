import ModalController from '../modal_controller'

export default class extends ModalController {
  static values = {
    url: String,
  }

  open(event) {
    const { id, ids, size, contactId, element, all } = event.detail

    this.formTarget.action = this.urlValue.replace(':id', id)

    const input = this.buildHiddenInput('contact_ids', ids.join(','))
    const contactIdInput = this.buildHiddenInput('contact_id', contactId)

    if(all || ids.length === size || size === 1) {
      this.element.querySelector('[data-modal-target="title"]').innerText = window.t.layouts.audience.conflict.remove.modal.title.all
      input.value = 'all'
    } else if(ids.length === 1) {
      this.element.querySelector('[data-modal-target="title"]').innerText = window.t.layouts.audience.conflict.remove.modal.title.one
    } else {
      this.element.querySelector('[data-modal-target="title"]').innerText = window.t.layouts.audience.conflict.remove.modal.title.other.replace('%{count}', ids.length)
    }

    this.formTarget.appendChild(input)
    this.formTarget.appendChild(contactIdInput)

    this.currentElement = element
    this.ids = ids

    super.open(event)
  }

  // private

  buildHiddenInput(name, value) {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = name
    input.value = value

    return input
  }

  hideTargetConnected() {
    this.dispatch('commit', {
      target: this.currentElement,
      detail: this.ids,
    })

    this.currentElement = null
    this.ids = null

    super.hideTargetConnected()
  }

  get formTarget() {
    return this.element.querySelector('form')
  }
}
