import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    path: String,
    searchUrl: String
  }

  static targets = [
    'mainContent',
    'detailPane',
    'detailPaneContainer',
    'detailPaneSkeleton',
    'link',
    'frameReload',
    'mainFrame',
    'list',
    'navigationLink',
    'searchUrl',
    'linksContainer',
    'content',
    'info',
    'searchInput',
    'searchContainer',
    'conflict',
  ]

  initialize() {
    super.initialize()
    this.popStateListener = this.popStateListener.bind(this)

    this.element.querySelectorAll('x-pane').forEach(pane => pane.remove())

    if(this.hasNavigationLinkTarget) {
      this.navigationLinkTarget.remove()
    }
  }

  connect() {
    window.addEventListener('popstate', this.popStateListener)
    this.nextTick(this.popStateListener)

    super.connect()
  }

  disconnect() {
    window.removeEventListener('popstate', this.popStateListener)
    super.disconnect()
  }

  popStateListener() {
    const path = window.location.pathname

    if(path === this.pathValue) {
      this.collapse()

      document
        .querySelector('.audience--contact-card[data-active]')
        ?.removeAttribute('data-active')

      this.show(this.contentTarget)
      this.hide(this.detailPaneContainerTarget)
    }

    if(!path.includes('conflicts')) {
      this.conflictTargets.forEach(element => element.remove())
    }
  }

  showSkeleton() {
    this.expand(true)
  }

  expand(hideEventsContainer = false) {
    this.mainContentTarget.classList.remove('max-w-1200')

    this.hide(this.contentTarget)
    this.show(this.detailPaneContainerTarget)

    this.hide(this.detailPaneTarget)

    if(hideEventsContainer && typeof hideEventsContainer === 'boolean') {
      this.detailPaneSkeletonTarget.querySelector('[data-events-container]').classList.add('hidden')
    }

    this.show(this.detailPaneSkeletonTarget)
  }

  collapse() {
    this.mainContentTarget.classList.add('max-w-1200')

    this.show(this.contentTarget)
    this.hide(this.detailPaneContainerTarget)

    if(this.hasLinksContainerTarget) {
      this.hide(this.linksContainerTarget)
    }

    this.hide(this.detailPaneSkeletonTarget)
    this.hide(this.detailPaneTarget)

    this.detailPaneSkeletonTarget.querySelector('[data-events-container]').classList.remove('hidden')
  }

  showLinksContainer() {
    if(this.onMobile) {
      this.show(this.linksContainerTarget)
      this.hide(this.contentTarget)
    }
  }

  hideLinksContainer() {
    if(this.onMobile) {
      this.hide(this.linksContainerTarget)
      this.show(this.contentTarget)
    }
  }

  closeDetailPane() {
    this.show(this.contentTarget)
    this.detailPaneContainerTarget.classList.add('hidden', 'md:block')
  }

  infoTargetConnected() {
    this.expand()

    this.show(this.detailPaneTarget)
    this.hide(this.detailPaneSkeletonTarget)
  }

  updatePathValue({ detail }) {
    const { path, count: contactsCount, exportUrl, batchUrl } = detail

    this.element.setAttribute('data-audience--radio-export-url-value', exportUrl)
    this.element.setAttribute('data-audience--radio-batch-url-value', batchUrl)
    this.element.setAttribute('data-audience--radio-count-value', contactsCount)

    this.pathValue = path
    this.searchInputTarget.placeholder = this.translations.placeholders.search_audience.replace('%{number}', contactsCount)

    if(!path.includes('conflicts')) {
      this.conflictTargets.forEach(element => element.remove())
    }

    if(parseInt(contactsCount) === 0) {
      this.addClass(this.searchContainerTarget, 'invisible')
    } else {
      this.removeClass(this.searchContainerTarget, 'invisible')
    }
  }
}
