import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    persisted: { type: Boolean, default: false },
    id: String
  }

  static targets = ["removedInput"]

  markToRemove(e) {
    // stop the event propagation to prevent the pane from hiding
    e.preventDefault()
    e.stopPropagation()

    if(this.persistedValue) {
      this.hide()
    } else {
      this.remove()
    }
  }

  save() {
    this.persistedValue = true
  }

  show() {
    this.element.classList.remove("hidden")
  }

  remove() {
    if(document.getElementById(this.idValue)) {
      this.dispatch("remove", {
        target: document.getElementById(this.idValue),
      })
    }

    this.element.remove()
  }

  delete() {
    if(this.hasRemovedInputTarget) {
      this.removedInputTarget.disabled = false
      this.element.replaceWith(this.removedInputTarget)
    }
  }

  // private

  hide() {
    this.element.classList.add("hidden")
  }
}
