import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template", "content", "header", "name"]

  add(removeable = true, focus = true) {
    const template = this.templateTarget.cloneNode(true)
    template.removeAttribute("hidden")
    template.removeAttribute("data-template")
    template.classList.remove("hidden")

    template.setAttribute("data-step--request--headers-target", "header")
    template.querySelectorAll("input").forEach(input => input.disabled = false)

    if(focus) {
      template.setAttribute("data-autofocus", "")
    }

    this.contentTarget.appendChild(template)

    if(removeable) {
      this.dispatch("allow-remove", {
        target: this.defaultHeader
      })
    }

    setTimeout(() => this.sync(), 1)
  }

  sync() {
    if(this.headerTargets.length === 1) {
      this.dispatch("prevent-remove", {
        target: this.defaultHeader
      })
    }
  }

  clear() {
    this.headerTargets.forEach(header => header.remove())
  }

  setContentType({ detail: contentType}) {
    const contentTypeHeader = this.nameTargets.find(name => name.value.toLowerCase() === "Content-Type".toLowerCase())

    if(contentTypeHeader) {
      this.dispatch("set:value", {
        target: contentTypeHeader.parentElement,
        detail: contentType
      })

      return
    }

    const dispatchEvent = () => {
      this.dispatch("set", {
        target: this.headerTargets[this.headerTargets.length - 1],
        detail: {
          name: "Content-Type",
          value: contentType
        }
      })
    }

    const emptyHeader = this.nameTargets
      .filter((name) => !name.parentElement.hasAttribute("data-template"))
      .find(name => name.value.length === 0)

    if (emptyHeader && emptyHeader.nextElementSibling.value.length === 0) {
      dispatchEvent()

      return
    }

    this.add(true, false)
    setTimeout(() => this.sync(), 1)
    setTimeout(dispatchEvent, 10)
  }

  save() {
    this.headerTargets.forEach(header => {
      this.dispatch("save", {
        target: header
      })

      header.setAttribute("data-saved", "")
    })
  }

  restoreState() {
    this.headerTargets.forEach(header => {
      if(header.hasAttribute("data-saved")) {
        this.dispatch("restore", {
          target: header
        })

        header.classList.remove("hidden")
      } else if(this.headerTargets.length > 1) {
        header.remove()
      }
    })
  }

  // private

  get defaultHeader() {
    return this.headerTargets[0]
  }
}
