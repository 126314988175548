import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "content"]

  showEmailInput(e) {
    this._showInput("email")
    e.target.classList.add("hidden")
  }

  showCompanyInput(e) {
    this._showInput("company")
    e.target.classList.add("hidden")
  }

  showBirthdayInput(e) {
    this._showInput("birthday")
    e.target.classList.add("hidden")
  }

  showAddressInput(e) {
    this._showInput("address")
    e.target.classList.add("hidden")
  }

  addPhoneField() {
    const element = document.getElementById("phone-template").cloneNode(true)
    element.removeAttribute("id")
    element.removeAttribute("hidden")
    element.querySelector("input").disabled = false
    element.setAttribute("data-controller", "phone-number remove")
    element.setAttribute("data-contact-identity", "")

    element.classList.remove("hidden")

    document.getElementById("new-contact-lists").before(element)
  }

  addCustomField(e) {
    const deepClone = true
    const input = this.inputTargets
      .find((input) => input.id === "custom")
      .cloneNode(deepClone)

    input.classList.remove("hidden")
    input.setAttribute("data-controller", "contactables--custom-field")
    input.getElementsByTagName("input")[0].disabled = false
    input.getElementsByTagName("input")[1].disabled = false
    input.id = null
    this.contentTarget.append(input)
  }

  _showInput(id) {
    const input = this.inputTargets.find((input) => input.id === id)
    input.classList.remove("hidden")

    this.contentTarget.append(input)
  }
}
