import PropertyController from "../contact/property_controller"
import { get } from "@rails/request.js";

import { useBatchUpdateNotifiers } from '../mixins/useBatchUpdateNotifiers'

export default class extends PropertyController {
  static values = {
    kind: String,
    searchUrl: String
  }

  static targets = ["deleteButton", "menu", "batchUpdateClearAllCheckbox"]

  initialize() {
    super.initialize()
    this.search = this.search.bind(this)
  }

  connect() {
    super.connect();
    this.propertyValue = this.element.dataset.propertyValue

    useBatchUpdateNotifiers(this, {
      onBatchUpdateCancellation: () => {
        if(this.blockedAnchor) {
          return this.blockedAnchor.click()
        }

        this.inputTarget.focus()
      },
      onBatchUpdateAbortion: () => {
        this.clearDebounce()
        this.blur()
      }
    })
  }

  saveChanges(e) {
    const { target } = e

    if(this.containerTarget.classList.contains("hidden")
      || this.containerTarget.contains(target)
      || target === this.buttonTarget
      || this.buttonTarget.contains(target)
      || target === this.inputTarget
      || target.getAttribute('data-audience--contact-target') === 'checkbox'
      || target.closest('dialog')
      || this.isBlockedAnchor(e)
    ) return

    if(this.inBatchUpdateMode()) {
      if(this.inputTarget.value.trim().length > 0 || (this.hasOverrideCheckboxTarget && this.overrideCheckboxTarget.checked)) {
        return this.blockAnchorClickAndRequestBatchUpdateConfirmation(e)
      }
    }

    if(this.hasError) {
      return this.restore()
    }

    this.clearDebounce()

    if(this.propertyValue === this.inputTarget.value) {
      this.blur()
    } else if(!this.inputTarget.value) {
      this.formTarget.querySelector("input[name='_method']").value = "delete"
    }

    if(this.inBatchUpdateMode()) {
      if(this.inputTarget.value.trim().length) {
        this.blockAnchorClickAndRequestBatchUpdateConfirmation(e)
      }

      return
    }

    this.formTarget.requestSubmit()
  }

  submit() {
    this.clearDebounce()

    if(this.element.closest('[data-mode="batch_update"]') || (this.hasOverrideCheckbox && this.overrideCheckboxTarget.checked)) {
      return this.requestBatchUpdateConfirmation({
        property: this.element.dataset.property,
        value: this.inputTarget.value,
      })
    }

    if(this.inputTarget.value.trim().length === 0) {
      if(this.persistedValue && this.hasDeleteButtonTarget) {
        this.deleteButtonTarget.click()
      }
    } else {
      this.formTarget.requestSubmit()
    }
  }

  debounceSearch() {
    if(this.hasOverrideCheckboxTarget) {
      this.overrideCheckboxTarget.checked = this.inputTarget.value.trim().length > 0
    }

    if(this.inputTarget.value.trim().length === 0) {
      this.clearDebounce()

      if(this.hasMenuTarget) {
        this.menuTarget.classList.add("hidden")
      }

      return
    }

    this.debounce(this.search)
  }

  blockAnchorClickAndRequestBatchUpdateConfirmation(e) {
    if(e.target.tagName === 'A' || e.target.closest('a')) {
      e.preventDefault()
      e.stopPropagation()

      this.blockedAnchor = e.target.closest('a') || e.target
    }

    this.requestBatchUpdateConfirmation()
  }

  async search() {
    await get(this.searchUrlValue, {
      responseKind: "turbo-stream",
      query: {
        search: this.inputTarget.value
      }
    })
  }

  set({ currentTarget }) {
    this.inputTarget.value = currentTarget.dataset.value
    this.submit()
  }

  unsetError() {
    super.unsetError()

    if(this.hasMenuTarget) {
      this.menuTarget.classList.replace("top-16", "top-12")
    }
  }

  onOverrideCheckboxChange({ target }) {
    this.shouldOverride = target.checked
    this.focus()
  }

  onSubmitEnd() {
    super.onSubmitEnd()
    this.inputTarget.focus()
  }

  getValueForBatch() {
    return this.inputTarget.value
  }
}
