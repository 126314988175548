import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    completed: { type: Boolean, default: false }
  }

  static targets = ['downloadButton']

  connect() {
    if(!this.completedValue) return

    this.nextTick(() => {
      if(this.hasDownloaded) return
      this.downloadButtonTarget.click()
    }, 2000)
  }

  download() {
    this.hasDownloaded = true
    this.element.remove()
  }

  cancel() {
    this.element.remove()
  }
}
