import { Modal } from "tailwindcss-stimulus-components"

export default class extends Modal {
  static values = {
    backdropClose: { type: Boolean, default: true }
  }

  static targets = ["hide", "title", "subtitle", "closeButton"]

  initialize() {
    this.onDialogOpen = this.onDialogOpen.bind(this)
    this.onDialogClose = this.onDialogClose.bind(this)
    this.escapeKeypressListener = this.escapeKeypressListener.bind(this)

    super.initialize()
  }

  connect() {
    this.dialogTarget.addEventListener('open', this.onDialogOpen)
    this.dialogTarget.addEventListener('close', this.onDialogClose)

    window.addEventListener('keydown', this.escapeKeypressListener)

    super.connect()
  }

  disconnect() {
    this.dialogTarget.removeEventListener('open', this.onDialogOpen)
    this.dialogTarget.removeEventListener('close', this.onDialogClose)

    window.removeEventListener('keydown', this.escapeKeypressListener)
    super.disconnect()
  }

  escapeKeypressListener(event) {
    if(event.key !== "Escape") return

    if(this.backdropCloseValue) {
      this.close()
    } else {
      event.preventDefault()
    }
  }

  onDialogOpen() {
    this.dispatch("opened")
  }

  onDialogClose() {
    this.dispatch("closed")
  }

  hideTargetConnected() {
    this.close()
    this.hideTarget.remove()
  }

  backdropClose(event) {
    if (event.target.nodeName === "DIALOG" && this.backdropCloseValue) this.close()
  }
}
