import { Loader } from "@googlemaps/js-api-loader"

import ApplicationController from '../../application_controller'
import {Current} from "@/controllers/models/current";

export default class extends ApplicationController {
  static values = {
    initial: String,
    apiKey: String,
    countryCode: String
  }

  static targets = [
    'searchInput',
    'hiddenInput',
    'listContainer',
    'list',
    'notes',
    'itemTemplate',
    'manual',
    'customItem',
    'googleLogo'
  ]

  initialize() {
    super.initialize()

    this.loader = new Loader({
      apiKey: this.apiKeyValue,
      version: "weekly",
      libraries: ["places"]
    });

    this.countryCodeValue = Current.business.country.code
    this.buildAddressElement = this.buildAddressElement.bind(this)
  }

  connect() {
    super.connect()

    this.loader.load().then((google) => {
      this.google = google
      this.autocompleteAPI = new google.maps.places.AutocompleteService()
    })
  }

  search({ currentTarget }) {
    if(currentTarget.value) {
      this.debounce(() => this.searchCities(currentTarget))
    } else {
      this.removeSearchResults()
      this.show(this.notesTarget)
      this.hide(this.listContainerTarget)
    }
  }

  async searchCities(input) {
    this.element.setAttribute('data-arrow-navigation-enabled-value', false)

    this.autocompleteAPI.getPlacePredictions({
      input: input.value,
      language: Current.user.locale,
      componentRestrictions: {
        country: this.countryCodeValue
      },
      types: ['(cities)']
    }).then(response => {
      this.removeSearchResults()

      response.predictions.map(this.buildAddressElement).forEach(element => this.listTarget.appendChild(element))

      if(response.predictions.length > 0) {
        this.show(this.googleLogoTarget)
      } else {
        this.hide(this.googleLogoTarget)

        const clonedCustomItem = this.customItemTarget.cloneNode(true)

        clonedCustomItem.querySelector('p').innerText = this.translations.property.addresses.form.add.replace('%{address}', this.searchInputTarget.value)
        clonedCustomItem.setAttribute('data-description', this.searchInputTarget.value)
        clonedCustomItem.setAttribute('data-value', this.searchInputTarget.value)
        clonedCustomItem.setAttribute('data-arrow-navigatable', '')

        this.show(clonedCustomItem)
        this.listTarget.appendChild(clonedCustomItem)
      }
    }).catch(() => this.removeSearchResults()).finally(() => {
      this.hide(this.notesTarget)
      this.show(this.listContainerTarget)
      this.show(this.listTarget)

      this.element.setAttribute('data-arrow-navigation-enabled-value', true)
    })
  }

  selectCity({ currentTarget }) {
    this.searchInputTarget.value = currentTarget.dataset.description
    this.hiddenInputTarget.value = currentTarget.dataset.value

    this.reset()

    this.focus(this.searchInputTarget, { moveCursorToEnd: true })
  }

  submit() {
    this.dispatch('submit')
  }

  // private

  removeSearchResults() {
   this.listTarget.innerHTML = ''
   this.hide(this.googleLogoTarget)
  }

  reset() {
    this.removeSearchResults()

    this.hide(this.listContainerTarget)
    this.show(this.notesTarget)
    this.hide(this.googleLogoTarget)
  }

  buildAddressElement(prediction, index) {
    const item = this.itemTemplateTarget.cloneNode(true)
    this.removeClass(item, 'hidden')

    item.innerText = [prediction.structured_formatting.main_text, this.countryCodeValue].join(', ')

    item.setAttribute('data-id', prediction.place_id)
    item.setAttribute('data-description', item.innerText)
    item.setAttribute('data-value', prediction.structured_formatting.main_text)
    item.setAttribute('data-action', 'click->property--address--city#selectCity:stop')
    item.setAttribute('tabindex', index + 1)
    item.setAttribute('data-arrow-navigatable', '')

    return item
  }

  selectCityFromAddress({ detail }) {
    this.hiddenInputTarget.value = detail.city
    this.searchInputTarget.value = `${detail.city}, ${this.countryCodeValue}`
  }
}
