import { AsYouType } from "libphonenumber-js"

import PropertyController from "../contact/property_controller"

export default class extends PropertyController {
  static targets = ["clearButton"]

  connect() {
    super.connect()

    this.nextTick(() => {
      if(this.errorValue) {
        this.focus()
      }
    })
  }

  format() {
    this.inputTarget.value = (new AsYouType("UY")).input(this.inputTarget.value)
  }

  saveChanges(e) {
    if(this.withinBoundaries(e)) return

    if(this.inputTarget.value === this.currentValue) {
      return this.blur()
    }

    if(this.errorValue) {
      return this.restore()
    }

    if(this.inputTarget.value.trim().length === 0) {
      if(this.hasClearButtonTarget) {
        this.clearButtonTarget.click()
      } else {
        this.blur()
      }
    } else {
      this.formTarget.requestSubmit()
    }
  }

  submit() {
    if(this.currentValue === this.inputTarget.value) {
      return this.blur()
    }

    if(this.inputTarget.value.trim().length === 0 && this.persistedValue) {
      return this.clearButtonTarget.click()
    }

    this.formTarget.requestSubmit()
  }
}
