const commaSeperatedListWithAndConjunction = (array) => {
  if(array.length === 1) {
    return array[0]
  } else if(array.length === 2) {
    return array.join(` ${t.sentence.conjunction.and.normal} `)
  } else {
    const secondToLast = array[array.length - 2]
    const last = array[array.length - 1]

    return `${array.slice(0, -2).join(', ')}, ${secondToLast} ${t.sentence.conjunction.and.normal} ${last}`
  }
}

const commaSeperatedListWithOrConjunction = (array) => {
  if(array.length === 1) {
    return array[0]
  } else if(array.length === 2) {
    return array.join(` ${t.sentence.conjunction.or.normal} `)
  } else {
    const secondToLast = array[array.length - 2]
    const last = array[array.length - 1]

    return `${array.slice(0, -2).join(', ')}, ${secondToLast} ${t.sentence.conjunction.or.normal} ${last}`
  }
}

export { commaSeperatedListWithAndConjunction, commaSeperatedListWithOrConjunction }
