import { computePosition, autoUpdate, flip } from '@floating-ui/dom'

import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [
    'selectedIcon',
    'unselectedIcon',
    'importCheckbox',
    'dateFormat',
    'dateFormatMenu',
    'dateFormatButton',
    'dateFormatInputsContainer',
    'option',
    'select',
  ]

  connect() {
    if(this.hasImportCheckboxTarget) {
      this.setupCheckbox()
      this.importCheckboxTarget.addEventListener('change', this.toggle.bind(this))
    }
  }

  disconnect() {
    super.disconnect()

    if(this.hasImportCheckboxTarget) {
      this.importCheckboxTarget.removeEventListener('change', this.toggle.bind(this))
    }
  }

  setupCheckbox() {
    this.updateDisplay()
  }

  toggle() {
    this.importCheckboxTarget.checked = !this.importCheckboxTarget.checked
    this.updateDisplay()
  }

  updateDisplay() {
    const isChecked = this.importCheckboxTarget.checked
    const isSelected = this.selectTarget.querySelector('x-placeholder').childNodes.length > 1
      || this.selectTarget.querySelector('x-placeholder').childNodes[0].nodeType !== Node.TEXT_NODE

    this.selectedIconTarget.classList.toggle('hidden', !isChecked)
    this.unselectedIconTarget.classList.toggle('hidden', isChecked)

    this.data.set('checked', isChecked)
    this.data.set('selected', isSelected)

    this.triggerEvent()
  }

  check() {
    this.importCheckboxTarget.checked = true
    this.updateDisplay()
  }

  uncheck() {
    this.importCheckboxTarget.checked = false
    this.updateDisplay()
  }

  select({ currentTarget }) {
    if(!['date', 'birthday'].includes(currentTarget.dataset.kind) && this.chosenDateFormatButton) {
      this.removeClass(this.chosenDateFormatButton, 'bg-lavender-light')
    }
  }

  showDateFormatMenu({ currentTarget }) {
    if(!['date', 'birthday'].includes(currentTarget.dataset.kind)) {
      return this.hideDateFormatMenu()
    }

    this.hoveredElement = currentTarget

    this.floatingUICleanup = autoUpdate(currentTarget, this.dateFormatMenuTarget, () => {
      computePosition(currentTarget, this.dateFormatMenuTarget, {
        placement: 'right-end',
      }).then(({x, y}) => {
        Object.assign(this.element.style, {
          top: `${y}px`,
        })
      })
    })

    this.show(this.dateFormatMenuTarget)
  }

  hideDateFormatMenu() {
    if(!this.hasDateFormatMenuTarget) return

    this.hide(this.dateFormatMenuTarget)

    if(this.floatingUICleanup) {
      this.floatingUICleanup()
    }
  }

  selectFormatForDate({ currentTarget }) {
    this.hoveredElement.querySelector('x-placeholder-content p').innerHTML = currentTarget.dataset.value

    this.hoveredElement.click()
    this.hideDateFormatMenu()

    this.dateFormatTarget.value = currentTarget.dataset.value

    if(this.chosenDateFormatButton) {
      this.removeClass(this.chosenDateFormatButton, 'bg-lavender-light')
    }

    this.chosenDateFormatButton = currentTarget
    this.addClass(currentTarget, 'bg-lavender-light')
  }

  triggerEvent() {
    const event = new Event('import-map-item:change')
    this.targets.element.dispatchEvent(event);
  }
}
