import { Controller } from "@hotwired/stimulus"

import {useRemove} from "../mixins/useRemove"

export default class extends Controller {
  static targets = ["removedInput", "removeButton"]

  connect() {
    useRemove(this, {
      afterRemove: () => {
        if(this.hasRemovedInputTarget) {
          this.removedInputTarget.disabled = false
          document.getElementById("contact_form").appendChild(this.removedInputTarget)
        }
      }
    })
  }

  onContactIdentityRemove() {
    if(this.onlyIdentity) {
      this.removeButtonTarget.classList.add("hidden")
    }
  }

  disconnect() {
    this.dispatch("removed", {
      target: document.documentElement,
    })
  }

  // private

  get onlyIdentity() {
    return document.querySelectorAll("[data-contact-identity]").length === 1
  }
}
