import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    show: Boolean,
    remove: Boolean,
    backTo: String,
  }

  connect() {
    if (this.showValue) {
      setTimeout(() => {
        this._show()
      }, 0)
    } else {
      this.hide()
    }

    super.connect()
  }

  disconnect() {
    this.hide()
    super.disconnect()
  }

  show() {
    this.element.classList.replace("-right-full", "right-0")
  }

  _show() {
    this.element.classList.replace("-right-full", "right-0")
  }

  hide({ currentTarget } = {}) {
    this.element.classList.replace("right-0", "-right-full")

    this.element.querySelectorAll('[data-error-container]')
      .forEach((el) => el.innerHTML = '')

    if(currentTarget && currentTarget.dataset.backTo) {
      Turbo.visit(currentTarget.dataset.backTo, { action: 'replace', frame: 'pane-frame' })
    }

    if(this.removeValue) {
      setTimeout(() => {
        this.element.remove()
      }, 300)
    }
  }

  hideIfSuccess(e) {
    if (e.detail.success) {
      this.hide()
    }
  }

  get hidden() {
    return this.element.classList.contains("-right-full")
  }

  get visible() {
    return this.element.classList.contains("right-0")
  }

  get invisible() {
    return !this.visible
  }
}
