import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['item']

  filter({ detail: query }) {
    this.itemTargets.forEach((item) => {
      if (item.dataset.searchable.startsWith(query.toLowerCase())) {
        this.show(item)
        item.setAttribute('data-arrow-navigatable', '')
      } else {
        this.hide(item)
        item.removeAttribute('data-arrow-navigatable')
      }
    })

    if(this.visibleItems.length > 0) {
      this.show()

      this.dispatch('arrow:navigate', {
        target: this.element,
        detail: this.visibleItems[0]
      })
    } else {
      this.hide()
    }
  }

  saveMention({ currentTarget }) {
    if(!this.isEditing) {
      return this.dispatch('save', {
        detail: currentTarget
      })
    }

    if(this.editedUserId === currentTarget.dataset.id) {
      this.closeByEscape()
    } else {
      this.dispatch('update', {
        detail: {
          id: this.editedUserId,
          element: currentTarget
        }
      })
    }
  }

  edit({ detail: userId }) {
    this.itemTargets.forEach((item) => {
      this.show(item)
      item.setAttribute('data-arrow-navigatable', '')

      if(item.dataset.id === userId) {
        this.dispatch('arrow:navigate', {
          target: this.element,
          detail: item
        })
      }
    })

    this.isEditing = true
    this.editedUserId = userId

    this.element.setAttribute('data-arrow-navigation-enabled-value', true)
    this.show()
  }

  closeByEscape() {
    if(this.isInvisible() || !this.isEditing) return

    this.hide()
    this.dispatch('closed')

    this.isEditing = false
    this.editedUserId = null
  }

  // private

  get visibleItems() {
    return this.itemTargets.filter(this.isVisible)
  }
}
