import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    destroyUrl: String,
  }

  static targets = ['form']

  show({ detail }) {
    const { id, size, groupId } = detail

    if(size > 1) {
      this.element.querySelector('[data-modal-target="title"]').innerText = this.translations.track.events.modal.title.other.replace('%{count}', size)
    } else {
      this.element.querySelector('[data-modal-target="title"]').innerText = this.translations.track.events.modal.title.one
    }

    const url = new URL(this.destroyUrlValue.replace(':id', id))

    if(groupId) {
      url.searchParams.set('group_id', groupId)
    }

    this.element.querySelector('form').action = url.toString()

    this.dispatch('open', { target: this.element })
    this.id = id
  }

  hide({ detail: id }) {
    if(this.id === id) {
      this.dispatch('close', { target: this.element })
    }
  }
}
