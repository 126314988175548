import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  requestTurboStream({ currentTarget }) {
    get(currentTarget.dataset.url, {
      responseKind: "turbo-stream",
    })
  }

  get() {
    get(this.data.get("url"), {
      responseKind: "turbo-stream",
    })
  }
}
