import PaneController from "../../journeys/pane_controller"

export default class extends PaneController {
  static targets = ["input"]
  static values = {
    periodKind: { type: String, default: "Minutes" },
  }

  initialize() {
    super.initialize()
    this.inputValue = this.inputTarget.value
    this.tempPeriodKind = this.periodKindValue
  }

  sync(commit = false) {
    this.dispatch("sync", {
      detail: {
        period: commit ? this.inputValue : this.inputTarget.value,
        kind: commit ? this.periodKindValue : this.tempPeriodKind,
        stepId: this.stepIdValue,
        commit,
      },
      target: document.documentElement,
    })
  }

  changePeriod(periodicitySelectEvent) {
    this.tempPeriodKind = periodicitySelectEvent.detail
    this.syncValues()
  }

  saveAndHide() {
    this.clearQueue()

    this.element.setAttribute('data-saved', 'true')

    this.inputValue = this.inputTarget.value
    this.periodKindValue = this.tempPeriodKind
    this.sync(true)
    this._hide()
  }

  cancel() {
    this._abort()
    this._hide()
    this._resetToLastSavedState()

    setTimeout(this.clearQueue, 1000)
  }

  syncValues() {
    if (this.hasUnsavedChanges) {
      window.paneId = this.element.id
    }
  }

  afterShow() {
   this.focusInput()
  }

  focusInput() {
    this.focus(this.inputTarget, { moveCursorToEnd: true })
  }

  _abort() {
    this.dispatch("sync:abort", {
      detail: this.stepIdValue,
      target: document.documentElement,
    })

    super._abort()
  }

  hidePane(e) {
    if (
      this.element.contains(e.target) ||
      Array.from(document.querySelectorAll("#toast")).some((toast) =>
        toast.contains(e.target)
      ) ||
      this.invisible ||
      e.target.id.split("_trigger")[0] === this.stepIdValue
    )
      return

    if(this.element.hasAttribute('data-saved')) {
      super.hidePane(e)
    } else if (this.inputTarget.value.trim().length === 0) {
      this.openModalButtonTarget.click()
    } else {
      this.saveAndHide()
    }
  }

  _resetToLastSavedState() {
    this.inputTarget.value = this.inputValue

    this.dispatch("periodicity-select:set", {
      target: this.element.querySelector(
        "[data-controller*='periodicity-select']"
      ),
      detail: this.periodKindValue,
    })

    this.tempPeriodKind = this.periodKindValue.toLowerCase()
  }

  get hasUnsavedChanges() {
    return (
      this.inputValue !== this.inputTarget.value ||
      this.periodKindValue.toLowerCase() !== this.tempPeriodKind.toLowerCase()
    )
  }
}
