import { Toggle } from 'tailwindcss-stimulus-components'

export default class extends Toggle {
  animate() {
    this.toggleableTargets.forEach((toggleable) => {
      const toggleClass = toggleable.dataset.toggleClass || 'hidden'

      if(toggleable.classList.contains(toggleClass)) {
        toggleable.classList.remove(toggleClass)
      } else {
        toggleable.classList.add(toggleClass)
      }
    })
  }
}
