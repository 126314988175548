import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  async display(e) {
    await get(e.target.dataset.url, {
      responseKind: "turbo-stream",
    })

    const event = new CustomEvent("contactable:show")
    window.dispatchEvent(event)
  }
}
