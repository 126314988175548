import ApplicationController from '../application_controller'
import { useRemove } from "../mixins/useRemove";

export default class extends ApplicationController {
  static targets = ["add", "remove", "actions", "customEmailInput", "select"]

  connect() {
    useRemove(this)
  }

  displayAddButton() {
    this.removeClass(this.actionsTarget, "invisible")
  }

  displayRemoveButton() {
    this.show(this.removeTarget)
    this.hide(this.addTarget)
  }

  enableCustomInput() {
    this.enable(this.customEmailInputTarget)
  }

  check({ detail: optionValue }) {
    this.dispatch("new-option-valid", {
      target: this.selectTarget,
      detail: this.checkIfEmail(optionValue)
    })
  }

  checkIfEmail(str) {
    const regexExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
    return regexExp.test(str);
  }
}
