export const useMoveCursorToEndOfInput = (controller) => {
  Object.assign(controller, {
    moveCursorToEndOfInput(input, { focus = false }) {
      input.selectionStart = input.selectionEnd = 10000

      if (focus) {
        input.focus()
      }

      return Promise.resolve()
    },
  })
}
