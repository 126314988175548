import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    name: String,
    id: String,
    subscribersCount: Number,
    resetOnClick: Boolean,
    included: Boolean,
    type: String,
  }

  select() {
    let event = new CustomEvent("audience-selected", {
      bubbles: true,
      cancelable: true,
    })

    event.element = {
      subscribersCount: this.subscribersCountValue,
      id: this.idValue,
      name: this.nameValue,
      resetOnClick: this.resetOnClickValue,
      include: this.includedValue,
      type: this.typeValue,
    }

    if (this.nameValue === "All Audience") {
      const event = new CustomEvent("all-audience-selected")
      event.subscribersCount = this.subscribersCountValue
      event.resetOnClick = this.resetOnClickValue
      event.name = "All Audience"
      window.dispatchEvent(event)
    } else {
      this.element.dispatchEvent(event)
    }
  }
}
