import { patch } from '@rails/request.js'

import ModalController from '../modal_controller'

export default class extends ModalController {
  static values = {
    url: String,
  }

  static targets = ['checkbox', 'submit', 'duplicateId', 'duplicateType', 'duplicate']

  onCheckboxChange() {
    this.submitTarget.disabled = this.checkboxTargets.every(checkbox => !checkbox.checked)
  }

  duplicateIdTargetConnected() {
    this.duplicateId = this.duplicateIdTarget.dataset.value
    this.duplicateIdTarget.remove()
  }

  duplicateTypeTargetConnected() {
    this.type = this.duplicateTypeTarget.dataset.value
  }

  async submit({ currentTarget }) {
    this.backdropCloseValue = false

    currentTarget.disabled = this.closeButtonTarget.disabled = true
    currentTarget.querySelector('[data-text]').innerText = currentTarget.dataset.disableWith

    const formData = new FormData()

    formData.append('commit', 'merge')
    formData.append('klass', this.type)

    this.checkboxTargets.forEach(checkbox => {
      if (checkbox.checked) {
        formData.append(checkbox.name, checkbox.value)
      }
    })

    await patch(this.urlValue.replace(':id', this.duplicateId), {
      body: formData,
      responseKind: 'turbo-stream',
    })

    currentTarget.disabled = this.closeButtonTarget.disabled = false
    currentTarget.querySelector('[data-text]').innerText = t.contacts.merge.commit

    this.backdropCloseValue = false
    this.type = null
  }

  duplicateTargetDisconnected() {
    this.type = null
    this.close()
  }

  checkboxTargetConnected() {
    this.onCheckboxChange()
  }
}
