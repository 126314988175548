import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    hour: { type: String, default: '10:00' },
    date: { type: String, default: '' }
  }

  static targets = [
    'commitInput',
    'customSnoozeSubmitButton'
  ]

  submit({ currentTarget }) {
    this.commitInputTarget.value = currentTarget.dataset.value
    this.element.requestSubmit()

    // this.element.setAttribute('data-dropdown-open-value', false)

    if(!currentTarget.dataset.value) {
      this.dispatch('custom')
    }
  }

  enable({ target }) {
    this.dateValue = target.value
  }

  sync({ detail: value }) {
    console.log(value, 'is value')
    this.hourValue = value
  }

  dateValueChanged() {
    this.customSnoozeSubmitButtonTarget.disabled = !this.hourValue || !this.dateValue
  }

  hourValueChanged() {
    this.customSnoozeSubmitButtonTarget.disabled = !this.hourValue || !this.dateValue
  }
}
