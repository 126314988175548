import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    valid: Boolean
  }

  static targets = [
    'between',
    'minInput',
    'maxInput'
  ]

  showBetween() {
    this.show(this.betweenTarget)

    if(this.minInputTarget.value) {
      this.focus(this.maxInputTarget, { moveCursorToEnd: true })
    } else {
      this.focus(this.minInputTarget, { moveCursorToEnd: true })
    }

    this.sync()
  }

  hideBetween() {
    this.hide(this.betweenTarget)
    this.focus(this.minInputTarget, { moveCursorToEnd: true })

    this.sync()
  }

  sync() {
    if(this.isVisible(this.betweenTarget)) {
      this.validValue = this.minInputTarget.value.trim().length > 0 && this.maxInputTarget.value.trim().length > 0
    } else {
      this.validValue = this.minInputTarget.value.trim().length > 0
    }
  }

  validValueChanged() {
    this.element.setAttribute('data-valid', this.validValue)

    this.dispatch('validation', {
      detail: this.validValue,
    })
  }
}
