import PaneController from "../../journeys/pane_controller"

export default class extends PaneController {
  static values = {
    targetTechnologies: Array,
    body: String
  }

  static targets = ['compose', 'submit']

  initialize() {
    super.initialize()

    this.state = {
      body: this.bodyValue,
      maxMessageLength: this.maxMessageLength,
      technologies: [],
      attachments: [],
    }

    this.changedState = { ...this.state }
  }

  connect() {
    super.connect()
    this.elementSnapshot = this.element.cloneNode(true)
  }

  onComposeInitialization({ detail: snapshot }) {
    this.state.body = snapshot.string
    this.state.technologies = snapshot.technologies
    this.state.maxMessageLength = snapshot.maxMessageLength
    this.state.footer = snapshot.footer

    this.state.latitude = snapshot.location?.lat
    this.state.longitude = snapshot.location?.lng

    this.state.attachments = snapshot.attachments

    this.snapshot = this.changedSnapshot = snapshot

    this.changedState = {
      ...this.changedState,
      ...this.state
    }
  }

  onButtonsInitialization({ detail }) {
    this.state.buttons = { ...detail }
    this.changedState.buttons = { ...detail }
  }

  onContentValueChange({ detail }) {
    const { string } = detail

    this.changedState.body = string
    this.changedState.footer = detail.footer

    this.changedState.latitude = detail.location?.lat
    this.changedState.longitude = detail.location?.lng
    this.changedState.buttons = { ...detail.buttons }

    this.changedState.attachments = detail.attachments
    this.changedSnapshot = detail

    this.submitTarget.disabled = string.length === 0
  }

  onMessageLengthChange({ detail }) {
    const { maxMessageLength } = detail
    this.changedState.maxMessageLength = maxMessageLength

    if(!this.state.maxMessageLength) {
      this.state.maxMessageLength = maxMessageLength
    }
  }

  onTargetTechnologiesChange({ detail }) {
    this.technologyNames = detail.names
    this.changedState.technologies = detail.ids
    this.submitTarget.disabled = detail.length === 0
  }

  save() {
    const { template, templateChanged } = this.changedSnapshot

    if(template && ['medium', 'low'].includes(template.quality) && !templateChanged) {
      this.dispatch('show', {
        target: this.element,
        detail: template.quality,
      })
    } else {
      this.saveAndHide()
    }
  }

  saveAndHide() {
    if(this.technologyNames.length === 1 && this.technologyNames[0] === 'sms') {
      delete this.changedSnapshot.buttons
      delete this.changedState.buttons

      this.changedSnapshot.attachments = this.changedState.attachments = []
      this.changedSnapshot.location = this.changedState.location = null

      this.changedSnapshot.footer = this.changedState.footer = ''

      this.dispatch('clear', {target: this.composeTarget})
    }

    this.state = { ...this.changedState }

    this.sync(true)
    this._hide()

    this.clearQueue(false)

    this.state = { ...this.changedState }

    this.snapshot = {
      ...this.changedSnapshot,
      technologies: this.changedState.technologies
    }

    this.elementSnapshot = this.element.cloneNode(true)
  }

  get hasUnsavedChanges() {
    return this.changedState.body !== this.state.body
      || this.changedState.maxMessageLength !== this.state.maxMessageLength
      || this.changedState.footer !== this.state.footer
      || this.targetTechnologiesChanged
      || this.changedState.latitude !== this.state.latitude
      || this.changedState.longitude !== this.state.longitude
      || this.buttonsChanged
      || this.changedState.attachments.length !== this.state.attachments.length || this.changedState.attachments.some((attachment, index) => this.state.attachments[index] !== attachment)
  }

  sync(commit = false) {
    this.dispatch("sync", {
      detail: {
        stepId: this.stepIdValue,
        body: this.state.body,
        smsCount: 1,
        smsLength: this.maxMessageLength,
        coupon: this.coupon,
        commit,
        pieces: this.changedSnapshot.pieces,
        ...this.changedSnapshot,
      },
      target: document.documentElement,
    })
  }

  syncValues() {
    if (this.hasUnsavedChanges) {
      window.paneId = this.element.id
    }
  }

  cancel() {
    this._abort()
    this._hide()

    setTimeout(this.clearQueue, 1000)
  }

  afterShow() {
    this.dispatch('focus', {
      target: this.composeTarget
    })
  }

  _abort() {
    this.dispatch("sync:abort", {
      detail: this.stepIdValue,
      target: document.documentElement,
    })

    this.element.querySelector('[data-close-modal]').click()

    this.delayed(() => {
      this.element.replaceWith(this.elementSnapshot)
    }, 300)
  }

  onEnterKeydown({ detail: snapshot }) {
    if(snapshot.string) {
      this.saveAndHide()
    }
  }

  editTemplate() {
    this.nextTick(() => {
      this.dispatch('focus', {
        target: this.composeTarget
      })
    },)
  }

  hidePane(e) {
    if (
      this.element.contains(e.target) ||
      Array.from(document.querySelectorAll("#toast")).some((toast) =>
        toast.contains(e.target)
      ) ||
      this.invisible ||
      e.target.id.split("_trigger")[0] === this.stepIdValue ||
      this.composeTarget.contains(document.activeElement)
    )
      return

    if (this.hasUnsavedChanges) {
      this.openModalButtonTarget.click()
      e.preventDefault()
      window.paneId = this.element.id
    } else {
      this._hide()
      this.cancel()
      this.clearQueue()
    }
  }

  get targetTechnologiesChanged() {
    return this.state.technologies !== this.changedState.technologies
  }

  get buttonsChanged() {
    const changedButtons = Object.values(this.changedState.buttons || {}).map(({ buttons }) => buttons).flat()
    const currentButtons  = Object.values(this.state.buttons || {}).map(({ buttons }) => buttons).flat()

    return changedButtons.length !== currentButtons.length || changedButtons
      .some((button, index) => { return button !== changedButtons[index] })
  }
}
