import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    triggerId: String,
    url: String,
    orderFilter: String,
  }

  static targets = ["orderFilter", "select", "inclusion"]

  connect() {
    this.trigger = window.triggerId
  }

  async checkAncestry({ detail: newTrigger }) {
    if(!newTrigger) return

    await get(this.urlValue, {
      responseKind: "turbo-stream",
      query: {
        condition_trigger_id: this.triggerIdValue,
        new_trigger_id: newTrigger || this.trigger,
        ...(this.isAfterWaitStep && { wait: true }),
      },
    })
  }

  onWaitStepSaved() {
    this.checkAncestry({ detail: undefined })
  }

  onStepRemoved({ detail }) {
    if(detail.kind === "wait") {
      this.checkAncestry({ detail: undefined })
    }
  }

  disableRangeFields() {
    this.element.querySelectorAll("[data-condition-range] input")
      .forEach((input) => input.disabled = true )
  }

  enableRangeFields() {
    this.element.querySelectorAll("[data-condition-range] input")
      .forEach((input) => input.disabled = false )
  }

  disableOrderFilter() {
    this.dispatch("disableDropdown", {
      target: this.orderFilterTarget,
      detail: this.orderFilterValue,
    })
  }

  enableOrderFilter() {
    this.dispatch("enableDropdown", {
      target: this.orderFilterTarget
    })
  }

  switchToCharacteristic() {
    this.selectTarget.querySelector("select").name = this.selectTarget.querySelector("select").name.replace("activity_", "characteristic_")
    this.inclusionTarget.name = this.inclusionTarget.name.replace("activity_", "characteristic_")
  }

  switchToActivity() {
    this.selectTarget.querySelector("select").name = this.selectTarget.querySelector("select").name.replace("characteristic_", "activity_")
    this.inclusionTarget.name = this.inclusionTarget.name.replace("characteristic_", "activity_")
  }

  // private

  get isAfterWaitStep() {
    return Array.from(document.querySelectorAll("[data-step='wait']")).some(
      (waitStep) => waitStep.parentElement.contains(this.element)
    )
  }
}
