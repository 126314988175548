import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    type: { type: String, default: 'name' },
  }

  static targets = [
    'filter',
    'filterOption',
  ]

  setFilter({ currentTarget }) {
    this.typeValue = currentTarget.dataset.optionValue

    this.filterTargets.forEach((filter) => {
      if(filter.dataset.type === this.typeValue) {
        this.show(filter)

        this.dispatch('show', {
          target: filter
        })
      } else {
        this.hide(filter)

        this.dispatch('hide', {
          target: filter
        })
      }
    })
  }

  focus() {
    const filter = this.filterTargets.find(filter => filter.dataset.type === this.typeValue)

    this.dispatch('focus', {
      target: filter
    })
  }

  saveState() {
    this.state = {
      type: this.typeValue
    }

    const filter = this.filterTargets.find(filter => filter.dataset.type === this.typeValue)

    this.dispatch('state:save', {
      target: filter
    })
  }

  restoreState() {
    if(!this.state) return

    this.typeValue = this.state.type

    this.filterTargets.forEach((filter) => {
      if(filter.dataset.type === this.state.type) {
        this.show(filter)
      } else {
        this.hide(filter)
      }

      this.dispatch('state:restore', {
        target: filter
      })
    })

    this.filterOptionTargets.find(option => option.dataset.optionValue === this.state.type).click()
  }
}
