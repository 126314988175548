import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ["template", "content", 'email']

  add() {
    const clonedNode = this.templateTarget.cloneNode(true)
    clonedNode.classList.remove("hidden")
    clonedNode.setAttribute("data-notification--emails-target", 'email')
    clonedNode.removeAttribute("hidden")

    this.contentTarget.appendChild(clonedNode)
  }

  displayAddButtonForNewLastItem() {
    const items = this.emailTargets.filter(item => item !== this.templateTarget)

    const lastItem = items[items.length - 2]
    const addButton = lastItem.querySelector("[data-notification--email-target='add']")
    const removeButton = lastItem.querySelector("[data-notification--email-target='remove']")

    if(items.length === 2) {
      this.hide(removeButton)
    }

    this.show(addButton)
  }
}
