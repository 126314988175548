import {Modal} from "tailwindcss-stimulus-components"

export default class extends Modal {
  open(e) {
    super.open(e)

    // give time for the modal to open before setting this value
    setTimeout(() => this.elementId = e.detail, 1)
  }

  close() {
    super.close()

    if(this.elementId) {
      this.dispatch("focus", {
        target: document.getElementById(this.elementId)
      })

      this.elementId = null
    }
  }

  destroy() {
    this.dispatch("destroy", {
      target: document.getElementById(this.elementId)
    })

    this.elementId = null
  }

  onModalOutsideClick(e) {
    if(!this.dialogTarget.open || this.modalCard.contains(e.target)) return

    if(this.elementId) {
     this.close()
    }
  }

  // private

  get modalCard() {
    return this.dialogTarget.querySelector("[data-modal-card]")
  }
}
