import { get } from '@rails/request.js'

import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static values = {
    technologies: Array,
    url: String,
    query: String
  }

  static targets = ["template", "skeletonsList", "list"]

  initialize() {
    super.initialize()
    this.activeIndex = 0
  }

  removeInvisibilityClasses() {
    this.removeClass(this.element,"invisible")
    this.element.classList.add("h-[20rem]")
    this.element.setAttribute('data-visible', '')
  }

  show(e) {
    const detail = e.type === "chat:templates:show" ? e.detail.detail : e.detail

    this.removeInvisibilityClasses()

    if(detail !== this.queryValue) {
      clearTimeout(this.searchTimeout)

      this.queryValue = detail
      this.search()
    }
  }

  hide() {
    super.hide(...this.templateTargets)

    this.addClass(this.element,"invisible")
    this.element.classList.remove("h-[20rem]")

    this.element.removeAttribute('data-visible')

    this.nextTick(() => {
      super.show(...this.templateTargets)
    })
  }

  onTemplateSelection({ currentTarget: templateElement }) {
    this.dispatchSelectedEvent(templateElement, {
      method: 'click'
    })
  }

  onTechnologyChange({ detail }) {
    this.technologiesValue = [detail.technology.id]
    this.search()
  }

  toggle(event) {
    if(event.detail) {
      this.removeInvisibilityClasses()
    } else {
      this.hide()
    }
  }

  close() {
    if(this.visible) {
      this.hide()

      this.dispatch('closed-by-escape')
    }
  }

  onArrowUp(e) {
    if(this.invisible) return;

    e.preventDefault()
    e.stopPropagation()

    this.activeIndex -= 1
    this.updateHighlightedElement({ scrollIntoView: true })
  }

  onArrowDown(e) {
    if(this.invisible) return;

    e.preventDefault()
    e.stopPropagation()

    this.activeIndex += 1
    this.updateHighlightedElement({ scrollIntoView: true })
  }

  onTemplateHover({ currentTarget }) {
    if(this.invisible) return

    this.activeIndex = this.templateTargets.indexOf(currentTarget)
    this.updateHighlightedElement({ scrollIntoView: false })
  }

  onEnter(e) {
    if(this.invisible) return;

    e.preventDefault()
    e.stopPropagation()

    const template = this.templateTargets[this.activeIndex]

    if(template) {
      this.dispatchSelectedEvent(template, {
        method: 'enter'
      })
    }
  }

  // private

  dispatchSelectedEvent(template, { method }) {
    const payload = JSON.parse(template.dataset.payload)

    this.dispatch("selected",
      {
        detail: {
          ...payload,
          selectionMethod: method,
          coupon: payload.coupon ? JSON.parse(payload.coupon) : null,
          element: template
        }
      }
    )
  }

  async search() {
    this.searchTimeout = setTimeout(async () => {
      if(this.queryValue) {
        super.hide(this.listTarget)
        super.show(this.skeletonsListTarget)
      }

      await get(this.urlValue, {
        responseKind: 'turbo-stream',
        query: {
          technologies: this.technologiesValue.join(','),
          search: this.queryValue.trim().replace(/\u200C/g, "")
        }
      })

      super.hide(this.skeletonsListTarget)
      super.show(this.listTarget)

      this.activeIndex = 0
    }, 300)
  }

  updateHighlightedElement({ scrollIntoView }) {
    if(this.activeIndex < 0) {
      this.activeIndex = this.templateTargets.length - 1
    } else if(this.activeIndex >= this.templateTargets.length) {
      this.activeIndex = 0
    }

    this.templateTargets.forEach((element, index) => {
      if(index === this.activeIndex) {
        this.addClass(element, ...this.activeClasses)

        element.setAttribute('data-active', '')

        if(scrollIntoView) {
          element.scrollIntoView(false)
        }
      } else {
        this.removeClass(element, ...this.activeClasses)
        this.element.removeAttribute('data-active')
      }
    })
  }

  get visible() {
    return this.element.hasAttribute('data-visible')
  }

  get invisible() {
    return !this.visible
  }

  get activeClasses() {
    return ['bg-night-5', 'border-l-tiger']
  }
}
