import {get, post} from "@rails/request.js";

import TagsController from "./tags_controller"

export default class extends TagsController {
  static targets = ["list", "attachedList"]

  async save({currentTarget: label}) {
    const options = {
      responseKind: "turbo-stream",
      body: {
        id: label.dataset.value,
        mode: this.element.closest('[data-mode]')?.dataset.mode
      }
    }

    const url = this.persistedValue ? this.labelingUrlValue : this.urlValue
    post(url, options)
  }

  async saveNewList({currentTarget: newList}) {
    const options = {
      responseKind: "turbo-stream",
      body: {
        list: { name: newList.dataset.value },
        mode: this.element.closest('[data-mode]')?.dataset.mode
      }
    }

    post(this.urlValue, options)
  }

  removeItem(e) {
    super.removeItem(e, '[data-property--list-target="item"]')
  }

  resetTargetConnected() {
    this.inputTarget.value = ""
    this.inputTarget.focus()

    this.resetTarget.remove()
  }

  async onInputEnter() {
    const options = {
      responseKind: "turbo-stream",
      body: {
        list: { name: this.inputTarget.value },
        mode: this.element.closest('[data-mode]')?.dataset.mode
      }
    }

    await post(this.urlValue, options)
    this.refocus()
  }
}
