import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["highest", "lowest", "chart"]

  connect() {
    super.connect()

    setTimeout(() => {
      this.chartTargets.forEach((chart) => {
        chart.classList.remove("opacity-0")

        if (chart.dataset.heightClass) {
          chart.classList.add(chart.dataset.heightClass)
        } else {
          chart.style.height = `${chart.dataset.heightStyle}`
        }
      })
    }, 200)
  }
}
