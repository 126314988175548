import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["label", "component", "option"]

  show({ detail: optionValue }) {
    this.labelTarget.innerText = optionValue
    this.element.classList.remove("hidden")

    this.optionTarget.setAttribute("data-option-value", optionValue)
    this.optionTarget.setAttribute("data-placeholder", optionValue)
  }
}
