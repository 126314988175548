import { Controller } from "stimulus"

import { useRemove } from "./mixins/useRemove"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["removedInput"]

  initialize() {
    this.markAsRemoved = this.markAsRemoved.bind(this)
    this.parentFrame = this.element.closest("turbo-frame")
    this.parentForm = this.element.closest("form")
  }

  connect() {
    useRemove(this, {
      afterRemove: this.markAsRemoved,
      stopEventPropagation: true,
    })
  }

  appendNewCondition({ currentTarget }) {
    get(currentTarget.dataset.url, {
      responseKind: "turbo-stream",
    })
  }

  markAsRemoveWithoutTurboFrame() {
    if (this.hasRemovedInputTarget) {
      this.removedInputTarget.disabled = false
      this.parentForm.appendChild(this.removedInputTarget)
    }
  }

  // private

  get withinTurboFrame() {
    return this.parentFrame?.tagName === "TURBO-FRAME"
  }

  markAsRemoved() {
    if (this.hasRemovedInputTarget) {
      this.removedInputTarget.disabled = false
      this.parentForm.appendChild(this.removedInputTarget)
    }

    if (this.withinTurboFrame) {
      this.parentFrame.remove()
    }
  }
}
