import Application from '../application_controller'

export default class extends Application {
  static targets = ['list', 'attachment']

  initialize() {
    super.initialize()
    this.attachmentsMutationObserver = new MutationObserver(this.attachmentsChanged.bind(this))
  }

  connect() {
    super.connect()
    this.attachmentsMutationObserver.observe(this.listTarget, {childList: true})
  }

  disconnect() {
    this.attachmentsMutationObserver.disconnect()
    super.disconnect()
  }

  attachmentsChanged() {
    if (this.listTarget.children.length === 0) {
      this.addClass(this.element, 'hidden')
    } else {
      this.removeClass(this.element, 'hidden')
    }
  }

  enable() {
    this.attachmentTargets.forEach((attachment) => {
      this.removeClass(attachment, 'opacity-40')
    })
  }

  disable() {
    this.attachmentTargets.forEach((attachment) => {
      this.addClass(attachment, 'opacity-40')
    })
  }
}
