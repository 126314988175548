import { Controller } from "@hotwired/stimulus"
import { useRemove } from "../../mixins/useRemove"
import { useDraggable } from "../../mixins/useDraggable"

export default class extends Controller {
  static values = {
    removeable: Boolean,
    placeholder: String,
    stepId: String,
  }

  static targets = [
    "orderInput",
    "typeInput",
    "requiredInput",
    "requiredInputContainer",
    "input",
    "placeholderInput",
    "removeContainer",
    "menu",
    "reorderButton",
  ]
  static classes = ["drag"]

  initialize() {
    this.mouseEnterListener = this.mouseEnterListener.bind(this)
    this.mouseLeaveListener = this.mouseLeaveListener.bind(this)

    if (!this.removeable) {
      this.reorderButtonTarget.classList.add("hidden")
    }
  }

  connect() {
    useRemove(this, {
      afterRemove: () => {
        // drag_controller destroys and recreate the records behind the scenes when an element is being reordered
        // causing disconnect() to be invoked when we don't want to
        // we only want to dispatch these events when the Trash icon was clicked
        document
          .querySelectorAll(`[data-field-id='${this.element.dataset.fieldId}']`)
          .forEach((field) => field.remove())

        this.dispatch("removed", {
          target: document.getElementById(this.stepIdValue),
          detail: this.element.dataset.fieldId,
        })
      },
    })

    this.inputTarget.style.fontSize = `${
      window.popupControls.font.base * this.inputTarget.dataset.size
    }px`

    useDraggable(this)

    if (this.element.previousElementSibling) {
      this.previousSibling = this.element.previousElementSibling
      this.dispatch("connected", {
        target: this.element.previousElementSibling,
      })
    }

    this.dispatch("connected", {
      target: document.getElementById(this.stepIdValue),
      detail: this.toObject
    })

    this.element.addEventListener("mouseenter", this.mouseEnterListener)
    this.element.addEventListener("mouseleave", this.mouseLeaveListener)

    if (this.element.nextElementSibling) {
      this.nextElement = this.element.nextElementSibling
    }
  }

  disconnect() {
    this.element.removeEventListener("mouseenter", this.mouseEnterListener)
    this.element.removeEventListener("mouseleave", this.mouseLeaveListener)

    if (this.previousSibling) {
      this.dispatch("disconnected", {
        target: this.previousSibling,
      })
    }

    if (this.nextElement) {
      this.dispatch("disconnected", {
        target: this.nextElement,
      })
    }
  }

  get draggable() {
    return this.inputTarget
  }

  afterDragEnabled() {
    this.element.classList.remove("not-draggable")
  }

  afterDragDisabled() {
    this.changeStateToNotDraggable()
  }

  onDragEnd({ detail: newIndex }) {
    this.orderInputTarget.value = newIndex
    this.draggable.classList.remove(...this.dragClasses)
    this.changeStateToNotDraggable()

    this.dispatchSyncEvent()

    Array.from(this.element.parentElement.children).forEach((li, index) => {
      if (li !== this.element) {
        this.dispatch("drag-end", {
          target: li,
          detail: index + 1,
        })
      }
    })
  }

  incrementOrder({ detail: newOrder }) {
    this.orderInputTarget.value = newOrder
    this.dispatchSyncEvent()
  }

  focus() {
    this.placeholderInputTarget.selectionStart =
      this.placeholderInputTarget.selectionEnd =
        this.placeholderInputTarget.value.length
    this.placeholderInputTarget.focus()
  }

  allowRemove() {
    this.removeContainerTarget.classList.remove("hidden")
    this.requiredInputContainerTarget.classList.remove("hidden")

    this.nextElement = this.element.nextElementSibling
    this.element.classList.remove("not-draggable")
    this.element.addEventListener("mouseenter", this.mouseEnterListener)
    this.element.addEventListener("mouseleave", this.mouseLeaveListener)
  }

  disallowRemoveIfOnlyField() {
    if (this.onlyField) {
      this.removeContainerTarget.classList.add("hidden")
      this.requiredInputContainerTarget.classList.add("hidden")

      this.element.classList.add("not-draggable")
    }
  }

  mouseEnterListener() {
    if (
      this.element.nextElementSibling ||
      (this.element.previousElementSibling &&
        this.reorderButtonTarget.classList.contains("hidden"))
    ) {
      this.reorderButtonTarget.classList.remove("hidden")
    }
  }

  mouseLeaveListener() {
    if (
      this.element.nextElementSibling ||
      (this.element.previousElementSibling && !this.dragging)
    ) {
      this.reorderButtonTarget.classList.add("hidden")
    }
  }

  changePlaceholder(e) {
    this.inputTarget.innerText = e.target.value || this.placeholderValue
  }

  saveChanges(e) {
    if (
      this.menuTarget.contains(e.target) ||
      this.menuTarget.classList.contains("hidden")
    ) {
      return
    }

    if (this.placeholderInputTarget.value.trim().length === 0) {
      this.inputTarget.innerText = this.placeholderInputTarget.value =
        this.placeholderValue
    } else {
      this.placeholderValue = this.placeholderInputTarget.value
    }

    this.changeStateToNotDraggable()
    this.dispatchSyncEvent()
  }

  replaceWithSavedVersion({ detail }) {
    console.log(detail)

    this.inputTarget.innerText =
      this.placeholderInputTarget.value =
      this.placeholderValue =
        detail.placeholder
    this.orderInputTarget.value = detail.order
    this.requiredInputTarget.checked = detail.required

    this.element.querySelector(`[data-type='${detail.type}']`).click()
  }

  changeType({ currentTarget }) {
    this.typeInputTarget.value = currentTarget.dataset.type
    this.placeholderValue =
      this.inputTarget.innerText =
      this.placeholderInputTarget.value =
        currentTarget.dataset.typePlaceholder
  }

  onRequiredChange() {
    this.dispatchSyncEvent()
  }

  // private

  changeStateToNotDraggable() {
    this.draggable.classList.remove(...this.dragClasses)
    this.element.classList.add("not-draggable")
    this.reorderButtonTarget.classList.add("hidden")
  }

  dispatchSyncEvent() {
    this.dispatch("sync", {
      target: this.sameElementInOtherView,
      detail: this.toObject,
    })
  }

  get sameElementInOtherView() {
    return Array.from(
      document.querySelectorAll(
        `[data-field-id='${this.element.dataset.fieldId}']`
      )
    ).find((field) => field !== this.element)
  }

  get toObject() {
    return {
      order: this.orderInputTarget.value,
      type: this.typeInputTarget.value,
      placeholder: this.placeholderValue,
      required: this.requiredInputTarget.checked,
      id: this.element.dataset.fieldId,
    }
  }

  get onlyField() {
    return (
      !this.element.nextElementSibling || !this.element.previousElementSibling
    )
  }

  get hasUnsavedChanges() {
    this.inputTarget.value.trim()
  }
}
