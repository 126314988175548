import { Controller } from "@hotwired/stimulus"
import {get} from "@rails/request.js";

export default class extends Controller {
  static values = {
    url: String,
    debounce: { type: Number, default: 300 }
  }

  static targets = ["input", "menu", "spinner"]

  search() {
    clearTimeout(this.searchTimeout)

    this.searchTimeout = setTimeout(async () => {
      // this.menuTarget.classList.add("invisible")
      this.spinnerTarget.classList.remove("hidden")

      await get(this.urlValue, {
        query: {
          search: this.inputTarget.value
        },
        responseKind: "turbo-stream"
      })

      this.spinnerTarget.classList.add("hidden")
    }, this.debounceValue)
  }
}
