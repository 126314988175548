import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    disabled: Boolean,
  }

  static targets = [
    'rechargeAmountInput',
  ]

  toggleAutoRecharge(e) {
    this.element.querySelectorAll('input')
      .forEach((el) => {
        if (el.dataset.disableable === "true") {
          el.disabled = !this.disabledValue
        }
      })

    this.disabledValue = !this.disabledValue
  }

  disabledValueChanged() {
    if(!this.disabledValue) {
      this.focus(this.rechargeAmountInputTarget, { moveCursorToEnd: true })
    }

    if(this.disabledValue) {
      this.addClass(this.element, 'text-night-60')
    } else {
      this.removeClass(this.element, 'text-night-60')
    }
  }
}
