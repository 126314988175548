import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["required"]
  static values = {
    errorClass: String,
    defaultClass: String,
  }

  attemptSubmit(e) {
    if (this.requiredTarget.value.trim().length === 0) {
      this.requiredTarget.classList.replace(
        this.defaultClassValue,
        this.errorClassValue
      )
      e.preventDefault()
    }
  }
}
