import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    timezone: String
  }

  static targets = ["date", "dateContent", "hour", "hourContent", "timeInput"]

  connect() {
   setTimeout(() => {this.setDateForTime()}, 1)
  }

  updateTime(event) {
    const [hour, minute] = (event?.detail || this.hourTarget.value).split(":")
    this.timeInputTarget.value = new Date(Date.parse(`${this.dateTarget.value} ${hour}:${minute}:00`))

    this.hourContentTarget.innerHTML = `${hour}:${minute}`
  }

  setDateForTime() {
    this.updateTime()
    const [hour, minute] = this.hourTarget.value.split(":")

    this.dateContentTarget.innerHTML =  new Intl.DateTimeFormat(this.userLocale, {
      weekday: "long",
      month: "long",
      day: "numeric",
      timeZone: this.timezoneValue,
    }).format(new Date(Date.parse(`${this.dateTarget.value} ${hour}:${minute}:00`)))
  }

  // private

  get userLocale() {
    return document.querySelector('meta[name="user-locale"]').getAttribute("value")
  }
}
