import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "mobileLayout",
    "desktopLayout",
    "image",
    "uploadImageButton",
    "imageControls",
    "fileInput",
    "header",
    "fitButtonContainer",
    "fillButtonContainer",
    "backgroundButton",
    "removedStepInput",
  ]

  connect() {
    this.imageValue = {}

    if (window.popup?.headerImage) {
      this.setHeaderBackgroundImage(window.popup.headerImage.path)

      if (window.popup.headerImage.fit === "cover") {
        this.setImageFitToCover()
      } else {
        this.setImageFitToContain()
      }
    }

    if (window.popupSteps) {
      window.popupSteps[this.element.id] = {}
    } else {
      window.popupSteps = {
        [this.element.id]: {},
      }
    }

    if(window.fontFamily) {
      this.element.style.fontFamily = window.fontFamily
    }
    this.dispatch("changed")
  }

  disconnect() {
    if (this.hasRemovedStepInputTarget) {
      this.removedStepInputTarget.disabled = false

      document.getElementById("popup_form")?.append(this.removedStepInputTarget)
    }
  }

  show({ detail }) {
    if (detail === this.element.id) {
      this.element.classList.remove("hidden")
    } else {
      this.element.classList.add("hidden")
    }
  }

  hide() {
    this.element.classList.add("hidden")
  }

  showMobileLayout({ detail }) {
    this.mobileLayoutTarget.classList.remove("hidden")
    this.desktopLayoutTarget.classList.add("hidden")
  }

  showDesktopLayout() {
    this.desktopLayoutTarget.classList.remove("hidden")
    this.mobileLayoutTarget.classList.add("hidden")
  }

  onImageSelect(e) {
    const imagePath = URL.createObjectURL(e.target.files[0])
    this.setHeaderBackgroundImage(imagePath)

    this.dispatch("changed")
  }

  removeImage() {
    this.headerTargets.forEach((layoutHeader) => {
      layoutHeader.style.removeProperty("background-image")
      layoutHeader.style.removeProperty("background-size")

      console.log(layoutHeader.style.backgroundImage)
    })

    this.uploadImageButtonTargets.forEach((el) => el.classList.remove("hidden"))
    this.imageControlsTargets.forEach((controls) =>
      controls.classList.add("hidden")
    )

    this.fileInputTarget.files = new DataTransfer().files

    this.fileInputTargets.forEach((fileInput) => {
      fileInput.files = new DataTransfer().files
    })

    this.imageValue = {}
    this.dispatch("changed")
  }

  setImageFitToCover() {
    this.headerTargets.forEach((layoutHeader) => {
      layoutHeader.style.backgroundSize = "cover"
    })

    this.imageValue.objectFit = "cover"

    this.fitButtonContainerTargets.forEach((container) =>
      container.classList.remove("hidden")
    )
    this.fillButtonContainerTargets.forEach((container) =>
      container.classList.add("hidden")
    )

    window.step = this.imageValue
    this.dispatch("changed")
  }

  setImageFitToContain() {
    this.headerTargets.forEach((layoutHeader) => {
      layoutHeader.style.backgroundSize = "contain"
    })

    this.fitButtonContainerTargets.forEach((container) =>
      container.classList.add("hidden")
    )
    this.fillButtonContainerTargets.forEach((container) =>
      container.classList.remove("hidden")
    )

    this.imageValue.objectFit = "contain"
    window.step = this.imageValue
    this.dispatch("changed")
  }

  setImageOnNewLayout() {
    if (this.imageValue?.imagePath) {
      this.setHeaderBackgroundImage(
        this.imageValue.imagePath,
        this.imageValue.objectFit
      )
      this.dispatch("changed")
    }
  }

  syncHeaderImage({ detail }) {
    this.setHeaderBackgroundImage(detail.path)
  }

  syncHeaderImageFill({ detail }) {
    if (detail.fit === "cover") {
      this.setImageFitToCover()
    } else {
      this.setImageFitToContain()
    }
  }

  newFieldAppended({ detail }) {
    if (Object.keys(detail).length === 0) return

    window.popupSteps[this.element.id] = {
      ...window.popupSteps[this.element.id],
      fields: {
        ...window.popupSteps[this.element.id]?.fields,
        [detail.id]: detail,
      },
    }

    this.dispatch("changed")
  }

  syncFields(e) {
    this.newFieldAppended(e)
  }

  removeField({ detail: id }) {
    delete window.popupSteps[this.element.id].fields[id]
    this.dispatch("changed")
  }

  // private

  setHeaderBackgroundImage(imagePath, fit = "cover") {
    this.headerTargets.forEach((layoutHeader) => {
      layoutHeader.style.backgroundImage = `url(${imagePath})`
      layoutHeader.style.backgroundSize = fit
    })

    this.uploadImageButtonTargets.forEach((el) => el.classList.add("hidden"))
    this.imageControlsTargets.forEach((controls) =>
      controls.classList.remove("hidden")
    )

    this.imageValue = {
      imagePath,
      objectFit: fit,
    }

    window.step = imagePath
    this.dispatch("changed")
  }
}
