import { get } from '@rails/request.js'

import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    searchUrl: String,
    property: String
  }

  static targets = [
    'input',
    'querySelect',
    'querySelectOption',
    'searchResultsList',
    'searchResultsContainer',
    'resultTemplate',
    'valuableIdInput',
    'valuableValueInput',
    'removeButton'
  ]

  scheduleSearchTimeout({ target }) {
    if(!this.searchUrlValue) return

    if(!target.value) {
      this.hide(this.searchResultsListTarget)
      this.hide(this.searchResultsContainerTarget)
      this.searchResultsListTarget.innerHTML = ''
      return
    }

    this.debounce(this.search.bind(this))
  }

  async search() {
    if(!this.inputTarget.value) return

    const value = this.inputTarget.value
    const url = this.searchUrlValue

    const response = await get(url, {
      query: {
        property: this.propertyValue,
        [this.propertyValue]: value
      }
    })

    if(response.ok) {
      const results = await response.json
      this.searchResultsListTarget.innerHTML = ''

      results.forEach((result) => {
        const item = this.resultTemplateTarget.cloneNode(true)
        item.classList.remove('hidden')

        item.dataset.value = result.value
        item.querySelector('[data-description]').innerText = result.value

        this.searchResultsListTarget.appendChild(item)
      })

      if(results.length > 0) {
        this.show(this.searchResultsListTarget)
        this.show(this.searchResultsContainerTarget)
      }
    }
  }

  apply({ currentTarget }) {
    const { value } = currentTarget.dataset

    this.hide(this.searchResultsListTarget)
    this.hide(this.searchResultsContainerTarget)

    this.searchResultsListTarget.innerHTML = ''
    this.inputTarget.value = value
  }

  hideDropdown({ target }) {
    if(this.isInvisible(this.searchResultsContainerTarget) || this.searchResultsContainerTarget.contains(target)) return

    this.hide(this.searchResultsListTarget)
    this.hide(this.searchResultsContainerTarget)

    this.searchResultsListTarget.innerHTML = ''
  }

  saveState() {
    const value = this.inputTarget.value
    const query = this.querySelectTarget.value

    if(!value && this.element.previousElementSibling) {
      return this.removeButtonTarget.click()
    }

    this.state = {
      value,
      query,
    }
  }

  restoreState() {
    if(!this.state) return

    this.inputTarget.value = this.state.value
    this.querySelectOptionTargets.find((option) => option.dataset.optionValue === this.state.query).click()
  }

  onQuerySelect() {
    this.focus(this.inputTarget)
  }
}
