import Hellotext from '@hellotext/hellotext'

import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    id: String,
    business: String
  }

  static targets = ['form', 'successMessage']

  initialize() {
    Hellotext.initialize(this.businessValue, {
      autoGenerateSession: false,
      forms: {
        successMessage: false,
      }
    })

    this.onFormCollection = this.onFormCollection.bind(this)
    this.onFormCompletion = this.onFormCompletion.bind(this)

    super.initialize()
  }

  connect() {
    Hellotext.on('forms:collected', this.onFormCollection)
    Hellotext.on('form:completed', this.onFormCompletion)

    super.connect()
  }

  disconnect() {
    Hellotext.removeEventListener('forms:collected', this.onFormCollection)
    Hellotext.removeEventListener('form:completed', this.onFormCompletion)

    super.disconnect()
  }

  onFormCollection(forms) {
    if(forms.getByIndex(0).hasBeenCompleted) {
      this.show(this.successMessageTarget)
    } else {
      this.removeClass(this.formTarget, '!hidden')
    }
  }

  onFormCompletion() {
    this.show(this.successMessageTarget)
  }
}
