import { Character } from './character'

class Piece {
  static wrap(piece) {
    return new Piece(piece)
  }

  constructor(piece) {
    this.piece = piece
    this.string = piece?.string
    this.character = Character.fromPiece(piece)
  }

  valueOf() {
    return this.string
  }

  get id() {
    return this.piece?.id
  }

  get isLineFeed() {
    return this.character.isLineFeed
  }

  get isWhitespace() {
    return this.character.isWhitespace
  }

  get endsWithWhitespace() {
    return this.string && new Character(this.string.charAt(this.string.length - 1)).isWhitespace
  }

  get endsWithLineFeed() {
    return this.string && new Character(this.string.charAt(this.string.length - 1)).isLineFeed
  }

  get isWord() {
    return this.string.trim().length > 0
  }

  get isShortlink() {
    return this.piece && !!this.piece.attachment
  }

  get isAnchor() {
    return this.piece.attributes.has('href')
  }

  get isTag() {
    if(this.#isPastedFromClipboard) {
      return this.piece.attributes.get('background') === 'rgb(231, 229, 234)'
    }

    return this.piece.attributes.has('background') && this.piece.attributes.has('padding') && this.piece.attributes.has('rounded')
  }

  get isAlphanumeric() {
    return this.character.isAlphanumeric
  }

  get #isPastedFromClipboard() {
    // When a tag is copied without the braces, it will only have the background attribute
    return this.piece.attributes.has('background') && !this.piece.attributes.has('padding') && !this.piece.attributes.has('rounded')
  }
}

export { Piece }
