import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

// this controller is used to manually update browser url without
// issuing any more requests to backend.
// you can attach data-action="turbo:submit-end@window->browser-url#change"
// and the controller will do the job for you.

export default class extends Controller {
  change(event) {
    if (event.detail.success) {
      const fetchResponse = event.detail.fetchResponse
      if (fetchResponse.response.url.indexOf("append") === -1) {
        history.pushState(
          { turbo_stream_response: true },
          "",
          fetchResponse.response.url
        )
      }
    }
  }
}
