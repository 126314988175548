import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    placeholderKind: { type: String, default: 'icon' }
  }

  static targets = ["trigger", "menu", "defaultIcon", "whatsapp", "sms", "defaultOption", "select", "tooltip"]

  connect() {
    this.triggerTitle = this.triggerTarget.title

    if(this.usesDefaultSettings) {
      this.disableBrowserTitleDialog()
    } else {
      this.disableTooltip()
    }
  }

  onDropdownOpen() {
    this.disableTooltip()
  }

  onDropdownHide() {
    if(this.usesDefaultSettings && this.tooltipDisabled) {
      this.enableTooltip()

      setTimeout(() => {
        const hoveredElement = document.querySelectorAll(':hover').item(
          document.querySelectorAll(':hover').length - 1
        )

        // when clicking the trigger button and the cursor is still on the trigger
        // the tooltip will not be shown, this is a way to tell the tooltip to show itself
        if(this.triggerTarget.contains(hoveredElement)) {
          this.dispatch('tooltip:show', {
            target: this.element
          })
        }
      }, 100)
    }
  }

  changeTriggerLabel({ currentTarget }) {
    if (currentTarget.dataset.channel === undefined) return

    this.triggerTarget.innerHTML = currentTarget.dataset.channel
    this.triggerTarget.classList.replace("px-1", "px-2")

    this.disableTooltip()

    this.dispatch("attachment:remove", {target: document.documentElement })

    this.dispatch('change', {
      detail: {
        technology: currentTarget.dataset.technology,
        identifier: currentTarget.dataset.identifier,
        channelId: currentTarget.dataset.optionValue,
        id: currentTarget.dataset.id
      }
    })
  }

  useDefault() {
    this.triggerTarget.classList.replace("px-2", "px-1")

    if(this.placeholderKindValue === 'icon') {
      this.triggerTarget.innerHTML = this.defaultIconTarget.innerHTML
    } else {
      this.triggerTarget.innerHTML = this.translations.informational.using_default_settings
    }

    this.enableTooltip()

    this.dispatch('change', {
      detail: {
        technology: 'default',
        identifier: 'default',
      }
    })

    this.element.setAttribute('data-dropdown-open-value', false)
  }

  closeByEscape() {
    if(this.isInvisible(this.menuTarget)) return
    this.triggerTarget.click()
  }

  // private

  disableTooltip() {
    if(!this.hasTooltipTarget) return

    this.element.setAttribute('data-tooltip-disabled', true)
    this.triggerTarget.title = this.triggerTitle
  }

  enableTooltip() {
    if(!this.hasTooltipTarget) return

    this.element.removeAttribute('data-tooltip-disabled')
    this.disableBrowserTitleDialog()
  }

  disableBrowserTitleDialog() {
    this.triggerTarget.title = ""
  }

  syncOrigin({ detail }) {
    const { technology, identifier } = detail
    if(identifier === this.activeOption.dataset.identifier) return

    const option = this[`${identifier}Targets`].length > 0 ? this[`${identifier}Targets`][0] : this.defaultOptionTarget

    option.setAttribute('data-notoggle', true)
    option.click()
    option.removeAttribute('data-notoggle')
  }

  get usesDefaultSettings() {
    return !this.element.querySelector("select").value
  }

  get tooltipDisabled() {
    return this.element.hasAttribute('data-tooltip-disabled')
  }

  get activeOption() {
    return this.element.querySelector("option:checked")
  }
}
