import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    searchUrl: String
  }

  static targets = ["list", "template", "listsContainer", "addButton"]

  add() {
    const clonedNode = this.templateTarget.cloneNode(true)
    clonedNode.querySelector("[data-segments--list-target='remove']").classList.remove("hidden")
    clonedNode.setAttribute("data-segments--lists-target", "list")

    const select = clonedNode.querySelector("[data-segments--list-target='select']")

    if(this.searchUrlValue) {
      const randomId = Date.now().toString()
      const url = new URL(this.searchUrlValue)

      url.searchParams.set("input", randomId)

      const menu = clonedNode.querySelector("[data-select--search-target='menu']")

      select.setAttribute("data-select--search-url-value", url.toString())
      menu.setAttribute("id", `${randomId}_list`)
    }

    clonedNode.querySelectorAll("input, select")
      .forEach(input => input.disabled = false)
    clonedNode.classList.remove("hidden")

    this.listsContainerTarget.appendChild(clonedNode)

    this.dispatch("allow-remove", {
      target: this.defaultList
    })

    this.addButtonTarget.blur()
  }

  hideRemoveIfSingleList() {
    if(this.listTargets.length === 1) {
      this.dispatch("set-to-default", {
        target: this.defaultList
      })
    }
  }

  allowAddingNewLists() {
    this.addButtonTarget.classList.remove("hidden")
  }

  disableAddingNewLists() {
    this.addButtonTarget.classList.add("hidden")
  }

  // private

  get defaultList() {
    return this.listTargets[0]
  }
}
