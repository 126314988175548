import {DirectUpload} from "@rails/activestorage";
import interact from "interactjs"

import ApplicationController from "../application_controller"
import { useRemove } from "../mixins/useRemove"

export default class extends ApplicationController {
  static values = {
    audioUrl: String,
    duration: Number
  }

  static targets = ["playButton", "pauseButton", "audio", "progress", "durationLabel", "pointer", "bar", "input", "overlay", "overlayActions"]

  initialize() {
    this.durationLabelTarget.innerText = this.formatDuration(this.durationValue)
    this.upload = new DirectUpload(this.inputTarget.files[0], this.inputTarget.dataset.directUploadUrl, this)
  }

  connect() {
    this.audioTarget.src = this.audioUrlValue
    this.audioTarget.load()

    this.audioTarget.addEventListener("loadedmetadata", this.onAudioMetadataLoaded.bind(this))
    this.audioTarget.addEventListener("play", this.onAudioPlay.bind(this))
    this.audioTarget.addEventListener("pause", this.onAudioPause.bind(this))

    useRemove(this, {
      stopEventPropagation: true,
      after: this.dispatchRemoveEvent.bind(this)
    })

    this.startUpload()

    interact(this.pointerTarget).draggable({
      restrict: {
        restriction: this.barTarget,
        elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
        endOnly: true
      },
      inertia: true,
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: this.barTarget,
          endOnly: true
        })
      ],
      onstart: (event) => {
        event.target.style.zIndex = '9999';
      },
      onmove: (event) => {
        const newX = (parseFloat(event.target.getAttribute('data-x')) || 0) + event.dx;
        const maxX = this.barTarget.clientWidth - event.target.clientWidth;

        if(newX < 0 || newX > maxX) return;

        this.progressTarget.style.width = `${(newX / maxX) * 100}%`;

        event.target.setAttribute('data-x', newX);

        const percentage = newX / maxX;
        const newTime = this.audioTarget.duration * percentage;
        this.audioTarget.currentTime = newTime;
        this.durationLabelTarget.innerText = this.formatDuration(newTime * 1000)
      }
    });

    this.element.querySelectorAll("input").forEach(input => {
      this.enable(input)
    })
  }

  disconnect() {
    this.dispatchRemoveEvent()
  }

  onAudioTimeUpdate() {
    const { currentTime, duration } = this.audioTarget

    if(this.isPlaying) {
      this.durationLabelTarget.innerText = this.formatDuration(currentTime * 1000)
      this.progressTarget.style.width = `${(currentTime / duration) * 100}%`
      this.progressTarget.style.minWidth = "8%"

      if (currentTime === duration) {
        this.onAudioEnd()
      }
    }
  }

  onAudioMetadataLoaded() {
    const { duration } = this.audioTarget

    if (duration === Infinity || isNaN(duration)) {
      this.audioTarget.currentTime = 10000000;
      this.delayed(() => this.audioTarget.currentTime = 0, 1000)
    }

    this.audioTarget.addEventListener("timeupdate", this.onAudioTimeUpdate.bind(this))
  }

  onAudioPlay() {
    this.show(this.pauseButtonTarget)
    this.hide(this.playButtonTarget)

    this.isPlaying = true
  }

  onAudioPause() {
    this.show(this.playButtonTarget)
    this.hide(this.pauseButtonTarget)

    this.isPlaying = false
  }

  onAudioEnd() {
    this.audioTarget.pause()

    this.delayed(() => {
      this.show(this.playButtonTarget)
      this.hide(this.pauseButtonTarget)

      this.isPlaying = false
      this.audioTarget.currentTime = 0

      this.progressTarget.style.removeProperty("width")
      this.progressTarget.style.removeProperty("min-width")
    }, 500)
  }

  formatDuration(duration = this.durationValue) {
    const totalSeconds = Math.floor(duration / 1000)
    const minutes = Math.floor(totalSeconds / 60)
    const seconds = Math.floor(totalSeconds % 60)

    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
  }

  play() {
    this.audioTarget.play()
  }

  pause() {
    this.audioTarget.pause()
  }

  startUpload() {
    this.element.setAttribute("data-uploading", "")

    this.upload.create((error, blob) => {
      if (error) {
        this.element.removeAttribute("data-uploading")
        this.element.setAttribute("data-upload-error", "")

        this.hide(this.overlayTarget.querySelector('[data-spinner]'))
        this.show(this.overlayActionsTarget)
      } else {
        this.element.removeAttribute("data-uploading")
        this.element.removeAttribute("data-upload-error")

        this.hide(this.overlayTarget)

        let hiddenField = document.createElement('input')
        hiddenField.type = "hidden"
        hiddenField.value = blob.signed_id;
        hiddenField.name = "message[voice][signed_id]"

        this.element.appendChild(hiddenField)
      }
    })
  }

  retryUpload() {
    this.show(this.overlayTarget.querySelector('[data-spinner]'))
    this.hide(this.overlayActionsTarget)

    this.startUpload()
  }

  dispatchRemoveEvent() {
    this.dispatch("remove", {
      target: document.getElementById("editor--voice-tool")
    })
  }

  enable() {
    this.element.removeAttribute('data-disabled')
    super.enable(this.playButtonTarget)

    interact(this.pointerTarget).draggable(true)
  }

  disable() {
    this.element.setAttribute('data-disabled', '')
    super.disable(this.playButtonTarget)

    interact(this.pointerTarget).draggable(false)
  }
}
