class PlaceDetail {
  constructor(response) {
    this.details = response
  }

  get streetNumber() {
    return this.details.address_components.find(component => component.types.includes('street_number'))?.long_name
  }

  get streetName() {
    return this.details.address_components.find(component => component.types.includes('route'))?.long_name
  }

  get postalCode() {
    return this.details.address_components.find(component => component.types.includes('postal_code'))?.long_name
  }

  get city() {
    return this.details.address_components.find(component => component.types.includes('locality'))?.long_name
  }

  get country() {
    return this.details.address_components.find(component => component.types.includes('country'))
  }

  get state() {
    return this.details.address_components.find(component => component.types.includes('administrative_area_level_1'))?.long_name
  }

  get countryCode() {
    return this.country?.short_name
  }
}

export { PlaceDetail }
