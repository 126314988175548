import { Controller } from "@hotwired/stimulus"

export const prevAll = (element) => {
  const prevElements = []
  let prevElement = element.parentNode.firstElementChild

  while (prevElement !== element) {
    prevElements.push(prevElement)
    prevElement = prevElement.nextElementSibling
  }

  return prevElements
}

export const nextAll = (element) => {
  const nextElements = []
  let nextElement = element

  while (nextElement.nextElementSibling) {
    nextElements.push(nextElement.nextElementSibling)
    nextElement = nextElement.nextElementSibling
  }

  return nextElements
}

export default class extends Controller {
  static targets = ["cdMorphDropdown"]

  connect() {
    function morphDropdown(element) {
      this.element = element
      this.mainNavigation = this.element.querySelector(".main-nav")
      this.mainNavigationItems =
        this.mainNavigation.querySelectorAll(".has-dropdown")
      this.dropdownList = this.element.querySelector(".morph-dropdown-list")
      this.dropdownWrappers = this.dropdownList.querySelectorAll(".dropdown")
      this.dropdownItems = this.dropdownList.querySelectorAll(".content")
      this.dropdownBg = this.dropdownList.querySelector(".bg-layer")
      this.mq = this.checkMq()
      this.bindEvents()
    }
    morphDropdown.prototype.checkMq = function () {
      var self = this
      return window
        .getComputedStyle(self.element, "::before")
        .getPropertyValue("content")
        .replace(/'/g, "")
        .replace(/"/g, "")
        .split(", ")
    }
    morphDropdown.prototype.bindEvents = function () {
      var self = this
      this.mainNavigationItems.forEach(function (e) {
        e.addEventListener(
          "mouseenter",
          function (event) {
            self.showDropdown(e)
          },
          false
        )
        e.addEventListener(
          "mouseleave",
          function () {
            setTimeout(function () {
              if (
                self.mainNavigation.querySelectorAll(".has-dropdown:hover")
                  .length == 0 &&
                self.element.querySelectorAll(".morph-dropdown-list:hover")
                  .length == 0
              )
                self.hideDropdown()
            }, 50)
          },
          false
        )
      })
      this.dropdownList.addEventListener(
        "mouseleave",
        function () {
          setTimeout(function () {
            self.mainNavigation.querySelectorAll(".has-dropdown:hover")
              .length == 0 &&
              self.element.querySelectorAll(".morph-dropdown-list:hover")
                .length == 0 &&
              self.hideDropdown()
          }, 50)
        },
        false
      )
      this.mainNavigationItems.forEach(function (e) {
        e.addEventListener("touchstart", function (event) {
          var selectedDropdown = self.dropdownList.querySelector(
            "#" + document.querySelector(this).data("content")
          )
          if (
            !self.element.classList.contains("is-dropdown-visible") ||
            !selectedDropdown.classList.contains("active")
          ) {
            event.preventDefault()
            self.showDropdown(document.querySelector(this))
          }
        })
      }, false)
      this.element.querySelector(".nav-trigger").addEventListener(
        "click",
        function (event) {
          event.preventDefault()
          self.element.classList.toggle("nav-open")
        },
        false
      )
    }
    morphDropdown.prototype.showDropdown = function (item) {
      this.mq = this.checkMq()

      if (this.mq == "desktop") {
        var self = this
        var selectedDropdown = this.dropdownList.querySelector(
          "#" + item.dataset.content
        )
        // item.style.display = "block"
        document.querySelector(
          ".cd-morph-dropdown .morph-dropdown-wrapper"
        ).style.display = "block"

        var selectedDropdownHeight = selectedDropdown.clientHeight,
          selectedDropdownWidth =
            selectedDropdown.querySelector(".content").clientWidth + 2,
          selectedDropdownRect = item.getBoundingClientRect(),
          selectedDropdownLeft =
            item.offsetLeft + item.clientWidth / 2 - selectedDropdownWidth / 6

        this.updateDropdown(
          selectedDropdown,
          parseInt(selectedDropdownHeight),
          selectedDropdownWidth,
          parseInt(selectedDropdownLeft)
        )

        this.element.querySelectorAll(".active").forEach(function (e) {
          e.classList.remove("active")
        })

        selectedDropdown.classList.add("active")
        selectedDropdown.classList.remove("move-left", "move-right")

        prevAll(selectedDropdown).forEach(function (e) {
          e.classList.add("move-left")
        })

        nextAll(selectedDropdown).forEach(function (e) {
          e.classList.add("move-right")
        })

        item.classList.add("active")

        if (!this.element.classList.contains("is-dropdown-visible")) {
          setTimeout(function () {
            self.element.classList.add("is-dropdown-visible")
          }, 10)
        }
      }
    }
    morphDropdown.prototype.updateDropdown = function (
      dropdownItem,
      height,
      width,
      left
    ) {
      this.dropdownList.style["-moz-transform"] = "translateX(" + left + "px)"
      this.dropdownList.style["-webkit-transform"] =
        "translateX(" + left + "px)"
      this.dropdownList.style["-ms-transform"] = "translateX(" + left + "px)"
      this.dropdownList.style["-o-transform"] = "translateX(" + left + "px)"
      this.dropdownList.style["transform"] = "translateX(" + left + "px)"
      this.dropdownList.style["width"] = width + "px"
      this.dropdownList.style["height"] = height + "px"

      this.dropdownBg.style["-moz-transform"] =
        "scaleX(" + width + ") scaleY(" + height + ")"
      this.dropdownBg.style["-webkit-transform"] =
        "scaleX(" + width + ") scaleY(" + height + ")"
      this.dropdownBg.style["-ms-transform"] =
        "scaleX(" + width + ") scaleY(" + height + ")"
      this.dropdownBg.style["-o-transform"] =
        "scaleX(" + width + ") scaleY(" + height + ")"
      this.dropdownBg.style["transform"] =
        "scaleX(" + width + ") scaleY(" + height + ")"
    }
    morphDropdown.prototype.hideDropdown = function () {
      this.mq = this.checkMq()
      if (this.mq == "desktop") {
        this.element.classList.remove("is-dropdown-visible")

        this.element.querySelectorAll(".active").forEach(function (e) {
          e.classList.remove("active")
        })

        this.element.querySelectorAll(".move-left").forEach(function (e) {
          e.classList.remove("move-left")
        })

        this.element.querySelectorAll(".move-right").forEach(function (e) {
          e.classList.remove("move-right")
        })
      }
    }
    morphDropdown.prototype.resetDropdown = function () {
      this.mq = this.checkMq()
      if (this.mq == "mobile") {
        this.dropdownList.removeAttribute("style")
        this.element
          .querySelector(".morph-dropdown-wrapper")
          .removeAttribute("style")
      }
    }

    var morphDropdowns = []
    if (document.querySelectorAll(".cd-morph-dropdown").length > 0) {
      document.querySelectorAll(".cd-morph-dropdown").forEach(function (e) {
        morphDropdowns.push(
          new morphDropdown(document.querySelector(".cd-morph-dropdown"))
        )
      })
      var resizing = false
      updateDropdownPosition()
      window.addEventListener(
        "resize",
        function () {
          if (!resizing) {
            resizing = true
            !window.requestAnimationFrame
              ? setTimeout(updateDropdownPosition, 300)
              : window.requestAnimationFrame(updateDropdownPosition)
          }
        },
        false
      )
      function updateDropdownPosition() {
        morphDropdowns.forEach(function (element) {
          element.resetDropdown()
        })
        resizing = false
      }
    }
  }
}
