import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = [
    "searchInput",
    "clearButton",
    "submitButton",
    "conversationsList"
  ]

  static values = {
    timer: Object,
    url: String,
  }

  search() {
    clearTimeout(this.searchTimeout)

    if(this.searchInputTarget.value.trim().length !== 0) {

      this.searchTimeout = setTimeout(async () => {
        this.element.querySelector("[data-search-skeleton]").classList.remove("hidden")
        this.conversationsListTarget.classList.add("hidden")

        if(this.element.querySelector("[data-search-result]")) {
          this.element.querySelector("[data-search-result]").classList.add("hidden")
        }

        get(this.urlValue, {
          query: {
            search: this.searchInputTarget.value,
          },
          responseKind: "turbo-stream"
        })
      }, 300)
    } else {
      this.element.querySelector("[data-search-result]").remove()
      this.element.querySelector("[data-search-skeleton]").classList.add("hidden")

      this.conversationsListTarget.classList.remove("hidden")
    }
  }

  focus() {
    this.searchInputTarget.focus()
  }
}
