class Token {
  constructor(token, { start, end, isValidTagName }) {
    this.word = token
    this.start = start
    this.end = end
    this.isValidTagName = isValidTagName
    this.addWhitespace = false
  }

  get range() {
    return [this.start, this.end]
  }

  get invalid() {
    return !this.isValidTagName
  }

  get valid() {
    return this.isValidTagName
  }

  get shouldAddWhitespaceBefore() {
    return this.addWhitespace
  }
}

export { Token }
