// inspired by https://gist.github.com/shuber/58902

export const useUpdatableTimestamp = (controller) => {
  return Object.assign(controller, {
    timeAgoInWordsWithParsing(from) {
      const date = new Date;
      date.setTime(Date.parse(from));
      return this.timeAgoInWords(date);
    },

    timeAgoInWords(from) {
      return this.distanceOfTimeInWords(new Date(), from);
    },

    distanceOfTimeInWords(to, from) {
      const translations = window.t.datetime.distance_in_words
      const distance_in_seconds = ((to - from) / 1000);
      const distance_in_minutes = Math.floor(distance_in_seconds / 60);

      if (distance_in_minutes === 0) {
        return translations.less_than_x_minutes.one
      }

      if (distance_in_minutes === 1) {
        return translations.x_minutes.one
      }

      if (distance_in_minutes < 45) {
        return translations.x_minutes.other.replace("%{count}", distance_in_minutes)
      }

      if (distance_in_minutes < 90)  {
        return translations.about_x_hours.one
      }

      if (distance_in_minutes < 1440) {
        return translations.about_x_hours.other.replace("%{count}", Math.floor(distance_in_minutes / 60))
      }

      if (distance_in_minutes < 2880) {
        return translations.x_days.one
      }

      if (distance_in_minutes < 43200) {
        return translations.x_days.other.replace("%{count}", Math.floor(distance_in_minutes / 1440))
      }

      if (distance_in_minutes < 86400) {
        return translations.x_months.one
      }
      if (distance_in_minutes < 525960) {
        return translations.x_months.other.replace("%{count}", Math.floor(distance_in_minutes / 43200))
      }

      if (distance_in_minutes < 1051199) {
        return translations.about_x_years.one
      }

      return translations.over_x_years.other.replace("%{count}",  Math.floor(distance_in_minutes / 525960))
    }
  })
}
