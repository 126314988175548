import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["radio", "checkmarkContainer"]
  static classes = ["active"]

  initialize() {
    if(this.hasRadioTarget) {
      this.toggleActiveClass()
    }
  }

  check() {
    this.radioTarget.checked = true

    this.dispatch("toggled", {
      target: document.documentElement,
      detail: this.radioTarget
    })

    this.dispatch("change", { detail: this.radioTarget.value, target: this.radioTarget })
    this.toggleActiveClass()
  }

  radioToggled({ target, detail }) {
    if(detail === this.radioTarget || detail.name !== this.radioTarget.name) return

    this.radioTarget.checked = false
    this.dispatch("change", { detail: this.radioTarget.value, target: this.radioTarget })

    this.toggleActiveClass()
  }

  toggleActiveClass() {
    if(this.radioTarget.checked) {
      this.addClass(this.element, ...this.elementClasses)
      if(this.hasCheckmarkContainerTarget) {
        this.show(this.checkmarkContainerTarget)
      }
    } else {
      this.removeClass(this.element, ...this.elementClasses)

      if(this.hasCheckmarkContainerTarget) {
        this.hide(this.checkmarkContainerTarget)
      }
    }
  }

  get elementClasses() {
    return this.hasActiveClass ? this.activeClasses : ["border-lavender"]
  }
}
