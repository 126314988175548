class Tag {
  static whitelistedTags = []

  static isTag(word) {
    return word.startsWith("{") && word.endsWith("}")
  }
}

class Coupon extends Tag {
  static isValidTag(word) {
    return ["{coupon}", "{couponLink}", "{couponDescription}"].includes(word)
  }
}

class Contact extends Tag {
  static isValidTag(token) {
    const word = token.toLowerCase()

    if (this.whitelistedTags.includes(word)) return true
    if(!word.startsWith("{") || !word.endsWith("}")) return false

    // Remove Braces from the word
    let strippedWord = word.substring(1, word.length - 1)

    return (
      strippedWord.split("|").length === 2 && // only a single pipe is present
      strippedWord.split("|")[1].trim().length > 0 // default value is provided
    )
  }
}

export { Contact, Coupon }
