import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["showMoreButton", "contact", "toggleButton", "actionsContainer"]

  showMore() {
    this.contactTargets
      .forEach((element, index) => {
        if(index === 0) {
          element.classList.replace("rounded-2xl", "rounded-t-2xl")
          return
        } else if(index === this.contactTargets.length - 1) {
          element.classList.replace("rounded-2xl", "rounded-b-2xl")
        } else {
          element.classList.replace("rounded-2xl", "rounded-none")
        }

        element.classList.remove(
          "absolute", "-z-10",
          ...element.dataset.positionClass,
          ...element.dataset.rotationClass.split(" ")
        )

        element.classList.add("w-full", "relative")
      })


    this.removeClass(this.actionsContainerTarget, "mb-8", "mb-2")
    this.showMoreButtonTarget.classList.add("hidden")
  }

  displayTimestamps({ target }) {
    if(target.tagName === "LI") {
      this.toggleButtonTarget.click()
    }
  }

  flash({ detail }) {
    if(detail.detail.id !== this.element.id) return

    const containerClass = this.element.dataset.messageState === "received" ? "bg-lavender-light" : "bg-night-10"

    this.contactTargets.forEach((element) => {
      element.classList.replace(containerClass, "bg-lavender")
      this.showMoreButtonTarget.classList.add("bg-lavender")
    })

    setTimeout(() => {
      this.contactTargets.forEach((element) => {
        element.classList.replace("bg-lavender", containerClass)
        this.showMoreButtonTarget.classList.remove("bg-lavender")
      })
    }, 500)
  }
}
