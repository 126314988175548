import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  static values = {
    name: String,
  }

  connect() {
    if (this.hasNameValue) {
      this.inputTarget.name = this.inputTarget.name + `[${this.nameValue}]`
      this.lastValue = `[${this.nameValue}]`

      return
    }

    this.inputTarget.name = this.inputTarget.name + `[field]`
    this.lastValue = "[field]"
  }

  changeFieldName(e) {
    let value = this.inputTarget.name

    value = value.replace(this.lastValue, `[${e.target.value}]`)
    this.inputTarget.name = value
    this.lastValue = `[${e.target.value}]`
  }
}
