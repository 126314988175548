import DragController from "../drag_controller"

export default class extends DragController {
  end(event) {
    this.dispatch("drag-end", {
      target: event.item,
      detail: event.newIndex + 1,
    })

    this.dispatch("drag-reorder", {
      target: Array.from(
        document.querySelectorAll(
          `[data-section-id='${this.element.dataset.sectionId}']`
        )
      ).find((layoutSection) => layoutSection !== this.element),
    })
  }

  clone() {
    const element = Array.from(
      document.querySelectorAll(
        `[data-section-id='${this.element.dataset.sectionId}']`
      )
    ).find((layoutSection) => layoutSection !== this.element)

    setTimeout(() => {
      this.element.replaceWith(element.cloneNode(true))
    }, 10)
  }
}
