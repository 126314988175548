import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    url: String,
    state: String,
    navigate: { type: Boolean, default: false }
  }

  static targets = [
    'link',
    'destroyed',
    'checkbox',
  ]

  static classes = ['active']

  initialize() {
    super.initialize()
    this.popStateListener = this.popStateListener.bind(this)
  }

  connect() {
    super.connect()

    if(this.stateValue === 'draft') {
      this.activate()
    }

    if(window.location.pathname === this.urlValue) {
      this.nextTick(() => this.activate())
    }

    if(this.navigateValue) {
      this.nextTick(() => {
        this.element.removeAttribute('data-audience--contact-navigate-value')
        this.linkTarget.click()
      })
    }

    window.addEventListener('popstate', this.popStateListener)
  }

  disconnect() {
    super.disconnect()
    window.removeEventListener('popstate', this.popStateListener)
  }

  popStateListener() {
    if(this.stateValue === 'draft') {
      return this.element.remove()
    }
  }

  clickLink({ target }) {
    if(target === this.checkboxTarget) return
    this.linkTarget.click()
  }

  markAsActive() {
    this.activate()
  }

  activate() {
    document
      .querySelectorAll(`.audience--contact-card`)
      .forEach(contactCard => contactCard.removeAttribute('data-active'))

    if(this.stateValue !== 'draft') {
      document
        .querySelector('.audience--contact-card[data-audience--contact-state-value="draft"]')
        ?.remove()
    }

    this.element.setAttribute('data-active', true)
  }

  destroyedTargetConnected() {
    this.remove()
  }

  remove() {
    this.element.remove()

    if(window.location.pathname === this.urlValue) {
      this.nextTick(() => {
        history.go(-1)
      }, 300)
    }
  }

  // private

  get active() {
    return this.element.hasAttribute('data-active')
  }

  get inactive() {
    return !this.active
  }
}
