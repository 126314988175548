import { Controller } from "@hotwired/stimulus"
import mixpanel from "mixpanel-browser"

export default class extends Controller {
  static values = {
    canonicalPath: String,
  }

  initialize() {
    mixpanel.init("af20f4811116455610c899a89aa6d4ca", {
      persistence: "localStorage",
    })
  }

  connect() {
    mixpanel.track_pageview({
      canonicalPath: this.canonicalPathValue,
    })
  }
}
