import Hellotext from "@hellotext/hellotext"

import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    businesses: Array
  }

  initialize() {
    super.initialize()

    this.didTrackForOtherBusinesses = false
    this.trackPageView = this.trackPageView.bind(this)
  }

  connect() {
    super.connect()

    Hellotext.on('session-set', this.trackPageView)
    Hellotext.initialize(this.businessesValue[0], { autogenerateSession: false })
  }

  disconnect() {
    super.disconnect()

    Hellotext.removeEventListener('session-set', this.trackPageView)
  }

  async trackPageView() {
    Hellotext.track('page.viewed')

    if(this.didTrackForOtherBusinesses) return
    this.didTrackForOtherBusinesses = true

    this.businessesValue.slice(1).forEach((business) => {
      Hellotext.initialize(business, { autogenerateSession: false })
    })
  }
}
