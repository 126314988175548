import { AttachmentManager } from './attachment_manager'

class MentionsManager extends AttachmentManager {
  insert({ currentTarget }) {
    super.insert(this.constructAttachmentElementForMention(currentTarget))
  }

  moveCursorToRightAndInsertAttachmentAndMoveToRight(attachment) {
    this.inputController.isParsing = true

    this.inputController.nextTick(() => {
      this.cursor.moveToRight()

      this.inputController.nextTick(() => {
        this.recordUndoEntryAndInsertAttachment(attachment)

        this.cursor.moveToRight()
        this.inputController.isParsing = false

        return this.afterAttachmentInsertion()
      })
    })
  }

  replace(id, withElement) {
    const piece = this.trixDocument.getPieces().filter(piece => piece.attachment)
      .find(piece => piece.attachment.attributes.values.id === id)

    const pieceIndex = this.trixDocument.getPieces().findIndex(p => p.id === piece.id)
    const contentLengthBeforePiece = this.inputController.contentLengthBeforePiece(pieceIndex)

    this.editor.setSelectedRange([contentLengthBeforePiece, contentLengthBeforePiece + 1])
    this.editor.deleteInDirection('backward')

    this.editor.insertAttachment(this.attachmentFor(withElement))
    this.inputController.afterAttachmentInsertion()
  }

  constructAttachmentElementForMention(currentTarget) {
    const pieceBeforeCursor = this.cursor.pieceBeforeCaret()
    const pieceIndex = this.trixDocument.getPieces().findIndex(piece => piece.id === pieceBeforeCursor.id)
    const contentLengthBeforePiece = this.inputController.contentLengthBeforePiece(pieceIndex)

    const content = pieceBeforeCursor.string.split('@')[1]

    const start = contentLengthBeforePiece + pieceBeforeCursor.string.split('@')[0].length
    const end = start + content.length + 1

    this.editor.setSelectedRange([start, end])
    this.editor.deleteInDirection('backward')

    return this.attachmentFor(currentTarget)
  }

  attachmentFor(element) {
    const attachmentContent = element.querySelector('[data-content]').cloneNode(true)
    attachmentContent.classList.remove('hidden')

    return new Trix.Attachment({
      content: attachmentContent.outerHTML.toString(),
      contentType: 'mention',
      sgid: element.dataset.sgid,
      id: element.dataset.id
    })
  }
}

export { MentionsManager }
