import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["divider"]

  initialize() {
    this.mOver = this.mOver.bind(this)
    this.mOut = this.mOut.bind(this)
  }

  connect() {
    const elements = document.querySelectorAll(".row")

    elements.forEach((element) => {
      element.addEventListener("touchstart", () => this.mOver(element))
      element.addEventListener("mouseover", () => this.mOver(element))
    })

    elements.forEach((element) => {
      element.addEventListener("touchend", () => this.mOut(element))
      element.addEventListener("touchcancel", () => this.mOut(element))
      element.addEventListener("mouseout", () => this.mOut(element))
    })
  }

  disconnect() {
    const elements = document.querySelectorAll(".row")

    elements.forEach((element) => {
      element.removeEventListener("touchstart", () => this.mOver(element))
      element.removeEventListener("mouseover", () => this.mOver(element))
    })

    elements.forEach((element) => {
      element.removeEventListener("touchend", () => this.mOut(element))
      element.removeEventListener("touchcancel", () => this.mOut(element))
      element.removeEventListener("mouseout", () => this.mOut(element))
    })
  }

  mOver(element) {
    if (element.previousElementSibling) {
      element.previousElementSibling.className =
        element.previousElementSibling.className.replace(
          this.dividerClass,
          "border-white"
        )
    }
  }

  mOut(element) {
    if (element.previousElementSibling) {
      element.previousElementSibling.className =
        element.previousElementSibling.className.replace(
          "border-white",
          this.dividerClass
        )
    }
  }
}
