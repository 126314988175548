import ButtonController from './button_controller'

export default class extends ButtonController {
  validate() {
    if(this.isValid && !this.dispatchedEvent) {
      this.element.setAttribute('data-valid', '')
      this.dispatchedEvent = true
      this.dispatch('valid')

      this.enable(this.element.querySelector('[data-button="remove"]'))
    } else if(this.isInvalid) {
      this.element.removeAttribute('data-valid')
      this.dispatchedEvent = false
      this.dispatch('invalid')
    }
  }

  focus() {
    super.focus(this.inputTarget, { moveCursorToEnd: true })
  }

  reset() {
    this.inputTarget.value = ''
    this.dispatchedEvent = false
  }

  toObject() {
    return {
      type: 'quick_reply',
      title: this.inputTarget.value.trim(),
      text: this.inputTarget.value.trim(),
      index: Array.from(this.element.parentElement.children).indexOf(this.element),
      dataset: this.element.dataset
    }
  }

  get isValid() {
    return this.inputTarget.value.trim().length > 0
  }
}
