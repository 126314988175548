import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submenu"]

  here() {
    const element = event.target

    this.submenuTargets.forEach((el, i) => {
      element.dataset.submenuIndex == i ? el.classList.add("block") : ""
    })
  }

  gone() {
    const element = event.target

    this.submenuTargets.forEach((el, i) => {
      element.dataset.submenuIndex == i ? el.classList.remove("block") : ""
    })
  }
}
