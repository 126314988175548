import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static values = {
    id: String,
  }

  connect() {
    console.log("connecting")
  }

  showPane() {
    this.dispatch("show", { target: this.pane })
  }

  hidePane() {
    this.dispatch("hide", { target: this.pane })
  }

  get pane() {
    return Array.from(document.getElementsByTagName("x-pane"))
      .find((pane) => pane.id === this.idValue)
  }
}
