import {computePosition, autoUpdate, shift, flip, offset, autoPlacement} from "@floating-ui/dom"

import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    placement: { type: String, default: "bottom-start" },
    shiftPadding: { type: Number, default: 24 },
    open: { type: Boolean, default: false },
    alignWidth: { type: Boolean, default: false },
    autoPlacement: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  }

  static targets = ['trigger', 'popover']

  connect() {
    this.floatingUICleanup = autoUpdate(this.triggerTarget, this.popoverTarget, () => {
      computePosition(this.triggerTarget, this.popoverTarget, {
        placement: this.placementValue,
        middleware: this.middlewares,
      }).then(({x, y}) => {
        const newStyle = {
          left: `${x}px`,
          top: `${y}px`
        }

        if(this.alignWidthValue) {
          newStyle.width = `${this.triggerTarget.offsetWidth}px`
        }

        Object.assign(this.popoverTarget.style, newStyle);
      });
    })
  }

  show() {
    this.openValue = true
  }

  hide() {
    this.openValue = false
  }

  toggle() {
    this.openValue = !this.openValue
  }

  onClickOutside(event) {
    if(event.target.closest('dialog') || event.target.tagName === 'DIALOG' || !this.openValue) return

    if (event.target.nodeType && this.element.contains(event.target) === false) {
      this.openValue = false
      setTimeout(() => this.dispatch("aborted"), 400)
    }
  }

  openValueChanged() {
    if(this.disabledValue) return

    this.dispatch("toggle", {
      detail: this.openValue
    })

    if(this.openValue) {
      this.popoverTarget.showPopover()
      this.popoverTarget.setAttribute("aria-expanded", "true")

      this.dispatch("opened")
    } else {
      this.popoverTarget.hidePopover()
      this.popoverTarget.removeAttribute("aria-expanded")

      this.dispatch("hidden")
    }
  }

  get middlewares() {
    const flipMechanism = this.autoPlacementValue ? autoPlacement({ allowedPlacements: ['top', 'bottom' ]}) : flip()

    return [
      shift({ padding: this.shiftPaddingValue }),
      flipMechanism,
    ]
  }
}
