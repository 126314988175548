import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    duration: Number
  }

  static targets = ['highlight']
  static classes = ['highlight']

  highlight() {
    this.addClass(this.highlightTarget, ...this.highlightClasses)

    this.nextTick(() => {
      this.removeClass(this.highlightTarget, ...this.highlightClasses)
    }, this.durationValue)
  }
}
