import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tooltip"]
  static values = {
    wait: { type: Number, default: 500 },
    show: { type: Boolean, default: false }
  }

  static classes = ["visible", "invisible"]

  initialize() {
    super.initialize()
    this.mutationObserver = new MutationObserver(this.callback.bind(this))
  }

  connect() {
    super.connect()

    if(this.showValue) {
      this.show()
    }

    this.mutationObserver.observe(this.element, { attributes: true })
  }

  disconnect() {
    this.mutationObserver.disconnect()
  }

  show() {
    this.showTimeout = setTimeout(() => {
      if(this.disabled) return

      this.tooltipTarget.classList.add("z-50")

      this.tooltipTarget.classList.remove("-z-10")
      this.tooltipTarget.classList.remove(...this.invisibilityClasses)

      setTimeout(() => {
        this.tooltipTarget.classList.add("opacity-100")
        if(this.visibilityClasses.length > 1) {
          this.tooltipTarget.classList.add(...this.visibilityClasses)
        }
      }, this.waitValue)
    }, this.waitValue)
  }

  hide(e) {
    this.hideTimeout = setTimeout(() => {
      if(this.visibilityClasses.length > 1) {
        this.tooltipTarget.classList.remove(...this.visibilityClasses)
      }

      this.tooltipTarget.classList.remove("opacity-100")

      setTimeout(() => {
        this.tooltipTarget.classList.add(...this.invisibilityClasses)
        this.tooltipTarget.classList.add("-z-10")

        if(this.visibilityClasses.length > 1) {
          this.tooltipTarget.classList.remove(...this.visibilityClasses)
        }

      }, this.waitValue)

    }, this.waitValue)

    clearTimeout(this.showTimeout)
  }

  blockHideAction() {
    clearTimeout(this.hideTimeout)
  }

  blockShowAction() {
    clearTimeout(this.showTimeout)
  }

  callback(mutationsList, _) {
    const attributeMutation = mutationsList.find(
      mutation => mutation.type === "attributes" && mutation.attributeName === "data-tooltip-disabled"
    )

    if(attributeMutation) {
      if(this.disabled) {
        this.hide()
      }
    }
  }

  // private

  get visibilityClasses() {
    return this.hasVisibleClass ? this.visibleClasses : [""]
  }

  get invisibilityClasses() {
    return this.hasInvisibleClass ? this.invisibleClasses : ["hidden"]
  }

  get disabled() {
    return this.element.hasAttribute("data-tooltip-disabled")
  }
}
