export const useLineNotifiers = (controller, { element = null } = {}) => {
  element = element || controller.element

  const payload = {
    target: document.querySelector("[data-controller*='audience--line']"),
    prefix: null,
    detail: {
      id: element.dataset.lineId,
      element,
    },
  }

  Object.assign(controller, {
    moveLineToThisElement() {
      this.element.setAttribute('data-line-active', true)
      this.dispatch('line:focus', payload)
    },
    disconnectLineFromThisElement() {
      this.dispatch('line:disconnect', payload)
    },
    blurLine() {
      this.element.removeAttribute('data-line-active')
      this.dispatch('line:blur', payload)
    },
    onLineMouseLeave() {
      this.dispatch('line:mouseleave', payload)
    },
    isMouseWithinElement() {
      const rect = element.getBoundingClientRect()
      return rect.left <= window.mouseX && window.mouseX <= rect.right && rect.top <= window.mouseY && window.mouseY <= rect.bottom
    }
  })
}
