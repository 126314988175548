import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.innerText = '...'

    this.interval = setInterval(() => {
      this.next()
    }, 500)
  }

  disconnect() {
    clearInterval(this.interval)
  }

  next() {
    this.element.innerText += '.'

    if (this.element.innerText.length > 3) {
      this.element.innerText = ''
    }
  }
}
