import MessageController from "./message_controller"

export default class extends MessageController {
  static targets = ["trigger"]

  initialize() {
    super.initialize()
    this.kind = "stop"
  }

  sync({ detail }) {
    if(detail.stepId !== this.element.id || detail.commit === false) return

    this.triggerTarget.classList.remove("halo--active")

    if(detail.journeyNames.length) {
      this.containerTarget.innerText = detail.journeyNames.join(", ")
    } else {
      this.containerTarget.innerText = this.translations.steps.stops.pane.journeys.all
    }

    if(!this.persistedValue) {
      this.dispatch("saved", {
        target: document.documentElement
      })
    }

    this.persistedValue = true
  }
}
