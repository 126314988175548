import LineController from "./line_controller"
import { StraightConnector } from "@jsplumb/core"
import { BlankEndpoint } from "@jsplumb/core"

export default class extends LineController {
  static values = {
    showPane: { type: Boolean, default: true },
    changed: { type: Boolean, default: false },
    name: String,
    triggerId: String,
  }
  static targets = [
    "draggable",
    "trigger",
    "dropzone",
    "triggerInput",
    "triggerKindInput",
    "openModalButton",
    "anyChannelCheckbox",
    "channelsConflictIcon",
    "saveChangesButton",
    "submitButton",
  ]

  initialize() {
    this.changedNameValue = this.nameValue
    super.initialize()
  }

  connect() {
    this.initialLine = this.draw(
      document.getElementById("start"),
      document.getElementById("end"),
      this.defaultDrawOptions
    )

    if (this.showPaneValue) {
      setTimeout(() => {
        window.dispatchEvent(
          new CustomEvent("step:toggle", {
            bubbles: true,
            cancelable: true,
            detail: "journeys--editor_pane",
          })
        )
        this.triggerTarget.classList.add("halo--active")
      }, 1)
    }

    window.triggerId = this.triggerIdValue

    if (!this.showPaneValue) {
      // when the journey is being edited
      // steps are added and connected to each other via JS. Which sets this.changedValue to true
      // although the journey hasn't changed.
      // Wait until all steps are connected to each other and set this.changedValue back to false
      setTimeout(() => {
        this.changedValue = false
      }, 1)
    }

    super.connect()
  }

  disconnect() {
    this.instance.deleteConnection(this.initialLine)
  }

  onWindowSizeChange() {
    this.instance.repaintEverything()
  }

  redrawLine({ detail }) {
    this.changedValue = true

    this.submitButtonTarget.disabled =
      document.querySelectorAll("[data-step]").length === 0 &&
      this.allStepsAreValid

    if (detail.target !== this.element.id) return
    this.instance.deleteConnection(this.initialLine)

    this.initialLine = this.draw(
      document.getElementById("start"),
      document.getElementById(detail.replaceWith),
      this.defaultDrawOptions
    )

    this.dropzoneTarget.setAttribute("data-child", detail.replaceWith)
  }

  reactivateSubmitButtons() {
    if (this.allStepsAreValid && window.triggerId) {
      document
        .querySelectorAll("input[type='submit']")
        .forEach((submitInput) => {
          submitInput.disabled = false
        })
    }
    this.submitButtonTarget.disabled =
      document.querySelectorAll("[data-step]").length === 0 &&
      this.allStepsAreValid
    this.changedValue = false
  }

  removeActiveClass() {
    setTimeout(() => {
      this.triggerTarget.classList.remove("halo--active")
    }, 0)
  }

  showDropzone({ detail }) {
    this.changedValue = true

    if (detail.target === this.element.id || detail.parent === this.element.id) {
      this.instance.deleteConnection(this.initialLine)
      this.initialLine = this.draw(
        document.getElementById("start"),
        document.getElementById(detail.child || "end"),
        this.defaultDrawOptions
      )

      this.instance.repaintEverything()
      this.dropzoneTarget.setAttribute("data-child", detail.child)
      setTimeout(() => this.instance.repaintEverything(), 0)
    }
    this.submitButtonTarget.disabled =
      document.querySelectorAll("[data-step]").length === 0 &&
      this.allStepsAreValid
  }

  alertUserAboutUnsavedChanges(e) {
    if(window.paneId) {
      window.dispatchEvent(
        new CustomEvent("pane:alert", { detail: this.element.id })
      )

      return
    }


    if (this.changedValue || this.changedNameValue !== this.nameValue) {
      this.openModalButtonTarget.click()
      e.preventDefault()
    }
  }

  setUnsavedChangesToFalse() {
    this.changedValue = false
  }

  showConflictIcon() {
    this.channelsConflictIconTarget.classList.remove("hidden")
  }

  hideConflictIcon() {
    if (document.querySelectorAll("[data-conflict]").length === 0) {
      this.channelsConflictIconTarget.classList.add("hidden")
    }
  }

  setTrigger({ dataset }) {

    console.log("Setting trigger to", dataset.trigger)
    this.dispatch("trigger:changed", {
      detail: dataset.trigger,
      target: document.documentElement,
    })

    if (dataset.anyChannel === "true") {
      window.triggerIsChannel = true
      this.anyChannelCheckboxTarget.checked = true
    } else {
      window.triggerIsChannel = false
      this.anyChannelCheckboxTarget.checked = false
    }

    if (dataset.journeyChannels === "true") {
      window.triggerIsChannel = true
      this.triggerKindInputTarget.checked = true
      this.anyChannelCheckboxTarget.checked = false
    } else {
      this.triggerKindInputTarget.checked = false
    }

    window.triggerId = dataset.trigger

    if (window.triggerId) {
      this.saveChangesButtonTarget.disabled = false

      if (
        document.querySelectorAll("[data-step]").length > 1 &&
        this.allStepsAreValid
      ) {
        this.submitButtonTarget.disabled = false
      }
    }

    setTimeout(() => {
      if (this.invalidStepPresent) {
        document
          .querySelectorAll("input[type='submit']")
          .forEach((submitInput) => {
            submitInput.disabled = true
          })
      }
    }, 500) // sync event bus for event management
    this.changedValue = true
  }

  // happens when journey name is changed without errors
  setNameValue(e) {
    this.changedNameValue = e.dataset.name
  }

  resetNameToDefaultValue() {
    this.changedNameValue = this.nameValue
  }

  // private

  get defaultDrawOptions() {
    return {
      fromOptions: this.blankEndpoint("Bottom"),
      toOptions: this.blankEndpoint("Top"),
      lineOptions: StraightConnector.type,
    }
  }

  get invalidStepPresent() {
    return document.querySelectorAll("[data-invalid]").length > 0
  }

  get allStepsAreValid() {
    return !this.invalidStepPresent
  }
}
