import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["previewImage", "button", "fileInput"]

  onImageSelect(e) {
    if (!e.target.files[0]) return

    const imagePath = URL.createObjectURL(e.target.files[0])

    this.previewImageTarget.src = imagePath
    this.previewImageTarget.classList.remove("hidden")
    this.previewImageTarget.classList.add("object-cover")
    this.buttonTarget.classList.add("hidden")

    this.dispatch("set", {
      target: document.documentElement,
      detail: {
        imagePath,
        objectFit: "cover",
      },
    })
  }

  setObjectFitToMosaic() {
    this.dispatch("set:to-mosaic", {
      target: document.documentElement,
    })
  }

  setObjectFitToCover() {
    this.dispatch("set:to-fill", {
      target: document.documentElement,
    })
  }

  remove() {
    this.fileInputTarget.files = new DataTransfer().files
    this.dispatch("remove", {
      target: document.documentElement,
    })

    this.previewImageTarget.src = ""
    this.previewImageTarget.classList.add("hidden")
    this.buttonTarget.classList.remove("hidden")
  }
}
