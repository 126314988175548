import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['input']

  connect() {
    super.connect()

    if(this.isValid) {
      this.element.setAttribute('data-valid', '')
    }
  }

  remove() {
    this.hide()
    this.dispatch('remove')
  }

  get isValid() {
    return false
  }

  get isInvalid() {
    return !this.isValid
  }
}
