import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    url: String,
    count: { type: Number, default: 1 },
    stepId: String,
  }

  spawn({ currentTarget }) {
    get(this.urlValue, {
      responseKind: "turbo-stream",
      query: {
        index: ++this.countValue,
        field: currentTarget.dataset.field,
        layout: window.desktopLayout,
        step_id: this.stepIdValue
      },
    })
  }
}
