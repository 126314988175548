import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.submitListener = this.submitListener.bind(this)
  }

  connect() {
    this.element.addEventListener("submit", this.submitListener)
  }

  disconnect() {
    this.element.removeEventListener("submit", this.submitListener)
  }

  submitListener(e) {
    if(e.key === "Enter" && e.target.tagName !== "TEXTAREA") {
      e.preventDefault()
    }
  }
}
