import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['unreadCountContainer']

  onClick(e) {
    if (this.onMobile && window.location.href.includes("inbox/")) {
      e.stopPropagation()
      e.preventDefault()

      let event = new CustomEvent("inbox:toggle", {
        bubbles: true,
        cancelable: true,
      })
      event.data = this.element.dataset

      window.dispatchEvent(event)
    }
  }

  updateUnreadCountContainer() {
    const count = parseInt(this.unreadCountContainerTarget.textContent) - 1

    if(count === 0) {
      this.unreadCountContainerTarget.classList.add("hidden")
    } else {
      this.unreadCountContainerTarget.textContent = count
      this.unreadCountContainerTarget.classList.remove("hidden")
    }
  }

  unreadCountContainerTargetDisconnected() {
    if(!document.location.href.endsWith('inbox')) return

    const count = parseInt(this.unreadCountContainerTarget.textContent) || 0

    if(count === 1) {
      document.title = t.conversations.unread_messages.one + ' | Hellotext'
    } else if(count > 1) {
      document.title = t.conversations.unread_messages.other.replace('%{count}', count) + ' | Hellotext'
    } else {
      document.title = t.inbox.inbox + '| Hellotext'
    }
  }
}
