class AttachmentManager {
  constructor(controller) {
    this.inputController = controller
  }

  insert(attachment) {
    const pieceAtCursor = this.cursor.pieceAtCaret()
    const pieceBeforeCursor = this.cursor.pieceBeforeCaret()

    if(this.inputController.empty) {
      return this.resetContentAndInsertAttachment(attachment)
    }

    if(pieceAtCursor.endsWithWhitespace || pieceBeforeCursor.endsWithWhitespace) {
      return this.moveCursorToRightAndInsertAttachment(attachment)
    }

    if(pieceAtCursor.isLineFeed && !pieceBeforeCursor) {
      return this.insertAttachment(attachment)
    }

    if(pieceAtCursor.string === pieceBeforeCursor.string || (pieceAtCursor.isLineFeed && pieceBeforeCursor)) {
      const characterAtCursor = this.cursor.characterAtCaret()
      const characterBeforeCursor = this.cursor.characterBeforeCaret()

      // Example: word |another
      if(characterBeforeCursor.isWhitespace) {
        return this.moveCursorToLeftAndInsertAttachmentAndMoveBackToRight(attachment)
      }

      // Example: word| another
      if(characterAtCursor.isWhitespace) {
        return this.insertWhitespaceAndAttachmentAndMoveToRight(attachment)
      }

      if(characterAtCursor.isZeroWidthNonJoinerSpace) {
        return this.removeZeroWidthNonJoinerSpaceAndInsertAttachment(attachment)
      }

      // Example word|
      if(characterAtCursor.isLineFeed) {
        return this.moveCursorToRightAndInsertAttachmentAndMoveToRight(attachment)
      }

      // Example wo|rd
      if(!characterBeforeCursor.isWhitespace && !characterAtCursor.isWhitespace) {
        this.insertWhitespaceTwiceAndThenAttachment(attachment)
      }
    }
  }

  resetContentAndInsertAttachment(attachment) {
    this.inputController.nextTick(() => {
      this.inputController.resetContent()

      this.recordUndoEntryAndInsertAttachment(attachment)

      this.afterAttachmentInsertion()
    })
  }

  moveCursorToRightAndInsertAttachment(attachment) {
    const characterBeforeCursor = this.cursor.characterBeforeCaret()

    // example word|<whitespace>
    if(!characterBeforeCursor.isWhitespace) {
      this.cursor.moveToRight()
    }

    this.inputController.nextTick(() => {
      this.recordUndoEntryAndInsertAttachment(attachment)
    })

    return this.afterAttachmentInsertion()
  }

  insertAttachment(attachment) {
    this.inputController.nextTick(() => {
      this.recordUndoEntryAndInsertAttachment(attachment)
    })

    return this.afterAttachmentInsertion()
  }

  moveCursorToLeftAndInsertAttachmentAndMoveBackToRight(attachment) {
    this.inputController.isParsing = true

    this.cursor.moveToLeft()

    this.inputController.nextTick(() => {
      this.inputController.insertWhitespace()

      this.recordUndoEntryAndInsertAttachment(attachment)

      this.inputController.isParsing = false

      this.afterAttachmentInsertion()
    })
  }

  insertWhitespaceAndAttachmentAndMoveToRight(attachment) {
    this.inputController.isParsing = true

    this.inputController.nextTick(() => {
      this.inputController.insertWhitespace()

      this.recordUndoEntryAndInsertAttachment(attachment)

      this.cursor.moveToRight()
      this.inputController.isParsing = false
    })

    this.afterAttachmentInsertion()
  }

  removeZeroWidthNonJoinerSpaceAndInsertAttachment(attachment) {
    this.inputController.isParsing = true

    this.editor.setSelectedRange([0, 0])
    this.editor.deleteInDirection("forward")

    this.inputController.nextTick(() => {
      this.recordUndoEntryAndInsertAttachment(attachment)

      this.cursor.moveToRight()
      this.inputController.isParsing = false
    })

    this.afterAttachmentInsertion()
  }

  moveCursorToRightAndInsertAttachmentAndMoveToRight(attachment) {
    this.inputController.isParsing = true

    this.inputController.nextTick(() => {
      this.inputController.insertWhitespace()
      this.cursor.moveToRight()

      this.inputController.nextTick(() => {
        this.recordUndoEntryAndInsertAttachment(attachment)

        this.cursor.moveToRight()
        this.inputController.isParsing = false

        return this.afterAttachmentInsertion()
      })
    })
  }

  insertWhitespaceTwiceAndThenAttachment(attachment) {
    this.inputController.isParsing = true

    this.inputController.nextTick(() => {
      this.splitWordAtCursor()
      this.cursor.moveToLeft()

      this.inputController.nextTick(() => {
        this.recordUndoEntryAndInsertAttachment(attachment)
        this.inputController.isParsing = false

        return this.afterAttachmentInsertion()
      })
    })
  }

  afterAttachmentInsertion() {
    this.inputController.nextTick(() => {
      const characterAfterCursor = this.cursor.characterAfterCaret()
      const characterAtCursor = this.cursor.characterAtCaret()

      if(characterAtCursor.isWhitespace && characterAfterCursor.isWhitespace) {
        this.cursor.moveToRight()
      }

      if(characterAtCursor.isLineFeed || characterAfterCursor.isLineFeed) {
        this.inputController.isParsing = true

        this.inputController.insertWhitespace()
        this.cursor.moveToRight()

        this.inputController.isParsing = false
      }

      this.inputController.afterAttachmentInsertion()
    })
  }

  deleteAttachmentInPosition() {
    // When the cursor is next to a shortlink. Pressing backspace only selects the shortlink since it is an attachment
    // We want to delete the shortlink instead of selecting it.

    const [start, end] = this.editor.getSelectedRange()

    this.editor.setSelectedRange([start - 1, end])
    this.editor.deleteInDirection('backward')

    if(this.trixDocument.toString().length === 2) {
      const characterAtCursor = this.cursor.characterAtCaret()
      const characterAfterCursor = this.cursor.characterAfterCaret()

      if(characterAtCursor.isZeroWidthNonJoinerSpace && characterAfterCursor.isLineFeed) {
        this.editor.setSelectedRange([0, 0])
        this.editor.deleteInDirection('forward')
        this.cursor.moveToRight()
      }
    }
  }

  splitWordAtCursor() {
    // Transforms wo|rd to wo  |rd
    this.inputController.insertWhitespace()
    this.inputController.insertWhitespace()
  }

  recordUndoEntryAndInsertAttachment(attachment) {
    this.editor.recordUndoEntry('insertAttachment')
    this.editor.insertAttachment(attachment)
  }

  get cursor() {
    return this.inputController.cursor
  }

  get editor() {
    return this.inputController.editor
  }

  get trixDocument() {
    return this.editor.getDocument()
  }

  get oldPosition() {
    return this.inputController.oldPosition
  }

  get trixPosition() {
    return this.inputController.trixPosition
  }
}

export { AttachmentManager }
