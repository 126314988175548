import { Controller } from "@hotwired/stimulus"
import { useDispatch } from "stimulus-use"

export default class extends Controller {
  static targets = [
    "inbox",
    "conversations",
    "chat",
    "skeleton",
    "filtersSkeleton",
    "currentConversation",
    "assignedUserAvatar",
    "closeButton",
    "assignedConversationActions",
    "closedConversationActions",
    "assignedClose",
    "skeletonTemplate",
    "conversationsList"
  ]


  connect() {
    super.connect()
    useDispatch(this, { eventPrefix: false })
  }

  showInbox({ currentTarget } = {}) {
    this.conversationsTarget.classList.add("hidden")
    this.inboxTarget.classList.remove("hidden")

    this.skeletonTarget.classList.remove("hidden")
    this.currentConversationTarget.classList.add("hidden")

    if(!currentTarget) return

    const { closeable, state } = currentTarget.dataset

    if(state === "unassigned") {
      this.assignedUserAvatarTarget.classList.add("hidden")
      this.closedConversationActionsTarget.classList.add("hidden")

      this.assignedConversationActionsTarget.classList.remove("hidden")

      if(closeable === "true") {
        this.assignedCloseTarget.classList.remove("hidden")
      } else {
        this.assignedCloseTarget.classList.add("hidden")
      }
    } else if(state === "assigned") {
      this.closedConversationActionsTarget.classList.add("hidden")

      this.assignedUserAvatarTarget.classList.remove("hidden")
      this.assignedConversationActionsTarget.classList.remove("hidden")
    } else if(state === "closed") {
      this.assignedUserAvatarTarget.classList.add("hidden")
      this.assignedConversationActionsTarget.classList.add("hidden")

      this.closedConversationActionsTarget.classList.remove("hidden")
    }

    if(closeable !== "true") {
      this.closeButtonTarget.classList.add("hidden")
    }
  }

  toggleInbox() {
    this.inboxTarget.classList.toggle("hidden")
    this.conversationsTarget.classList.toggle("hidden")

    if (this.inboxTarget.classList.contains("hidden")) {
      this.shown = "conversations"
    } else {
      this.shown = "inbox"
    }
  }

  showConversationsList(e) {
    this.conversationsTarget.classList.remove("hidden")
    this.inboxTarget.classList.add("hidden")

    this.shown = "conversations"
  }

  showSkeletonLoaders() {
    if (this.hasSkeletonTarget) {
      this.skeletonTarget.classList.remove("hidden")
    }
  }

  hideSkeletonLoaders() {
    if (this.hasSkeletonTarget) {
      this.skeletonTarget.classList.add("hidden")
    }
  }

  // when a filter is clicked, display the skeleton loaders that will be hidden by response
  showFiltersSkeleton({ currentTarget }) {
    const { filterCount = 3 } = currentTarget.dataset

    for (let i = 0; i < filterCount - 1; i++) {
      const skeleton = this.filtersSkeletonTarget.firstElementChild.cloneNode(true)
      this.filtersSkeletonTarget.appendChild(skeleton)

      if(i > 8) break
    }

    this.conversationsListTarget.classList.add("hidden")
    this.filtersSkeletonTarget.classList.remove("hidden")

    this.conversationsTarget.scroll({ top: 0, behavior: "auto" })
    this.dispatch("reset-pages")
  }

  get indexPage() {
    return window.location.href.endsWith("inbox")
  }
}
