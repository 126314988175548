import { Controller } from "@hotwired/stimulus"
import { gsap } from "gsap"

import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Observer } from "gsap/Observer"

gsap.registerPlugin(ScrollTrigger, Observer)

export default class extends Controller {
  connect() {
    // Before & After
    var tl_ba = gsap.timeline()

    tl_ba.to("#transition_before", {
      autoAlpha: 0,
      duration: 0.3,
    })
    tl_ba.to("#transition_after", {
      autoAlpha: 1,
      duration: 0.3,
      delay: -0.2,
      display: "block",
    })

    ScrollTrigger.create({
      // anticipatePin: 1,
      trigger: "#transition_container",
      pin: true,
      id: "container",
      start: "top 50px",
    })

    ScrollTrigger.create({
      animation: tl_ba,
      trigger: "#transition_before .transition-list",
      id: "before",
      start: "center center",
      scrub: true,
      toggleActions: "restart complete reverse complete",
    })
  }

  disconnect() {
    ScrollTrigger.killAll()
  }
}
