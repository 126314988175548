import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select"]

  selectOption({ currentTarget }) {
    const value = currentTarget.dataset.optionValue
    const selectedOption = Array.from(this.selectTarget.options).find((option => option.value === value))

    if(selectedOption) {
      selectedOption.selected = true
    } else {
      const newOption = document.createElement("option")
      newOption.text = value
      newOption.value = value
      newOption.selected = true

      this.selectTarget.add(newOption)
    }

    this.selectTarget.dispatchEvent(new Event("change"))
  }

  select({ detail: option }) {
    this.selectFromOptions(option, false)
  }

  // private

  selectFromOptions(value, propagate = true) {
    const selectedOption = Array.from(this.selectTarget.options).find((option => option.value === value))

    if(selectedOption) {
      selectedOption.selected = true
    }

    if (propagate === false) return

    this.selectTarget.dispatchEvent(new Event("change"))
  }
}
