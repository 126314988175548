import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    filter: String,
    replace: String,
    notify: { type: Boolean, default: false },
    data: Array,
    label: String,
  }

  connect() {
    const dropdown = this.element.querySelector(
      '[data-custom-dropdown-target="menu"]'
    )
    dropdown
      ?.querySelectorAll("button")
      .forEach((button) => this._sendRequestTo(button.dataset.url, true))
  }

  change({ target }) {
    // document
    //   .querySelector('[data-chart--filters-target="metric"][data-chart="true"]')
    //   .classList.add("hidden")

    // this._displaySkeletonLoaders(target.dataset)
    this._sendRequestTo(target.dataset.url)
  }

  _displaySkeletonLoaders(dataset) {
    const element = document.getElementById(dataset.skeleton)
    element.firstElementChild.classList.remove("hidden")
  }

  _sendRequestTo(targetUrl, skeleton = false) {

    const url = new URL(targetUrl.concat(window.location.search.replace("?", "&")))
    url.searchParams.append("measurement", this.filterValue)
    // url.searchParams.append("replace", this.element.id)

    if (skeleton) {
      url.searchParams.append("skeleton", "1")
    }
    //
    // Array.from(document.querySelectorAll("[id^=track_action_]"))
    //   .filter((el) => el.id !== this.element.id)
    //   .forEach((el) =>
    //     url.searchParams.append(`exclude[]`, el.id.split("track_action_")[1])
    //   )

    get(url.toString(), { responseKind: "turbo-stream" })
  }
}
