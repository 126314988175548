import { Controller } from "@hotwired/stimulus"

import { useColorPickerVisibilityControls } from "../mixins/useColorPickerVisibilityControls"
import {
  useCenterAlignment,
  useLeftAlignment,
  useRightAlignment,
} from "../mixins/tools/useAlignment"

export default class extends Controller {
  static values = {
    textColor: { type: String, default: "#ffffff" },
    backgroundColor: { type: String, default: "#ffffff" },
  }

  static classes = ["active", "inactive"]
  static targets = [
    "container",
    "activeAlignment",
    "menu",
    "alignmentButton",
    "alignmentInput",
    "textColorPreview",
    "colorPickerControls",
    "colorInput",
    "input",
    "label",
    "colorButton",
    "backgroundButton",
    "backgroundInput",
    "backgroundColorPreview",
    "backgroundPickerControls",
    "backgroundContainer",
  ]

  initialize() {
    this.enableSync = true
  }

  connect() {
    useColorPickerVisibilityControls(this, {
      pickerTarget: this.colorPickerControlsTarget,
      onColorPickerHide: () => {
        this.backgroundPickerControlsTarget.classList.add("hidden")
      },
    })

    useLeftAlignment(this, {
      on: this.containerTarget,
      applyClasses: ["justify-start"],
      removeClasses: ["justify-end", "justify-center"],
      afterAlignment: () => this.syncAlignmentTo("left"),
    })

    useCenterAlignment(this, {
      on: this.containerTarget,
      applyClasses: ["justify-center"],
      removeClasses: ["justify-start", "justify-end"],
      afterAlignment: () => this.syncAlignmentTo("center"),
    })

    useRightAlignment(this, {
      on: this.containerTarget,
      applyClasses: ["justify-end"],
      removeClasses: ["justify-start", "justify-center"],
      afterAlignment: () => this.syncAlignmentTo("right"),
    })
  }

  changeAlignmentActiveClassTo(button) {
    this.alignmentButtonTargets.forEach((alignmentButton) => {
      if (alignmentButton === button) {
        alignmentButton.classList.add("text-tiger")
      } else {
        alignmentButton.classList.remove("text-tiger")
      }
    })
  }

  sync() {
    if (!this.enableSync) return

    this.dispatch("sync", {
      target: this.otherIdenticalElement,
      detail: this.currentState,
    })
  }

  updateInput(e) {
    this.inputTarget.value = e.currentTarget.innerText.trim()
    this.dispatch("changed", {
      detail: {
        attribute: "text",
        value: this.inputTarget.value,
      },
    })

    this.sync()
  }

  replaceWithSynced({ detail }) {
    this.enableSync = false

    Promise.resolve()
      .then(() => {
        switch (detail.alignmentValue) {
          case "left":
            this.alignToLeft({
              currentTarget: this.element.querySelector("[data-align='left']"),
            })
            break
          case "center":
            this.alignToCenter({
              currentTarget: this.element.querySelector(
                "[data-align='center']"
              ),
            })
            break
          case "right":
            this.alignToRight({
              currentTarget: this.element.querySelector("[data-align='right']"),
            })
            break
        }

        this.dispatch("color:change", {
          target: this.colorPickerControlsTarget,
          detail: detail.textColor,
        })

        this.dispatch("color:change", {
          target: this.backgroundPickerControlsTarget,
          detail: detail.backgroundColor,
        })

        this.labelTarget.innerText = this.inputTarget.value = detail.labelValue
      })
      .then(() => (this.enableSync = true))
  }

  toggleBackgroundPickerControls(e) {
    if (
      this.backgroundButtonTarget.contains(e.target) === false &&
      this.backgroundPickerControlsTarget.classList.contains("hidden") === false
    ) {
      this.backgroundPickerControlsTarget.classList.add("hidden")
      return
    }

    if (
      this.backgroundPickerControlsTarget.classList.contains("hidden") &&
      this.backgroundButtonTarget.contains(e.target)
    ) {
      this.colorPickerControlsTarget.classList.add("hidden")
      this.backgroundPickerControlsTarget.classList.remove("hidden")
    }
  }

  hidePicker() {
    this.colorPickerControlsTarget.classList.add("hidden")
  }

  changeColor(e) {
    if (this.colorButtonTarget.contains(e.target)) {
      this.textColorPreviewTarget.style.background = e.detail
      this.containerTarget.style.color = e.detail

      this.textColorValue = e.detail

      this.colorInputTarget.value = e.detail

      this.dispatch("changed", {
        detail: {
          attribute: "color",
          value: this.textColorValue,
        },
      })
    } else {
      this.backgroundColorPreviewTarget.style.background = e.detail
      this.backgroundContainerTarget.style.background = e.detail

      this.backgroundColorValue = e.detail
      this.backgroundInputTarget.value = e.detail

      this.dispatch("changed", {
        detail: {
          attribute: "background_color",
          value: this.backgroundColorValue,
        },
      })
    }

    this.sync()
  }

  // private

  syncAlignmentTo(alignment) {
    this.changeAlignmentActiveClassTo(
      this.element.querySelector(`[data-align='${alignment}']`)
    )
    this.alignmentInputTarget.value = alignment
    this.sync()

    this.dispatch("changed", {
      detail: {
        attribute: "alignment",
        value: alignment,
      },
    })
  }

  get otherIdenticalElement() {
    return Array.from(
      document.querySelectorAll(`[data-controller='popup--bubble dropdown']`)
    ).find((layoutSection) => layoutSection !== this.element)
  }

  get currentState() {
    return {
      alignmentValue: this.alignmentInputTarget.value,
      textColor: this.textColorValue,
      backgroundColor: this.backgroundColorValue,
      labelValue: this.inputTarget.value,
    }
  }
}
