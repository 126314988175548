import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    'templatesIcon',
    'templates',
    'input'
  ]

  initialize() {
    this.state = { templatesVisible: false }
    this.observer = new MutationObserver(
      this.onMutation.bind(this),
      { attributes: true }
    )
  }

  connect() {
    this.observer.observe(this.templatesTarget, { attributes: true })
  }

  toggleTemplates() {
    this.dispatch('templates:toggle', {
      target: this.templatesTarget,
      detail: this.state.templatesVisible = !this.state.templatesVisible
    })

    this.onTemplatesVisibilityChange()
  }

  onMutation(mutations) {
    mutations.forEach((mutation) => {
      if(mutation.type === 'attributes') {
        this.state.templatesVisible = mutation.target.hasAttribute('data-visible')
        this.onTemplatesVisibilityChange()
      }
    })
  }

  onTemplatesVisibilityChange() {
    if(this.state.templatesVisible) {
      this.addClass(this.templatesIconTarget, 'text-tiger',)
    } else {
      this.removeClass(this.templatesIconTarget, 'text-tiger',)
    }
  }
}
