export const useLeftAlignment = (
  controller,
  { applyClasses, removeClasses, on, afterAlignment = () => {} }
) => {
  Object.assign(controller, {
    alignToLeft(e) {
      this.activeAlignmentTarget.innerHTML = e.currentTarget.innerHTML

      on.classList.remove(...removeClasses)
      on.classList.add(...applyClasses)
      afterAlignment(e)
    },
  })
}

export const useCenterAlignment = (
  controller,
  { applyClasses, removeClasses, on, afterAlignment = () => {} }
) => {
  Object.assign(controller, {
    alignToCenter(e) {
      this.activeAlignmentTarget.innerHTML = e.currentTarget.innerHTML

      on.classList.remove(...removeClasses)
      on.classList.add(...applyClasses)
      afterAlignment(e)
    },
  })
}

export const useRightAlignment = (
  controller,
  { applyClasses, removeClasses, on, afterAlignment = () => {} }
) => {
  Object.assign(controller, {
    alignToRight(e) {
      this.activeAlignmentTarget.innerHTML = e.currentTarget.innerHTML

      on.classList.remove(...removeClasses)
      on.classList.add(...applyClasses)
      afterAlignment(e)
    },
  })
}

export const useFullWidthAlignment = (
  controller,
  { applyClasses, removeClasses, on, afterAlignment = () => {} }
) => {
  Object.assign(controller, {
    alignToFullWidth(e) {
      this.activeAlignmentTarget.innerHTML = e.currentTarget.innerHTML

      on.classList.remove(...removeClasses)
      on.classList.add(...applyClasses)
      afterAlignment(e)
    },
  })
}
