import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["error", "codeInput", "submitButton", "facebookLoginButton"]

  initialize() {
    this.facebookLoginCallback = this.facebookLoginCallback.bind(this)
  }

  connect() {
    const appId = this.valueOfMetaTag("meta-app-id")

    window.fbAsyncInit = function () {
      FB.init({
        appId,
        cookie: true,
        xfbml: true,
        version: 'v20.0'
      });
    };

    window.fbAsyncInit()
  }

  start() {
    const config = {
      config_id: 1343256599651519,
      override_default_response_type: true,
      response_type: 'code',
      extras: {
        feature: 'whatsapp_embedded_signup'
      }
    }

    window.FB.login(this.facebookLoginCallback, config);
  }

  facebookLoginCallback(response) {
    if (response.authResponse) {
      this.codeInputTarget.value = response.authResponse.code

      this.enable(this.submitButtonTarget)
      // this.submitButtonTarget.innerText = this.submitButtonTarget.dataset.disableWith
      this.submitButtonTarget.click()
    } else {
      this.show(this.errorTarget)
    }
  }
}
