import { post } from '@rails/request.js'
import ModalController from '../modal_controller'

export default class extends ModalController {
  static values = {
    url: String,
  }

  static targets = [
    'confirmButton',
  ]

  open({ currentTarget }) {
    this.id = currentTarget.dataset.id
    this.subtitleTarget.innerHTML = t.admin.campaign.rejection_reasons.rejection_reason.payment.description[currentTarget.dataset.type]

    super.open()
  }

  async submit() {
    this.confirmButtonTarget.querySelector('[data-text]').innerText = this.confirmButtonTarget.dataset.disableWith
    this.confirmButtonTarget.disabled = true

    this.backdropCloseValue = false

    await post(this.urlValue.replace(':id', this.id), {
      responseKind: 'turbo-stream',
    })

    this.confirmButtonTarget.querySelector('[data-text]').innerText = t.actions.confirm_and_continue
    this.confirmButtonTarget.disabled = false

    this.backdropCloseValue = true

    this.close()
  }
}
