import { computePosition, autoUpdate, flip } from '@floating-ui/dom'

import ApplicationController from '../application_controller'
import { useURLValidations } from '../mixins/useURLValidations'

export default class extends ApplicationController {
  static values = {
    placement: String
  }

  static targets = ['input']

  initialize() {
    super.initialize()
    this.placementValue ||= 'top-start'
  }

  connect() {
    super.connect()
    useURLValidations(this)
  }

  show({ detail }) {
    this.clearFocusedElement()
    const { element, url, id } = detail

    this.focusedElement = element
    this.focusedElementId = id

    this.initialValue = url
    this.inputTarget.value = url

    this.setup()
  }

  setup() {
    this.floatingUICleanup = autoUpdate(this.focusedElement, this.element, () => {
      computePosition(this.focusedElement, this.element, {
        placement: this.placementValue,
        middleware: [
          flip()
        ]
      }).then(({x, y}) => {
        Object.assign(this.element.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
      });
    })

    super.show()
    this.focus(this.inputTarget, { moveCursorToEnd: true })
  }

  async update() {
    if(this.invalidURL(this.inputTarget.value)) {
      return this.addClass(this.inputTarget, 'border-red-500')
    }

    if(this.unchanged) {
      return this.cancel()
    }

    this.removeClass(this.inputTarget, 'border-red-500')

    this.dispatch('updated', {
      detail: {
        id: this.focusedElementId,
        url: this.inputTarget.value,
      }
    })

    this.clearFocusedElement()
    this.hide()

    this.dispatch('canceled')
  }

  cancel() {
    this.element.classList.add('hidden')
    this.clearFocusedElement()

    this.dispatch('cancelled')
  }

  destroy() {
    this.focusedElement.remove()
    this.element.classList.add('hidden')
    this.floatingUICleanup()

    this.dispatch('canceled')
  }

  clearAndHide({ target }) {
    if(this.isVisible() && !this.element.contains(target)) {
      this.element.classList.add('hidden')
      this.clearFocusedElement()
    }
  }

  clearFocusedElement() {
    if(this.floatingUICleanup) {
      this.floatingUICleanup()
    }

    if(this.focusedELementLink) {
      this.dispatch('finished', {
        target: this.focusedELementLink
      })
    }

    this.focusedElementId = null
    this.focusedElement = null
  }

  closeByEscape() {
    if(this.invisible) return

    this.cancel()
  }

  editLink({ detail }) {
    this.show({
      detail: {
        element: detail.figureElement,
        url: detail.clientUrl,
        id: detail.id
      }
    })

    this.dispatch('start', {
      target: this.focusedELementLink
    })
  }

  // private

  get unchanged() {
    return this.inputTarget.value.trim() === this.initialValue.trim()
  }
}
