import ComposeController from '../compose/base_controller'
import { useURLValidations } from "../mixins/useURLValidations"
import {KeyboardKey} from "@/controllers/compose/models";

export default class extends ComposeController {
  static targets = [
    'toolbar',
    'italicButton',
    'boldButton',
    'linkButton',
    'linkInput',
    'addLinkButton',
    'editLinkContainer',
    'newLinkContainer',
  ]

  onTrixFocus() {
    this.element.classList.add('border-solid', 'border-tiger')
    this.show(this.toolbarTarget)
    this.focused = true
  }


  blur() {
    this.trixTarget.blur()
    this.element.classList.remove('border-solid', 'border-tiger')
    this.hide(this.toolbarTarget)
    this.focused = false
  }

  onClickOutside(event) {
    if(this.element.contains(event.target) || !this.focused) return
    this.blur()
  }

  onTrixSelectionChange() {
    super.onTrixSelectionChange()

    if(this.editor.attributeIsActive('italic')) {
      this.italicButtonTarget.classList.add('text-tiger')
    } else {
      this.italicButtonTarget.classList.remove('text-tiger')
    }

    if(this.editor.attributeIsActive('bold')) {
      this.boldButtonTarget.classList.add('text-tiger')
    } else {
      this.boldButtonTarget.classList.remove('text-tiger')
    }

    this.linkButtonTarget.disabled = this.cursor.stringAtRange().trim().length === 0 && !this.editor.attributeIsActive('href')

    if(this.editor.attributeIsActive('href')) {
      this.linkButtonTarget.classList.add('text-tiger')
    } else {
      this.linkButtonTarget.classList.remove('text-tiger')
    }
  }

  toggleBold() {
    if(this.editor.attributeIsActive('bold')) {
      this.editor.deactivateAttribute('bold')
    } else {
      this.editor.activateAttribute('bold')
    }
  }

  onTrixKeydown(event) {
    const key = KeyboardKey.fromKeyboardEvent(event)

    if(key.activateLink) {
      event.preventDefault()

      if(this.cursor.stringAtRange(this.currentRange).length > 0 || this.editor.attributeIsActive('href')) {
        this.toggleLinkContainer()
      }
    }
  }


  toggleLinkContainer() {
    const pieceAtCaret = this.cursor.pieceAtCaret()

    if(pieceAtCaret.isAnchor) {
      this.linkInputTarget.value = pieceAtCaret.piece.attributes.get('href')

      this.editLinkContainerTarget.classList.remove("hidden")
      this.addLinkButtonTarget.classList.add("hidden")

      const pieceIndex = this.trixDocument.getPieces().indexOf(pieceAtCaret.piece)
      const contentLengthBeforePiece = this.contentLengthBeforePiece(pieceIndex)
      this.linkRange = [contentLengthBeforePiece, contentLengthBeforePiece + pieceAtCaret.piece.string.length]
    } else {
      this.editLinkContainerTarget.classList.add("hidden")
      this.addLinkButtonTarget.classList.remove("hidden")
      this.linkRange = this.trixPosition
    }

    this.newLinkContainerTarget.classList.toggle("hidden")
    this.editor.activateAttribute('frozen')

    this.focus(this.linkInputTarget, { moveCursorToEnd: true })
  }

  saveLink() {
    const url = this.linkInputTarget.value

    this.editor.activateAttribute('href', url)
    this.editor.deactivateAttribute('frozen')

    this.newLinkContainerTarget.classList.add("hidden")
    this.linkInputTarget.value = 'https://www.'

    this.editor.setSelectedRange([this.trixPosition[1], this.trixPosition[1]])
    this.trixTarget.focus()
  }

  abortLinkEdit() {
    this.editor.deactivateAttribute('frozen')

    this.newLinkContainerTarget.classList.add("hidden")
    this.linkInputTarget.value = 'https://www.'

    if (this.trixPosition) {
      this.editor.setSelectedRange([this.trixPosition[1], this.trixPosition[1]])
    }

    this.trixTarget.focus()
  }

  unlink() {
    this.editor.setSelectedRange(this.linkRange)

    this.editor.deactivateAttribute('href')
    this.editor.deactivateAttribute('frozen')

    this.abortLinkEdit()
  }
}
