import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["message"]
  static classes = ["visible", "invisible", "error", "information"]
  static values = {
    visible: Boolean,
    initialColor: String,
    automaticallyHides: { type: Boolean, default: true },
    gracePeriod: { type: Number, default: 5000 }
  }

  initialize() {
    this.hide = this.hide.bind(this)
  }

  connect() {
    if (this.visibleValue) this.showElement()

    if(this.automaticallyHidesValue) {
      setTimeout(this.hide, this.gracePeriodValue)
    }
  }

  show({ detail }) {
    if(detail.message) {
      this.messageTarget.innerText = detail.message
      this.messageTarget.classList.remove('hidden')
    }

    if(detail.type === "error") {
      this.element.classList.replace(this.initialColorValue, this.errorClass)
    }

    this.showElement()

    if(detail.autoHide) {
      setTimeout(this.hide, detail.hideAfter || this.gracePeriodValue)
    }
  }

  hide() {
    this.element.classList.remove(...this.elementVisibleClass)
    this.element.classList.add(this.invisibleClass)
    this.element.classList.replace(this.errorClass, this.initialColorValue)
  }

  // private

  showElement() {
    this.element.classList.remove(this.invisibleClass)
    this.element.classList.add(...this.elementVisibleClass)
  }

  get elementVisibleClass() {
    return ['right-0', this.visibleClass]
  }
}
