import ApplicationController from '../application_controller'

import { KeyboardKey } from "../compose/models";
import { useLineNotifiers } from '../mixins/useLineNotifiers'
import { useBatchUpdateNotifiers } from '../mixins/useBatchUpdateNotifiers'

export default class extends ApplicationController {
  static values = {
    day: String,
    month: String,
    year: String,
    data: Object,
  }

  static targets = [
    "day",
    "month",
    "year",
    "deleteButton",
    "displayError",
    "inputGroup",
    "form",
    "button",
    "container",
    "overrideCheckbox",
  ]

  connect() {
    super.connect();

    this.dayValue = this.dayTarget.value
    this.monthValue = this.monthTarget.value
    this.yearValue = this.yearTarget.value

    useLineNotifiers(this)
    useBatchUpdateNotifiers(this, {
      onBatchUpdateCancellation: () => {
        if(this.blockedAnchor) {
          return this.blockedAnchor.click()
        }

        this.focus()
      },
      onBatchUpdateAbortion: () => this.restore()
    })
  }

  focus() {
    this.buttonTarget.classList.add("hidden")
    this.containerTarget.classList.remove("hidden")

    this.dayTarget.focus()
    this.nextTick(() => this.moveLineToThisElement())
  }

  blur() {
    this.buttonTarget.classList.remove("hidden")
    this.containerTarget.classList.add("hidden")
    this.blurLine()

    this.unsetError()

    this.overrideCheckboxTargets.forEach(checkbox => checkbox.checked = false)
  }

  onDayChange(e) {
    if(this.dayTarget.value.length === 2 || parseInt(this.dayTarget.value.charAt(0)) > 3) {
      this.monthTarget.focus()
    }

    this.dayTarget.nextElementSibling.checked = this.dayTarget.value.length > 0
  }

  onDayKeydown(e) {
    const key = KeyboardKey.fromKeyboardEvent(e)

    if(key.isRightArrow && (this.dayTarget.value.length === 2 || parseInt(this.dayTarget.value.charAt(0)) > 3)) {
      e.preventDefault()
      super.focus(this.monthTarget, { moveCursorToStart: true })
    }
  }

  onMonthKeydown(e) {
    const key = KeyboardKey.fromKeyboardEvent(e)

    if(key.isBackspace && this.monthTarget.value.length === 0) {
      this.dayTarget.focus()
    }

    if(key.isLeftArrow && this.monthTarget.selectionStart === 0) {
      e.preventDefault()
      this.dayTarget.focus()
    }

    if(key.isRightArrow && this.monthTarget.selectionStart === 2) {
      e.preventDefault()
      super.focus(this.yearTarget, { moveCursorToStart: true })
    }
  }

  onMonthChange() {
    if(this.monthTarget.value.length === 2 || parseInt(this.monthTarget.value.charAt(0)) > 1) {
      this.yearTarget.focus()
    }

    this.monthTarget.nextElementSibling.checked = this.monthTarget.value.length > 0
  }

  onYearChange() {
    this.yearTarget.nextElementSibling.checked = this.yearTarget.value.length > 0
  }

  onYearKeydown(e) {
    const key = KeyboardKey.fromKeyboardEvent(e)

    if(key.isBackspace && this.yearTarget.value.length === 0) {
      this.monthTarget.focus()
    }

    if(key.isLeftArrow && this.yearTarget.value.length === 0) {
      e.preventDefault()
      this.monthTarget.focus()
    }
  }

  moveCursor({ currentTarget, key }) {
    if(currentTarget.value.length === 0 && currentTarget === this.yearTarget && key === "Backspace") {
      this.moveSelectionToEndOfInput(this.monthTarget)
      this.monthTarget.focus()
    }

    if(currentTarget.value.length === 0 && currentTarget === this.monthTarget && key === "Backspace") {
      this.moveSelectionToEndOfInput(this.dayTarget)
      this.dayTarget.focus()
    }
  }

  saveChanges(e) {
    if(
      this.containerTarget.classList.contains("hidden")
      || this.hasFocus
      || e.target.getAttribute('data-audience--contact-target') === 'checkbox'
      || this.containerTarget.contains(e.target)
      || this.isBlockedAnchor(e)
    ) return

    if(this.inBatchUpdateMode() && e.target.tagName === 'A' || e.target.closest('a')) {
      e.preventDefault()
      e.stopPropagation()

      this.blockedAnchor = e.target.closest('a') || e.target
    }

    this.submit()
  }

  submit() {
    if(this.inBatchUpdateMode() && this.dirty) {
      return this.requestBatchUpdateConfirmation()
    }

    if(!this.dirty) {
      return this.blur()
    }

    if(this.element.contains(document.activeElement)) {
      document.activeElement.blur()
    }

    this.inputGroupTarget.classList.add("readonly")

    if(this.empty) {
      this.deleteButtonTarget.click()
    } else {
      this.formTarget.requestSubmit()
    }
  }

  restore() {
    this.blur()

    this.dayTarget.value = this.dataValue.day || ""
    this.monthTarget.value = this.dataValue.month || ""
    this.yearTarget.value = this.dataValue.year || ""

    this.unsetError()

    window.blockedAnchor = null
  }

  validatePastedContent(e) {
    const parsedClipboardData = parseInt(e.clipboardData.getData('text/plain'))

    if(isNaN(parsedClipboardData)) {
      this.showToast({
        message: this.translations.activerecord.errors.models['property/date'].attributes.invalid_pasted_content,
        type: 'error',
        autoHide: true
      })
    } else {
      e.target.value = parsedClipboardData
    }
  }

  onSubmitEnd() {
    this.inputGroupTarget.classList.remove("readonly")
    this.dayTarget.focus()
  }

  onOverrideCheckboxChange({ target }) {
    target.previousElementSibling.focus()
  }

  // private

  unsetError() {
    this.element.querySelector('[data-error]')?.remove()
  }

  get dirty() {
    return (this.dayValue !== this.dayTarget.value)
      || (this.monthValue !== this.monthTarget.value)
      || (this.yearValue !== this.yearTarget.value)
      || this.overrideCheckboxTargets.some(checkbox => checkbox.checked)
  }

  get hasFocus() {
    return document.activeElement === this.dayTarget
      || document.activeElement === this.monthTarget
      || document.activeElement === this.yearTarget
  }

  get empty() {
    return this.dayTarget.value.trim().length === 0
      && this.monthTarget.value.trim().length === 0
      && this.yearTarget.value.trim().length === 0
  }

  get hasError() {
    return this.displayErrorTarget.classList.contains("hidden")
  }

  getValueForBatch() {
    const object = {}

    if(this.dayTarget.value.length > 0 || this.dayTarget.nextElementSibling.checked) {
      object.day = this.dayTarget.value
    }

    if(this.monthTarget.value.length > 0 || this.monthTarget.nextElementSibling.checked) {
      object.month = this.monthTarget.value
    }

    if(this.yearTarget.value.length > 0 || this.yearTarget.nextElementSibling.checked) {
      object.year = this.yearTarget.value
    }

    return object
  }
}
