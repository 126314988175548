import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [
    'campaignsCount',
    'campaignsCountParagraph',
    'decrement',
  ]

  decrementTargetConnected() {
    this.decrementTarget.remove()

    const count = parseInt(this.campaignsCountTarget.textContent) - 1

    if(count === 0) {
      return this.hide(this.campaignsCountParagraphTarget, this.campaignsCountTarget)
    }

    this.campaignsCountTarget.textContent = count

    if(count === 1) {
      this.campaignsCountParagraphTarget.textContent = t.admin.businesses.business.upcoming_campaigns.one
    } else {
      this.campaignsCountParagraphTarget.textContent = t.admin.businesses.business.upcoming_campaigns.other.replace('%{count}', count)
    }
  }
}
