import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ["amountToPay", "rechargeAmountInput"]

  connect() {
    super.connect()
    this.focus(this.rechargeAmountInputTarget, { moveCursorToEnd: true })
  }

  onInputValueChanged({ target }) {
    if(this.hasAmountToPayTarget) {
      this.amountToPayTarget.innerText = `$${target.value}`
    }
  }
}
