import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    text: String,
    copiedLabel: { type: String, default: "Copied!" },
    successTimeout: { type: Number, default: 3000 }
  }

  static targets = ["button", "success"]
  static classes = ["hidden"]

  initialize() {
    this.copiedLabelValue = this.translations.actions.copied
  }

  connect() {
    this.originalContent = this.buttonTarget.cloneNode(true)
  }

  async copy() {
    await navigator.clipboard.writeText(this.textValue)

    if(this.hasSuccessTarget) {
      this.buttonTarget.classList.add(this.invisibilityClasses)
      this.successTarget.classList.remove(this.invisibilityClasses)

      setTimeout(() => {
        this.buttonTarget.classList.remove(this.invisibilityClasses)
        this.successTarget.classList.add(this.invisibilityClasses)
      }, this.successTimeoutValue)
    } else {
      clearTimeout(this.timeout)

      this.buttonTarget.blur()
      this.buttonTarget.innerText = this.copiedLabelValue

      this.timeout = setTimeout(() => {
        this.buttonTarget.replaceWith(this.originalContent.cloneNode(true))
      }, this.successTimeoutValue)
    }
  }

  // private

  get invisibilityClasses() {
    return this.hasHiddenClass ? this.hiddenClasses : "hidden"
  }
}
