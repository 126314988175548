import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['item', 'template']

  initialize() {
    super.initialize()
    this.childListObserver = new MutationObserver(this.handleChildListChange.bind(this))
  }

  connect() {
    super.connect()
    this.childListObserver.observe(this.element, { childList: true })
  }

  disconnect() {
    super.disconnect()
    this.childListObserver.disconnect()
  }

  addItem({ currentTarget }) {
    const clonedTemplate = this.templateTarget.cloneNode(true)
    clonedTemplate.setAttribute('data-cloneable-target', 'item')

    this.show(clonedTemplate)
    this.element.appendChild(clonedTemplate)

    this.nextTick(() => this.dispatchEventTo(clonedTemplate, 'cloned'))

    const currentItem = currentTarget.closest('[data-cloneable-target="item"]')
    this.hideAddButtonFor(currentItem)
    this.showRemoveButtonFor(currentItem)
  }

  removeItem({ currentTarget }) {
    currentTarget.closest('[data-cloneable-target="item"]').remove()
  }

  showAddButtonFor(item) {
    this.show(item.querySelector('[data-button="add"]'))
  }

  hideAddButtonFor(item) {
    this.hide(item.querySelector('[data-button="add"]'))
  }

  showRemoveButtonFor(item) {
    this.show(item.querySelector('[data-button="remove"]'))
  }

  hideRemoveButtonFor(item) {
    this.hide(item.querySelector('[data-button="remove"]'))
  }

  handleChildListChange(mutations) {
    const mutation = mutations.find(mutation => mutation.type === 'childList')
    if(!mutation) return

    if(this.itemTargets.length === 1) {
      const item = this.itemTargets[0]

      this.showAddButtonFor(item)
      this.hideRemoveButtonFor(item)

      this.dispatchEventTo(item, 'marked-as-last')
    }

    if(this.itemTargets.length > 1) {
      this.itemTargets.forEach((item, index) => {
        if(index === this.itemTargets.length - 1) {
          this.showAddButtonFor(item)
          this.dispatchEventTo(item, 'marked-as-last')
        } else {
          this.hideAddButtonFor(item)
          this.showRemoveButtonFor(item)
        }
      })
    }
  }

  dispatchEventTo(item, event) {
    this.dispatch(event, {
      target: item
    })
  }
}
