import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto"

export default class extends Controller {
  static targets = ["bar", "skeleton"]

  convertToSkeleton() {
    this.skeletonTargets.forEach((target) => target.classList.remove("hidden"))
    this.barTargets.forEach((target) => target.classList.add("hidden"))
  }
}
