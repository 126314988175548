import ApplicationController from '../application_controller'
import Browser from "../models/browser";

// events dispatched from this controller
// list:added: fired when the list is connected to the dom, dispatched event contains id and the client url
// list:removed: fired when the list is removed, the dispatched event contains the id of the removed element

export default class extends ApplicationController {
  static values = {
    initial: String,
    id: String,
    toggle: { type: Boolean, default: false }
  }
  static targets = ['linkButton']

  initialize() {
    super.initialize()
  }

  toggle() {
    if(this.toggleValue) {
      this.abortEditing()
    } else {
      this.edit()
    }
  }

  highlight() {
    this.linkButtonTarget.classList.replace('bg-night-10', 'bg-night-20')
  }

  unhighlight() {
    this.linkButtonTarget.classList.replace('bg-night-20', 'bg-night-10')
  }

  edit() {
    this.highlight()

    this.dispatch(
      'edit',
      {
        target: this.editForm,
        detail: {
          id: this.idValue,
          url: this.initialValue,
          element: this.element
        }
      }
    )

    this.toggleValue = true
  }

  abortEditing() {
    this.dispatch(
      'abort',
      {
        target: this.editForm
      }
    )

    this.toggleValue = false
    this.unhighlight();
  }

  onEditAborted() {
    this.toggleValue = false
    this.unhighlight();
  }

  updateUrl({ detail: newUrl }) {
    this.initialValue = newUrl
  }

  // private

  initialValueChanged() {
    this.element.dataset.clientUrl = this.initialValue
  }

  get editForm() {
    return this.element.closest('[data-controller*="compose"]').querySelector('[data-controller="link--edit"]')
  }
}
