import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "preview", "label", "fileSelectorButton", "error" ]

  initialize() {
    this.showErrorMessage = this.showErrorMessage.bind(this)
    this.hideErrorMessage = this.hideErrorMessage.bind(this)
  }

  openFileSelector() {
    this.labelTarget.click()
  }

  updatePreview() {
    const file = this.inputTarget.files[0]
    const reader = new FileReader()

    reader.onload = () => {
      const image = new Image();

      image.onload = () => {
        if (image.width >= 640 && image.height >= 640 && image.width === image.height) {
          this.previewTarget.style.backgroundImage = `url(${reader.result})`
          this.previewTarget.style.backgroundSize = "cover"

          this.dispatch("changed", { detail: reader.result })

          this.fileSelectorButtonTarget.classList.add("hidden")
          this.previewTarget.classList.remove("hidden")

          this.hideErrorMessage()
        } else {
          this.showErrorMessage()
        }
      };

      image.src = reader.result;
    }

    reader.readAsDataURL(file)
  }

  remove() {
    this.inputTarget.value = ""
    this.previewTarget.style.backgroundImage = ""

    this.previewTarget.classList.add("hidden")
    this.fileSelectorButtonTarget.classList.remove("hidden")

    this.dispatch("changed", { detail: null })
  }

  showErrorMessage() {
    this.errorTarget.classList.remove("hidden")
  }

  hideErrorMessage() {
    this.errorTarget.classList.add("hidden")
  }
}
