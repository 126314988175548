import ApplicationController from '../application_controller'

import { useLineNotifiers } from '../mixins/useLineNotifiers'

export default class extends ApplicationController {
  static values = {
    url: String,
    persisted: { type: Boolean, default: false },
    property: String,
    placement: { type: String, default: "bottom-start" },
    current: { type: String, default: "" },
    error: { type: Boolean, default: false }
  }

  static targets = ["input", "button", "form", "container", "menu", "overrideCheckbox"]

  initialize() {
    super.initialize()
    this.currentValue ||= this.inputTarget.value
  }

  connect() {
    super.connect()
    useLineNotifiers(this)
  }

  disconnect() {
    this.disconnectLineFromThisElement()
    super.disconnect()
  }

  focus() {
    this.buttonTarget.classList.add(this.invisibleClass)
    this.containerTarget.classList.remove("hidden")

    super.focus(this.inputTarget, { moveCursorToEnd: true })

    this.nextTick(() => {
      this.moveLineToThisElement()
    }, 0)
  }

  blur(dispatchMouseLeaveEvent = true) {
    this.buttonTarget.classList.remove(this.invisibleClass)
    this.containerTarget.classList.add("hidden")

    if(this.currentValue && this.errorValue) {
      this.buttonTarget.querySelector('button[data-placeholder]').innerText = this.currentValue
    }

    if(this.hasError || this.errorValue) {
      this.unsetError()
    }

    if(dispatchMouseLeaveEvent === true) {
      this.onLineMouseLeave()
    }

    if(this.hasMenuTarget) {
      this.menuTarget.classList.add('hidden')
    }

    if(this.hasOverrideCheckbox) {
      this.overrideCheckboxTarget.checked = false
    }
  }

  restore() {
    if(!this.persistedValue) {
      this.currentValue = ''
    }

    this.inputTarget.value = this.currentValue
    this.blur(false)

    if(this.hasError || this.errorValue) {
      this.unsetError()
    }

    this.blurLine()

    if(this.hasMenuTarget) {
      this.menuTarget.classList.add('hidden')
    }

    if(this.hasOverrideCheckbox) {
      this.overrideCheckboxTarget.checked = false
    }
  }

  onSubmitEnd() {
    this.element.querySelectorAll("input").forEach((input) => {
      input.readOnly = false
    })
  }

  // private

  unsetError() {
    this.inputTarget.classList.remove('error')

    this.element.querySelector('[data-error]').remove()
    this.errorValue = false

    if(this.hasMenuTarget) {
      this.menuTarget.classList.add('hidden')
    }
  }

  withinBoundaries(e) {
    return this.containerTarget.classList.contains("hidden")
      || this.buttonTarget.contains(e.target)
      || e.target === this.inputTarget
      || this.containerTarget.contains(e.target)
      || e.target === this.buttonTarget
  }

  get invisibleClass() {
    return "hidden"
  }

  get hasError() {
    return this.element.querySelector('[data-error]')
  }

  get unsetClasses() {
    return ['text-night-60', 'italic']
  }
}
