import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["monthlySMS", "dedicatedPhoneNumbers"]

  onPackageClick(e) {
    const { mobile, sms } = e.currentTarget.dataset
    this.monthlySMSTarget.innerText = sms;
    this.dedicatedPhoneNumbersTarget.innerText = mobile
  }
}
