import ButtonController from './button_controller'

import { useURLValidations } from '../../mixins/useURLValidations'

export default class extends ButtonController {
  static targets = ['titleInput', 'urlInput']

  connect() {
    super.connect()
    useURLValidations(this)
  }

  validate() {
    if(this.isValid && !this.dispatchedEvent) {
      this.element.setAttribute('data-valid', '')

      this.dispatchedEvent = true
      this.dispatch('valid')

      this.enable(
        this.element.querySelector('[data-button="remove"]')
      )
    } else if(this.isInvalid && this.titleInputTarget.value.trim().length === 0 && this.urlInputTarget.value.trim().length === 0) {
      this.dispatchedEvent = false
      this.dispatch('invalid')
    }
  }

  focus() {
    if(this.titleInputTarget.value.trim().length === 0) {
      super.focus(this.titleInputTarget)
    } else if(this.urlInputTarget.value.trim().length === 0) {
      super.focus(this.urlInputTarget)
    } else {
      super.focus(this.titleInputTarget)
    }
  }

  focusUrlInput() {
    super.focus(this.urlInputTarget)
  }

  reset() {
    this.clearInputs(this.titleInputTarget, this.urlInputTarget)

    this.dispatchedEvent = false
  }

  toObject() {
    return {
      type: 'url',
      text: this.titleInputTarget.value.trim(),
      title: this.titleInputTarget.value.trim(),
      url: this.urlInputTarget.value.trim(),
      index: Array.from(this.element.parentElement.children).indexOf(this.element)
    }
  }

  get isValid() {
    return this.titleInputTarget.value.trim().length > 0 && this.urlInputTarget.value.trim().length > 0
  }
}
