import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    containerClass: String,
    widthClass: String,
    activeId: String,
    shownValue: { type: String, default: "list" }
  }

  static targets = [
    "information",
    "links",
    "hamburger",
    "list",
    "contactableInformation",
    "newContactForm",
    "aside"
  ]

  initialize() {
    super.initialize()
    this.shown = this.shownValue
    this.windowResizeListener = this.windowResizeListener.bind(this)
    this.popStateListener = this.popStateListener.bind(this)
  }

  connect() {
    window.addEventListener("resize", this.windowResizeListener)
    window.addEventListener("popstate", this.popStateListener)
    this.element.classList.add(this.containerClassValue)

    if(this.activeIdValue) {
      this.expandFlash()
    }
  }

  disconnect() {
    if (this.activeElement) {
      this.activeElement.classList.add("hidden")
    }

    this.element.classList.replace(
      this.widthClassValue,
      this.containerClassValue
    )

    window.removeEventListener("popstate", this.popStateListener)
  }

  popStateListener() {
    const urlParts = window.location.href.split("/")
    const section = urlParts[urlParts.length - 1]

    // if url ends with /audience or contains /lists or /segments
    if(section === "audience" || urlParts.includes("lists") || urlParts.includes("segments") || urlParts.includes("unsubscribed")) {
      this.hideContactableInformation()

      this.element.classList.replace(
        this.widthClassValue,
        this.containerClassValue
      )

      this.shrinkFlash()
    }
  }

  windowResizeListener(e) {
    if (this.onMobile) {
      this._restoreSavedStateForHiddenElements()
    } else {
      this.listTarget.classList.remove("hidden")
      this.linksTarget.classList.remove("hidden")
    }
  }

  async showSidebar(e) {
    if(!e.target.dataset.contactableInfoUrl) return

    if (this.hasContactableInformationTarget) {
      this.contactableInformationTarget.classList.remove("hidden")
    }

    if (this.onMobile) {
      this.listTarget.classList.add("hidden")
      this.asideTarget.classList.remove("hidden")

      this.shown = "contactableInformation"
    } else {
      this.element.classList.replace(
        this.containerClassValue,
        this.widthClassValue
      )
    }

    this.expandFlash()
    await get(e.target.dataset.contactableInfoUrl, {
      responseKind: "turbo-stream",
    })
  }

  showSidebarForDraftProfiles() {
    if (this.onMobile) {
      this.listTarget.classList.add("hidden")

      this.shown = "contactableInformation"
    } else {
      this.element.classList.replace(
        this.containerClassValue,
        this.widthClassValue
      )
    }

    this.showContactForm()
    this.expandFlash()
  }

  dispatchEvent(e) {
    const event = new CustomEvent(e.target.dataset.event)
    window.dispatchEvent(event)
  }

  getSegmentForm(e) {
    get(e.target.dataset.url, {
      responseKind: "turbo-stream",
    })

    this.dispatchEvent(e)
  }

  showContactForm(e) {
    this.contactableInformationTarget.classList.add("hidden")
    this.newContactFormTarget.classList.remove("hidden")
  }

  toggleLinksVisibility() {
    this.linksTarget.classList.toggle("hidden")
    this.listTarget.classList.toggle("hidden")

    if (this.listTarget.classList.contains("hidden")) {
      this.shown = "links"
    } else {
      this.shown = "list"
    }
  }

  _restoreSavedStateForHiddenElements() {
    console.log(this.shown)
    if (this.shown === "links") {
      this.showLinks()
    } else if (this.shown === "list") {
      this.showList()
    } else {
      this.showContactableInformation()
    }
  }

  showList() {
    this.linksTarget.classList.add("hidden")
    this.listTarget.classList.remove("hidden")
  }

  showLinks() {
    this.linksTarget.classList.remove("hidden")
    this.listTarget.classList.add("hidden")
  }

  hideLinks() {
    this.linksTarget.classList.add("hidden")
    this.listTarget.classList.remove("hidden")
  }

  showContactableInformation() {
    this.asideTarget.classList.remove("hidden")
    this.contactableInformationTarget.classList.remove("hidden")
    this.linksTarget.classList.add("hidden")
    this.listTarget.classList.add("hidden")
  }

  hideContactableInformation() {
    this.asideTarget.classList.add("hidden")
    this.contactableInformationTarget.classList.add("hidden")
    this.listTarget.classList.remove("hidden")
  }

  removeSelectedBorder(event) {
    let articles = event.target
      .closest("#contacts")
      .querySelectorAll(".border-transparent")

    let parent = event.target.closest(
      "[data-controller='radio dropdown toggle']"
    )

    if (articles) {
      articles.forEach((el) => el.classList.remove("border-transparent"))
    }
    if (parent && parent.previousElementSibling) {
      parent.previousElementSibling
        .querySelector("article")
        .classList.add("border-transparent")
    }
    event.target.classList.add("border-transparent")
  }

  onContactableCreated({ dataset }) {
    document.getElementById(dataset.id).click()
  }

  expandFlash() {
    document.querySelector("#flashes-container").classList.add(this.widthClassValue)
  }

  shrinkFlash() {
    document.querySelector("#flashes-container").classList.remove(this.widthClassValue)
  }

  get onMobile() {
    return window.innerWidth < 768
  }
}
