import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = ["notice"]

  async connect() {
    await this.checkSubscriptionAndSendIfExists()
  }

  async requestPermission() {
    if ("Notification" in window) {
      const permission = await Notification.requestPermission()
      if (permission === "granted") {
        await this.createSubscriptionAndSend()
        this.hideNotice()
      }
    } else {
      console.warn("Push notifications not supported.")
    }
  }

  async checkSubscriptionAndSendIfExists() {
    const subscription = await this.getSubscription()

    if (subscription) {
      await this.sendSubscriptionToServer(subscription)
      this.hideNotice()
    } else {
      this.showNotice()
    }
  }

  async createSubscriptionAndSend() {
    let subscription = await this.getSubscription()

    if (!subscription) {
      subscription = await this.subscribeToPushManager()
    }

    if (subscription) {
      await this.sendSubscriptionToServer(subscription)
    }
  }

  async getSubscription() {
    if ('serviceWorker' in navigator) {
      const sw = await navigator.serviceWorker.ready
      return await sw.pushManager.getSubscription()
    }
    return null
  }

  async subscribeToPushManager() {
    if ('serviceWorker' in navigator) {
      const sw = await navigator.serviceWorker.ready
      return await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: window.vapidPublicKey
      })
    }
    return null
  }

  async sendSubscriptionToServer(subscription) {
    const { endpoint } = subscription

    const p256dh = btoa(
      String.fromCharCode.apply(
        null,
        new Uint8Array(subscription.getKey('p256dh'))
      )
    );

    const auth = btoa(
      String.fromCharCode.apply(
        null,
        new Uint8Array(subscription.getKey('auth'))
      )
    );

    try {
      await post('/push_subscriptions', {
        body: {
          subscription: {
            endpoint,
            p256dh,
            auth
          }
        },
      })
    } catch (error) {
      console.error('Failed to send subscription to server:', error)
    }
  }

  noticeTargetConnected() {
    if(typeof Notification === 'undefined') return

    if (Notification.permission === "denied") {
      this.showNotice()
    }
  }

  showNotice() {
    if(this.hasNoticeTarget) {
      this.noticeTarget.classList.remove("hidden")
    }
  }

  hideNotice() {
    if(this.hasNoticeTarget) {
      this.noticeTarget.classList.add("hidden")
    }
  }
}
