import { get } from "@rails/request.js"

import ApplicationController from "../application_controller"
import { Current } from "../models/current"

export default class extends ApplicationController {
  static values = {
    path: String,
    navigate: { type: Boolean, default: false },
    creatorId: String
  }

  static targets = ["link", "editLink", "spinner", "editButton", 'pane']

  initialize() {
    super.initialize()
  }

  connect() {
    if(Current.user.role.agent) {
      this.editButtonTarget.remove()
    }

    if(this.navigateValue && this.creatorIdValue === Current.user.id) {
      this.element.removeAttribute('data-audience--list-navigate-value')
      this.nextTick(() => this.linkTarget.click())
    }

    if(window.location.href.includes(this.pathValue)) {
      this.markAsActive()
    }

    super.connect()
  }

  disconnect() {
    this.element.removeAttribute('data-audience--list-navigate-value')
    super.disconnect()
  }

  markAsActive() {
    document.querySelector('.audience--link.active')?.classList?.remove('active')
    this.addClass(this.linkTarget, 'active')
  }

  paneTargetConnected() {
    this.removeClass(this.editLinkTarget, 'group-hover:flex')
  }

  paneTargetDisconnected() {
    this.addClass(this.editLinkTarget, 'group-hover:flex')
  }
}
