import { Piece } from './piece';
import { Character } from './character';

class Cursor {
  constructor(inputController) {
    this.inputController = inputController;
  }

  ensureCaretVisibility() {
    const document = this.editor.getDocument()
    const documentString = document.toString()

    if(documentString.length <= 1) {
      this.editor.setSelectedRange([0, 0])
      this.editor.insertHTML('&zwnj;')
    }
  }

  moveToLeft() {
    setTimeout(() => {
      const [start, _] = this.editor.getSelectedRange()
      this.moveTo(start - 1, false)
    })
  }

  moveToRight() {
    setTimeout(() => {
      const [_, end] = this.editor.getSelectedRange()
      this.moveTo(end + 1, false)
    })
  }

  moveTo(range, timeout = true) {
    if(timeout) {
      return setTimeout(() => {
        this.editor.setSelectedRange(range)
      })
    }

    this.editor.setSelectedRange(range)
  }

  moveToEnd() {
    this.editor.setSelectedRange(this.trixDocument.toString().length - 1)
  }

  pieceAtCaret(position = this.editor.getSelectedRange()[0]) {
    return new Piece(this.editor.getDocument().getPieceAtPosition(position))
  }

  pieceAtCaretIsShortLink(position = this.editor.getSelectedRange()[0]) {
    return this.pieceAtCaret(position).isShortlink
  }

  pieceAtCaretIsTag(position = this.editor.getSelectedRange()[0]) {
    const pieceBeforeCursor = this.pieceBeforeCaret(position)
    const pieceAtCursor = this.pieceAtCaret(position)

    const userIsWritingInsideATag = pieceBeforeCursor.piece === pieceAtCursor.piece

    return pieceAtCursor.isTag && !userIsWritingInsideATag
  }
  pieceBeforeCaret(position = this.editor.getSelectedRange()[0]) {
    return new Piece(this.editor.getDocument().getPieceAtPosition(position - 1))
  }

  pieceBeforeCaretIsShortLink(position = this.editor.getSelectedRange()[0]) {
    return this.pieceBeforeCaret(position).isShortlink
  }

  pieceBeforeCaretIsTag(position = this.editor.getSelectedRange()[0]) {
    const pieceBeforeCursor = this.pieceBeforeCaret(position)
    const pieceAtCursor = this.pieceAtCaret(position)

    const userIsWritingInsideATag = pieceBeforeCursor.piece === pieceAtCursor.piece

    return pieceBeforeCursor.isTag && !userIsWritingInsideATag
  }

  pieceAfterCaret(position = this.editor.getSelectedRange()[0]) {
    return new Piece(this.editor.getDocument().getPieceAtPosition(position + 1))
  }

  characterAtCaret(position = this.editor.getSelectedRange()[0]) {
    return new Character(this.editor.getDocument().getCharacterAtPosition(position))
  }

  characterBeforeCaret(position = this.editor.getSelectedRange()[0]) {
    return new Character(this.editor.getDocument().getCharacterAtPosition(position - 1))
  }

  characterAfterCaret(position = this.editor.getSelectedRange()[0]) {
    return new Character(this.editor.getDocument().getCharacterAtPosition(position + 1))
  }

  stringAtRange(range = this.inputController.trixPosition) {
    return this.trixDocument.getStringAtRange(range).toString()
  }

  get editor() {
    return this.inputController.editor
  }

  get trixDocument() {
    return this.editor.getDocument()
  }
}

export { Cursor }
