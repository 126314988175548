import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select"]

  onSelectValueChanged({ currentTarget }) {
    this.dispatch("value-changed", {
      detail: currentTarget.value
    })
  }

  setPeriodValue({ detail }) {
    this.dispatch("select", {
      target: this.selectTarget,
      detail,
    })

    this.dispatch("menu:set-label", {
      target: this.element.querySelector("[data-controller='custom-dropdown']"),
      detail,
    })
  }
}