import { get } from '@rails/request.js'

import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    url: String,
  }

  static targets = [
    'searchInput',
    'category',
    'duplicate',
    'trigger',
  ]

  show() {
    if(this.hasSearchInputTarget) {
      this.nextTick(() => {
        this.focus(this.searchInputTarget, { moveCursorToEnd: true })
      })
    }
  }

  hide() {
    if(this.hasSearchInputTarget) {
      this.searchInputTarget.value = ''
    }
  }

  clear() {
    if(this.hasSearchInputTarget) {
      this.searchInputTarget.value = ''
    }

    clearTimeout(this.searchTimeout)
  }

  debounceSearch() {
    clearTimeout(this.searchTimeout)

    this.searchTimeout = setTimeout(() => {
      this.search()
    })
  }

  focus() {
    this.searchInputTarget.focus()
  }

  search() {
    get(this.urlValue, {
      responseKind: 'turbo-stream',
      query: {
        search: this.searchInputTarget.value
      }
    })
  }

  select() {
    this.duplicateTargets[0]?.click()
  }

  review({ currentTarget }) {
    currentTarget.querySelector('[data-review]').click()
  }

  duplicateTargetDisconnected() {
    if(!this.hasTriggerTarget) return

    if(this.duplicateTargets.length === 0) {
      this.clear()
      this.triggerTarget.classList.add('hidden')
      return this.dispatch('popover:hide', {
        target: this.element
      })
    } else if(this.duplicateTargets.length === 1) {
      this.triggerTarget.querySelector('[data-text]').textContent = t.contacts.merge.label.one
    } else {
      this.triggerTarget.querySelector('[data-text]').textContent = t.contacts.merge.label.other.replace('%{count}', this.duplicateTargets.length)
    }

    this.categoryTargets
      .filter(category => category.querySelectorAll('[data-audience--merge-dropdown-target="duplicate"]').length === 0)
      .forEach(category => category.classList.add('hidden'))
  }
}
