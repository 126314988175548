import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"

export default class extends Controller {
  static values = {
    options: Object,
  }

  connect() {
    this.slimselect = new SlimSelect({
      select: this.element,
      ...this.optionsValue,
    })

    // for some reason without using this hack it doesn't work!
    // replace the default icon with a custom icon
    setTimeout(() => {
      document.querySelector(".ss-add").innerHTML = `
      <svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>New</title>
          <g id="Iconography" stroke="none" stroke-width="currentStroke" fill="none" fill-rule="evenodd">
              <g id="Product" transform="translate(-76.000000, -970.000000)">
                  <g id="New" transform="translate(76.000000, 970.000000)">
                      <rect id="Frame" x="0" y="0" width="28" height="28"></rect>
                      <line x1="7" y1="13.8205128" x2="21" y2="13.8205128" id="Line" stroke="currentColor" stroke-width="currentStroke" stroke-linecap="round"></line>
                      <line x1="7" y1="13.8205128" x2="20.6410256" y2="13.8205128" id="Line" stroke="currentColor" stroke-width="currentStroke" stroke-linecap="round" transform="translate(13.820513, 13.820513) rotate(90.000000) translate(-13.820513, -13.820513) "></line>
                  </g>
              </g>
          </g>
      </svg>
`
    }, 0)

    super.connect()
  }

  disconnect() {
    this.slimselect.destroy()
  }
}
