import PaneController from "../../journeys/pane_controller"

export default class extends PaneController {
  static values = {
    method: { type: String, default: "" },
    url: { type: String, default: "" },
    headers: Object,
    contentType: String
  }

  static targets = ["header", "headersList", "addButton", "template", "request", "url", "headers", "headersNote", "addHeadersButton", "submit", "method", "body", "addBodyButton", "contentTypeSelect", "bodyContent", "bodyRow", "bodyTextarea", "addFieldButton", "bodyField"]

  initialize() {
    super.initialize()

    this.state = {
      method: this.methodValue,
      url: this.urlValue,
      headerVisible: this.headersTarget.classList.contains("hidden") === false,
      bodyVisible: this.bodyTarget.classList.contains("hidden") === false,
      contentType: this.contentTypeValue,
      rawBody: this.bodyTextareaTarget.value
    }

    this.changedState = {...this.state}
  }

  sync(commit = false) {
    this.dispatch("sync", {
      detail: {
        commit,
        stepId: this.stepIdValue,
        method: this.state.method,
        url: this.state.url,
      },
      target: document.documentElement,
    })
  }

  saveAndHide() {
    if(this.invalidFields.length > 0) {
      this.invalidFields.forEach(field => {
          this.dispatch("invalidate", { target: field })
        })

      return
    }

    const oldHeaderVisibility = this.state.headerVisible
    const oldBodyVisibility = this.state.bodyVisible

    this.state = { ...this.changedState }

    this.clearQueue()
    this.sync(true)
    this._hide()

    if(oldHeaderVisibility !== this.state.headerVisible && this.state.headerVisible === false) {
      this.dispatch("headers:clear", {
        target: this.headersTarget
      })
    }

    if(oldBodyVisibility !== this.state.bodyVisible && this.state.bodyVisible === false) {
      this.dispatch("content-type:clear", {
        target: this.contentTypeSelectTarget
      })

      this.dispatch("body:clear", {
        target: this.bodyContentTarget
      })

      this.bodyTextareaTarget.value = ""
      this.bodyTextareaTarget.disabled = true
      this.bodyTextareaTarget.classList.add("hidden")
      this.bodyContentTarget.classList.add("hidden")

      this.hideBody()
    }

    if(this.state.headerVisible) {
      this.dispatch("headers:save", {
        target: this.headersTarget
      })
    }

    if(this.state.bodyVisible) {
      this.dispatch("body:save", {
        target: this.bodyContentTarget
      })
    }

    if(this.state.contentType) {
      this.showBodyContent(this.state.contentType, true)
    }
  }

  cancel() {
    this._abort()
    this._hide()

    setTimeout(this.clearQueue, 1000)

    this.urlTarget.value = this.state.url

    if(this.changedState.method !== this.state.method) {
      this.dispatch("select", {
        target: this.methodTarget,
        detail: this.state.method
      })
    }

    if(this.state.headerVisible && !this.changedState.headerVisible) {
      this.showHeaders()
    }

    if(!this.state.headerVisible && this.changedState.headerVisible) {
      this.hideHeaders()
    }

    if(this.state.bodyVisible && !this.changedState.bodyVisible) {
      this.showBody()
    }

    if(!this.state.bodyVisible && this.changedState.bodyVisible) {
      this.hideBody()
    }

    if(this.state.contentType !== this.changedState.contentType) {
      this.dispatch("content-type:set", {
        detail: this.state.contentType,
        target: this.contentTypeSelectTarget
      })

      this.showBodyContent(this.state.contentType, true)
    }

    this.dispatch("headers:restore", {
      target: this.headersTarget
    })

    this.dispatch("body:restore", {
      target: this.bodyContentTarget
    })

    this.bodyTextareaTarget.value = this.state.rawBody

    this.changedState = { ...this.state }
  }

  syncValues() {
    if (this.hasUnsavedChanges) {
      window.paneId = this.element.id
    }
  }

  _abort() {
    this.dispatch("sync:abort", {
      detail: this.stepIdValue,
      target: document.documentElement,
    })

    super._abort()
  }

  methodChanged({ target: { value } }) {
    this.changedState = {
      ...this.changedState,
      method: value,
    }

    this.submitTarget.disabled = this.changedState.method.length === 0 || this.changedState.url.length === 0
  }

  showHeaders() {
    this.changedState = {
      ...this.changedState,
      headerVisible: true,
    }

    if(this.headers.length === 0) {
      this.dispatch("headers:add", { target: this.headersTarget })
    }

    this.headersTarget.classList.remove("hidden")
    this.headersNoteTarget.classList.remove("hidden")

    this.addHeadersButtonTarget.classList.add("hidden")
  }

  showBody() {
    this.changedState = {
      ...this.changedState,
      bodyVisible: true,
    }

    this.bodyTarget.classList.remove("hidden")
    this.addBodyButtonTarget.classList.add("hidden")
  }

  hideBody() {
    this.changedState = {
      ...this.changedState,
      bodyVisible: false,
      contentType: null
    }

    this.bodyTarget.classList.add("hidden")
    this.addBodyButtonTarget.classList.remove("hidden")
  }

  hideHeaders() {
    this.changedState = {
      ...this.changedState,
      headerVisible: false,
    }

    this.headersTarget.classList.add("hidden")
    this.headersNoteTarget.classList.add("hidden")

    this.addHeadersButtonTarget.classList.remove("hidden")
  }

  onUrlChange() {
    this.changedState = {
      ...this.changedState,
      url: this.urlTarget.value,
    }

    this.submitTarget.disabled = this.changedState.method.length === 0 || this.changedState.url.length === 0
  }

  onRawBodyChange() {
    this.changedState = {
      ...this.changedState,
      rawBody: this.bodyTextareaTarget.value,
    }
  }

  setContentType({ currentTarget: { dataset } }) {
    this.changedState = {
      ...this.changedState,
      contentType: dataset.contentType,
    }

    if(this.bodyFields.length === 0) {
      this.dispatch("body:add", { target: this.bodyContentTarget })
    }

    this.dispatch("content-type:set", {
      detail: dataset.contentType,
      target: this.headersTarget
    })

    if(!this.state.headerVisible) {
      this.showHeaders()
    }

    this.bodyContentTarget.classList.remove("hidden")
    this.showBodyContent(dataset.contentType)
  }

  showBodyContent(contentType, commitChanges = false) {
    if(["multipart/form-data", "application/x-www-form-urlencoded"].includes(contentType)) {
      this.bodyRowTarget.querySelectorAll("input").forEach((input) => input.disabled = false)
      this.bodyRowTarget.classList.remove("hidden")

      this.bodyTextareaTarget.classList.add("hidden")
      this.addFieldButtonTarget.classList.remove("hidden")

      if(commitChanges) {
        this.bodyTextareaTarget.value = ""
        this.bodyTextareaTarget.disabled = true
      }
    } else {
      this.bodyRowTarget.classList.add("hidden")
      this.addFieldButtonTarget.classList.add("hidden")

      if(commitChanges) {
        this.bodyRowTarget.querySelectorAll("input").forEach((input) => {
          input.value = ""
          input.disabled = true
        })
      }

      this.bodyTextareaTarget.classList.remove("hidden")
      this.bodyTextareaTarget.disabled = false
    }
  }

  get hasUnsavedChanges() {
    return Object.keys(this.state).some((key) => this.state[key] !== this.changedState[key])
      || this.element.querySelectorAll("[data-changed]").length > 0
      || this.changedState.headerVisible && this.headers.some((header) => !header.hasAttribute("data-saved")) || this.headers.some((header) => header.hasAttribute("data-saved") && header.classList.contains("hidden"))
      || this.hasRows && this.changedState.bodyVisible && this.bodyFields.some((field) => !field.hasAttribute("data-saved")) || this.bodyFields.some((field) => field.hasAttribute("data-saved") && field.classList.contains("hidden"))
  }

  get invalidFields() {
    return [
      ...(this.changedState.headerVisible ? this.headers.filter(header => header.hasAttribute("data-invalid")) : []),
      ...(this.hasRows ? this.bodyFields.filter(field => field.hasAttribute("data-invalid")) : []),
    ]
  }

  get headers() {
    return this.headerTargets.filter((header) => !header.hasAttribute("data-template"))
  }

  get bodyFields() {
    return this.bodyFieldTargets.filter((field) => !field.hasAttribute("data-template"))
  }

  get hasRows() {
    return ["multipart/form-data", "application/x-www-form-urlencoded"].includes(this.changedState.contentType)
  }
}
