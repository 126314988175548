import { useDispatch } from "stimulus-use"

import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ["input", "cancelButton", "form"]

  static values = {
    url: String,
    clientUrl: String,
    turbo: Boolean,
    initial: String,
  }

  static classes = ["default", "error"]

  initialize() {
    this.submitListener = this.submitListener.bind(this)
  }

  connect() {
    super.connect()
    this.inputTarget?.addEventListener("keydown", this.submitListener)
    this.sync()

    useDispatch(this, {
      eventPrefix: false,
    })
  }

  disconnect() {
    if (this.hasInputTarget) {
      this.inputTarget?.removeEventListener("keydown", this.submitListener)
    }

    super.disconnect()
  }

  sync(e = null) {
    const url = this.inputTarget.value
    this.url = url
    this.inputTarget.value = this.url
    this.inputTarget.classList.replace(this.errorClass, this.defaultClass)

    if (e) {
      e.preventDefault()
      e.stopImmediatePropagation()
    }
  }

  submitListener(e) {
    if (e.key === "Enter") {
      e.stopPropagation()
      e.preventDefault()

      this.onEnterPress()
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "v") {
      e.stopPropagation()
      e.stopImmediatePropagation()
    }
  }

  clear() {
    this.inputTarget.value = "https://www."
  }

  cancel(e) {
    this._cancel(true)
  }

  _cancel(dispatchEvent = false) {
    if(this.hasFormTarget) {
      this.formTarget.classList.remove("border-tiger", "ring-1", "ring-tiger")
      this.formTarget.classList.add("hidden")
    }

    if (dispatchEvent) {
      this.dispatch("link:hide")
    }

    this.inputTarget.value = this.initialValue
  }

  focus() {
    this.inputTarget.focus()
    this.inputTarget.selectionStart = this.inputTarget.selectionEnd = 10000
  }

  onDropdownOpen() {
    setTimeout(() => this.focus(), 1)

    this.dispatch("link:shown")
  }

  onDropdownHide() {
    this.dispatch('link:hidden')
  }

  isValidURL() {
    const res = this.inputTarget.value.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    )
    return res !== null
  }
}
