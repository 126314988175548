import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    persisted: { type: Boolean, default: false },
    name: String
  }

  static targets = ["remove"]

  initialize() {
    this.pane = this.element.closest("[data-controller*='step--stop--pane']")
  }

  connect() {
    if(this.persistedValue) {
      this.element.setAttribute("data-saved", "")
    }
  }

  allowRemoval() {
    this.removeTarget.classList.remove("hidden")
  }

  preventRemoval() {
    this.removeTarget.classList.add("hidden")
  }

  save() {
    this.persistedValue = true
    this.element.classList.remove("hidden")
    this.element.removeAttribute("data-marked-for-remove")
  }

  onJourneySelected({ currentTarget }) {
    this.nameValue = currentTarget.dataset.name
  }

  remove() {
    if(this.persistedValue) {
      this.hide()

      this.dispatch("marked-for-remove", {
        target: this.pane,
        detail: this.nameValue,
      })

      this.element.setAttribute("data-marked-for-remove", "")
    } else {
      this.delete()

      this.dispatch("removed", {
        target: this.pane
      })
    }
  }

  delete() {
    this.element.remove()
  }

  removeIfNotPersisted() {
    if(!this.persistedValue) {
      this.delete()
    }
  }

  // private

  hide() {
    this.element.classList.add("hidden")
  }
}
