import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    id: String,
    name: String,
    subscriptionState: String,
    conversationUrl: String,
  }

  static targets = [
    'propertiesContainer',
    'minimalPropertiesContainer',
    'mergeDropdown',
  ]

  removeMinimalPropertiesContainer() {
    this.minimalPropertiesContainerTarget.remove()
  }

  focusChatInput({ params }) {
    const inbox = document.querySelector("[data-controller='chat']")

    if(inbox) {
      return this.dispatch("focus", {
        target: inbox,
        detail: params.element
      })
    }

    Turbo.visit(this.conversationUrlValue)
  }

  showSubscriptionModal() {
    this.dispatch('subscription:subscribe', {
      target: document.querySelector('[data-controller="contact--modal"]'),
      detail: {
        id: this.idValue,
        name: this.nameValue,
      },
    })
  }

  showUnsubscriptionModal() {
    this.dispatch('subscription:unsubscribe', {
      target: document.querySelector('[data-controller="contact--modal"]'),
      detail: {
        id: this.idValue,
        name: this.nameValue,
      },
    })
  }

  showDeleteModal() {
    this.dispatch('delete', {
      target: document.querySelector('[data-controller="contact--modal"]'),
      detail: {
        id: this.idValue,
        name: this.nameValue,
      },
    })
  }

  updateName({ detail: newName }) {
    this.nameValue = newName
  }

  showMergeDropdown() {
    this.dispatch('merge:toggle', {
      target: this.mergeDropdownTarget,
    })
  }

  focusMergeDropdown() {
    if(this.isVisible(this.mergeDropdownTarget)) {
      this.dispatch('merge:focus', {
        target: this.mergeDropdownTarget
      })
    }
  }
}
