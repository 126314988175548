import { get } from '@rails/request.js'

import ApplicationController from '../application_controller'
import Sortable from "sortablejs";

export default class extends ApplicationController {
  static values = {
    url: String,
  }

  static targets = [
    'menu',
    'trigger',
    'item',
    'inputList',
    'inputTemplate',
  ]

  connect() {
    super.connect()

    this.sortable = Sortable.create(this.inputListTarget, {
      group: 'inputs',
      animation: 150,
      filter: ".not-draggable",
      onEnd: this.onDragEnd.bind(this),
      preventOnFilter: false
    })
  }

  async add({ currentTarget }) {
    const query = {
      kind: currentTarget.dataset.kind,
      position: this.inputListTarget.children.length + 1,
    }

    if(currentTarget.hasAttribute('data-property')) {
      query.property_id = currentTarget.getAttribute('data-property')
    }

    await get(this.urlValue, {
      query,
      responseKind: 'turbo-stream'
    })

    currentTarget.classList.add('hidden')

    this.dispatch('selected', {
      detail: query.property_id || query.kind
    })
  }

  async replace({ currentTarget }) {
    const { replace, propertyId } = currentTarget.dataset

    const query = {
      kind: currentTarget.dataset.kind,
      perform: 'replace'
    }

    if(currentTarget.hasAttribute('data-option-value')) {
      query.property_id = currentTarget.getAttribute('data-option-value')
    }

    await get(this.urlValue, {
      query,
      responseKind: 'turbo-stream'
    })

    this.inputTemplateTargets.find(template => template.dataset.kind === replace).classList.remove('hidden')
    this.inputTemplateTargets.find(template => template.dataset.kind === currentTarget.dataset.kind).classList.add('hidden')

    this.dispatch('replaced', {
      detail: {
        replaced: propertyId || replace,
        with: query.property_id || query.kind
      }
    })
  }

  hideSelectedInputTypes() {
    this.inputTemplateTargets
      .filter(template => this.selectedInputTypes.includes(template.dataset.kind) || this.selectedInputTypes.includes(template.dataset.propertyId))
      .forEach(template => template.classList.add('hidden'))
  }

  focus({ detail }) {
    this.element.classList.add('border-tiger', 'border-solid')
    this.triggerTarget.classList.remove('hidden')

    if(detail.menu !== this.menuTarget) {
      this.menuTarget.classList.add("hidden")
      this.menuTarget.removeAttribute("aria-expanded")
    }
  }

  blur() {
    if(this.isInvisible(this.menuTarget)) return
    this.triggerTarget.click()
  }

  removeFocus({ detail: { menu }}) {
    if(this.isVisible(this.menuTarget) && menu !== this.menuTarget) return

    this.element.classList.remove('border-solid', 'border-tiger')
    this.triggerTarget.classList.add('hidden')
  }

  onDragEnd(event) {
    // do nothing
  }

  itemTargetConnected() {
    if(this.itemTargets.length === 1) {
      this.nextTick(() => {
        this.dispatch('require', {
          target: this.itemTargets[0]
        })
      })
    } else {
      this.itemTargets.forEach(item => {
        this.dispatch('enable', {
          target: this.itemTargets[0]
        })
      })
    }

    if(document.querySelector('button[data-form-target="submit"]')) {
      document.querySelector('button[data-form-target="submit"]').disabled = this.missingRequiredInputs
    }

    if(this.missingRequiredInputs) {
      if(this.hasShownToast) return

      this.hasShownToast = true
      this.showToast({
        message: this.translations.html.inputs.missing_required_fields,
        type: 'error'
      })
    } else {
      this.hasShownToast = false
    }
  }

  get missingRequiredInputs() {
    return !(this.itemKinds.includes('email') || this.itemKinds.includes('phone'))
  }

  get itemKinds() {
    return this.itemTargets.map(item => item.dataset.inputType)
  }

  itemTargetDisconnected() {
    this.itemTargetConnected()
  }

  get hiddenInputTypes() {
    return this.inputTemplateTargets
      .filter(template => template.classList.contains('hidden'))
      .map(template => template.dataset.property || template.dataset.kind)
  }

  get selectedInputTypes() {
    return window.selectedInputTypes || []
  }
}
