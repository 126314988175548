import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  displayOnMobile() {
    this.dispatch("display:mobile-only", {
      target: this.layoutButtonsContainer,
    })

    this.dispatch("display-changed", {
      target: document.getElementById("popup_form"),
      detail: "mobile",
    })
  }

  displayOnDesktop() {
    this.dispatch("display:desktop-only", {
      target: this.layoutButtonsContainer,
    })

    this.dispatch("display-changed", {
      target: document.getElementById("popup_form"),
      detail: "desktop",
    })
  }

  displayOnAll() {
    this.dispatch("display:mobile-and-desktop", {
      target: this.layoutButtonsContainer,
    })

    this.dispatch("display-changed", {
      target: document.getElementById("popup_form"),
      detail: "both",
    })
  }

  showBubble() {
    this.dispatch("bubble:show", {
      target: this.layoutButtonsContainer,
    })

    this.dispatch("bubble:visibility-changed", {
      target: document.getElementById("popup_form"),
      detail: {
        attribute: "show",
        value: "1",
      },
    })
  }

  hideBubble() {
    this.dispatch("bubble:hide", {
      target: this.layoutButtonsContainer,
    })

    this.dispatch("bubble:visibility-changed", {
      target: document.getElementById("popup_form"),
      detail: {
        attribute: "show",
        value: "0",
      },
    })
  }

  // private

  get layoutButtonsContainer() {
    return document.querySelector("[data-controller='popup--steps']")
  }
}
