import { Loader } from "@googlemaps/js-api-loader"

import ApplicationController from '../application_controller'
import { Current } from "@/controllers/models/current"
import { useToastNotifiers } from "@/controllers/mixins/useToastNotifiers"
import { useTextareaExpansion } from "@/controllers/mixins/useTextareaExpansion"
import { useLineNotifiers } from "@/controllers/mixins/useLineNotifiers"

import { PlaceDetail, PlaceAutocomplete } from '../models/google'

export default class extends ApplicationController {
  static values = {
    initial: String,
    apiKey: String,
    countryCode: String,
    persisted: Boolean,
    international: { type: Boolean, default: false }
  }

  static targets = [
    'searchInput',
    'streetNameInput',
    'input',
    'form',
    'formContainer',
    'select',
    'dropdown',
    'addressList',
    'addressListContainer',
    'itemTemplate',
    'toggleButton',
    'referenceInput',
    'stateInput',
    'countryInput',
    'deleteButton',
    'updateButton',
    'customAddressButton',
    'customAddressLabel',
    'cityInput',
    'removeCustomAddressViewButton',
    'searchContainer',
    'googleLogo',
    'error',
  ]

  initialize() {
    super.initialize()

    this.loader = new Loader({
      apiKey: this.apiKeyValue,
      version: "weekly",
      libraries: ["places"]
    });

    this.countryCodeValue ||= Current.business.country.code

    if(!this.internationalValue) {
      this.countryInputTarget.value = this.countryCodeValue
      this.countryInputTarget.setAttribute('data-original', this.countryCodeValue)
    }

    this.buildAddressElement = this.buildAddressElement.bind(this)
    this.removeSearchResults = this.removeSearchResults.bind(this)
    this.placeDetails = {}
    this.places = {}
  }

  connect() {
    super.connect()

    this.setupGoogle()

    useToastNotifiers(this)
    useTextareaExpansion(this)
    useLineNotifiers(this)

    this.nextTick(() => this.autoExpandTextareasHeight())
  }

  showForm() {
    this.show(this.formContainerTarget)
    this.show(this.removeCustomAddressViewButtonTarget)

    this.hide(this.addressListTarget)
    this.hide(this.addressListContainerTarget)

    if(this.hasAddressListContainerTarget) {
      this.hide(this.addressListContainerTarget)
    }

    this.focus(this.streetNumberInputTarget, { moveCursorToEnd: true })

    this.autoExpandTextareasHeight()
    this.nextTick(this.removeSearchResults)

    this.hide(this.googleLogoTarget)
  }

  hideAndResetForm(focus = true) {
    this.hide(this.formContainerTarget)
    this.hide(this.removeCustomAddressViewButtonTarget)
    this.hide(this.addressListContainerTarget)

    this.show(this.addressListTarget)
    this.formTarget.querySelectorAll('input, textarea').forEach(this.clearInput)

    if(focus) {
      this.focus(this.searchInputTarget, { moveCursorToEnd: true })
    }
  }

  focusSearchInput() {
    this.autoExpandTextareasHeight()

    this.nextTick(
      () => this.focus(
        this.searchInputTarget,
        { moveCursorToEnd: true }
      )
    )
  }

  search({ currentTarget }) {
    if(currentTarget.value) {
      this.streetNameInputTarget.value = currentTarget.value
      this.debounce(() => this.searchAddress(currentTarget))
    } else {
      this.resetInput(this.streetNameInputTarget)
      this.removeSearchResults()
    }
  }

  async searchAddress(currentTarget) {
    this.selectTarget.setAttribute('data-arrow-navigation-enabled-value', false)

    const response = await this.autocompleteAPI.getPlacePredictions({
      input: currentTarget.value,
      language: Current.user.locale,
      componentRestrictions: !this.internationalValue && {
        country: this.countryCodeValue
      }
    })

    this.removeSearchResults()

    response.predictions
      .map(this.buildAddressElement)
      .forEach(element => this.addressListTarget.appendChild(element))

    if(response.predictions.length !== 0) {
      this.show(this.googleLogoTarget)

      if(this.hasRemoveCustomAddressViewButtonTarget) {
        this.hide(this.removeCustomAddressViewButtonTarget)
      }
    }

    if(this.hasAddressListContainerTarget) {
      if(response.predictions.length !== 0) {
        this.show(this.addressListContainerTarget)
      } else {
        this.hide(this.addressListContainerTarget)
      }
    }

    if(response.predictions.length === 0 && this.hasFormContainerTarget && this.isInvisible(this.formContainerTarget)) {
      this.hide(this.googleLogoTarget)
      this.showCustomAddressButton()
    }

    this.show(this.addressListTarget)
    this.selectTarget.setAttribute('data-arrow-navigation-enabled-value', true)
  }

  selectAddress({ currentTarget }) {
    const { id, description } = currentTarget.dataset

    this.streetNameInputTarget.value = description
    this.referenceInputTarget.value = id
    this.countryInputTarget.value = this.countryCodeValue

    const place = this.places[id]

    if(place.isCountry) {
      this.countryInputTarget.value = description
      this.streetNameInputTarget.value = ''
    }

    if(this.persistedValue) {
      this.setInputFieldsFromPlaceDetails(id)
      this.nextTick(this.removeSearchResults)

      if(this.isInvisible(this.formContainerTarget)) {
        this.submitForm()
      }
    } else if(this.hasFormTarget) {
      this.formTarget.requestSubmit()
    } else {
      this.searchInputTarget.value = description

      this.hide(this.addressListContainerTarget)
      this.hide(this.addressListTarget)
    }

    this.hide(this.googleLogoTarget)
  }

  setInputFieldsFromPlaceDetails(id) {
    const detail = this.placeDetails[id]

    if(detail.streetNumber) {
      this.streetNumberInputTarget.value = detail.streetNumber
      this.searchInputTarget.value = this.searchInputTarget.value.replace(detail.streetNumber, '').trim()
    }

    if(detail.postalCode) {
      this.postalCodeInputTarget.value = detail.postalCode
      this.searchInputTarget.value = this.searchInputTarget.value.replace(detail.postalCode, '').trim()
    }

    if(detail.city) {
      this.dispatch('select:city', {
        target: this.formContainerTarget,
        detail: {
          city: detail.city,
          countryCode: detail.countryCode
        }
      })
    }

    if(detail.state) {
      this.stateInputTarget.value = detail.state
    }

    if(this.hasAddressListContainerTarget) {
      this.hide(this.addressListContainerTarget)
    }

    this.hide(this.addressListTarget)
    this.focus(this.searchInputTarget, { moveCursorToEnd: true })

    this.streetNameInputTarget.value = this.searchInputTarget.value
  }

  saveChanges({ target }) {
    if(this.hasDropdownTarget) {
      if(this.isInvisible(this.dropdownTarget) || this.dropdownTarget.contains(target) || this.toggleButtonTarget.contains(target)) return

      if(this.hasError) {
        this.restoreLastSavedState()
      } else {
        this.submitForm()
      }

      this.blurLine()
    }
  }

  restoreLastSavedState() {
    if(!this.hasDropdownTarget) return
    if(this.isInvisible(this.dropdownTarget)) return

    this.hide(this.dropdownTarget)
    this.hide(this.addressListContainerTarget)

    if(this.hasToggleButtonTarget) {
      this.show(this.toggleButtonTarget)
    }

    this.persistedValue ? this.showForm() :  this.hideAndResetForm()
    this.element.querySelectorAll('input, textarea').forEach(this.resetInput)

    this.removeSearchResults()

    this.dispatch('restore', {
      target: this.formContainerTarget
    })

    this.dropdownTarget.classList.remove('error')
    this.hasError = false

    this.blurLine()
  }

  showDropdown() {
    if(this.hasToggleButtonTarget) {
      this.hide(this.toggleButtonTarget)
    }

    this.show(this.dropdownTarget)
    this.nextTick(() => this.moveLineToThisElement())
  }

  submit() {
    this.submitForm()
  }

  autoExpandTextareasHeight() {
    this.element.querySelectorAll('textarea')
      .forEach(textarea => this.expandTextarea(textarea))
  }

  errorTargetConnected() {
    this.hasError = true
    this.dropdownTarget.classList.add('error')

    this.errorTarget.remove()
  }
  // private

  setupGoogle() {
    this.loader.load().then((google) => {
      this.google = google
      this.autocompleteAPI = new google.maps.places.AutocompleteService()

      this.map = new google.maps.Map(document.createElement('div'), {})
      this.placeService = new google.maps.places.PlacesService(this.map)

    }).catch((error) => {
      console.log("failed to initialize google maps api", error)
    })
  }

  submitForm() {
    if(!this.hasFormTarget) return

    this.hide(this.addressListContainerTarget)

    if(this.unchanged) {
      this.hide(this.dropdownTarget)

      if(this.hasToggleButtonTarget) {
        this.show(this.toggleButtonTarget)
      }

      return this.persistedValue ? this.showForm() :  this.hideAndResetForm(false)
    }

    if(this.isNewRecord) {
      return this.formTarget.requestSubmit()
    }

    if(this.cleared && this.hasDeleteButtonTarget) {
      this.deleteButtonTarget.click()
    } else {
      this.updateButtonTarget.click()
    }
  }

  showCustomAddressButton() {
    const customAddressItem = this.customAddressButtonTarget.cloneNode(true)

    customAddressItem.setAttribute('data-arrow-navigatable', '')
    this.removeClass(customAddressItem, 'hidden')

    this.addressListTarget.appendChild(customAddressItem)

    if(this.persistedValue && this.streetNameInputTarget.value === this.streetNameInputTarget.dataset.original) {
      this.customAddressLabelTarget.innerText = this.translations.property.addresses.form.edit
    } else {
      this.customAddressLabelTarget.innerText = this.translations.property.addresses.form.open
    }

    this.show(this.addressListContainerTarget)
    this.show(this.addressListTarget)
  }

  removeSearchResults() {
    this.addressListTarget.innerHTML = ''

    this.hide(this.addressListContainerTarget)
    this.hide(this.googleLogoTarget)
  }

  buildAddressElement(prediction, index) {
    const item = this.itemTemplateTarget.cloneNode(true)
    this.removeClass(item, 'hidden')

    if(this.persistedValue) {
      this.placeService.getDetails({
        placeId: prediction.place_id,
        fields: ['name', 'formatted_address', 'geometry', 'address_components'],
      }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          this.placeDetails[prediction.place_id] = new PlaceDetail(place)
        }
      })
    }

    this.places[prediction.place_id] = new PlaceAutocomplete(prediction)

    if(this.internationalValue) {
      item.querySelector('[data-address-description]').innerText = prediction.description
    } else {
      item.querySelector('[data-address-description]').innerText = [prediction.structured_formatting.main_text, this.countryCodeValue].join(', ')
    }

    item.setAttribute('data-id', prediction.place_id)
    item.setAttribute('data-description', this.internationalValue ? prediction.description : prediction.structured_formatting.main_text)
    item.setAttribute('data-action', 'click->property--address#selectAddress')
    item.setAttribute('tabindex', index + 1)
    item.setAttribute('data-arrow-navigatable', '')

    console.log(prediction)
    if(this.places[prediction.place_id].type) {
      item.querySelector('[data-address-type]').innerText = this.translations.address.types[this.places[prediction.place_id].type]
      item.querySelector('[data-address-type]').classList.remove('hidden')
    }

    return item
  }

  get selectIsInvisible() {
    return this.selectTarget.classList.contains('hidden')
  }

  get formIsVisible() {
    return !this.formContainerTarget.classList.contains('hidden')
  }

  get unchanged() {
    return Array.from(this.formContainerTarget.querySelectorAll('input, textarea'))
      .every(input => input.value === input.dataset.original)
  }

  get cleared() {
    return this.searchInputTarget.value === ''
  }

  get isNewRecord() {
    return !this.persistedValue
  }

  get streetNumberInputTarget() {
    return this.inputTargets.find(input => input.dataset.field === 'number')
  }

  get postalCodeInputTarget() {
    return this.inputTargets.find(input => input.dataset.field === 'postal_code')
  }
}
