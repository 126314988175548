import { Controller } from "@hotwired/stimulus"
import { useColorPickerVisibilityControls } from "../mixins/useColorPickerVisibilityControls"
import {
  useLeftAlignment,
  useCenterAlignment,
  useRightAlignment,
  useFullWidthAlignment,
} from "../mixins/tools/useAlignment"

export default class extends Controller {
  static targets = [
    "menu",
    "colorPickerControls",
    "backgroundPickerControls",
    "textColorPreview",
    "backgroundColorPreview",
    "container",
    "activeAlignment",
    "alignmentButton",
    "backgroundButton",
    "colorButton",
    "contenteditable",
    "labelInput",
  ]
  static classes = ["active", "inactive"]

  static values = {
    textColor: { type: String, default: "#ffffff" },
    backgroundColor: { type: String, default: "#550AAB" },
    alignment: { type: String, default: "full_width" },
  }

  initialize() {
    this.allowSync = true
  }

  connect() {
    useColorPickerVisibilityControls(this, {
      pickerTarget: this.colorPickerControlsTarget,
      onColorPickerHide: () => {
        this.backgroundPickerControlsTarget.classList.add("hidden")
      },
    })

    useLeftAlignment(this, {
      on: this.containerTarget,
      applyClasses: ["w-fit", "self-start"],
      removeClasses: ["self-end", "self-center", "w-full"],
      afterAlignment: () => this.syncAlignmentTo("left"),
    })

    useRightAlignment(this, {
      on: this.containerTarget,
      applyClasses: ["w-fit", "self-end"],
      removeClasses: ["self-start", "self-center", "w-full"],
      afterAlignment: () => this.syncAlignmentTo("right"),
    })

    useCenterAlignment(this, {
      on: this.containerTarget,
      applyClasses: ["w-fit", "self-center"],
      removeClasses: ["self-start", "self-end", "w-full"],
      afterAlignment: () => this.syncAlignmentTo("center"),
    })

    useFullWidthAlignment(this, {
      on: this.containerTarget,
      applyClasses: ["w-full"],
      removeClasses: ["self-start", "self-center", "self-end", "w-fit"],
      afterAlignment: () => this.syncAlignmentTo("full_width"),
    })

    this.syncAlignment()

    super.connect()
  }

  syncAlignmentTo(alignment) {
    this.changeAlignmentActiveClassTo(
      this.element.querySelector(`[data-align='${alignment}']`)
    )
    this.alignmentValue = alignment
    this.sync()

    this.dispatch("changed", {
      detail: {
        attribute: "alignment",
        value: alignment,
      },
    })
  }

  changeAlignmentActiveClassTo(button) {
    this.alignmentButtonTargets.forEach((alignmentButton) => {
      if (alignmentButton === button) {
        alignmentButton.classList.add("text-tiger")
      } else {
        alignmentButton.classList.remove("text-tiger")
      }
    })
  }

  toggleToolbar(e) {
    if (this.menuTarget.contains(e.target)) {
      return
    }

    if (this.element.contains(e.target)) {
      this.menuTarget.classList.toggle("hidden")
    } else {
      this.menuTarget.classList.add("hidden")
    }

    if (this.menuTarget.classList.contains("hidden")) {
      this.element.classList.add(...this.inactiveClasses)
      this.element.classList.remove(...this.activeClasses)
    } else {
      this.element.classList.remove(...this.inactiveClasses)
      this.element.classList.add(...this.activeClasses)
    }
  }

  changeColor(e) {
    if (this.colorButtonTarget.contains(e.target)) {
      this.textColorPreviewTarget.style.background = e.detail
      this.containerTarget.style.color = e.detail

      this.textColorValue = e.detail

      this.dispatch("changed", {
        detail: {
          color: "color",
          value: this.textColorValue,
        },
      })
    } else {
      this.backgroundColorPreviewTarget.style.background = e.detail
      this.containerTarget.style.background = e.detail

      this.backgroundColorValue = e.detail

      this.dispatch("changed", {
        detail: {
          color: "background_color",
          value: this.backgroundColorValue,
        },
      })
    }

    this.sync()
  }

  syncLabel(e) {
    this.labelInputTarget.value = e.target.textContent

    this.dispatch("label:sync", {
      target: Array.from(
        document.querySelectorAll(
          `[data-element-id='${this.element.dataset.elementId}']`
        )
      ).find((layoutSection) => layoutSection !== this.element),
      detail: this.labelInputTarget.value,
    })
  }

  toggleBackgroundPickerControls(e) {
    if (
      this.backgroundButtonTarget.contains(e.target) === false &&
      this.backgroundPickerControlsTarget.classList.contains("hidden") === false
    ) {
      this.backgroundPickerControlsTarget.classList.add("hidden")
      return
    }

    if (
      this.backgroundPickerControlsTarget.classList.contains("hidden") &&
      this.backgroundButtonTarget.contains(e.target)
    ) {
      this.colorPickerControlsTarget.classList.add("hidden")
      this.backgroundPickerControlsTarget.classList.remove("hidden")
    }
  }

  syncButtonLabel({ detail: label }) {
    this.contenteditableTarget.textContent = label
    this.labelInputTarget.value = label
  }

  syncGlobalButtonState({ detail }) {
    if (detail.elementToSyncWith === this.element) return

    this.allowSync = false

    Promise.resolve()
      .then(() => {
        this.dispatch("color:change", {
          target: this.colorPickerControlsTarget,
          detail: detail.state.textColor,
        })

        this.dispatch("color:change", {
          target: this.backgroundPickerControlsTarget,
          detail: detail.state.backgroundColor,
        })

        this.alignmentValue = detail.state.alignment
        this.syncAlignment()
      })
      .then(() => (this.allowSync = true))
  }

  sync() {
    if (!this.allowSync) return

    this.dispatch("sync", {
      target: document.documentElement,
      detail: {
        elementToSyncWith: this.element,
        stepId: this.element.dataset.stepId,
        state: this.currentState,
      },
    })
  }

  // private

  syncAlignment() {
    switch (this.alignmentValue) {
      case "left":
        this.alignToLeft({
          currentTarget: this.element.querySelector("[data-align='left']"),
        })
        break
      case "center":
        this.alignToCenter({
          currentTarget: this.element.querySelector("[data-align='center']"),
        })
        break
      case "right":
        this.alignToRight({
          currentTarget: this.element.querySelector("[data-align='right']"),
        })
        break
      case "full_width":
        this.alignToFullWidth({
          currentTarget: this.element.querySelector(
            "[data-align='full_width']"
          ),
        })
        break
    }
  }

  get currentState() {
    return {
      textColor: this.textColorValue,
      backgroundColor: this.backgroundColorValue,
      alignment: this.alignmentValue,
      label: this.labelInputTarget.value,
    }
  }
}
