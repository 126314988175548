import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.classList.add("not-draggable")
  }

  allowDragging() {
    this.element.classList.remove("not-draggable")
  }

  disallowDragging() {
    this.element.classList.add("not-draggable")
  }
}
