import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tag"]

  tagTargetDisconnected() {
    if(this.tagTargets.length === 0) {
      this.element.remove()
    }
  }
}
