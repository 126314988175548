import ApplicationController from '../application_controller'

import { commaSeperatedListWithAndConjunction } from '@/controllers/models/sentence';

export default class extends ApplicationController {
  static targets = ["all", "technology", "error", "description"]
  static classes = ["enabled", "disabled"]

  initialize() {
    this.checkAndDisableTechnology = this.checkAndDisableTechnology.bind(this)
    this.checkAndEnableTechnology = this.checkAndEnableTechnology.bind(this)

    this.enableTechnology = this.enableTechnology.bind(this)
  }

  connect() {
    if (this.allTarget.checked) {
      this.messageLengthChangedToLowest()

      this.dispatch('changed', {
        detail: {
          ids: ['all'],
          names: ['all']
        }
      })
    } else {
      this.dispatch('changed', {
        detail: {
          ids: this.checkedTechnologies.map(technology => technology.dataset.hashedId),
          names: this.checkedTechnologies.map(technology => technology.dataset.name),
        }
      })
    }

    if (this.checkedTechnologies.length === 1) {
      this.changeMessageLengthToCheckedTechnology(this.checkedTechnologies[0])
      this.checkAndDisableTechnology(this.checkedTechnologies[0])
    }
  }

  onSendViaAllClick() {
    if (this.allTarget.checked) {
      this.technologyTargets.forEach(this.checkAndDisableTechnology)
      this.messageLengthChangedToLowest()

      this.dispatch('changed', {
        detail: {
          ids: ['all'],
          names: ['all']
        }
      })

      if (this.hasErrorTarget) {
        this.hide(this.errorTarget)
      }

      if(this.hasDescriptionTarget) {
        this.descriptionTarget.textContent = t.compose.technologies.summary.all
      }
    } else {
      this.technologyTargets.forEach(this.checkAndEnableTechnology)
      this.onTechnologyClick()
    }
  }

  onTechnologyClick() {
    if (this.checkedTechnologies.length === 1) {
      this.changeMessageLengthToCheckedTechnology(this.checkedTechnologies[0])
      this.checkAndDisableTechnology(this.checkedTechnologies[0])
    } else {
      this.disabledTechnologies.forEach(this.checkAndEnableTechnology)
      this.messageLengthChangedToLowest()
    }

    if(this.hasDescriptionTarget) {
      this.descriptionTarget.textContent = t.compose.technologies.summary.specific.replace(
        '%{technologies}',
        commaSeperatedListWithAndConjunction(this.checkedTechnologies.map(technology => technology.dataset.formattedName))
      )
    }

    if (this.hasErrorTarget) {
      if (this.checkedTechnologies.length === 0) {
        this.show(this.errorTarget)
      } else {
        this.hide(this.errorTarget)
      }
    }

    this.dispatch('changed', {
      detail: {
        ids: this.checkedTechnologies.map(technology => technology.dataset.hashedId),
        names: this.checkedTechnologies.map(technology => technology.dataset.name),
      }
    })
  }

  restoreSnapshot({technologies}) {
    if (technologies.includes('all')) {
      this.allTarget.checked = true
      this.technologyTargets.forEach(this.checkAndDisableTechnology)
    } else {
      this.allTarget.checked = false

      this.technologyTargets.forEach(technology => {
        technology.checked = technologies.includes(technology.dataset.hashedId)
        technology.disabled = false

        this.enableTechnology(technology)
      })
    }
  }

  // private

  changeMessageLengthToCheckedTechnology(technology) {
    this.dispatch("messageLengthChange", {
      detail: {
        name: technology.dataset.name,
        maxMessageLength: Number.parseInt(technology.dataset.maxMessageLength)
      }
    })
  }

  messageLengthChangedToLowest() {
    if (!this.technologyWithLowestMessageLength) return

    this.dispatch("messageLengthChange", {
      detail: this.technologyWithLowestMessageLength
    })
  }

  checkAndDisableTechnology(technologyCheckbox) {
    technologyCheckbox.checked = true
    technologyCheckbox.classList.add('text-gray-300')
    technologyCheckbox.parentElement.classList.add('pointer-events-none')

    technologyCheckbox.parentElement.classList.add(...this.disabledClasses)
    technologyCheckbox.parentElement.classList.remove(...this.enabledClasses)
  }

  checkAndEnableTechnology(technologyCheckbox) {
    technologyCheckbox.disabled = false

    technologyCheckbox.classList.remove('text-gray-300')
    technologyCheckbox.parentElement.classList.remove('pointer-events-none')

    this.enableTechnology(technologyCheckbox)
  }

  enableTechnology(technologyCheckbox) {
    technologyCheckbox.parentElement.classList.remove(...this.disabledClasses)
    technologyCheckbox.parentElement.classList.add(...this.enabledClasses)
  }

  get technologyWithLowestMessageLength() {
    const lowestMessageLength = Math.min(
      ...this.checkedTechnologies.map(checkbox => Number.parseInt(checkbox.dataset.maxMessageLength))
    )

    const technology = this.checkedTechnologies.find(
      technology => technology.dataset.maxMessageLength === lowestMessageLength.toString()
    )


    return technology ? {name: technology.dataset.name, maxMessageLength: lowestMessageLength} : null
  }

  get checkedTechnologies() {
    return this.technologyTargets.filter(technology => technology.checked)
  }

  get disabledTechnologies() {
    return this.technologyTargets.filter(technology => technology.disabled || technology.classList.contains('text-gray-300'))
  }

  get snapshot() {
    return {
      technologies: this.allTarget.checked ? ['all'] : this.checkedTechnologies.map(technology => technology.dataset.hashedId),
      maxMessageLength: this.technologyWithLowestMessageLength?.maxMessageLength
    }
  }
}
