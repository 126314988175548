import ComposeController from '../compose/base_controller'

import {
  KeyboardKey,
  Character,
  MentionsManager
} from "../compose/models"

export default class extends ComposeController {
  static values = {
    ensureCaretVisibility: Boolean,
  }

  static targets = [
    'mentionsList',
    'submit',
    'hideButton',
    'noteUsage',
    'destinationList',
    'destinationListToggleButton'
  ]

  initialize() {
    super.initialize()
    this.mentionsManager = new MentionsManager(this)
  }

  connect() {
    super.connect()

    if(this.ensureCaretVisibilityValue) {
      this.nextTick(() => this.cursor.ensureCaretVisibility())
    }
  }

  onTrixFocus() {
    if(this.ensureCaretVisibilityValue) {
      this.nextTick(() => this.cursor.ensureCaretVisibility())
    }
  }

  onTrixContentChange() {
    if(this.ensureCaretVisibilityValue) {
      this.cursor.ensureCaretVisibility()
    }

    if (this.empty) return

    if (this.documentStartsWithZeroWidthNonJoinerSpace) {
      this.deleteStartingZeroWidthNonJoinerSpace()
    }
  }

  onTrixKeydown(event) {
    const key = KeyboardKey.fromKeyboardEvent(event)
    const character = Character.fromKeyboardEvent(event)
    const characterBeforeCursor = this.cursor.characterBeforeCaret()

    if(key.isEscape && this.empty) {
      return this.hideButtonTarget.click()
    }

    if(this.hasDestinationListTarget && this.isVisible(this.destinationListTarget)) {
      this.destinationListToggleButtonTarget.click()
    }

    if (key.isHotkey) {
      return this.handleHotkey(event)
    }

    if (key.isBackspace && this.cursor.pieceBeforeCaret().isShortlink) {
      return this.mentionsManager.deleteAttachmentInPosition()
    }

    if (key.isArrow) {
      return this.handleArrowNavigation(event)
    }

    if(character.isAtSymbol && characterBeforeCursor.isWhitespace) {
      const [start, _] = this.editor.getSelectedRange()
      const rect = this.editor.getClientRectAtPosition(start)

      this.mentionStartsAt = start
      this.showMentionsDropdown(rect)
    }

    if (this.noSelection && key.isAlphanumeric && this.cursor.pieceBeforeCaretIsShortLink()) {
      this.ensureWhitespaceBeforeCursor()
    }

    if (this.noSelection && key.isAlphanumeric && this.cursor.pieceBeforeCaretIsTag()) {
      this.ensureWhitespaceBeforeCursor()
    }
  }

  onTrixKeyup(event) {
    const key = KeyboardKey.fromKeyboardEvent(event)

    if (this.noSelection && key.isAlphanumeric && this.cursor.pieceAtCaretIsShortLink()) {
      this.ensureWhitespaceAfterCursor()
    }

    if (this.noSelection && key.isAlphanumeric && this.cursor.pieceAtCaretIsTag()) {
      this.ensureWhitespaceAfterCursor()
    }

    const pieceAtPosition = this.cursor.pieceBeforeCaret()
    const pieceContent = (pieceAtPosition.string || '').split(/(\s+)/)
    const pieceIndex = this.trixDocument.getPieces().findIndex(piece => piece.id === pieceAtPosition.id)

    const contentLengthBeforePiece = this.contentLengthBeforePiece(pieceIndex)

    const mention = pieceContent.find(substring => substring.startsWith('@'))

    if(!key.isVerticalArrow) {
      if(mention) {
        const mentionIndex = pieceContent.findIndex(substring => substring.startsWith('@'))

        const mentionStartsAt = contentLengthBeforePiece + pieceContent.slice(0, mentionIndex).join('').length
        const mentionEndsAt = mentionStartsAt + mention.length
        const rect = this.editor.getClientRectAtPosition(mentionStartsAt)

        this.showMentionsDropdown(rect)

        const cursorWithinMention = this.trixPosition[0] > mentionStartsAt && this.trixPosition[0] <= mentionEndsAt

        if(cursorWithinMention) {
          this.dispatch('query', {
            target: this.mentionsListTarget,
            detail: mention.replace('@', '')
          })
        } else {
          this.hide(this.mentionsListTarget)
        }
      } else {
        this.hide(this.mentionsListTarget)
      }
    }
  }

  handleArrowNavigation(event) {
    const key = KeyboardKey.fromKeyboardEvent(event)
    const characterBeforeCaret = this.cursor.characterBeforeCaret()

    if (key.isLeftArrow && this.cursor.pieceBeforeCaretIsShortLink()) {
      this.cursor.moveToLeft()
    } else if (key.isRightArrow && this.cursor.pieceAtCaretIsShortLink()) {
      this.cursor.moveToRight()
    }
  }

  handleHotkey(event) {
    const key = KeyboardKey.fromKeyboardEvent(event)

    if(key.activateBold || key.activateItalic || key.activateLink) {
      event.preventDefault()
    }

    if(key.isEnter && !key.isShift && this.mentionsListTarget.classList.contains('hidden')) {
      this.submitTarget.click()
    }
  }

  blur() {
    // When the user clicks a button in the toolbar, trix will blur, but the cursor will still be visible,
    // allowing the user to type. This way, we force Trix to blur.
    const input = window.document.createElement('input')
    input.classList.add('absolute', '-left-[20rem]', '-top-[20rem]')

    document.body.appendChild(input)

    input.focus()
    input.remove()
  }

  hideDestinationListDropdown() {
    if(this.hasDestinationListTarget && this.isVisible(this.destinationListTarget)) {
      this.destinationListToggleButtonTarget.click()

      this.editor.setSelectedRange(this.trixPosition)
      this.focus()
    }
  }

  showMentionsDropdown(rect) {
    const parentRect = this.element.getBoundingClientRect()

    const x = Math.abs(rect.left - parentRect.left)
    this.mentionsListTarget.style.transform = `translateX(${x}px)`

    this.show(this.mentionsListTarget)
    this.mentionsListTarget.setAttribute('data-arrow-navigation-enabled-value', true)
  }

  addMention({ detail: currentTarget }) {
    this.mentionsManager.insert({ currentTarget })

    this.mentionStartsAt = null
    this.mentionsListTarget.setAttribute('data-arrow-navigation-enabled-value', false)
  }

  updateMention({ detail: { id, element } }) {
    this.mentionsManager.replace(id, element)
  }

  focus() {
    if (!this.editor) return

    if(this.ensureCaretVisibilityValue) {
      this.cursor.ensureCaretVisibility()
    }

    this.trixTarget.focus()
  }

  afterAttachmentInsertion() {
    this.hide(this.mentionsListTarget)

    this.element.querySelectorAll('figure').forEach(figure => {
      figure.removeEventListener('click', this.onFigureClick.bind(this))
      figure.addEventListener('click', this.onFigureClick.bind(this))
    })
  }

  onFigureClick({ currentTarget: figure }) {
    const rect = figure.getBoundingClientRect()
    const parentRect = this.element.getBoundingClientRect()

    const x = Math.abs(rect.left - parentRect.left)
    this.mentionsListTarget.style.transform = `translateX(${x}px)`


    const userId = figure.firstElementChild.getAttribute('data-id')

    this.dispatch('mention:edit', {
      target: this.mentionsListTarget,
      detail: userId
    })
  }

  clear() {
    this.resetContent()

    this.dispatch('cleared', {
      target: document.getElementById('compose')
    })
  }

  clearAndHide() {
    if(this.isInvisible()) return
    this.hideButtonTarget.click()
  }

  focusPreviousLocation() {
    this.editor.setSelectedRange(this.oldPosition)
    this.focus()
  }

  resetTargetConnected() {
    this.resetTarget.remove()
    this.hideButtonTarget.click()
  }

  resetNoteUsageDescription({ currentTarget }) {
    this.noteUsageTarget.innerText = currentTarget.dataset.description

    this.editor.setSelectedRange(this.trixPosition)
    this.focus()
  }

  updateNoteUsageDescription() {
    this.noteUsageTarget.innerText = this.noteUsageTarget.dataset.altText

    this.editor.setSelectedRange(this.trixPosition)
    this.focus()
  }

  // private

  get empty() {
    return this.trixDocument.toString().length === 2
  }
}
