import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ["submit", "input"]
  static values = {
    enabled: Boolean,
    autofocus: Boolean
  }

  initialize() {
    this.onSubmitListener = this.onSubmitListener.bind(this)
  }

  connect() {
    if(this.enabledValue) {
      this.activate()
    } else {
      this.deactivate()
    }

    if(this.autofocusValue) {
      this.focus(this.element.querySelector('input[data-autofocus]'), {
        moveCursorToEnd: true
      })
    }

    this.element.addEventListener("submit", this.onSubmitListener)
    super.connect()
  }

  disconnect() {
    this.element.removeEventListener("submit", this.onSubmitListener)
    super.disconnect()
  }

  activate() {
    if (this.active) return

    this.submitTarget.disabled = false
  }

  deactivate() {
    if (this.inactive) return
    this.submitTarget.disabled = true
  }

  // call this on an input where you would like it to disable the submit button
  // when it becomes empty
  sync(e) {
    const length = e.target.isContentEditable
      ? e.target.innerText.trim().length
      : e.target.value.trim().length

    if (length === 0) {
      this.deactivate()
    } else {
      this.activate()
    }
  }

  syncInputTargets() {
    if (this.filledInputs && (!this.composeSnapshot || this.composeSnapshot.string.length > 0)) {
      this.activate()
    } else {
      this.deactivate()
    }
  }

  syncComposeSnapshot({ detail: composeSnapshot }) {
    this.composeSnapshot = composeSnapshot

    if(this.filledInputs && composeSnapshot.string.length > 0 && composeSnapshot.technologies.length > 0) {
      this.activate()
    } else {
      this.deactivate()
    }
  }

  onSubmitListener(e) {
    if(this.inactive && e.submitter.getAttribute('data-prevent') === null) {
      e.preventDefault()
    }
  }

  get active() {
    return this.submitTarget.disabled === false
  }

  get inactive() {
    return this.submitTarget.disabled === true
  }

  get filledInputs() {
    return this.inputTargets.every((input) => {
      if(input.isContentEditable) {
        return input.innerText.trim().length > 0
      } else if(input.type === 'checkbox') {
        return input.checked
      } else {
        return input.value.trim().length > 0
      }
    })
  }
}
