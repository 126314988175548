import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['handle']

  connect() {
    this.addClass(this.element, 'not-draggable')
  }

  enable() {
    this.removeClass(this.element, 'not-draggable')
  }

  disable() {
    this.addClass(this.element, 'not-draggable')
  }

  activate() {
    this.addClass(this.handleTarget, 'group-hover:visible')
    this.removeClass(this.handleTarget, 'hidden')
  }

  deactivate() {
    this.addClass(this.handleTarget, 'hidden')
    this.removeClass(this.handleTarget, 'group-hover:visible')
  }
}
