import { Application } from "@hotwired/stimulus"
import Carousel from "stimulus-carousel"

const application = Application.start()
application.register("carousel", Carousel)

export default class extends Carousel {
  connect() {
    super.connect()

    // The swiper instance.
    this.swiper

    // Default options for every carousels.
    this.defaultOptions
  }

  // You can set default options in this getter.
  get defaultOptions() {
    return {
      loop: true,
      autoplay: { delay: 3500 },
      allowTouchMove: false,
      slidesPerView: 1,
      effect: "fade",
      speed: 1000,
      fadeEffect: {
        crossFade: true,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        type: "custom",
        bulletClass: "swiper-pagination-item",
        renderCustom: function (swiper, current, total) {
          var names = []
          document
            .querySelectorAll(".swiper-wrapper .swiper-slide")
            .forEach(function (i) {
              names.push(i.dataset.name)
            })
          var text =
            "<ul class='space-x-6 lg:space-x-16 lg:text-xl font-medium flex items-center justify-center'>"
          for (let i = 1; i <= total; i++) {
            if (current == i) {
              text += `<li class="swiper-pagination-item active cursor-pointer space-y-1"><div>${names[i]}</div><div class="h-2 rounded-full bg-tiger"></div></li>`
            } else {
              text += `<li class="swiper-pagination-item cursor-pointer space-y-1"><div>${names[i]}</div><div class="h-2"></div></li>`
            }
          }
          text += "</ul>"
          return text
        },
      },
    }
  }
}
