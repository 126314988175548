import ApplicationController from '../application_controller'

import { useNumberHelpers } from '../mixins/useNumberHelpers'

export default class extends ApplicationController {
  static values = {
    agreement: String,
    activePackageId: String,
    creditBundle: String,
    swap: Boolean
  }

  static targets = ['package', 'agreementCheckbox', 'feature', 'submitButton', 'monthlyAgreementRadio', 'yearlyAgreementRadio']

  initialize() {
    super.initialize()

    this.initialState = {
      agreement: this.agreementValue,
      activePackage: this.activePackageIdValue,
      credit_bundle: parseInt(this.creditBundleValue)
    }

    this.state = { ...this.initialState }
  }

  connect() {
    super.connect()

    useNumberHelpers(this)
  }

  switchToMonthlyAgreement() {
    this.agreementValue = 'monthly'
    this.agreementCheckboxTarget.checked = false
  }

  switchToYearlyAgreement() {
    this.agreementValue = 'yearly'
    this.agreementCheckboxTarget.checked = true
  }

  toggleAgreement() {
    if(this.agreementCheckboxTarget.checked) {
      this.switchToMonthlyAgreement()
    } else {
      this.switchToYearlyAgreement()
    }
  }

  onPackageChange({ detail: info }) {
    this.state.activePackage = info.hashed_id
    this.state.credit_bundle = parseInt(info.credit_bundle) || 0

    this.stateChanged()

    const seat = {
      element: this.featureTargets.find(element => element.dataset.type === 'seat'),
      feature: info.features.find(feature => feature.identifier === 'seat')
    }

    if(parseInt(seat.feature.available) > 1) {
      seat.element.innerText = this.translations.static.pricing.features.seat.available.other.replace(
        '%{count}',
        parseInt(seat.feature.available)
      )
    } else {
      seat.element.innerText = this.translations.static.pricing.features.seat.available.one
    }

    const credit = {
      element: this.featureTargets.find(element => element.dataset.type === 'credit'),
      feature: info.features.find(feature => feature.identifier === 'credit')
    }

    credit.element.innerText = this.translations.static.pricing.features.credit.available.replace(
      '%{count}',
      this.numberWithDelimiter(parseInt(credit.feature.available))
    )

    const phoneNumber = {
      element: this.featureTargets.find(element => element.dataset.type === 'phone_number'),
      feature: info.features.find(feature => feature.identifier === 'phone_number')
    }

    if(parseInt(phoneNumber.feature.available) > 1) {
      phoneNumber.element.innerText = this.translations.static.pricing.features.phone_number.available.other.replace(
        '%{count}',
        parseInt(phoneNumber.feature.available)
      )
    } else {
      phoneNumber.element.innerText = this.translations.static.pricing.features.phone_number.available.one
    }

    const keyword = {
      element: this.featureTargets.find(element => element.dataset.type === 'keyword'),
      feature: info.features.find(feature => feature.identifier === 'keyword')
    }

    if(keyword.feature) {
      keyword.element.querySelector('x-available').innerText = this.translations.static.pricing.features.keyword.allowed_with_owned_shortcode
    } else {
      keyword.element.querySelector('x-available').innerText = this.translations.static.pricing.feature_status.not_included
    }

    const audienceTracking = {
      element: this.featureTargets.find(element => element.dataset.type === 'audience_tracking'),
      feature: info.features.find(feature => feature.identifier === 'audience_tracking')
    }

    audienceTracking.element.querySelector('x-available').innerText =
      this.translations.static.pricing.features.audience_tracking.available.replace('%{count}', parseInt(audienceTracking.feature.available))

    const activityReport = {
      element: this.featureTargets.find(element => element.dataset.type === 'activity_report'),
      feature: info.features.find(feature => feature.identifier === 'activity_report')
    }


    if('real_time' in activityReport.feature.metadata) {
      activityReport.element.querySelector('x-available').innerText =
        this.translations.static.pricing.features.activity_reports.delay.realtime
    } else {
      activityReport.element.querySelector('x-available').innerText =
        this.translations.static.pricing.features.activity_reports.delay.minute.other.replace('%{count}', activityReport.feature.metadata.delay)
    }

    const whiteLabel = {
      element: this.featureTargets.find(element => element.dataset.type === 'white_label'),
      feature: info.features.find(feature => feature.identifier === 'white_label')
    }

    if(whiteLabel.feature) {
      whiteLabel.element.querySelector('x-available').innerText = this.translations.static.pricing.feature_status.included
    } else {
      whiteLabel.element.querySelector('x-available').innerText = this.translations.static.pricing.feature_status.not_included
    }

    const whiteGlove = {
      element: this.featureTargets.find(element => element.dataset.type === 'white_glove'),
      feature: info.features.find(feature => feature.identifier === 'white_glove')
    }

    if(whiteGlove.feature) {
      whiteGlove.element.querySelector('x-available').innerText = this.translations.static.pricing.feature_status.included
    } else {
      whiteGlove.element.querySelector('x-available').innerText = this.translations.static.pricing.feature_status.not_included
    }
  }

  // private

  agreementValueChanged() {
    this.state.agreement = this.agreementValue
    this.notifyPackages()

    this.stateChanged()

    if(this.agreementValue === 'monthly') {
      this.monthlyAgreementRadioTarget.checked = true
    } else {
      this.yearlyAgreementRadioTarget.checked = true
    }
  }

  stateChanged() {
    if(this.swapValue) {
     this.submitButtonTarget.disabled = Object.keys(this.state).every(key => {
        return this.state[key] === this.initialState[key]
      })
    }
  }

  notifyPackages() {
    this.packageTargets.forEach(element => {
      this.dispatch('agreement:change', {
        target: element,
        detail: this.agreementValue
      })
    })
  }
}
