import { Controller } from "@hotwired/stimulus"
import { gsap, Sine } from "gsap"

import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Observer } from "gsap/Observer"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

gsap.registerPlugin(ScrollTrigger, Observer, ScrollToPlugin)

export default class extends Controller {
  connect() {
    gsap.from(".title-hero", { y: 50, duration: 0.5, opacity: 0 })
    gsap.from(".headline-hero", {
      y: 25,
      duration: 0.25,
      opacity: 0,
      delay: 0.25,
    })

    const randomX = random(1, 10)
    const randomY = random(1, 10)
    const randomDelay = random(0, 1)
    const randomTime = random(3, 5)
    const randomTime2 = random(5, 10)
    const randomAngle = random(-10, 10)

    const cans = gsap.utils.toArray(".hero-image-item")
    cans.forEach((can) => {
      gsap.set(can, {
        x: randomX(-1),
        y: randomX(1),
        rotation: randomAngle(-1),
      })

      moveX(can, 1)
      moveY(can, -1)
      rotate(can, 1)
    })

    function rotate(target, direction) {
      gsap.to(target, randomTime2(), {
        rotation: randomAngle(direction),
        // delay: randomDelay(),
        ease: Sine.easeInOut,
        onComplete: rotate,
        onCompleteParams: [target, direction * -1],
      })
    }

    function moveX(target, direction) {
      gsap.to(target, randomTime(), {
        x: randomX(direction),
        ease: Sine.easeInOut,
        onComplete: moveX,
        onCompleteParams: [target, direction * -1],
      })
    }

    function moveY(target, direction) {
      gsap.to(target, randomTime(), {
        y: randomY(direction),
        ease: Sine.easeInOut,
        onComplete: moveY,
        onCompleteParams: [target, direction * -1],
      })
    }

    function random(min, max) {
      const delta = max - min
      return (direction = 1) => (min + delta * Math.random()) * direction
    }
  }
}
