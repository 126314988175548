import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    eventName: String,
    remove: Boolean,
  }

  connect() {
    if (this.removeValue === null) {
      this.removeValue = true
    }

    const event = new CustomEvent(this.eventNameValue)
    event.dataset = this.element.dataset
    window.dispatchEvent(event)

    if (this.removeValue) {
      this.element.remove()
    }
  }
}
