import { Controller } from "@hotwired/stimulus"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Observer } from "gsap/Observer"

gsap.registerPlugin(ScrollTrigger, Observer)

export default class extends Controller {
  connect() {
    let items = gsap.utils.toArray(".about-item")

    items.forEach((item) => {
      console.log(item)
      gsap.from(item, {
        opacity: 0.1,
        scrollTrigger: {
          trigger: item,
          start: "top bottom",
          id: item,
          toggleActions: "restart none none none",
          scrub: true,
        },
      })
    })
  }
}
