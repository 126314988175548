import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

import { Current } from "../models/current";

export default class extends Controller {
  static values = {
    url: String,
    stateFilter: String,
    memberFilter: Array,
    labelFilter: {
      type: Array,
      default: [],
    },
  }

  changeStateFilter(e) {
    this.stateFilterValue = e.target.dataset.filter

    this.displayStateFilterSkeletons()
    this.getFilteredResults()
  }

  applyLabelFilter(e) {
    this.labelFilterValue = [...this.labelFilterValue, e.currentTarget.dataset.id]
    this.displayStateFilterSkeletons()

    this.getFilteredResults()
  }

  applyMemberFilter(e) {
    this.memberFilterValue = Array.from(
      new Set([...this.memberFilterValue, e.currentTarget.dataset.id])
    )

    this.displayStateFilterSkeletons()
    this.getFilteredResults()
  }

  async removeMemberFilter({ currentTarget }) {
    this.memberFilterValue = this.memberFilterValue.filter(
      (memberId) => memberId !== currentTarget.dataset.id
    )

    this.displayStateFilterSkeletons()
    await this.getFilteredResults()
  }

  async removeLabelFilter({ currentTarget }) {
    this.labelFilterValue = this.labelFilterValue.filter(
      (labelId) => labelId !== currentTarget.dataset.id
    )

    this.displayStateFilterSkeletons()
    await this.getFilteredResults()
  }

  async getFilteredResults() {
    const url = new URL(this.urlValue)

    url.searchParams.set("filter", this.stateFilterValue)

    if (this.appliedMemberFiltersWithoutSiteAdmin.length === 0) {
      url.searchParams.append("team", "1")
    } else if(this.appliedMemberFiltersWithoutSiteAdmin.length > 0) {
      url.searchParams.append("member", this.appliedMemberFiltersWithoutSiteAdmin.join(","))
    }

    url.searchParams.append("label", this.labelFilterValue.join(","))

    this.changeBrowserUrl()

    await get(url.toString(), {
      responseKind: "turbo-stream",
    })
  }

  filter(e) {
    const url = new URL(this.urlValue)
    url.searchParams.set(this.queryValue, e.target.dataset.filter)

    get(url.toString(), {
      responseKind: "turbo-stream",
    })

    this.changeBrowserUrl()
  }

  changeBrowserUrl() {
    const url = new URL(window.location.href.split("?")[0])
    url.searchParams.set("filter", this.stateFilterValue)

    if(this.labelFilterValue.length > 0) {
      url.searchParams.append("label", this.labelFilterValue.join(","))
    }

    if (this.appliedMemberFiltersWithoutSiteAdmin.length === 0) {
      url.searchParams.append("team", "1")
    } else if(this.appliedMemberFiltersWithoutSiteAdmin.length > 0) {
      url.searchParams.append("member", this.appliedMemberFiltersWithoutSiteAdmin.join(","))
    }

    history.pushState({}, null, url.toString().replaceAll("%2C", ","))
    Turbo.navigator.history.replace(url.toString().replaceAll("%2C", ","))

    this.dispatch("reset-pages", { prefix: false, detail: { page: 2 } })
  }

  stateFilterValueChanged() {
    this.dispatch("stateChanged", {
      target: document.getElementById("conversations-list"),
      detail: {
        state: this.stateFilterValue,
      }
    })
  }

  // private

  displayStateFilterSkeletons() {
    this.element.querySelectorAll("[data-state-filter]").forEach((element) => {
      element.querySelector("x-count").classList.add("hidden")
      element.querySelector("x-count-skeleton").classList.remove("hidden")
    })
  }

  get appliedMemberFiltersWithoutSiteAdmin() {
    if(Current.user.teamMember) {
      return this.memberFilterValue
    } else {
      return this.memberFilterValue.filter((memberId) => memberId !== Current.user.id)
    }
  }
}
