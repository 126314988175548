import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["remove", "name", "value"]

  connect() {
    this.headersController = this.element.closest("[data-controller*='step--request--headers']")
    this.saveState()

    if(this.element.hasAttribute("data-autofocus")) {
      this.nameTarget.focus()
    }
  }

  disconnect() {
    super.disconnect()

    this.dispatch("removed", {
      target: this.headersController
    })
  }

  onNameChange() {
    this.changedState.name = this.nameTarget.value
    this.removeErrorClass()

    if(this.changed) {
      this.element.setAttribute("data-changed", "")
    } else {
      this.element.removeAttribute("data-changed")
    }

    this.validate()
  }

  onValueChange() {
    this.changedState.value = this.valueTarget.value
    this.removeErrorClass()

    if(this.changed) {
      this.element.setAttribute("data-changed", "")
    } else {
      this.element.removeAttribute("data-changed")
    }

    this.validate()
  }

  allowRemove() {
    this.removeTarget.classList.remove("hidden")
  }

  preventRemove() {
    this.removeTarget.classList.add("hidden")
  }

  remove() {
    if(this.element.hasAttribute("data-saved")) {
      this.element.classList.add("hidden")
    } else {
      this.element.remove()
    }
  }

  setValue({ detail: value }) {
    this.valueTarget.value = value
  }

  setData({ detail: { name, value } }) {
    this.nameTarget.value = name
    this.valueTarget.value = value

    this.saveState()
  }

  saveState() {
    this.state = {
      name: this.nameTarget.value,
      value: this.valueTarget.value
    }

    this.changedState = {...this.state}
    this.element.removeAttribute("data-changed")

    this.validate()
  }

  restoreState() {
    this.nameTarget.value = this.state.name
    this.valueTarget.value = this.state.value

    this.saveState()
  }

  invalidate() {
    if(this.changedState.name.trim().length === 0) {
      this.nameTarget.classList.add("error")
    }

    if(this.changedState.value.trim().length === 0) {
      this.valueTarget.classList.add("error")
    }
  }

  // private

  validate() {
    if(this.invalid) {
      this.element.setAttribute("data-invalid", "")
    } else {
      this.element.removeAttribute("data-invalid")
    }
  }

  removeErrorClass() {
    this.nameTarget.classList.remove("error")
    this.valueTarget.classList.remove("error")
  }

  get invalid() {
    return this.changedState.name.trim().length === 0 || this.changedState.value.trim().length === 0
  }

  get changed() {
    return Object.keys(this.state).some((key) => this.state[key] !== this.changedState[key])
  }
}
