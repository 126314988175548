import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { FetchRequest, patch } from "@rails/request.js"

export default class extends Controller {
  static values = {
    url: String,
    group: { type: String, default: "shared" }
  }

  static targets = [
    'dragList'
  ]

  connect() {
    this.sortable = Sortable.create(this.hasDragListTarget ? this.dragListTarget : this.element, {
      group: this.groupValue,
      animation: 150,
      filter: ".not-draggable",
      onEnd: this.end.bind(this),
      preventOnFilter: false
    })
  }

  end(event) {
    let token = event.item.dataset.channelToken
    let data = {
      position: event.newIndex + 1,
    }

    patch(this.urlValue.replace(":token", token), {
      body: JSON.stringify(data),
    })
  }
}
