class TagsManager {
  constructor(inputController) {
    this.inputController = inputController
  }

  setup() {
    Trix.config.textAttributes.background = {
      styleProperty: 'backgroundColor',
      inheritable: false,
    }

    Trix.config.textAttributes.padding = {
      styleProperty: 'padding',
      inheritable: false,
    }

    Trix.config.textAttributes.rounded = {
      styleProperty: 'borderRadius',
      inheritable: false,
    }
  }

  activate() {
    this.editor.activateAttribute('background', 'rgb(231, 229, 234)')
    this.editor.activateAttribute('padding', '0.125rem')
    this.editor.activateAttribute('rounded', '0.375rem')
  }

  deactivate() {
    this.editor.deactivateAttribute('background', 'rgb(231, 229, 234)')
    this.editor.deactivateAttribute('padding', '0.125rem')
    this.editor.deactivateAttribute('rounded', '0.375rem')
  }

  insert(event) {
    const pieceAtCursor = this.cursor.pieceAtCaret()
    const pieceBeforeCursor = this.cursor.pieceBeforeCaret()

    if(pieceBeforeCursor.isTag) {
      return this.insertTagAfterTagElement(event.detail)
    }

    if(pieceAtCursor.string === pieceBeforeCursor.string || (pieceAtCursor.isLineFeed && pieceBeforeCursor)) {
      return this.insertTagBetweenCharacters(event.detail)
    }

    if(pieceAtCursor.isWhitespace || pieceAtCursor.endsWithWhitespace) {
      return this.moveToRightAndInsertTag(event.detail)
    }

    if(pieceAtCursor.isLineFeed) {
      this.manuallyInsertTag(event.detail)
    }
  }

  insertTagAfterTagElement(tag) {
    const pieceAtCursor = this.cursor.pieceAtCaret()

    if(pieceAtCursor.isWhitespace) {
      this.moveToRightOfWhitespaceAndInsertTag(tag)
    }

    if(pieceAtCursor.isLineFeed) {
      this.moveToRightOfLineFeedAndInsertTag(tag)
    }
  }

  insertTagBetweenCharacters(tag) {
    const characterAtCursor = this.cursor.characterAtCaret()
    const characterBeforeCursor = this.cursor.characterBeforeCaret()

    if(characterBeforeCursor.isWhitespace) {
      return this.insertWhitespaceMoveToBackwardAndInsertTag(tag)
    }

    if(characterAtCursor.isWhitespace) {
      return this.insertWhitespaceAndTag(tag)
    }

    if(!characterBeforeCursor.isWhitespace && !characterAtCursor.isWhitespace) {
      return this.insertWhitespaceTwiceAndTag(tag)
    }
  }

  moveToRightOfWhitespaceAndInsertTag(tag) {
    this.cursor.moveToRight()

    this.inputController.nextTick(() => {
      this.editor.insertString(tag)
    })
  }

  moveToRightOfLineFeedAndInsertTag(tag) {
    this.inputController.isParsing = true

    this.inputController.insertWhitespace()
    this.cursor.moveToRight()
    this.inputController.isParsing = false

    this.inputController.nextTick(() => {
      this.editor.insertString(tag)
    })
  }

  insertWhitespaceMoveToBackwardAndInsertTag(tag) {
    this.inputController.isParsing = true

    this.inputController.insertWhitespace()
    this.cursor.moveToLeft()

    this.manuallyInsertTag(tag)
  }

  insertWhitespaceAndTag(tag) {
    this.inputController.isParsing = true

    this.inputController.insertWhitespace()
    return this.manuallyInsertTag(tag)
  }

  insertWhitespaceTwiceAndTag(tag) {
    this.inputController.isParsing = true

    // when the input is not empty, insert whitespaces
    if(this.trixDocument.toString().length !== 1) {
      this.inputController.insertWhitespace()
      this.inputController.insertWhitespace()

      this.cursor.moveToLeft()
    }

    return this.manuallyInsertTag(tag)
  }

  moveToRightAndInsertTag(tag) {
    this.cursor.moveToRight()
    this.manuallyInsertTag(tag)
  }

  manuallyInsertTag(tag) {
    this.inputController.isParsing = true

    this.inputController.nextTick(() => {
      this.editor.recordUndoEntry('insert tag')
      this.editor.insertString(tag)

      const [_, tagEndsAt] = this.editor.getSelectedRange()
      const tagStartsAt = tagEndsAt - tag.length

      this.editor.setSelectedRange([tagStartsAt, tagEndsAt])
      this.activate()

      this.editor.setSelectedRange([tagEndsAt, tagEndsAt])
      this.inputController.isParsing = false
    })
  }

  get cursor() {
    return this.inputController.cursor
  }

  get editor() {
    return this.inputController.editor
  }

  get trixDocument() {
    return this.editor.getDocument()
  }
}

export { TagsManager }
