import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    widthClass: String,
    firstPage: Boolean,
  }

  initialize() {
    super.initialize()
    this.wizardStepChanged = this.wizardStepChanged.bind(this)
  }

  connect() {
    super.connect()

    if (this.firstPageValue) {
      this._changeHistoryStateToTurboStream()
    }

    window.addEventListener("popstate", this.wizardStepChanged)
  }

  disconnect() {
    this.element.classList.replace(this.widthClassValue, "w-2/12")
    this.widthClassValue = "w-2/12"
  }

  progress(e) {
    if (e.dataset.title) {
      const brandName = document.title.split("-")[1]
      document.title = `${e.dataset.title} - ${brandName}`
    }

    this.element.classList.replace(this.widthClassValue, e.dataset.stepClass)
    this.widthClassValue = e.dataset.stepClass
  }

  widthClassValueChanged() {
    if (this.widthClassValue === "w-full") {
      this.removeRoundedCorners()
    } else {
      this.applyRoundedCorners()
    }
  }

  removeRoundedCorners() {
    this.element.classList.remove("rounded-tr-lg", "rounded-br-lg")
  }

  applyRoundedCorners() {
    this.element.classList.add("rounded-tr-lg", "rounded-br-lg")
  }

  wizardStepChanged(event) {
    // when a user presses browser back-button inside a wizard step
    // turbo updates url but not the page since we are manually updating urls
    // when we return a stream response

    // when back-button pressed manually perform visit
    if (event.state.turbo_stream_response) {
      Turbo.visit(window.location.href, { action: "replace" })
    }
  }

  _changeHistoryStateToTurboStream() {
    history.replaceState(
      { turbo_stream_response: true },
      window.location.title,
      window.location.href
    )
  }
}
