import { get } from '@rails/request.js'

import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static values = { url: String, coupon: Object }
  static targets = [
    "menu",
    "active",
    "noneOption",
    "tags",
    "selected",
    "checkbox",
    "option",
  ]

  connect() {
    if (Object.keys(this.couponValue).length !== 0) {
      this.dispatch("set", {
        detail: this.couponValue,
      })
    }
  }

  async showPane() {
    await get(this.urlValue, { responseKind: "turbo-stream" })
    window.dispatchEvent(new CustomEvent("coupon:show"))
  }

  setCoupon({ currentTarget }) {
    this.noneOptionTarget.classList.remove("hidden")
    this.tagsTarget.classList.remove("hidden")

    this.dispatch("set", {
      detail: JSON.parse(currentTarget.dataset.coupon),
    })
  }

  onCouponCreated(e) {
    this.dispatch("set", {
      detail: JSON.parse(e.dataset.coupon)
    })
  }

  selectedTargetConnected() {
    this.dispatch("set", {
      detail: JSON.parse(this.selectedTarget.dataset.coupon),
    })
  }

  unsetCoupon() {
    window.coupon = undefined
    this.noneOptionTarget.classList.add("hidden")
    this.tagsTarget.classList.add("hidden")

    this.dispatch("unset")
  }

  insertTag({ currentTarget }) {
    this.dispatch("insert-tag", {
      detail: currentTarget.dataset.tag,
    })
  }

  hideByEscape() {
    if(this.isInvisible(this.menuTarget)) return

    this.activeTarget.click()
  }

  enable() {
    super.enable(this.activeTarget)
  }

  disable() {
    super.disable(this.activeTarget)
  }

  inheritCouponFromTemplate({ detail: coupon }) {
    if(this.optionTargets.length === 0) return

    if(coupon) {
      this.inheritedCouponFromTemplate = coupon
      this.optionTargets.find(option => option.dataset.optionValue === coupon.token).click()
    } else if(this.inheritedCouponFromTemplate) {
      this.noneOptionTarget.click()
    }
  }
}
