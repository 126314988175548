import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "form",
    "button",
    "nameInput",
    "nameSelect",
    "priceInput",
    "priceSelect",
    "priceToggleable",
    "nameToggleable",
    "nameTrigger",
    "priceTrigger",
  ]
  static values = {
    priceFilter: Boolean,
    category: String,
    option: String,
  }

  connect() {
    if (!this.priceFilterValue) {
      this.nameInputTarget.disabled = false
      this.nameSelectTarget.disabled = false

      this.priceInputTarget.disabled = true
      this.priceSelectTarget.disabled = true
    }

    this.nameInputTargets.forEach((input) => {
      input.setAttribute("data-saved-state", input.value)
    })

    this.priceInputTargets.forEach((input) => {
      input.setAttribute("data-saved-state", input.value)
    })
  }

  disabledNameInputs() {
    this.nameInputTarget.disabled = true
    this.nameSelectTarget.disabled = true

    this.priceInputTarget.disabled = false
    console.log(this.priceSelectTarget)
    this.priceSelectTarget.disabled = false
  }

  disabledPriceInputs() {
    this.nameInputTarget.disabled = false
    this.nameSelectTarget.disabled = false

    this.priceInputTarget.disabled = true
    this.priceSelectTarget.disabled = true
  }

  toggleDisabledInputs({ target }) {
    if (target.dataset.default === "true") {
      this.disabledPriceInputs()
    } else {
      this.disabledNameInputs()
    }
  }

  showProductNameFilters() {
    this.disabledPriceInputs()
    this.activeCategory = "name"
    this.showNameSection()

    this.onCategoryChange()
  }

  showProductPriceFilters() {
    this.disabledNameInputs()
    this.activeCategory = "price"
    this.showPriceSection()

    this.onCategoryChange()
  }

  showNameSection() {
    this.nameToggleableTarget.classList.remove("hidden")
    this.priceToggleableTarget.classList.add("hidden")
    this.disabledPriceInputs()
  }

  showPriceSection() {
    this.nameToggleableTarget.classList.add("hidden")
    this.priceToggleableTarget.classList.remove("hidden")
    this.disabledNameInputs()
  }

  remove(e) {
    e.preventDefault()
    e.stopPropagation()

    this.formTarget.remove()
    this.element.id = "where_product"
    this.buttonTarget.classList.remove("hidden")
  }

  // private

  onCategoryChange() {
    this.dispatch("category-changed", {
      detail: this.activeCategory,
    })
  }
}
