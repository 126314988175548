import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    unsavedChanges: { type: Boolean, default: false },
    persisted: { type: Boolean, default: false },
    config: Object,
    display: String,
    capturesUrl: String,
    newCaptureUrl: String,
    name: String,
    backgroundImageUrl: String,
  }

  static targets = [
    "previewTokenInput",
    "previewLink",
    "openModalButton",
    "modalBackButton",
    "removedStepInput",
    "overlayRemovedCheckbox",
    "overlayReplacedCheckbox",
    "backgroundRemovedCheckbox",
    "backgroundReplacedCheckbox",
  ]

  initialize() {
    this.popupValue = {}
    this.changedConfig = Object.assign({}, this.configValue)
    this.changedDisplay = this.displayValue
    this.changedNameValue = this.nameValue

    if (this.backgroundImageUrlValue) {
      this.popupValue = {
        image: {
          path: this.backgroundImageUrlValue,
        },
      }
    }

    this.replaceFontFamilyWith(this.configValue.font.family)
  }

  connect() {
    if (!this.unsavedChangesValue) {
      // when the journey is being edited
      // steps are added and connected to each other via JS. Which sets this.changedValue to true
      // although the journey hasn't changed.
      // Wait until all steps are connected to each other and set this.changedValue back to false
      setTimeout(() => {
        this.unsavedChangesValue = false
        this.changedConfig = this.configValue
      }, 10)
    }
  }

  setTextColor({ detail }) {
    this.popupValue.color = detail.color
    this.changedConfig.font.color = detail.color
    this.unsavedChangesValue = true
    window.popup = this.popupValue
  }

  setBackgroundColor({ detail }) {
    this.popupValue.backgroundColor = detail.color
    window.popup = this.popupValue
  }

  setFontSize({ detail }) {
    this.popupValue.fontSizeBase = detail.size
    this.changedConfig.font.base_size = Number.parseFloat(detail.size)
    window.popup = this.popupValue
  }

  setBackgroundImage(e) {
    this.popupValue.image = {
      path: e.target.files[0]
        ? URL.createObjectURL(e.target.files[0])
        : undefined,
      fit: "fill",
    }

    window.popup = this.popupValue
    this.changedConfig.background.attachment = e.target.files[0]
    if (this.persistedValue) {
      this.backgroundRemovedCheckboxTarget.checked = false
      this.backgroundReplacedCheckboxTarget.checked = true
    }
  }

  setBackgroundImageFitToMosaic() {
    this.popupValue.image.fit = "mosaic"
    this.changedConfig.background.background_size = "contain"
    window.popup = this.popupValue
  }

  setBackgroundImageFitToFill() {
    this.popupValue.image.fit = "fill"
    this.changedConfig.background.background_size = "cover"
    window.popup = this.popupValue
  }

  onImageSelect(e) {
    const imagePath = URL.createObjectURL(e.target.files[0])
    this.setOverlayImageTo(imagePath)

    if (this.persistedValue) {
      this.overlayRemovedCheckboxTarget.checked = false
      this.overlayReplacedCheckboxTarget.checked = true
    }
  }

  setImageFitToCover() {
    this.popupValue.headerImage.fit = "cover"
    this.dispatch("sync:header-image-fit", {
      target: document.documentElement,
      detail: this.popupValue.headerImage,
    })

    this.changedConfig.overlay.object_fit = "cover"
    window.popup = this.popupValue
  }

  setImageFitToContain() {
    this.popupValue.headerImage.fit = "contain"
    this.dispatch("sync:header-image-fit", {
      target: document.documentElement,
      detail: this.popupValue.headerImage,
    })

    window.popup = this.popupValue
  }

  openPreview(e) {
    if (this.previewTokenInputTarget.value.trim().length === 0) {
      this.previewTokenInputTarget.value = e.dataset.token
      this.url = e.dataset.url
    }

    this.previewLinkTarget.href = this.url
    this.previewLinkTarget.click()
    this.previewLinkTarget.href = ""
  }

  removeBackgroundImage() {
    this.popupValue.image = undefined
    window.popup = this.popupValue

    if (this.persistedValue) {
      this.backgroundRemovedCheckboxTarget.checked = true
      this.backgroundReplacedCheckboxTarget.checked = false
    }
  }

  removeOverlayImage() {
    if (this.persistedValue) {
      this.overlayRemovedCheckboxTarget.checked = true
    }

    this.setOverlayImageTo("")
  }

  changeFontFamily({ target }) {
    this.changedConfig.font.family = target.value
    this.replaceFontFamilyWith(target.value)
  }

  showModalIfHasUnsavedChanges({ detail: originalEvent }) {
    if (
      JSON.stringify(this.configValue) !== JSON.stringify(this.changedConfig) ||
      this.displayValue !== this.changedDisplay ||
      this.nameValue !== this.changedNameValue ||
      this.unsavedChangesValue
    ) {
      originalEvent.preventDefault()
      this.openModalButtonTarget.click()
    }
  }

  setHasUnsavedChangesToTrue() {
    this.unsavedChangesValue = true
  }

  changePopupBubbleProps({ detail }) {
    this.changedConfig.bubble[detail.attribute] = detail.value
  }

  changePopupButtonProps({ detail }) {
    this.changedConfig.button_style[detail.attribute] = detail.value
  }

  changePopupDisplayProps({ detail: newDisplaySettings }) {
    this.changedDisplay = newDisplaySettings
  }

  setOverlayBackgroundColor({ detail: color }) {
    this.changedConfig.overlay.background_color = color
  }

  setNameValue({ dataset }) {
    this.changedNameValue = dataset.value
  }

  resetNameToDefaultValue() {
    this.changedNameValue = this.nameValue
  }

  overwriteHistoryEntry(e) {
    const { result } = e.detail.formSubmission

    if (
      result.success &&
      result.fetchResponse.location.href.includes("/assign-coupon") &&
      !this.persistedValue
    ) {
      const url = result.fetchResponse.location.href.replace(
        "/assign-coupon",
        "/edit"
      )

      history.replaceState(
        { turbo_stream_response: true },
        window.location.title,
        url
      )
    }
  }

  // private

  replaceFontFamilyWith(newFontFamily) {
    document
      .querySelectorAll("[data-controller='popup--step']")
      .forEach((el) => {
        el.style.fontFamily = newFontFamily
      })

    window.fontFamily = newFontFamily
  }

  setOverlayImageTo(path) {
    this.popupValue.headerImage = {
      path: path,
      fit: "cover",
    }

    this.dispatch("sync:header-image", {
      target: document.documentElement,
      detail: this.popupValue.headerImage,
    })

    window.popup = this.popupValue
  }
}
