import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['dropdown', 'dropdownButton', 'link']

  toggle() {
    if (this.hasDropdownTarget) {
      this.dropdownTarget.classList.add('hidden')
    }
  }

  clickLink() {
    this.linkTarget.click()
  }
}
