import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["highlight"]

  connect() {
    super.connect()

    setTimeout(() => {
      this.element.classList.replace(this.highlightClass, "bg-transparent")
    }, 0)
  }
}
