import { Controller } from "@hotwired/stimulus"
import intlTelInput from "intl-tel-input"

import IdentityController from "./contact/identity_controller"

export default class extends IdentityController {
  static values = {
    validateOnConnect: { type: Boolean, default: true }
  }
  static targets = ["input"]

  initialize() {
    this.iti = intlTelInput(this.inputTarget, {
      initialCountry: "uy",
      separateDialCode: true,
      formatOnInit: true,
      utilsScript: require("intl-tel-input/build/js/utils"),
      hiddenInput: "contactable[][phone]",
      customContainer: "default__input_group w-full",
    })

    if(this.validateOnConnectValue) {
      this.validate()
    }
  }

  disconnect() {
    super.disconnect()

    this.iti.destroy()
    window.dispatchEvent(new CustomEvent("phone-number:removed"))
  }

  validate() {
    if(this.inputTarget.value.trim().length === 0) {
      this.element.setAttribute("data-invalid", "")
      this.element.removeAttribute("data-valid")

      return this.dispatchValidityEvent()
    }

    if(this.iti.isValidNumber()) {
      this.element.setAttribute("data-valid", "")
      this.element.removeAttribute("data-invalid")
    } else if(this.inputTarget.value.trim().length > 0) {
      this.element.setAttribute("data-invalid", "")
      this.element.removeAttribute("data-valid")
    } else {
      if(document.querySelector("[data-contact-mercadolibre]")) {
        this.element.removeAttribute("data-invalid")
      } else {
        this.element.setAttribute("data-invalid", "")
        this.element.removeAttribute("data-valid")
      }
    }

    this.dispatchValidityEvent()
  }

  // private

  dispatchValidityEvent() {
    const validPhoneNumbers = document.querySelectorAll(`[data-contact-identity][data-valid]`)
    const invalidPhoneNumbers = document.querySelectorAll(`[data-contact-identity][data-invalid]`)

    if(validPhoneNumbers.length > 0 && invalidPhoneNumbers.length <= 0) {
      this.dispatch("valid")
    } else {
      this.dispatch("invalid")
    }
  }
}
