import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "active",
    "item",
    "checkbox",
    "include",
    "exclude",
    "excludeCheckbox",
    "placeholder",
  ]
  static values = {
    selectedListSubscribersCount: Number,
    selectedListName: String,
    initial: String,
    canReset: Boolean,
    status: String,
    selectedListId: String,
  }

  connect() {
    this.checkboxTarget.value = this.selectedId
    this.excludeCheckboxTarget.value = this.selectedId

    if (this.statusValue === "included") {
      this.includeSelected(false, false)
    }

    if (this.statusValue === "excluded") {
      this.excludeSelected(false, false)
    }

    let event = new CustomEvent("list:appended")
    event.listId = this.element.id
    event.subscribersCount = this.selectedListSubscribersCountValue
    window.dispatchEvent(event)

    if (this.initialValue === "All Audience") {
      this.checkboxTarget.value = "all"
      this.checkboxTarget.checked = true

      this.includeTarget.classList.add("hidden")
      this.excludeTarget.classList.add("hidden")
    }
  }

  check(e) {
    console.log(e)
    const { element } = e
    this.activeTarget.innerHTML = element.name
    this.selectedListNameValue = element.name
    this.selectedListSubscribersCountValue = element.subscribersCount

    this.isFirst = element.resetOnClick

    this.checkboxTarget.value = element.id ? element.id : "all"
    this.checkboxTarget.checked = true

    if (element.type === "Segment") {
      this.checkboxTarget.setAttribute("name", "included_segment[]")
      this.excludeCheckboxTarget.setAttribute("name", "excluded_segment[]")
    } else {
      this.checkboxTarget.setAttribute("name", "included_list[]")
      this.excludeCheckboxTarget.setAttribute("name", "excluded_list[]")
    }

    if (this.canResetValue) {
      this.dispatchEvent("reset-count")
    } else if (element.include) {
      this.includeSelected(e)
    } else {
      this.excludeSelected(e)
    }
  }

  includeSelected(event, shouldDispatchEvent = true) {
    if (event) {
      event.stopImmediatePropagation()
    }
    this.checkboxTarget.checked = true

    this.excluded = false
    this.excludeCheckboxTarget.checked = false

    this.includeTarget.classList.remove("hidden")
    this.excludeTarget.classList.add("hidden")

    if (shouldDispatchEvent) {
      this.dispatchEvent("inclusion")
    }
  }

  excludeSelected(event, shouldDispatchEvent = true) {
    if (event) {
      event.stopImmediatePropagation()
    }
    if (this.excluded) return

    this.checkboxTarget.checked = false
    this.excludeCheckboxTarget.checked = true
    this.excludeCheckboxTarget.value = this.checkboxTarget.value

    this.includeTarget.classList.add("hidden")
    this.excludeTarget.classList.remove("hidden")

    if (shouldDispatchEvent) {
      this.dispatchEvent("exclusion")
    }
    this.excluded = true
  }

  dispatchEvent(eventName) {
    let event = new CustomEvent(eventName, { bubbles: true, cancelable: true })

    event.subscribersCount = this.selectedListSubscribersCountValue
    event.includesAll = this.initialValue === this.selectedListNameValue
    event.isFirst = this.isFirst
    event.listId = this.element.id

    this.element.dispatchEvent(event)
  }

  resetToggle(e) {
    if (e.name === this.initialValue) {
      this.includeTarget.classList.add("hidden")
      this.excludeTarget.classList.add("hidden")
      return
    }
    this.includeTarget.classList.remove("hidden")
    this.excludeTarget.classList.add("hidden")
  }

  removeSelected() {
    if (this.element.nextElementSibling === null) {
      this.showPreviousListPlusButton()
    }

    this.element.remove()

    let event = new CustomEvent("list:removed")
    event.listId = this.element.id
    window.dispatchEvent(event)
  }

  reset() {
    this.includeTarget.classList.add("hidden")
    this.excludeTarget.classList.add("hidden")
    this.activeTarget.innerHTML = "All Audience"
    this.checkboxTarget.value = "all"
  }

  get selectedId() {
    return this.data.get("id")
  }

  showPreviousListPlusButton() {
    const previousList =
      this.element.previousElementSibling.querySelector("#actions")
    previousList.querySelector("#hidden").click()
  }
}
