import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

export default class extends Controller {
  static values = { next: String }

  next(event) {
    if (event.detail.success) {
      if (this.hasNextValue) {
        Turbo.visit(this.nextValue)
      } else {
        const fetchResponse = event.detail.fetchResponse

        history.pushState(
          { turbo_frame_history: true },
          "",
          fetchResponse.response.url
        )

        Turbo.visit(fetchResponse.response.url)
      }
    }
  }
}
