import ApplicationController from '../../application_controller'
import Audio from '../../models/audio'

export default class extends ApplicationController {
  static targets = ["recordButton", "controls", "pauseButton", "durationLabel", "recordingTemplate", "sendButton"]

  initialize() {
    this.audioChunks = []
  }

  record() {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        this.mediaRecorder = new MediaRecorder(stream)

        this.mediaRecorder.addEventListener("dataavailable", this.onAudioDataAvailable.bind(this))
        this.mediaRecorder.addEventListener("start", this.onAudioRecorderStart.bind(this))
        this.mediaRecorder.addEventListener("stop", this.onAudioRecorderStop.bind(this))

        this.mediaRecorder.start()
      })
      .catch((err) => {
        this.showToast({
          message: this.translations.compose.tools.voice.permission_denied,
          type: "error"
        })
      })
  }

  onAudioDataAvailable(event) {
    this.audioChunks.push(event.data)
  }

  onAudioRecorderStart() {
    this.show(this.controlsTarget)
    this.hide(this.recordButtonTarget)

    const startTime = new Date().getTime()
    this.durationInterval = setInterval(() => {
      this.updateDurationLabel(startTime)
      this.mediaRecorder.requestData()
    }, 1000)

    this.dispatch('start')
  }

  onAudioRecorderStop() {
    const audio = new Audio(this.audioChunks)
    this.audioChunks = []

    const voiceContainer = document.querySelector("voice-container")
    const recordingElement = this.recordingTemplateTarget.cloneNode(true)

    recordingElement.setAttribute("data-controller", "editor--recorded-voice")
    recordingElement.setAttribute("data-editor--recorded-voice-audio-url-value", audio.objectURL)
    recordingElement.setAttribute("data-editor--recorded-voice-duration-value", this.duration)
    recordingElement.setAttribute("data-compose--toolbar-target", "recording")
    recordingElement.id = "recorded-voice"

    recordingElement.querySelector("x-duration").innerText = this.durationLabelTarget.innerText

    this.recordingElement = recordingElement

    const fileList = new DataTransfer()
    const audioFile = new File([audio.blobAsOGG()], "recording.ogg", { type: "audio/ogg" })

    fileList.items.add(audioFile)

    recordingElement.querySelector("input").files = fileList.files

    recordingElement.classList.remove("hidden")
    voiceContainer.appendChild(recordingElement)

    this.show(voiceContainer)
    this.duration = 0

    this.dispatch('completed')
  }

  pause() {
    clearInterval(this.durationInterval)

    this.mediaRecorder.requestData()
    this.mediaRecorder.stop()

    this.show(this.sendButtonTarget)
    this.hide(this.controlsTarget)

    this.durationLabelTarget.innerText = "00:00"
  }

  reset() {
    this.hide(this.sendButtonTarget)
    this.show(this.recordButtonTarget)

    this.hide(document.querySelector("voice-container"))

    this.dispatch('completed')
  }

  updateDurationLabel(startTime) {
    const currentTime = new Date().getTime()
    this.duration = currentTime - startTime

    const totalSeconds = Math.floor(this.duration / 1000)
    const minutes = Math.floor(totalSeconds / 60)
    const seconds = totalSeconds % 60

    this.durationLabelTarget.innerText = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }

  enable() {
    super.enable(this.recordButtonTarget)
    super.enable(this.sendButtonTarget)

    if(this.recordingElement) {
      this.dispatch('enabled', {
        target: this.recordingElement
      })
    }
  }

  disable() {
    super.disable(this.recordButtonTarget)
    super.disable(this.sendButtonTarget)

    if(this.recordingElement) {
      this.dispatch('disabled', {
        target: this.recordingElement
      })
    }
  }
}
