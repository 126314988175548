import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static values = {
    inputName: String,
    multiple: { type: Boolean, default: false },
    media: Object
  }

  static targets = ["fileInput", "template", "button", "attachment"]

  static classes = ['width']

  initialize() {
    this.files = []
    super.initialize()
  }

  onFileInputChange() {
    this.files =  Array.from(this.fileInputTarget.files)

    const fileMaxSizeTooMuch = this.files.find(file => {
      const type = file.type.split("/")[0]

      if(['image', 'video', 'audio'].includes(type)) {
        return this.mediaValue[type].max_size < file.size
      } else {
        return this.mediaValue.document.max_size < file.size
      }
    })


    if(fileMaxSizeTooMuch) {
      const type = fileMaxSizeTooMuch.type.split("/")[0]
      const mediaType = ['image', 'audio', 'video'].includes(type) ? type : 'document'

      this.showToast({
        message: this.translations.compose.tools.attachment.size_out_of_bounds.replace('%{limit}', this.byteToMegabyte(this.mediaValue[mediaType].max_size)),
        type: "error"
      })
    } else {
      this.files.forEach(file => this.createAttachmentElement(file))
    }

    this.fileInputTarget.value = null
    this.files = []
  }

  openFileDialog() {
    this.fileInputTarget.click()
  }

  updateMediaRestrictions({ detail }) {
    this.fileInputTarget.accept = detail.accept
    this.mediaValue = detail.media
  }

  setAttachmentFromTemplate({ detail: attachment }) {
    this.createAttachmentElementFromTemplate(attachment)
  }

  // private

  createAttachmentElementFromTemplate(data) {
    const element = this.attachmentElement()

    if(data.content_type.startsWith('image/')) {
      const thumbnail = document.createElement('img')
      thumbnail.src = data.url
      this.addClass(thumbnail, ...this.widthClasses, 'h-28', 'rounded-md', 'object-cover')

      element.querySelector('main').innerHTML = ''
      element.querySelector('main').appendChild(thumbnail)
    } else {
      element.querySelector('main').classList.add(...this.widthClasses, 'h-20', 'rounded-md', 'bg-gray-200', 'p-1')
      element.querySelector('p[data-attachment-name]').innerText = data.filename
    }

    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = `${this.inputNameValue}[signed_id]`
    input.value = data.signed_id

    element.setAttribute("data-editor--attached-media-upload-value", false)


    this.appendElement(element)
  }

  byteToMegabyte(bytes) {
    return Math.ceil(bytes / 1024 / 1024)
  }

  createAttachmentElement(file) {
    const element = this.attachmentElement()

    if(file.type.startsWith("image/")) {
      const thumbnail = document.createElement("img")
      thumbnail.src = URL.createObjectURL(file)
      this.addClass(thumbnail, ...this.widthClasses, "h-28", "rounded-md", "object-cover")

      element.querySelector("main").innerHTML = ""
      element.querySelector("main").appendChild(thumbnail)
    } else {
      element.querySelector("main").classList.add(...this.widthClasses, "h-20", "rounded-md", "bg-gray-200", "p-1")
      element.querySelector("p[data-attachment-name]").innerText = file.name
    }

    const dataTransfer = new DataTransfer()

    dataTransfer.items.add(file)
    element.querySelector("input").files = dataTransfer.files

    element.setAttribute("data-compose--toolbar-target", "attachment")
    element.setAttribute("data-editor--attached-media-upload-value", true)

    element.setAttribute('data-file', file)

    this.appendElement(element)
  }

  appendElement(element) {
    if(this.inputNameValue) {
      element.setAttribute("data-editor--attached-media-input-name-value", this.inputNameValue)
    }

    this.show(element)

    if(document.querySelector("media-container ul")) {
      if(!this.multipleValue) {
        document.querySelector("media-container ul").innerHTML = ""
      }

      document.querySelector("media-container ul").appendChild(element)
    }

    this.dispatch('attachment:added', {
      detail: element
    })
  }

  attachmentElement() {
    const element = this.templateTarget.cloneNode(true)
    element.removeAttribute("hidden")
    element.setAttribute("data-controller", "editor--attached-media")
    element.setAttribute("data-editor--media-target", "attachment")

    return element
  }

  enable() {
    super.enable(this.buttonTarget)

    if(document.querySelector('media-container')) {
      this.dispatch('enabled', {
        target: document.querySelector('media-container')
      })
    }
  }

  disable() {
    super.disable(this.buttonTarget)

    if(document.querySelector('media-container')) {
      this.dispatch('disabled', {
        target: document.querySelector('media-container')
      })
    }
  }
}
