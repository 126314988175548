import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'inputWrapper',
    'suggestions',
    'suggestedList',
    'input',
    'newListInput',
    'newListForm'
  ];

  connect() {
    this.focusInput();
    this.inputWrapperTarget.addEventListener('click', this.focusInput.bind(this));

    this.inputTarget.addEventListener('focus', this.showSuggestions.bind(this));
    document.addEventListener('click', this.hideSuggestions.bind(this));

    this.inputTarget.addEventListener('input', this.filterLists.bind(this));

    this.inputTarget.addEventListener('keydown', (e) => {
      if (e.key == 'Enter') {
        e.preventDefault();

        this.newListFormTarget.querySelector('[type="submit"]').click();
      }
    });
  }

  focusInput() {
    this.inputTarget.focus();
  }

  showSuggestions() {
    this.suggestionsTarget.classList.remove('hidden');
  }

  hideSuggestions(e) {
    if (this.element.contains(e.target)) {
      return;
    }

    this.suggestionsTarget.classList.add('hidden');
  }

  filterLists(e) {
    this.showSuggestions.bind(this)();
    this.updateNewListInput.bind(this)(e);

    const query = e.target.value.toLowerCase();

    this.suggestedListTargets.forEach((list) => {
      list.classList.toggle('hidden', !list.dataset.value.toLowerCase().match(query));
    });
  }

  updateNewListInput(e) {
    this.newListInputTarget.value = e.target.value;
    this.newListFormTarget.classList.toggle('hidden', e.target.value.length === 0);
  }
}
