import { Controller } from "@hotwired/stimulus"
import hljs from "highlight.js"
import json from "highlight.js/lib/languages/json"
import hljsCurl from "highlightjs-curl"

hljs.registerLanguage("json", json)
hljs.registerLanguage("curl", hljsCurl)

export default class extends Controller {
  connect() {
    document.addEventListener("turbo:load", (event) => {
      document
        .querySelectorAll("pre code.language-json, pre code.language-curl")
        .forEach((el) => {
          hljs.highlightElement(el)
        })
    })

    // http://jsfiddle.net/x3V6Y/
    // $(function () {
    var sections = {},
      _height = window.innerHeight,
      i = 0,
      root = this

    // Grab positions of our sections
    document.querySelectorAll("section-component").forEach((section) => {
      sections[section.id] = root.offset(section).top + window.innerHeight - 200
    })

    window.addEventListener("scroll", function () {
      var pos = window.pageYOffset
      for (i in sections) {
        if (sections[i] > pos && sections[i] < pos + _height) {
          document.querySelectorAll("aside li a").forEach((element) => {
            // Hide sub-collections
            if (element.closest(".sub-collection-items")) {
              element.parentElement.parentElement.classList.add("hidden")
            }

            // Remove active class
            element.classList.remove("text-tiger", "hover:text-tiger-80")
          })

          var element = document.querySelector("#nav_" + i)

          // Add active class
          element.classList.add("text-tiger", "hover:text-tiger-80")

          // Show sub-collection when parent item is active
          if (element.parentElement.querySelector(".sub-collection-items")) {
            element.parentElement
              .querySelector(".sub-collection-items")
              .classList.remove("hidden")
          }

          // Show sub-collection
          if (element.closest(".sub-collection-items")) {
            element.parentElement.parentElement.classList.remove("hidden")
          }
        }
      }
    })
  }

  offset(el) {
    var box = el.getBoundingClientRect()
    var docElem = document.documentElement
    return {
      top: box.top + window.pageYOffset - docElem.clientTop,
      left: box.left + window.pageXOffset - docElem.clientLeft,
    }
  }

  showChildAttributes({ currentTarget }) {
    currentTarget
      .closest("api-attribute-component-container")
      .classList.toggle("expanded")
  }
}
