import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["next"]

  connect() {
    this.element.addEventListener("scroll", this.scroll.bind(this))
  }

  scroll() {
    if (
      this.element.scrollTop < this.element.scrollHeight - 50 &&
      this.hasNextTarget
    ) {
      this.nextTarget.click()
    }
  }
}
