import { post, get } from '@rails/request.js'

import ModalController from '../modal_controller'

import { useNumberHelpers } from '../mixins/useNumberHelpers'

export default class extends ModalController {
  static values = {
    url: String,
  }

  static targets = [
    'pane',
    'detailPaneContainer',
    'singularProfilePane',
    'name',
    'submitButton',
    'modificationsContainer',
    'propertyForm',
    'propertyFormSkeleton',
    'overrideCheckbox',
    'stateModificationContainer',
    'stateOptionsButton',
  ]

  initialize() {
    this.abortChanges = this.abortChanges.bind(this)
    super.initialize()
  }

  connect() {
    useNumberHelpers(this)
    super.connect()
  }

  async create({ currentTarget }) {
    currentTarget.disabled = true
    currentTarget.querySelector('[data-text]').innerText = currentTarget.dataset.disableWith

    await post(this.urlValue, {
      responseKind: 'turbo-stream',
      body: {
        update: {
          ...this.activePropertyPayload,
          contact_ids_to_operate_on: this.contactIdsToOperateOn,
          state: this.stateValue,
        },
      },
    })

    currentTarget.disabled = false

    this.reset()
    this.close()

    this.dispatch('confirmed', {
      target: this.element,
    })
  }

  onSelectionChange({ detail }) {
    const { count, contactIdsToOperateOn } = detail

    this.contactIdsToOperateOn = contactIdsToOperateOn

    if (count <= 1) {
      return this.reset()
    }

    const content = this.numberWithDelimiter(count)

    this.nameTarget.innerText = t.audience.batch_update.title.replace(
      '%{count}',
      content
    )

    this.titleTarget.innerText = t.audience.batch_update.modal.title.replace(
      '%{count}',
      content
    )

    this.submitButtonTarget.querySelector('[data-text]').innerText = t.audience.batch_update.submit.replace('%{count}', content)

    this.paneTarget.classList.remove('hidden')
    this.singularProfilePaneTarget.classList.add('hidden')

    this.detailPaneContainerTarget.classList.remove('hidden')
    this.replaceListAndTagsWithSkeletons()

    get(this.urlValue, {
      responseKind: 'turbo-stream',
      query: {
        count: content,
        contact_ids_to_operate_on: contactIdsToOperateOn,
      }
    })
  }

  requestConfirmation({ detail, srcElement }) {
    this.stateValue = null

    this.activePropertyPayload = detail
    this.activePropertyFormElement = srcElement

    this.abortAllChanges()

    const content = srcElement.cloneNode(true)
    this.transform(content)

    if(['date', 'birthday'].includes(this.activePropertyPayload.kind)) {
      content.querySelector('[data-content]').innerHTML = ''

      if(Object.values(this.activePropertyPayload.value).some(value => value.length === 0)) {
        return this.openAltViewOfDateProperty(content, srcElement)
      }

      const li = document.createElement('span')
      li.innerText = `${t.date.month_names[parseInt(this.activePropertyPayload.value.month)]}, ${parseInt(this.activePropertyPayload.value.day)} ${this.activePropertyPayload.value.year}`
      content.querySelector('[data-content]').appendChild(li)
    }

    this.modificationsContainerTarget.classList.remove('flex-col')
    this.modificationsContainerTarget.replaceWith(content)

    this.modificationsContainerTarget.classList.remove('hidden')
    this.stateModificationContainerTarget.classList.add('hidden')

    this.open()
  }

  onDialogClose() {
    this.modificationsContainerTarget.innerHTML = ''

    if(this.activePropertyFormElement) {
      this.dispatch('cancelled', {
        target: this.activePropertyFormElement,
      })
    }

    if(this.stateValue) {
      this.stateOptionsButtonTarget.click()
      this.stateValue = null
    }

    this.activePropertyFormElement = null
  }

  updatePathValue({ detail }) {
    this.urlValue = detail.batchUpdateUrl
  }

  changeState({ currentTarget }) {
    const { state } = currentTarget.dataset

    this.stateModificationContainerTarget.querySelector('p').textContent = t.audience.batch_update.modal.state[state]

    this.stateModificationContainerTarget.classList.remove('hidden')
    this.modificationsContainerTarget.classList.add('hidden')

    this.stateValue = state
    this.open()
  }

  // private

  transform(content) {
    const batchClearAllCheckbox = content.querySelector('input[type="checkbox"][data-audience--batch-update-target="overrideCheckbox"]')

    content.querySelectorAll('input:not(input[type="hidden"])').forEach((input) => {
      if(input.type === 'checkbox') {
        return input.classList.add('pointer-events-none')
      }

      if(input.hasAttribute('data-search')) {
        return input.remove()
      }

      const span = document.createElement('span')
      const overrideCheckbox = ['date', 'birthday'].includes(this.activePropertyPayload.kind) ? input.nextElementSibling : batchClearAllCheckbox

      input.classList.add('hidden')
      input.insertAdjacentElement('afterend', span)

      if(overrideCheckbox?.checked && input.value.length === 0) {
        span.classList.add('line-through', 'acts-as-placeholder')
        span.innerText = ['date', 'birthday'].includes(this.activePropertyPayload.kind) ? input.placeholder : t.properties.fields.multiple
      } else {
        span.innerText = input.value || input.placeholder

        if(input.nextElementSibling.hasAttribute('data-spacer')) {
          input.nextElementSibling.remove()
        }
      }
    })

    content
      .querySelectorAll('.dropdown-container, input[type="hidden"], input[type="checkbox"][data-audience--batch-update-target="overrideCheckbox"]')
      .forEach(element => element.remove())

    this.removeDataAttributes(content)

    content
      .querySelectorAll('.property__array_input-group, .default__input_group')
      .forEach((group) => group.classList.add('disabled'))

    content.setAttribute(
      'data-audience--batch-update-target',
      'modificationsContainer'
    )
  }

  openAltViewOfDateProperty(content, srcElement) {
    content.querySelector('[data-content]').classList.add('flex-col')

    Object
      .entries(this.activePropertyPayload.value)
      .filter(([key, value]) => {
        let duplicateSrcElement = srcElement.cloneNode(true)
        const input = duplicateSrcElement.querySelector(`input[name="date[${key}]"]`)

        return value.length > 0 || input.value.length === 0 && input.nextElementSibling.checked
      })
      .forEach(([key, value]) => {
        let duplicateSrcElement = srcElement.cloneNode(true)

        this.removeDataAttributes(duplicateSrcElement)

        duplicateSrcElement.querySelector('x-property[data-name]').textContent += t.properties.fields.date_parts[key]
        duplicateSrcElement.querySelector('[data-container]').innerText = value.length === 0 ? t.properties.fields.date_placeholders[key] : parseInt(value)

        if(value.length === 0) {
          duplicateSrcElement.querySelector('[data-container]').classList.add('line-through', 'acts-as-placeholder')
        }

        content.querySelector('[data-content]').appendChild(duplicateSrcElement)
      })

    this.modificationsContainerTarget.innerHTML = content.querySelector('[data-content]').innerHTML
    this.modificationsContainerTarget.classList.add('flex-col')

    this.open()
  }

  reset() {
    this.singularProfilePaneTarget.classList.remove('hidden')
    this.paneTarget.classList.add('hidden')

    this.propertyFormTargets.forEach(this.abortChanges)
    this.replaceListAndTagsWithSkeletons()
  }

  replaceListAndTagsWithSkeletons() {
    this.propertyFormSkeletonTargets.forEach((skeleton) => {
      if(['list', 'tags'].includes(skeleton.dataset.kind) && ['list', 'tags'].includes(skeleton.previousElementSibling.dataset.kind)) {
        skeleton.classList.remove('hidden')
        skeleton.previousElementSibling.remove()
      }
    })
  }

  // Ensures that only one property form is active at a time
  // When there's a pending change and the user clicks on another property
  // The property's form shows up, this is to prevent these cases from happening.
  abortAllChanges() {
    this.propertyFormTargets
      .filter((target) => target !== this.activePropertyFormElement)
      .forEach(this.abortChanges)

    this.dispatch('aborted', {
      target: document,
    })
  }

  abortChanges(target) {
    this.dispatch('aborted', {
      target,
    })
  }

  removeDataAttributes(node) {
    if (node.nodeType === 1) {
      Array.from(node.attributes).forEach((attr) => {
        if (attr.name.startsWith('data-controller') || attr.name.endsWith('-target')) {
          node.removeAttribute(attr.name)
        }
      })
    }

    if (node.tagName === 'FORM') {
      node.removeAttribute('action')
      node.removeAttribute('method')
      node.removeAttribute('id')
    }

    node.childNodes.forEach((child) => this.removeDataAttributes(child))
  }
}
