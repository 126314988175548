import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    code: String
  }

  submit(event) {
    const { value: newCountryCode } = event.target
    const formAction = this.element.getAttribute('action')

    if(formAction.includes(this.codeValue)) {
      this.element.setAttribute('action', formAction.replace(this.codeValue, newCountryCode))
      this.element.requestSubmit()
    }
  }
}
