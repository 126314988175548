import { Controller } from "@hotwired/stimulus"

import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["checkbox", "checkmark"]
  static classes = ["checked", "unchecked"]

  connect() {
    this.sync()
  }

  check() {
    this.checkboxTarget.checked = true
    this.checkboxTarget.dispatchEvent(new Event("change"))

    this.applyActiveClasses()
  }

  uncheck() {
    this.checkboxTarget.checked = false
    this.checkboxTarget.dispatchEvent(new Event("change"))

    this.applyInactiveClasses()
    this.hideCheckmark()
  }

  toggle() {
    this.checkboxTarget.checked = !this.checkboxTarget.checked
    this.checkboxTarget.dispatchEvent(new Event("change"))

    this.sync()
  }

  sync() {
    if(this.checkboxTarget.checked) {
      this.applyActiveClasses()
      this.showCheckmark()
    } else {
      this.applyInactiveClasses()
      this.hideCheckmark()
    }
  }

  // private

  applyActiveClasses() {
    this.element.classList.add(...this.checkedClasses)

    if(this.hasUncheckedClass) {
      this.element.classList.remove(...this.uncheckedClasses)
    }
  }

  applyInactiveClasses() {
    if(this.hasUncheckedClass) {
      this.element.classList.add(...this.uncheckedClasses)
    }

    this.element.classList.remove(...this.checkedClasses)
  }

  showCheckmark() {
    if(this.hasCheckmarkTarget) {
      this.show(this.checkmarkTarget)
    }
  }

  hideCheckmark() {
    if(this.hasCheckmarkTarget) {
      this.hide(this.checkmarkTarget)
    }
  }

  get checkedClasses() {
    return this.hasCheckedClass ? this.checkedClass : ["border-lavender"]
  }
}
