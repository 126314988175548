import LinkController from "./base_controller"
import { post } from "@rails/request.js"

export default class extends LinkController {
  static targets = ["button", "closeButton"]

  toggleButton(event) {
    let button = this.targets.find("button");

    if (button) {
      button.disabled = event.detail.shouldDisable;
    }
  }

  enable() {
    super.enable(this.buttonTarget)
  }

  disable() {
    super.disable(this.buttonTarget)
  }

  async create() {
    if (!this.isValidURL()) {
      this.inputTarget.classList.replace(this.defaultClass, this.errorClass)
      this.focus()
      return
    }

    const response = await post(this.urlValue, {
      body: JSON.stringify({ url: this.url })
    })

    if (response.ok) {
      const data = await response.json

      this.dispatch('create', {
        linkPartial: data.link_partial
      })

      this.clear()
      this.cancelButtonTarget.click()
    } else {
      this.inputTarget.classList.replace(this.defaultClass, this.errorClass)
    }
  }

  onEnterPress() {
    this.create()
  }

  cancel(e) {
    super.cancel(e)

    if (this.formTarget.classList.contains("hidden")) {
      this.openButtonTarget.click()
    } else {
      this.closeButtonTarget.click()
    }
  }

  closeByEscape() {
    if(this.isInvisible(this.formTarget)) return;

    this.dispatch('link:hidden')
    this.cancelButtonTarget.click()
  }

  _cancel(dispatchEvent = false) {
    super._cancel(dispatchEvent)

    if (this.formTarget.classList.contains("hidden")) {
      this.openButtonTarget.click()
    } else {
      this.closeButtonTarget.click()
    }
  }
}
