import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ["hidden", "allTime", "overTheLast"]
  static classes = ["selected"]

  showPeriodFilters() {
    this.element.querySelectorAll('input, select').forEach(this.enable)
    this.hiddenTargets.forEach(this.show)

    this.toggleSelectedClass()
  }

  hidePeriodFilters() {
    this.element.querySelectorAll('input, select').forEach(this.disable)
    this.hiddenTargets.forEach(this.hide)

    this.toggleSelectedClass()
  }

  // private

  toggleSelectedClass() {
    if(this.hasSelectedClass) {
      this.allTimeTarget.classList.toggle(this.selectedClasses)
      this.overTheLastTarget.classList.toggle(this.selectedClasses)
    }
  }
}
