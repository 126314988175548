import PropertyController from "../property_controller"

import { useBatchUpdateNotifiers } from '../mixins/useBatchUpdateNotifiers'

export default class extends PropertyController {
  static targets = ["deleteButton", "checkbox"]

  initialize() {
    this.checkboxTarget.indeterminate = this.checkboxTarget.hasAttribute('data-indeterminate')
    super.initialize()
  }

  connect() {
    useBatchUpdateNotifiers(this, {
      onBatchUpdateAbortion: () => this.restore(),
      onBatchUpdateCancellation: () => {
        if(this.blockedAnchor) {
          return this.blockedAnchor.click()
        }

        this.restore()
      }
    })

    super.connect()
  }

  saveChanges(e) {
    if(
      !this.isViewable
      || this.element.contains(e.target)
      || e.target.getAttribute('data-audience--contact-target') === 'checkbox'
      || e.target.closest('dialog')
      || this.isBlockedAnchor(e)
    ) return

    if(this.inBatchUpdateMode() && !this.checkboxTarget.indeterminate) {
      if(e.target.closest('a') || e.target.tagName === 'A') {
        e.preventDefault()
        e.stopPropagation()

        this.blockedAnchor = e.target.closest('a') || e.target
      }

      this.requestBatchUpdateConfirmation()
    }
  }

  onCheckboxValueChange(e) {
    if(this.inBatchUpdateMode()) {
      return;
    }

    if(e.target.checked) {
      this.formTarget.requestSubmit()
    } else if(this.persistedValue) {
      this.deleteButtonTarget.click()
    }
  }

  restore() {
    this.checkboxTarget.checked = false
    this.checkboxTarget.indeterminate = this.checkboxTarget.hasAttribute('data-indeterminate')
  }

  get isViewable() {
    return !(this.element.closest('[data-audience--batch-update-target="pane"].hidden') || this.element.closest('[data-audience--content-target="detailPane"].hidden'))
  }

  getValueForBatch() {
    return this.checkboxTarget.checked
  }
}
