export const useNumberHelpers = (controller) => {
  Object.assign(controller, {
    numberWithDelimiter(number, delimiter = ',') {
      number = number.toString();

      const split = number.split('.');

      split[0] = split[0].replace(
        /(\d)(?=(\d\d\d)+(?!\d))/g,
        '$1' + delimiter
      );
      return split.join('.');
    },
    roundToNearestTenth(number) {
      const decimal = number / 10
      return Math.round(decimal) * 10
    }
  })
}
