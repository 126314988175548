import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [
    'openButton',
    'form',
    'formSubmitButton',
  ]

  open(event) {
    const { url, element, profileScoped } = event.detail
    this.formTarget.action = url

    const translationKey = profileScoped ? 'remove_from_profile' : 'remove_from_business'
    this.element.querySelector('[data-modal-target="subtitle"]').innerText =
      this.translations.layouts.audience.property_modal[translationKey]

    this.dispatch('open', {
      target: this.element
    })

    this.activeElement = element
  }

  close() {
    this.activeElement = null

    this.dispatch('close', {
      target: this.element
    })
  }

  focusActiveElement() {
    if(this.activeElement) {
      this.dispatch('focus', {
        target: this.activeElement
      })
    }
  }
}
