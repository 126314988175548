import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  allowModalOverflow() {
    this.element.classList.replace("overflow-y-auto", "overflow-visible")
  }

  disableModalOverflow() {
    this.element.classList.replace("overflow-visible", "overflow-y-auto")
  }
}
