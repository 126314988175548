import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["container", "select", "whatsappNotice"]

  static values = {
    count: Number,
    includeAll: Boolean,
    allSubscribersCount: Number,
    lists: Number,
    url: String,
    technology: String,
    whatsappLimit: Number,
  }

  initialize() {
    this.allSubscribersCountValue = this.countValue
    this.audience = {}
  }

  incrementCount(e) {
    this.countValue += Number.parseInt(e.target.dataset.subscribersCount)
  }

  decrementCount(e) {
    this.countValue -= Number.parseInt(e.target.dataset.subscribersCount)
  }

  // These will be called in response to an action from
  // select_controller
  onAudienceInclusion(e) {
    this.includeAllValue = false
    if (this.listsValue > 1) {
      this.countValue += Number.parseInt(e.subscribersCount)
      this.audience[e.listId] = Number.parseInt(e.subscribersCount)
    } else {
      this.audience[e.listId] = Number.parseInt(e.subscribersCount)
    }

    this.countValueChange()
  }

  onAudienceExclusion(e) {
    this.countValue -= Number.parseInt(e.subscribersCount)

    if (this.includeAllValue) {
      this.audience[e.listId] = -e.subscribersCount
    } else {
      delete this.audience[e.listId]
    }
    this.countValueChange()
  }

  reset(e) {
    this.audience["all"] = Number.parseInt(e.subscribersCount)
    this.countValueChange()
    this.includeAllValue = true
  }

  // new list was appended to the DOM, select_controller.js
  onListAppend(e) {
    this.listsValue += 1
    this.audience = {
      ...this.audience,
      [e.listId]: e.subscribersCount || 0,
    }
  }

  // a list was removed from the DOM, select_controller.js
  onListRemove(e) {
    this.listsValue -= 1
    delete this.audience[e.listId]
    this.countValueChange()
  }

  countValueChange() {
    const total =  Object.values(this.audience).reduce(
      (total, num) => total + num,
      0
    )

    this.containerTarget.innerText = total

    if(this.technologyValue === 'whatsapp' && total > this.whatsappLimitValue) {
      this.whatsappNoticeTarget.classList.remove('hidden')
    } else {
      this.whatsappNoticeTarget.classList.add('hidden')
    }
  }

  append() {
    const url = new URL(this.urlValue)

    Array.from(
      document.querySelectorAll(
        "input[type=checkbox][name='included_list[]']:checked"
      )
    ).forEach((input) => {
      url.searchParams.append("included_list[]", input.value)
    })

    Array.from(
      document.querySelectorAll(
        "input[type=checkbox][name='included_segment[]']:checked"
      )
    ).forEach((input) => {
      url.searchParams.append("included_segment[]", input.value)
    })

    Array.from(
      document.querySelectorAll(
        "input[type=checkbox][name='excluded_list[]']:checked"
      )
    ).forEach((input) => {
      url.searchParams.append("excluded_list[]", input.value)
    })

    Array.from(
      document.querySelectorAll(
        "input[type=checkbox][name='excluded_segment[]']:checked"
      )
    ).forEach((input) => {
      url.searchParams.append("excluded_list[]", input.value)
    })

    get(url.toString(), {
      responseKind: "turbo-stream",
    })
  }
}
