import HeroController from "./hero_controller"

export default class extends HeroController {
  sync() {
    if (!this.allowSync) return

    this.dispatch("sync", {
      target: Array.from(
        document.querySelectorAll(
          `[data-notice-id='${this.element.dataset.noticeId}']`
        )
      ).find((layoutSection) => layoutSection !== this.element),
      detail: this.currentState,
    })
  }
}
