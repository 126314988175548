// Allows elements that have data-controller="auto-expandable-textarea" to automatically expand to fit their content.
// This mixin allows parent controllers to let their targets know that they should expand to fit their content easily
// without the need of dispatching an event to the target.

export const useTextareaExpansion = (controller) => {
  Object.assign(controller, {
    expandTextarea(targetElement) {
      targetElement.setAttribute('data-auto-expand', '')
    }
  })
}
