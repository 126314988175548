import { Controller } from "@hotwired/stimulus"
import {useRemove} from "../mixins/useRemove"

export default class extends Controller {
  static values = {
    defaultLabel: String,
    altLabel: String
  }

  static targets = ["remove", "label", "select"]
  static classes = ["defaultLabel"]

  initialize() {
    this.adjustLabel()
  }

  connect() {
    this.parentComponent = this.element.closest('[data-controller="segments--lists"]')
    useRemove(this)
  }

  disconnect() {
    this.dispatch("removed", {
      target: this.parentComponent
    })
  }

  allowRemove() {
    this.adjustLabel()
    this.removeTarget.classList.remove("hidden")
  }

  markAsDefault() {
    this.element.setAttribute("data-default", "")
    this.adjustLabel()

    this.removeTarget.classList.add("hidden")
  }

  // private

  adjustLabel() {
    if(this.element.hasAttribute("data-default")) {
      if(this.defaultLabelValue) {
        this.labelTarget.innerText = this.defaultLabelValue
        this.labelTarget.classList.add(...this.defaultLabelClasses)
      } else {
        this.labelTarget.classList.add("hidden")
        this.labelTarget.setAttribute("hidden", "")
      }
    } else {
      this.labelTarget.innerText = this.altLabelValue
      this.labelTarget.classList.remove(...this.defaultLabelClasses)
    }
  }
}
