import { Controller } from "@hotwired/stimulus"
import {get} from "@rails/request.js";

export default class extends Controller {
  static values = {
    selectUrl: String,
    deselectUrl: String
  }

  connect() {
    if(document.querySelectorAll("[data-selected]").length > 0) {
      this.dispatch("enableSubmit")
    } else {
      this.dispatch("disableSubmit")
    }
  }

  async select() {
    // if the user selects multiple channels and this request has not been finished
    // UI would end up in an inconsistent state.
    // Treat this item as selected before making the request
    this.element.setAttribute("data-selected", "")

    let query = {
      selected_ids: Array.from(document.querySelectorAll("[data-selected]"))
        .map(listItem => listItem.dataset.recordId),
      selected_count: document.querySelectorAll("[data-selected]").length
    }

    if(document.querySelector("input[type='hidden'][name='quota_full']")) {
      query.quota_full = 1
    }

    get(this.selectUrlValue, {
      responseKind: "turbo-stream",
      query,
    }).catch(() => {
      this.element.removeAttribute("data-selected")
    })
  }

  async deselect() {
    // if the user deselects multiple channels and this request has not been finished
    // UI would end up in an inconsistent state.
    // Treat this item as deselected before making the request
    this.element.removeAttribute("data-selected")

    get(this.deselectUrlValue, {
      responseKind: "turbo-stream",
      query: {
        selected_ids: Array.from(document.querySelectorAll("[data-selected]"))
          .map(listItem => listItem.dataset.recordId)
      }
    }).catch(() => {
      this.element.setAttribute("data-selected", "")
    })
  }
}
