import ProductController from "../../segments/product_controller"

export default class extends ProductController {
  static targets = ["nameFilter", "priceFilter", "option", "newConditionButton"]
  static values = {
    duplicate: { type: String, default: "name" },
    stepId: String,
    removeForm: { type: Boolean, default: true },
  }

  connect() {
    this.activeCategory = this.categoryValue

    if (this.formTarget.classList.contains("hidden") === false) {
      this.activeCategory = ""
      this.categoryValue = ""
    }

    super.connect()
  }

  saveState({ detail }) {
    if (detail.stepId !== this.stepIdValue) return

    this.nameInputTargets.forEach((input) => {
      input.setAttribute("data-saved-state", input.value)
    })

    this.priceInputTargets.forEach((input) => {
      input.setAttribute("data-saved-state", input.value)
    })

    if (this.formTarget.classList.contains("hidden") === false) {
      this.optionValue = this.activeFilterId
      this.categoryValue = this.activeCategory
    } else {
      this.optionValue = ""
      this.categoryValue = ""
    }
  }

  restoreSavedState({ detail: stepId }) {
    if (stepId !== this.stepIdValue) return

    this.nameInputTargets.forEach((input) => {
      input.value = input.dataset.savedState
    })

    this.priceInputTargets.forEach((input) => {
      input.value = input.dataset.savedState
    })

    if (this.categoryValue) {
      this.formTarget.classList.remove("hidden")
      this.buttonTarget.classList.add("hidden")

      if (this.categoryValue === "name") {
        this.showNameSection()
        this.nameTriggerTarget.click()
      } else {
        this.showPriceSection()
        this.priceTriggerTarget.click()
      }
    }

    this.optionTargets
      .find((el) => el.dataset.optionValue === this.optionValue)
      ?.click()
  }

  setActiveFilter({ target }) {
    this.activeFilterId = target.dataset.optionValue
    this.activeCategory = target.dataset.optionCategory
  }

  disableSubmit() {
    if (this.activeCategory === "name" && this.hasEmptyNameInput) {
      this.dispatch("disable-submit")
    }

    if (this.activeCategory === "price" && this.hasEmptyPriceInput) {
      this.dispatch("disable-submit")
    }

    if (this.activeCategory === "") {
      this.dispatch("disable-submit")
    }
  }

  syncConditionFilters(e) {
    this.clauseDropdowns.forEach((dropdown) => {
      this.dispatch("dropdown:set", {
        target: dropdown,
        detail: e.currentTarget.value,
      })
    })
  }

  syncExtraConditionButtonState() {
    this.disableAddConditionButton(this.activeCategory)
  }

  remove(e) {
    if (this.removeFormValue) {
      super.remove(e)
      return
    }

    this.disabledNameInputs()
    this.disabledPriceInputs()

    this.activeFilterId = ""
    this.activeCategory = ""

    this.formTarget.classList.add("hidden")
    this.buttonTarget.classList.remove("hidden")

    this.dispatch("enableSubmit")
  }

  // private

  get hasEmptyNameInput() {
    return (
      this.nameInputTargets.filter(
        (input) => !input.disabled && input.value.trim().length === 0
      ).length > 0
    )
  }

  get hasEmptyPriceInput() {
    return (
      this.priceInputTargets.filter(
        (input) => !input.disabled && input.value.trim().length === 0
      ).length > 0
    )
  }

  get clauseDropdowns() {
    return [
      this.nameFilterTarget.querySelector(
        "[data-controller='custom-dropdown']"
      ),
      this.priceFilterTarget.querySelector(
        "[data-controller='custom-dropdown']"
      ),
      ...this.element.querySelectorAll(
        "[data-clone] [data-controller='custom-dropdown']"
      ),
    ]
  }
}
