import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['input', 'label', 'shortcode', 'message']

  inputValueChanged() {
    this.messageTarget.textContent = this.inputTarget.value.trim().toUpperCase()
  }

  shortcodeChanged({ currentTarget }) {
    this.shortcodeTarget.textContent = currentTarget.textContent
    this.removeClass(this.shortcodeTarget, 'invisible')
  }
}
