import { Token } from './token'
import { Contact, Coupon } from './tag'

class Stack {
  constructor() {
    this.stack = []
  }

  push(item) {
    this.stack.push(item)
    return this
  }

  map(callback) {
    return this.stack.map(callback)
  }

  tokenize(stackStartsAt) {
    const word = this.join('')
    const start = stackStartsAt + this.head.index
    const end = start + this.size
    const isValidTagName = Contact.isValidTag(word) || Coupon.isValidTag(word)

    return new Token(word, {
        start,
        end,
        isValidTagName,
      }
    )
  }

  join(separator) {
    return this.stack.map(({token}) => token.key).join(separator)
  }

  clear() {
    this.stack = []
  }

  get borderlineObjects() {
    return [this.head, this.tail]
  }

  get head() {
    return this.stack[0]
  }

  // private

  get tail() {
    if(this.size === 1) return null
    return this.stack[this.stack.length - 1]
  }

  get size() {
    return this.stack.length
  }
}

export { Stack }
