import ApplicationController from "../application_controller"
import {useUpdatableTimestamp} from "../mixins/useUpdatableTimestamp";

export default class extends ApplicationController {
  static targets = ["timestamp", "radio", "unreadCountContainer"]
  static values = {
    state: String,
    lastEventTimestamp: String,
    removeable: { type: Boolean, default: true }
  }

  initialize() {
    this.popStateListener = this.popStateListener.bind(this)
    this.url = this.element.dataset.url
  }

  connect() {
    window.addEventListener("popstate", this.popStateListener)

    if(this.alreadyPresentInDOM && this.removeableValue) {
      // We want this element to be successfully shown for other clients, but,
      // for a client that already has the element and is already there, we don't to do anything with it.
      this.element.remove()
    } else {
      this.element.id = this.element.dataset.documentId
    }

    if(this.invisible) {
      this.dispatch("appended", {
        target: document.getElementById("conversations-list"),
        detail: {
          element: this.element,
          state: this.stateValue,
        }
      })

      this.syncLinkWithClientFilters()
    }

    useUpdatableTimestamp(this)

    this.updateTimestampEveryMinute()
  }

  disconnect() {
    clearInterval(this.timestampInterval)
  }

  popStateListener() {
    const parts = window.location.pathname.split("/")
    const id = parts[parts.length - 1]

    if(id === this.element.dataset.id) {
      this.element.click()
    } else if(this.hasRadioTarget) {
      this.radioTarget.checked = false
    }
  }

  changeBrowserUrl() {
    if(window.location.href === this.url) return

    const skeleton = document.getElementById("current-conversation-skeleton")
    skeleton.classList.remove("hidden")

    const url = new URL(this.url)
    history.pushState({}, null, url.toString())
  }

  syncLinkWithClientFilters() {
    const clientFilters = new URL(window.location.href).searchParams

    const newLinkURL = new URL(this.url.split('?')[0])

    if(clientFilters.has("filter")) {
      newLinkURL.searchParams.append("filter", clientFilters.get("filter"))
    }

    if(clientFilters.get("team")) {
      newLinkURL.searchParams.append("team", clientFilters.get("team"))
    }

    clientFilters.getAll("member").forEach((memberId) => {
      newLinkURL.searchParams.append("member[]", memberId)
    })

    this.url = newLinkURL.toString()
    this.element.setAttribute("data-url", this.url)
  }

  uncheck({ dataset }) {
    if(this.radioTarget.checked && dataset.id !== this.element.dataset.id) {
      this.radioTarget.checked = false

      if(window.location.href !== dataset.newUrl && dataset.newUrl) {
        const url = new URL(dataset.newUrl)
        history.pushState({}, null, url.toString())
      }
    }
  }

  updateUnreadMessagesCount() {
    const newCount = parseInt(this.unreadCountContainerTarget.innerText) -1

    if(newCount > 0) {
      this.unreadCountContainerTarget.innerText = newCount
    } else {
      this.hide(this.unreadCountContainerTarget)
    }
  }

  // private

  updateTimestampEveryMinute() {
    if(this.lastEventTimestampValue) {
      this.timestampInterval = setInterval(() => {
        this.timestampTarget.innerHTML = this.timeAgoInWordsWithParsing(this.lastEventTimestampValue)
      }, 30000)
    }
  }

  get alreadyPresentInDOM() {
    // The reason we use data-document-id instead of id is because when broadcasting from the server
    // Turbo will replace any element that has the same id attribute automatically.
    return document.querySelectorAll(`[data-document-id='${this.element.dataset.documentId}']`).length > 1
  }
}
