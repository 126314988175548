import interact from "interactjs"

import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static values = {
    duration: { type: Number, default: 0 }
  }

  static targets = ["playButton", "pauseButton", "audio", "progress", "durationLabel", "pointer", "bar", "input"]

  initialize() {
    if(this.durationValue) {
      this.durationLabelTarget.innerText = this.formatDuration(this.durationValue)
    }
  }

  connect() {
    this.audioTarget.load()

    this.audioTarget.addEventListener("loadedmetadata", this.onAudioMetadataLoaded.bind(this))
    this.audioTarget.addEventListener("play", this.onAudioPlay.bind(this))
    this.audioTarget.addEventListener("pause", this.onAudioPause.bind(this))

    interact(this.pointerTarget).draggable({
      restrict: {
        restriction: this.barTarget,
        elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
        endOnly: true
      },
      inertia: true,
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: this.barTarget,
          endOnly: true
        })
      ],
      onstart: (event) => {
        event.target.style.zIndex = '9999';
      },
      onmove: (event) => {
        const newX = (parseFloat(event.target.getAttribute('data-x')) || 0) + event.dx;
        const maxX = this.barTarget.clientWidth - event.target.clientWidth;

        if(newX < 0 || newX > maxX) return;

        this.progressTarget.style.width = `${(newX / maxX) * 100}%`;

        event.target.setAttribute('data-x', newX);

        const percentage = newX / maxX;
        const newTime = this.audioTarget.duration * percentage;
        this.audioTarget.currentTime = newTime;
        this.durationLabelTarget.innerText = this.formatDuration(newTime * 1000)
      }
    });
  }

  onAudioTimeUpdate() {
    const { currentTime, duration } = this.audioTarget

    if(this.isPlaying) {
      this.durationLabelTarget.innerText = this.formatDuration(currentTime * 1000)
      this.progressTarget.style.width = `${(currentTime / duration) * 100}%`
      this.progressTarget.style.minWidth = "8%"

      if (currentTime === duration) {
        this.onAudioEnd()
      }
    }
  }

  onAudioMetadataLoaded() {
    const { duration } = this.audioTarget

    if (duration === Infinity || isNaN(duration)) {
      this.audioTarget.currentTime = 10000000;

      this.delayed(() => {
        this.audioTarget.currentTime = 0
        this.durationValue = this.audioTarget.duration
        this.durationLabelTarget.innerText = this.formatDuration(this.audioTarget.duration * 1000)
      }, 1000)

    } else {
      this.durationValue = duration
      this.durationLabelTarget.innerText = this.formatDuration(duration * 1000)
    }

    this.audioTarget.addEventListener("timeupdate", this.onAudioTimeUpdate.bind(this))
  }

  onAudioPlay() {
    this.show(this.pauseButtonTarget)
    this.hide(this.playButtonTarget)

    this.dispatch('play', {
      target: document.documentElement,
      detail: { audio: this.audioTarget }
    })

    this.isPlaying = true
  }

  onAudioPause() {
    this.show(this.playButtonTarget)
    this.hide(this.pauseButtonTarget)

    this.isPlaying = false
  }

  onAudioEnd() {
    this.audioTarget.pause()

    this.delayed(() => {
      this.show(this.playButtonTarget)
      this.hide(this.pauseButtonTarget)

      this.isPlaying = false
      this.audioTarget.currentTime = 0

      this.progressTarget.style.removeProperty("width")
      this.progressTarget.style.removeProperty("min-width")
    }, 500)
  }

  formatDuration(duration = this.durationValue) {
    const totalSeconds = Math.floor(duration / 1000)
    const minutes = Math.floor(totalSeconds / 60)
    const seconds = Math.floor(totalSeconds % 60)

    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
  }

  play() {
    this.audioTarget.play()
  }

  pause({ type, detail }) {
    if(type === 'click' || type !== 'click' && detail.audio !== this.audioTarget) {
      this.audioTarget.pause()
    }
  }
}
