import PaneController from "../pane_controller"
import { get } from "@rails/request.js"

export default class extends PaneController {
  static values = {
    stepId: String,
    anyChannel: { type: Boolean, default: false },
    activeTriggerId: String,
    triggerUrl: String,
    initial: String,
    triggerCheckConflictUrl: String,
    replacementUrl: String,
    journeyId: String
  }

  static targets = [
    "modal",
    "anyChannelLabel",
    "includeLabel",
    "extraChannel",
    "anyChannelButton",
    "triggersSelect",
    "newChannelDropdownButton",
    "channelsLabel",
    "closeModalButton",
  ]

  connect() {
    if (this.anyChannelValue) {
      this.currentTarget = this.element.querySelector("[data-expands]")
    }

    this.triggers = Array.from(
      document.querySelectorAll("select[name='triggers[]']")
    ).map((select) => select.value)
  }

  show({ detail }) {
    if (detail === this.element.id) {
      super.show()
      this.afterShow()
    }

    if (detail !== this.element.id && !this.hidden) {
      this._hide()
    }
  }

  toggle({ detail }) {
    if (detail === this.element.id) {
      if (!this.hasUnsavedChanges) {
        this.element.classList.toggle("right-0")
        this.element.classList.toggle("-right-full")
      }

      if (this.visible) {
        window.dispatchEvent(new CustomEvent("step:disable-dragging"))
        this.afterShow()
      } else {
        window.dispatchEvent(new CustomEvent("step:enable-dragging"))
      }
    }

    if (detail !== this.element.id && !this.hidden) {
      this._hide()
    }
  }

  afterShow() {

  }

  hide(e) {
    if (e?.detail === this.element.id) {
      if (this.hasUnsavedChanges) {
        this.openModalButtonTarget.click()

        e.preventDefault()
        e.stopPropagation()
        window.paneId = this.element.id
      } else {
        super.hide()
        this.cancel()
      }
    }
  }

  setActiveTriggerId({ currentTarget }) {
    this.currentTrigger = currentTarget.dataset.triggerId
    this.currentTarget = currentTarget
    this.hasUnsavedChanges = true
    window.paneId = this.element.id

    if (!currentTarget.dataset.channel) {
      this.element.querySelector("#applied_filters")?.classList.add("hidden")
      this.hideChannelDropdownButton()
    }

    if (currentTarget.dataset.expands) {
      this.anyChannelValue = true
      this.element.querySelector("#applied_filters")?.classList.remove("hidden")
      this.showChannelDropdownButton()
    }
  }

  setActiveTriggerToChannel({ currentTarget }) {
    this.currentTarget = currentTarget

    setTimeout(() => {
      this.anyChannelValue =
        currentTarget.dataset.anyChannel !== undefined ||
        this.anyChannelOptionSelected
    }, 0)
  }

  setHasUnsavedChanges() {
    this.hasUnsavedChanges = true
    window.paneId = this.element.id
  }

  async saveAndHide() {
    this.element.setAttribute('data-saved', 'true')

    this.activeTriggerIdValue = this.currentTrigger || this.activeTriggerIdValue

    let query = {
      trigger_id:
        this.activeTriggerIdValue === "channels"
          ? ""
          : this.activeTriggerIdValue,
    }

    if (
      this.element
        .querySelector("#applied_filters")
        ?.classList.contains("hidden") === false
    ) {
      this.triggers = Array.from(
        document.querySelectorAll("select[name='triggers[]']")
      ).map((select) => select.value)

      query.triggers = this.triggers
    }

    this.anyChannelValue = this.currentTarget?.dataset.expands !== undefined

    await get(this.triggerUrlValue, {
      responseKind: "turbo-stream",
      query,
    })

    this.dispatch("trigger:set", {
      target: document.getElementById("journeys--editor"),
      detail: {
        triggerId: this.activeTriggerIdValue,
        isChannelTrigger: false,
        anyChannel: false,
        journey_id: this.journeyIdValue
      },
    })

    this.clearQueue()
    this._hide()
    this.hasUnsavedChanges = false
    this.targetTechnologiesValue = this.selectedTechnologyIds
  }

  async cancel() {
    if (this.invisible) return

    this.hasUnsavedChanges = false
    window.paneId = undefined
    this._hide()

    get(this.replacementUrlValue, {
      responseKind: "turbo-stream",
      query: {
        trigger_id: this.activeTriggerIdValue,
        triggers: this.triggers,
        journey_id: this.journeyIdValue
      },
    })
  }

  setChannels() {}

  _abort() {}

  _hide() {
    window.dispatchEvent(new CustomEvent("step:enable-dragging"))
    super.hide()
  }

  getStream({ currentTarget }) {
    get(currentTarget.dataset.url, { responseKind: "turbo-stream" })
  }

  showChannelDropdownButton() {
    this.channelsLabelTarget.classList.remove("hidden")
    this.newChannelDropdownButtonTarget.classList.remove("hidden")
  }

  hideChannelDropdownButton() {
    this.channelsLabelTarget.classList.add("hidden")
    this.newChannelDropdownButtonTarget.classList.add("hidden")
  }

  async getNewChannelDropdown({ currentTarget }) {
    let query = {}

    if (this.anyChannelValue) {
      query.exclude = "yes"
    }

    await get(currentTarget.dataset.url, {
      responseKind: "turbo-stream",
      query,
    })

    if (this.anyChannelValue) {
      this.anyChannelLabelTargets.forEach((el) => el.classList.remove("hidden"))
    }
  }

  setHasAnyChannel() {
    if (this.hasIncludeLabelTarget) {
      this.includeLabelTarget.classList.add("hidden")
      this.anyChannelLabelTarget.classList.remove("hidden")
    }
  }

  disableHasAnyChannel() {
    setTimeout(() => {
      if (this.anyChannelOptionSelected) return

      this.anyChannelValue = false

      if (this.hasIncludeLabelTarget) {
        this.includeLabelTarget.classList.remove("hidden")
        this.anyChannelLabelTarget.classList.add("hidden")
      }
    }, 0)
  }

  remove() {
    this.dispatch("remove", {
      detail: this.stepIdValue,
      target: document.documentElement,
    })

    this._hide()
    this.element.remove()
  }

  hidePane(e) {
    if (
      this.element.contains(e.target) ||
      Array.from(document.querySelectorAll("#toast")).some((toast) =>
        toast.contains(e.target)
      ) ||
      this.invisible ||
      e.target.id.split("_trigger")[0] === this.stepIdValue
    )
      return

    if (this.hasUnsavedChanges) {
      this.openModalButtonTarget.click()
      e.preventDefault()
      window.paneId = this.element.id
    } else {
      this._hide()
      this.cancel()
      this.clearQueue()
    }
  }

  alertUserAboutUnsavedChanges({ detail: requestingStepId }) {
    if (window.paneId === this.element.id) {
      this.openModalButtonTarget.click()
    }
  }

  clearQueue() {
    window.paneId = undefined
  }

  get visible() {
    return this.element.classList.contains("right-0")
  }

  get anyChannelOptionSelected() {
    return Array.from(
      document.querySelectorAll(
        '[data-controller="custom-dropdown journeys--trigger hidden-select"]'
      )
    )
      .map((element) => element.dataset.selected)
      .find((value) => value === "any")
  }

  get openModalButtonTarget() {
    return this.element.querySelector("[data-open-modal]")
  }
}
