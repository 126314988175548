import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = { url: String }
  static targets = ["metric", "skeleton"]

  async apply({ target }) {
    this.metricTargets.forEach((target) => target.classList.add("hidden"))
    this.skeletonTargets
      .forEach((target) => target.classList.remove("hidden"))

    await get(this.urlValue, {
      query: { measurement: target.dataset.filter },
      responseKind: "turbo-stream",
    })

    this.metricTargets.forEach((target) => target.classList.remove("hidden"))
    this.skeletonTargets.forEach((target) => target.classList.add("hidden"))
  }
}
