import ApplicationController from '../application_controller'

import { useLineNotifiers } from '../mixins/useLineNotifiers'

export default class extends ApplicationController {
  static targets = [
    'expander',
    'item',
  ]

  connect() {
    super.connect()

    useLineNotifiers(this, {
      element: this.itemTargets[0]
    })
  }

  hideExpanderAndHoverFirstItemInGroup() {
    this.applyInvisibilityClassesOnExpander()
    this.simulateHoverOnFirstItemInGroup()
  }

  // private

  applyInvisibilityClassesOnExpander() {
    // Because we are implementing a hover/active state with JS for the lines
    // When toggling a group, if we simply add the 'hidden' class a "flash" effect is seen with the line
    // This is because the floating-ui will recalculate the position.
    // To avoid this, we need to hide then remove the element as following.

    this.expanderTarget.classList.add('invisible', 'absolute', 'opacity-0')
  }

  simulateHoverOnFirstItemInGroup() {
    const event = new MouseEvent('mouseenter', {
      bubbles: true,
      cancelable: true,
      view: window,
    })

    this.itemTargets[0].dispatchEvent(event)
  }
}
