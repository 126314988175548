import LineController from "../journeys/line_controller"
import { get, patch } from "@rails/request.js"
import { StraightConnector } from "@jsplumb/core"

export default class extends LineController {
  static values = { url: String, replaceUrl: String }
  static targets = [
    "root",
    "else",
    "end",
    "elseContainer",
    "stepsContainer",
    "conditionsSelect",
    "branch"
  ]

  initialize() {
    this.kind = "condition"
    super.initialize()
  }

  connect() {
    this.cloneElseDropzone()
    this.drawRootToElseBranch()
    this.drawElseBranchToEndLine()

    this.drawEndLine()

    if (!this.persistedValue) {
      setTimeout(() => {
        this.togglePane({})
      }, 0)
    }
    super.connect()
  }

  disconnect() {
    this.instance.deleteConnection(this.elseToEndLine)
    this.instance.deleteConnection(this.endLine)
    this.instance.deleteConnection(this.rootToElseLine)

    super.disconnect()
  }

  invalidate({ dataset }) {
    if (dataset.target !== this.element.id) return

    this.element.setAttribute("data-invalid", "true")
    this.invalid = true
    this.triggerTarget.classList.remove("halo--active")
    this.triggerTarget.classList.add("halo--error")
  }

  repositionLines() {
    setTimeout(() => this.instance.repaintEverything(), 0)
  }

  onWindowSizeChange() {
    this.instance.repaintEverything()
  }

  redraw({ detail }) {
    setTimeout(() => this.instance.repaintEverything(), 0)

    if (detail.target !== this.element.id) return

    if (detail.child) {
      this.childValue = detail.child
    }

    if (detail.branch.includes("else")) {
      this.instance.deleteConnection(this.elseToEndLine)
      this.instance.deleteConnection(this.rootToElseLine)
      this.drawRootToElseBranch(document.getElementById(detail.replaceWith))
      this.elseTarget.classList.add("hidden")
    } else if (detail.branch.includes("branchless")) {
      this.instance.deleteConnection(this.endLine)
      this.childValue = detail.replaceWith
      this.drawEndLine()
    }

    this.instance.repaintEverything()
    setTimeout(() => this.instance.repaintEverything(), 0)
  }

  redrawEndLineIfNeeded({ detail }) {
    setTimeout(() => this.instance.repaintEverything(), 0)

    if (detail.target === this.element.id) {
      switch (detail.branch) {
        case "else":
          this.allowDroppingToElseBranch()
          break
        default:
          this.childValue = detail.child
      }
    }

    if (detail.child === this.element.id) {
      this.parentTarget.value = detail.parent
      this.branchInputTarget.value = detail.branch
    }

    if (detail.parent === this.element.id) {
      this.childValue = detail.child
      if (
        this.element.contains(document.getElementById(detail.child)) === false
      ) {
        this.instance.deleteConnection(this.endLine)
        this.drawEndLine()
      }
    }
  }

  sync({ detail }) {
    if (detail.stepId !== this.element.id) return

    this.triggerTarget.disabled = false
    this.triggerTarget.classList.remove("halo--active")
    this.persistedValue = detail.commit
    this.invalid = false
    this.triggerTarget.classList.remove("halo--error")
    this.element.removeAttribute("data-invalid")

    this.dispatch("became-valid", {
      target: document.documentElement,
    })

    this.branchTargets.forEach((branch) => {
      this.dispatch("branch:became-valid", {
        target: branch,
      })
    })


    patch(this.replaceUrlValue, {
      responseKind: "turbo-stream",
      body: new FormData(this.element.closest("form")),
    })

    this.branchesInsidePane.forEach((branchInsidePane) => {
      const eventName = branchInsidePane.classList.contains("hidden") ? "remove" : "persist"
      this.dispatch(eventName, {
        target: branchInsidePane
      })
    })
  }

  remove({ detail }) {
    if (detail !== this.element.id) return

    this.dropzone.remove()
    this.element.remove()

    if (this.hasRemovedInputTarget) {
      this.removedInputTarget.disabled = false
      document
        .getElementById("drawing_container")
        .appendChild(this.removedInputTarget)
    }
  }

  abortChanges({ detail }) {
    if (detail !== this.element.id) return
    this.triggerTarget.classList.remove("halo--active")
    if (!this.persistedValue) {
      this.element.remove()
      this.dropzone.remove()
      return
    }

    this.branchesInsidePane.forEach((branchInsidePane) => {
        this.dispatch("show", {
          target: branchInsidePane
        })
      })
  }

  childValueChanged() {
    this.dropzone.setAttribute("data-child", this.childValue)
  }

  // private

  allowDroppingToElseBranch() {
    this.instance.deleteConnection(this.elseToEndLine)
    this.instance.deleteConnection(this.rootToElseLine)

    if (this.elseBranchEmpty) {
      this.elseContainerTarget.appendChild(this.elseBranchDropzone)
      this.elseTarget.classList.remove("hidden")
      this.drawElseBranchToEndLine()
    }

    this.drawRootToElseBranch()

    setTimeout(() => this.instance.repaintEverything(), 0)
  }

  drawRootToElseBranch(end = this.elseContainerTarget) {
    this.rootToElseLine = this.draw(this.rootTarget, end, {
      fromOptions: this.blankEndpoint("Right"),
      toOptions: this.blankEndpoint("Top"),
    })
  }

  drawElseBranchToEndLine() {
    this.elseToEndLine = this.draw(this.elseContainerTarget, this.endTarget, {
      fromOptions: this.blankEndpoint("Bottom"),
      toOptions: this.blankEndpoint("Right"),
    })
  }

  cloneElseDropzone() {
    this.elseBranchDropzone =
      this.elseContainerTarget.firstElementChild.nextElementSibling.cloneNode(
        true
      )

    this.elseBranchDropzone.classList.remove("hidden")
  }

  drawEndLine() {
    if (this.isLastStep) {
      this.drawToEndElement()
      return
    }

    if (this.endIdValue && !this.childValue) {
      this.endLine = this.draw(
        this.endTarget,
        document.getElementById(this.endIdValue),
        {
          fromOptions: this.blankEndpoint("Bottom"),
          toOptions: this.blankEndpoint(),
          lineOptions: this.flowChartLineOptions,
        }
      )
    } else if (this.childValue) {
      this.endLine = this.draw(
        this.endTarget,
        document.getElementById(this.childValue),
        {
          fromOptions: this.blankEndpoint("Bottom"),
          toOptions: this.blankEndpoint("Top"),
          lineOptions: StraightConnector.type,
        }
      )
    } else {
      this.endLine = this.draw(
        this.endTarget,
        document.getElementById("end-connector"),
        {
          fromOptions: this.blankEndpoint("Bottom"),
          toOptions: this.blankEndpoint("Center"),
          lineOptions: StraightConnector.type,
        }
      )
    }
  }

  drawToEndElement() {
    if (this.isNestedInElseBranch) {
      this.endLine = this.draw(
        this.endTarget,
        document.getElementById(this.childValue || this.endIdValue),
        {
          fromOptions: this.blankEndpoint("Bottom"),
          toOptions: this.blankEndpoint(),
        }
      )
    } else {
      this.endLine = this.draw(
        this.endTarget,
        document.getElementById(this.childValue || "end-connector"),
        {
          fromOptions: this.blankEndpoint("Bottom"),
          toOptions: this.blankEndpoint(),
          lineOptions: StraightConnector.type,
        }
      )
    }
  }

  get isLastStep() {
    return (
      this.branchValue === "branchless" && !this.childValue && !this.rootValue
    )
  }

  get isNestedInElseBranch() {
    return this.element.closest(
      "div [data-step--condition-target='elseContainer']"
    )
  }

  get elseBranchEmpty() {
    return this.elseContainerTarget.querySelectorAll("[data-step]").length === 0
  }

  get dropzone() {
    return document.getElementById(`${this.element.id}_dropzone`)
  }

  get flowChartLineOptions() {
    const originalOptions = this.flowchartConnectorLineOptions

    return {
      ...originalOptions,
      options: {
        ...originalOptions.options,
        cornerRadius:
          this.inBranch && !this.insideEdgeBranch
            ? 0
            : originalOptions.options.cornerRadius,
      },
    }
  }

  get branchesInsidePane() {
    return this.element.querySelectorAll('[data-controller*="step--condition--pane--branch"]')
  }
}
