import {Dropdown} from "tailwindcss-stimulus-components"
import { computePosition, autoUpdate, shift, flip } from "@floating-ui/dom"

export default class extends Dropdown {
  static values = {
    applyActiveClass: { type: Boolean, default: false },
    placement: { type: String, default: "bottom-start" },
    flip: { type: Boolean, default: true },
    shiftPadding: { type: Number, default: 24 }
  }

  static classes = ["selected"]
  static targets = ["trigger"]

  connect() {
    super.connect()

    const floatingMenuParent = this.hasTriggerTarget ? this.triggerTarget : this.element

    if(this.placementValue === 'none') return

    const milddlewares = [
      shift({ padding: this.shiftPaddingValue })
    ]

    if(this.flipValue) {
      milddlewares.push(flip())
    }

    this.floatingUICleanup = autoUpdate(floatingMenuParent, this.menuTarget, () => {
      computePosition(floatingMenuParent, this.menuTarget, {
        placement: this.placementValue,
        middleware: milddlewares
      }).then(({x, y}) => {
        Object.assign(this.menuTarget.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
      });
    })
  }

  disconnect() {
    super.disconnect()

    if(this.floatingUICleanup) {
      this.floatingUICleanup()
    }
  }

  openValueChanged() {
    this.dispatch("toggle", {
      detail: this.openValue
    })

    if(this.openValue) {
      this.menuTarget.classList.remove("hidden")
      this.menuTarget.setAttribute("aria-expanded", "true")

      this.dispatch("opened", {
        detail: { menu: this.menuTarget }
      })
    } else {
      this.menuTarget.classList.add("hidden")
      this.menuTarget.removeAttribute("aria-expanded")

      this.dispatch("hidden", {
        detail: { menu: this.menuTarget }
      })
    }
  }

  hide(event) {
    if(event.target.closest('dialog') || event.target.tagName === 'DIALOG') return

    if (event.target.nodeType && this.element.contains(event.target) === false && this.openValue) {
      this.openValue = false

      setTimeout(() => this.dispatch("aborted"), 400)
    }
  }

  toggle(e) {
    if (e.currentTarget && this.menuTarget.contains(e.currentTarget)) {
      this.#applyActiveClassOn(e.currentTarget)
    }

    if(e.currentTarget.hasAttribute("data-notoggle")) return

    super.toggle()
  }

  onMouseLeave() {
    this.openValue = false
  }

  applyActiveClassOn({ detail: currentTarget }) {
    this.#applyActiveClassOn(currentTarget)
  }

  closeOtherDropdowns() {
    document.querySelectorAll('[data-dropdown-open-value="true"]').forEach((dropdown) => {
        const dropdownController = this.application.getControllerForElementAndIdentifier(dropdown, 'dropdown')

        if (dropdownController && dropdownController !== this) {
            dropdownController.openValue = false
        }
    })
  }

  // private

  #applyActiveClassOn(option) {
    if(!this.applyActiveClassValue) return

    this.items.forEach((element) => {
      if(element !== option) {
        element.classList.remove(...this.activeElementClass)
        element.removeAttribute("data-active")
      }
    })

    const listItem = option.tagName === "LI" ? option : option.closest("li")
    listItem.classList.add(...this.activeElementClass)

    setTimeout(() => listItem.setAttribute("data-active", ""), 1)
  }

  get items() {
    return Array.from(this.menuTarget.querySelectorAll("li"))
  }

  get activeElementClass() {
    return this.hasSelectedClass ? this.selectedClasses : ["bg-lavender-light"]
  }

  beforeCache() {
    if(this.element.hasAttribute("data-turbo-permanent")) return
    super.beforeCache()
  }
}
