import QuickReplyController from './quick_reply_button_controller'

export default class extends QuickReplyController {
  toObject() {
    return {
      type: 'copy_code',
      title: this.inputTarget.value.trim(),
      index: Array.from(this.element.parentElement.children).indexOf(this.element),
      dataset: this.element.dataset
    }
  }
}
