import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static values = {
    path: String,
  }

  static targets = [
    'link',
    'count',
  ]

  initialize() {
    super.initialize()
    this.popStateListener = this.popStateListener.bind(this)
  }

  connect() {
    if (window.location.pathname === this.pathValue) {
      this.markAsActive()
    }

    window.addEventListener('popstate', this.popStateListener)
    super.connect()
  }

  disconnect() {
    window.removeEventListener('popstate', this.popStateListener)
    super.disconnect()
  }

  popStateListener() {
    if (window.location.pathname === this.pathValue) {
      this.markAsActive()
    }
  }

  markAsActive() {
    document.querySelector('.audience--link.active')?.classList?.remove('active')
    this.addClass(this.linkTarget, 'active')

    this.dispatch('activated', {
      detail: {
        path: this.pathValue,
        count: this.element.dataset.count,
        exportUrl: this.element.dataset.exportPath,
        batchUrl: this.element.dataset.batchPath,
        batchUpdateUrl: this.element.dataset.batchUpdatePath,
      }
    })
  }

  countTargetConnected() {
    const { value } = this.countTarget.dataset
    const currentCount = this.element.dataset.count

    if(value === 'decrement') {
      this.element.dataset.count = parseInt(currentCount) - 1
    } else if(value === 'increment') {
      this.element.dataset.count = parseInt(currentCount) + 1
    } else {
      this.element.dataset.count = value
    }

    this.countTarget.remove()

    if (window.location.pathname.startsWith(this.pathValue)) {
      this.dispatch('activated', {
        detail: {
          path: this.pathValue,
          count: this.element.dataset.count,
        }
      })
    }
  }
}
