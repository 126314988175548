import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    fontSizeStyleUrl: String,
    baseFontSize: { type: Number, default: 1 },
  }

  static targets = [
    "controls",
    "style",
    "layout",
    "settings",
    "mobileLayoutTemplates",
    "desktopLayoutTemplates",
    "container",
    "settings",
  ]

  initialize() {
    this.controlsValue = {
      font: {
        base: 1,
      },
    }

    window.popupControls = this.controlsValue
  }

  connect() {
    setTimeout(() => {
      this.dispatch("font-size:changed", {
        target: document.documentElement,
        detail: {
          size: this.baseFontSizeValue,
        },
      })
    }, 0)
  }

  showStyleControls() {
    this.controlsTarget.classList.add("hidden")
    this.styleTarget.classList.remove("hidden")
  }

  showControls() {
    this.controlsTarget.classList.remove("hidden")
    this.styleTarget.classList.add("hidden")
    this.layoutTarget.classList.add("hidden")
    this.settingsTarget.classList.add("hidden")
  }

  showLayoutTypes() {
    this.controlsTarget.classList.add("hidden")
    this.layoutTarget.classList.remove("hidden")
  }

  showMobileLayoutTemplates() {
    this.mobileLayoutTemplatesTarget.classList.remove("hidden")
    this.desktopLayoutTemplatesTarget.classList.add("hidden")
  }

  showDesktopLayoutTemplates() {
    this.desktopLayoutTemplatesTarget.classList.remove("hidden")
    this.mobileLayoutTemplatesTarget.classList.add("hidden")
  }

  showSettings() {
    this.controlsTarget.classList.add("hidden")
    this.settingsTarget.classList.remove("hidden")
  }

  changeFontSize({ target }) {
    this.controlsValue.font.base = target.dataset.size
    window.popupControls = this.controlsValue

    get(this.fontSizeStyleUrlValue, {
      responseKind: "turbo-stream",
      query: {
        base_size: target.dataset.size,
      },
    })

    this.dispatch("font-size:changed", {
      target: document.documentElement,
      detail: {
        size: target.dataset.size,
      },
    })
  }
}
