export const useBatchUpdateNotifiers = (
  controller,
  {
    onBatchUpdateCancellation = () => {},
    onBatchUpdateAbortion = () => {}
  } = {}
) => {
  Object.assign(controller, {
    getMode() {
      return this.element.closest('[data-mode]')?.dataset?.mode
    },
    inBatchUpdateMode() {
      return this.element.closest('[data-mode]')?.dataset?.mode === 'batch_update'
    },
    requestBatchUpdateConfirmation() {
      this.dispatch('batch-update:request-confirmation', {
        prefix: null,
        detail: {
          id: this.element.dataset.property,
          kind: this.element.dataset.kind,
          value: this.getValueForBatch()
        }
      })
    },
    isBlockedAnchor(e) {
      return e.target.closest('a') === this.blockedAnchor || e.target === this.blockedAnchor
    },
    onBatchUpdateCancellation,
    onBatchUpdateAbortion,
  })
}
