import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  connect() {
    this.collectUtmParams()
  }

  collectUtmParams() {
    const expirationDate = new Date();
    const landingPage = window.location.href;

    expirationDate.setTime(expirationDate.getTime() + 30 * 60 * 1000);

    if (this.hasUtmParams) {
      const utmParams = {
        source: this.utmSource,
        medium: this.utmMedium,
        campaign: this.utmCampaign,
        content: this.utmContent,
        term: this.utmTerm
      }

      document.cookie = `utm_params=${JSON.stringify(utmParams)}; path=/; expires=${expirationDate.toUTCString()}`;
    }
  }

  get hasUtmParams() {
    return this.utmSource && this.utmMedium && this.utmCampaign && this.utmContent && this.utmTerm
  }

  get utmSource() {
    return this.urlSearchParams.get('utm_source')
  }

  get utmMedium() {
    return this.urlSearchParams.get('utm_medium')
  }

  get utmCampaign() {
    return this.urlSearchParams.get('utm_campaign')
  }

  get utmContent() {
    return this.urlSearchParams.get('utm_content')
  }

  get utmTerm() {
    return this.urlSearchParams.get('utm_term')
  }

  get urlSearchParams() {
    return new URLSearchParams(window.location.search)
  }
}
