import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox"]

  check() {
    this.checkboxTarget.checked = true
  }

  uncheck() {
    this.checkboxTarget.checked = false
  }
}
