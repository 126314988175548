import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["input", "creator", "content", "locationIcon", "listIcon", "subscriberIcon", "mediaIcon", "attachmentIcon", "attachmentContainer", "voiceIcon", "videoIcon"]

  show({ detail }) {
    this.reset()

    const { id, body, creator, element } = detail

    this.inputTarget.value = id
    this.creatorTarget.innerHTML = creator
    this.contentTarget.innerHTML = body || this.translations.message.replies.default_location_message

    if(detail.attachedLocation) {
      super.show(this.locationIconTarget)
    }

    if(detail.type === "vcard") {
      if(detail.isMultiple) {
        super.show(this.listIconTarget)
      } else {
        super.show(this.subscriberIconTarget)
      }
    }

    if(detail.type === "voice") {
      super.show(this.voiceIconTarget)
      this.contentTarget.innerHTML = body || this.translations.message.replies.default_voice_message
    }

    if(detail.type === "media") {
      const attachment = element.querySelectorAll("[data-message-attachment]")[0]

      if(attachment.querySelector("[data-attachment-image]")) {
        super.show(this.mediaIconTarget)
        const image = attachment.querySelector("[data-attachment-image]").cloneNode(true)
        image.style.objectFit = "contain"

        this.attachmentContainerTarget.append(image)
      } else {
        super.show(this.attachmentIconTarget)

        this.contentTarget.innerHTML = Array.from(element.querySelectorAll("[data-message-attachment]"))
          .map(attachment => attachment.querySelector("[data-attachment-name]").title)
          .join(", ")
      }
    }

    if(detail.type === "video") {
      super.show(this.videoIconTarget)
      this.contentTarget.innerHTML = body || this.translations.message.replies.default_video_message
    }

    this.enable(this.inputTarget)
    super.show()

    this.dispatch("focus", {
      target: document.documentElement
    })
  }

  hide() {
    super.hide()

    this.disable(this.inputTarget, { clear: true })
    this.reset()
  }

  reset() {
    this.contentTarget.innerHTML = ""
    this.attachmentContainerTarget.innerHTML = ""

    this.icons.forEach(icon => super.hide(icon))

    this.dispatch("blur", {
      target: document.documentElement
    })
  }

  // private

  get icons() {
    return [
      this.locationIconTarget,
      this.listIconTarget,
      this.subscriberIconTarget,
      this.mediaIconTarget,
      this.attachmentIconTarget,
      this.voiceIconTarget,
      this.videoIconTarget,
    ]
  }
}
