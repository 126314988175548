import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    valid: Boolean
  }

  static targets = [
    'input',
  ]

  connect() {
    this.focus(this.inputTarget, { moveCursorToEnd: true })
  }

  sync() {
    this.validValue = this.inputTarget.value.trim().length > 0
  }

  validValueChanged() {
    this.element.setAttribute('data-valid', this.validValue)

    this.dispatch('validation', {
      detail: this.validValue,
    })
  }
}
