import TooltipController from "../../tooltip_controller"
import { useDraggable } from "../../mixins/useDraggable"
import { useRemove } from "../../mixins/useRemove"

export default class extends TooltipController {
  static targets = [
    "inputContainer",
    "input",
    "label",
    "orderInput",
    "deleteButton",
    "reorderButton",
  ]

  static classes = ['drag']

  connect() {
    super.connect()
    useDraggable(this)
  }

  edit() {
    this.inputContainerTarget.classList.remove("hidden")
    this.labelTarget.classList.add("hidden")

    this.inputTarget.selectionStart = this.inputTarget.selectionEnd =
      this.inputTarget.value.length
    this.inputTarget.focus()
    this.editing = true
    this.hideToolbar()
  }

  cancelEdit() {
    this.inputContainerTarget.classList.add("hidden")
    this.labelTarget.classList.remove("hidden")

    this.editing = false

    this.inputTarget.value = ''
  }

  saveEdit() {
    this.inputContainerTarget.classList.add("hidden")
    this.labelTarget.classList.remove("hidden")

    this.editing = false

    this.labelTarget.textContent = this.inputTarget.value.trim()

    this.dispatch('update:name', {
      target: this.step,
      detail: this.inputTarget.value,
    })
  }

  enable() {
    this.reorderButtonTarget.classList.remove("hidden")
    this.deleteButtonTarget.classList.remove("hidden")

    this.tooltipTarget.classList.replace("left-3", "-left-8")
    this.tooltipTarget.classList.add("space-x-4")
  }

  disable() {
    this.reorderButtonTarget.classList.add("hidden")
    this.deleteButtonTarget.classList.add("hidden")

    this.tooltipTarget.classList.replace("-left-8", "left-3")
    this.tooltipTarget.classList.remove("space-x-4")
  }

  onDragEnd({ detail: { newIndex, oldIndex }}) {
    this.afterDragDisabled()

    this.dispatch('update:position', {
      target: this.step,
      detail: {
        oldIndex: oldIndex + 1,
        newIndex: newIndex + 1,
      },
    })
  }

  saveChanges() {
    if(this.tooltipTarget.classList.contains('hidden') && this.inputContainerTarget.classList.contains('hidden')) return

    if(this.inputTarget.value.trim().length === 0) {
      this.cancelEdit()
    } else {
      this.saveEdit()
    }
  }

  // private

  showToolbar() {
    super.show()
  }

  hideToolbar() {
    this.tooltipTarget.classList.add("hidden")
  }

  afterDragEnabled() {
    this.hideToolbar()

    this.labelTarget.classList.remove('border-transparent')
    this.element.classList.remove("not-draggable")
  }

  afterDragDisabled() {
    this.labelTarget.classList.add('border-transparent')
    this.element.classList.add("not-draggable")

    this.labelTarget.classList.remove(...this.dragClasses)
  }

  get draggable() {
    return this.labelTarget
  }

  get step() {
    return document.querySelector(`[data-controller='capture--step'][data-step='${this.element.dataset.step}']`)
  }
}
