import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    previewUrl: String,
    couponUrl: String,
  }

  async syncPreview(e) {
    this.activeJourney = e.target.dataset.journey

    await get(this.previewUrlValue, {
      query: {
        journey: this.activeJourney,
        coupon: this.activeCoupon,
      },
      responseKind: "turbo-stream",
    })
  }

  async syncPreviewWithCoupon(e) {
    this.activeCoupon = e.target.dataset.coupon

    await get(this.previewUrlValue, {
      query: {
        journey: this.activeJourney || "",
        coupon: this.activeCoupon,
      },
      responseKind: "turbo-stream",
    })
  }

  removeAssignedJourney() {
    get(this.previewUrlValue, {
      query: {
        journey: "",
        coupon: "",
      },
      responseKind: "turbo-stream",
    })
  }

  async showPane() {
    await get(this.couponUrlValue, { responseKind: "turbo-stream" })
    window.dispatchEvent(new CustomEvent("coupon:show"))
  }
}
