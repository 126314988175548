import { get } from "@rails/request.js"
import PaginationController from "./pagination_controller"

export default class extends PaginationController {
  static targets = [
    "searchInput",
    "clearButton",
    "filterButton",
    "submitButton",
    "searchContainer",
    "allContainer",
    "skeletons",
    "paginationButton",
  ]
  static values = {
    timer: Object,
    url: String,
  }

  sync() {
    if (this.searchInputTarget.value.trim().length !== 0) {
      this.clearButtonTarget.classList.remove("hidden")

      if (this.hasFilterButtonTarget) {
        this.filterButtonTarget.classList.add("hidden")
      }
      clearTimeout(this.timer)
      document.getElementById("search-results").innerHTML = ""
      this._showSkeletonLoaders()
      this._setTimerToRequestResource()

      this.searchContainerTarget.classList.remove("hidden")
      this.allContainerTarget.classList.add("hidden")
    } else {
      this.clear()
      clearTimeout(this.timer)
      if (this.hasPaginationButtonTarget) {
        this.paginationButtonTarget.classList.remove("hidden")
      }
    }
  }

  clear() {
    this.searchInputTarget.value = ""
    this.clearButtonTarget.classList.add("hidden")
    this.searchContainerTarget.classList.add("hidden")
    this.allContainerTarget.classList.remove("hidden")
    this._hideSkeletonLoaders()

    if (this.hasFilterButtonTarget) {
      this.filterButtonTarget.classList.remove("hidden")
    }

    this.resetPage()
  }

  _setTimerToRequestResource() {
    this.timer = setTimeout(async () => {
      const url = new URL(this.urlValue)
      url.searchParams.append("search", this.searchInputTarget.value)
      await get(url.toString(), { responseKind: "turbo-stream" })
      this._hideSkeletonLoaders()
    }, 400)
  }

  _showSkeletonLoaders() {
    if (this.hasSkeletonsTarget) {
      this.skeletonsTarget.classList.remove("hidden")
    }

    if (this.hasPaginationButtonTarget) {
      this.paginationButtonTarget.classList.add("hidden")
    }
  }

  _hideSkeletonLoaders() {
    if (this.hasSkeletonsTarget) {
      this.skeletonsTarget.classList.add("hidden")
    }

    if (this.hasPaginationButtonTarget) {
      this.paginationButtonTarget.classList.remove("hidden")
    }
  }

  get paginationUrl() {
    return super.paginationUrl.concat(`&search=${this.searchInputTarget.value}`)
  }
}
