import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['menu']

  resetState() {
    this.dispatch('reset', {
      detail: {
        type: this.element.dataset.type
      }
    })
  }

  reset({ target }) {
    if(this.isInvisible(this.menuTarget) || this.element.contains(target)) return
    this.resetState()
  }
}
