import Sortable from 'sortablejs'
import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['item']

  static values = {
    group: { type: String, default: "shared" }
  }

  initialize() {
    super.initialize()

    this.childListObserver = new MutationObserver(this.childListCallback.bind(this))
    this.activateItem = this.activateItem.bind(this)
    this.deactivateItem = this.deactivateItem.bind(this)
  }

  connect() {
    this.sortable = Sortable.create(this.element, {
      group: this.groupValue,
      animation: 150,
      filter: ".not-draggable",
      onEnd: this.end.bind(this),
      preventOnFilter: false
    })

    if(!this.isSortable) {
      this.nextTick(() => this.visibleItems.forEach(this.deactivateItem))
    }

    this.childListObserver.observe(this.element, { childList: true })
  }

  disconnect() {
    super.disconnect()
    this.childListObserver.disconnect()
  }

  end(event) {
    this.dispatch('end', {
      detail: {
        item: event.item
      }
    })
  }

  childListCallback(mutations) {
    const mutation = mutations.find(mutation => mutation.type === 'childList')
    if(!mutation) return

    if(this.visibleItems.length > 1) {
      this.visibleItems.forEach(this.activateItem)
    } else {
      this.visibleItems.forEach(this.deactivateItem)
    }
  }

  activateItem(item) {
    this.dispatch('activate', {
      target: item
    })
  }

  deactivateItem(item) {
    this.dispatch('deactivate', {
      target: item
    })
  }

  get isSortable() {
    return this.visibleItems.length > 1
  }

  get visibleItems() {
    return this.itemTargets.filter(item => !item.classList.contains('hidden'))
  }
}
