import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    inactiveActions: String
  }

  static targets = ["activeList", "inactiveList", "filter", "hint"]
  static classes = ["active", "inactive"]

  connect() {
    super.connect()

    if(this.noMoreInactiveFilters) {
      this.show(this.hintTarget)
    }
  }

  apply({ currentTarget }) {
    const clonedNode = currentTarget.cloneNode(true)
    clonedNode.removeAttribute("data-inbox--filters-target")

    currentTarget.classList.add("hidden")

    this.activeListTarget.appendChild(clonedNode)
    this.applyActiveStateOn(clonedNode)

    if(this.noMoreInactiveFilters) {
      this.show(this.hintTarget)
    }
  }

  remove(e) {
    e.stopImmediatePropagation()
    e.preventDefault()

    const { currentTarget } = e

    const filterToRemove = this.activeListTarget.querySelector(`[data-id="${currentTarget.dataset.id}"]`)
    const actualHiddenFilter = this.inactiveListTarget.querySelector(`[data-id="${currentTarget.dataset.id}"]`)

    filterToRemove.remove()
    actualHiddenFilter.classList.remove("hidden")

    this.hide(this.hintTarget)
  }

  // private

  applyActiveStateOn(element) {
    element.removeAttribute("data-action")
    element.classList.remove(...this.inactiveClasses)
    element.classList.add(...this.activeClasses)
    element.querySelector("[data-remove-button]").classList.remove("hidden")
  }

  applyInactiveStateOn(element) {
    element.setAttribute("data-action", this.inactiveActionsValue)
    element.classList.remove(...this.activeClasses)
    element.classList.add(...this.inactiveClasses)
    element.querySelector("[data-remove-button]").classList.add("hidden")
  }

  get noMoreInactiveFilters() {
    return this.filterTargets.filter(element => !element.classList.contains('hidden')).length === 0
  }
}
