import {get} from "@rails/request.js";

import ApplicationController from "../../application_controller"

export default class extends ApplicationController {
  static values = {
    selected: Array,
    searchUrl: String,
  }

  static targets = [
    "button",
    "menu",
    "checkbox",
    "searchInput",
    "clearButton",
    "shareContainer",
    "shareButton",
    "activeList",
    "inactiveList",
    "typeInput"
  ]

  search() {
    clearTimeout(this.searchTimeout)

    this.searchTimeout = setTimeout(() => {
      get(this.searchUrlValue, {
        query: {
          search: this.searchInputTarget.value,
          "vcard[entries]": this.selectedValue
        },
        responseKind: "turbo-stream"
      })
    }, 100)

    if(this.searchInputTarget.value.trim().length > 0) {
      this.clearButtonTarget.classList.replace("opacity-0", "opacity-100")
      this.hide(this.activeListTarget)
    } else {
      this.clearButtonTarget.classList.replace("opacity-100", "opacity-0")
      this.show(this.activeListTarget)
    }
  }

  focus() {
    super.focus(this.searchInputTarget)
  }

  clearSearch() {
    this.searchInputTarget.value = ""
    this.search()
  }

  onCheckboxChange(event) {
    if(event.target.checked) {
      this.selectedValue = [...this.selectedValue, event.target.value]
      this.moveCheckedToActive(event.target)
    } else {
      event.stopPropagation()
      event.preventDefault()

      this.selectedValue = this.selectedValue.filter((value) => value !== event.target.value)
      this.moveCheckedToInactive(event.target)
    }
  }

  toggle(event) {
    event.target.checked = !event.target.checked
  }

  moveCheckedToActive(target) {
    const parent = target.closest("li")
    const originalIndex = Array.from(this.inactiveListTarget.children).indexOf(parent)

    parent.setAttribute("data-index", originalIndex)

    this.activeListTarget.appendChild(parent)
  }

  moveCheckedToInactive(target) {
    const parent = target.closest("li")
    const originalIndex = parent.dataset.index
    const inactiveItems = this.inactiveListTarget.children
    const referenceNode = (originalIndex >= inactiveItems.length) ? null : inactiveItems[originalIndex];

    parent.removeAttribute("data-index")

    this.inactiveListTarget.insertBefore(parent, referenceNode)
  }

  selectedValueChanged() {
    if(this.selectedValue.length === 0) {
      this.shareContainerTarget.classList.add("hidden")
      return
    }

    this.shareContainerTarget.classList.remove("hidden")

    if(this.selectedValue.length === 1) {
      this.shareButtonTarget.innerText = window.t.actions.share.one
    } else {
      this.shareButtonTarget.innerText = window.t.actions.share.other.replace("%{n}", this.selectedValue.length)
    }
  }

  share() {
    document.getElementById("vcards-form").requestSubmit()
  }

  closeByEscape(event) {
    if(this.isInvisible(this.menuTarget)) return

    this.buttonTarget.click()
  }

  enable() {
    super.enable(this.buttonTarget)
  }

  disable() {
    super.disable(this.buttonTarget)
  }

  remove() {
    this.typeInputTarget.remove()
  }
}
