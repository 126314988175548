import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [
    'nameInput',
    'positionInput',
  ]

  updateName({ detail: value }) {
    this.nameInputTarget.value = value
  }

  updatePosition({ detail }) {
    const { newIndex } = detail
    this.positionInputTarget.value = newIndex
  }
}
