import { Modal } from "tailwindcss-stimulus-components"

export default class extends Modal {
  static targets = ["hide", "video"]

  close(t) {
    super.close(t)
    this.dispatch("closed")
    if (this.videoTarget) {
      // this.videoTarget.pause()
      this.videoTarget.contentWindow.postMessage(
        '{"event":"command","func":"pauseVideo","args":""}',
        "*"
      )
      this.videoTarget.contentWindow.blur()
      this.videoTarget.blur()
    }
  }

  open(t) {
    super.open(t)
    this.dispatch("opened")
    if (this.videoTarget) {
      // this.videoTarget.play()
      this.videoTarget.contentWindow.postMessage(
        '{"event":"command","func":"playVideo","args":""}',
        "*"
      )
      this.videoTarget.contentWindow.blur()
      this.videoTarget.blur()
    }
  }

  hideTargetConnected() {
    this.close()
    this.hideTarget.remove()
    if (this.videoTarget) {
      // this.videoTarget.pause()
      this.videoTarget.contentWindow.postMessage(
        '{"event":"command","func":"pauseVideo","args":""}',
        "*"
      )
      this.videoTarget.contentWindow.blur()
      this.videoTarget.blur()
    }
  }
}
