import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  alertUserAboutUnsavedChanges(e) {
    this.dispatch("requested-cancellation", {
      target: document?.getElementById("popup_form"),
      detail: e,
    })
  }
}
