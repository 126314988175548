import { Controller } from "@hotwired/stimulus"
import rome from "@bevacqua/rome/src/rome"
import moment from "moment"
import "@bevacqua/rome/dist/rome.min.css"

export default class extends Controller {
  static values = {
    initial: { type: String, default: "10:00" },
    min: String,
    max: String,
    inputName: String
  }
  static classes = ["container"]

  initialize() {
    this.onOutsideClick = this.onOutsideClick.bind(this)
    this.onInputValueChange = this.onInputValueChange.bind(this)

    this.firstTime = true
  }

  connect() {
    window.addEventListener("click", this.onOutsideClick)
    rome.use(moment)
    this.rome = rome(this.element, {
      appendTo: this.element.parentElement,
      date: false,
      styles: {
        container:
          `z-max w-36 dropdown-container py-2 mt-2 absolute hidden ${this.getContainerClasses}`,
        timeList: "dropdown-list max-h-96 overflow-scroll",
        timeOption: "dropdown-item-hoverable",
        time: "z-9999 w-full",
        selectedTime: "hidden",
      },
      min: this.minValue,
      max: this.maxValue,
      initialValue: this.initialValue,
      autoHideOnBlur: false,
      autoHideOnClick: false
    })


    this.rome.on("show", () => {
      // rome adds style attributes that modifies the classes already set which results in weird UI state
      // remove the style attributes that rom adds automatically
      this.rome.container.style.removeProperty("left")
      this.rome.container.style.removeProperty("top")

      this.options.forEach((option) => {
        option.setAttribute("data-action", "click->rome-parent#choose")
        option.setAttribute("data-rome-parent-target", "option")
      })

      if(this.firstTime) {
        this.dispatch("initialized", {
          target: this.element.closest("[data-controller='rome-parent']")
        })

        this.firstTime = false
      }
    })

    // rome adds an extra bracket to the input's name attribute
    // which results in extra nesting when processing the data on the server
    if(this.inputNameValue) {
      this.element.name = this.inputNameValue
    }

    this.element.addEventListener("keyup", this.onInputValueChange)
    this.element.addEventListener("keydown", this.onInputValueChange)
  }

  disconnect() {
    window.removeEventListener("click", this.onOutsideClick)

    this.element.removeEventListener("keyup", this.onInputValueChange)
    this.element.removeEventListener("keydown", this.onInputValueChange)
  }

  onOutsideClick(e) {
    if(this.element.parentElement.contains(e.target) === false) {
      this.rome.hide()
    }
  }

  // private

  onInputValueChange(e) {
    if(e.key === "Backspace") return

    if(e.key === "Escape") {
      this.rome.hide()
    }

    if(e.key === "Enter") {
      e.preventDefault()
      this.element.blur()
      this.rome.hide()
    }

    if(e.target.value.length === 5) {
      e.preventDefault()
    }

    if(e.target.value.length === 2) {
      e.target.value = `${e.target.value}:`
    }
  }

  get getContainerClasses() {
    if(this.hasContainerClass) {
      return this.containerClasses.join(" ")
    } else {
      return "top-8 left-0"
    }
  }

  get options() {
    return Array.from(this.element.parentElement.querySelectorAll("[class*='dropdown-item-hoverable']"))
  }
}
