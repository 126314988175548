import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    active: { type: Number, default: 0 },
    enabled: Boolean
  }

  static targets = ['panel', 'tab']
  static classes = ['active']

  initialize() {
    this.onArrowUp = this.onArrowUp.bind(this)
    this.onArrowDown = this.onArrowDown.bind(this)
  }

  onArrowUp(e) {
    if(this.isDisabled) return;

    this.activeValue = this.activeValue - 1
    this.updateHighlightedElement()
  }

  onArrowDown(e) {
    if(this.isDisabled) return;

    this.activeValue = this.activeValue + 1
    this.updateHighlightedElement()
  }

  updateHighlightedElement() {
    if(this.activeValue < 0) {
      this.activeValue = this.navigatableElements.length - 1
    } else if(this.activeValue >= this.navigatableElements.length) {
      this.activeValue = 0
    }

    this.navigatableElements.forEach((element, index) => {
      if(index === this.activeValue) {
        this.addClass(element, ...this.activeElementClasses)
        element.scrollIntoView(false)
      } else {
        this.removeClass(element, ...this.activeElementClasses)
      }
    })
  }

  clickActiveElement() {
    if(this.isDisabled) {
      return this.dispatch('enter')
    }

    if(this.activeElement) {
      this.activeElement.click()
    } else {
      this.dispatch('empty')
    }
  }

  navigateToElement({ detail: element }) {
    this.activeValue = this.navigatableElements.indexOf(element)
    this.updateHighlightedElement()
  }

  setActiveElement({ currentTarget }) {
    this.activeValue = this.navigatableElements.indexOf(currentTarget)
    this.updateHighlightedElement()
  }

  // private

  enabledValueChanged() {
    if(this.isDisabled) return;
    this.updateHighlightedElement()
  }

  get activeElement() {
    return this.navigatableElements[this.activeValue]
  }

  get activeElementClasses() {
    return this.hasActiveClass ? [...this.activeClasses] : ['bg-night-10']
  }

  get navigatableElements() {
    return Array.from(this.element.querySelectorAll('[data-arrow-navigatable]'))
  }

  get isDisabled() {
    return !this.enabledValue
  }
}
