import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['radio', 'list', 'cancelButton', 'triggerButton']

  initialize() {
    super.initialize()
    this.resetList = this.resetList.bind(this)
  }

  disconnect() {
    super.disconnect()
  }

  insert() {
    const controller = this.application.getControllerForElementAndIdentifier(this.activeItem, `compose--template--button-list`)
    const object = controller.toObject()

    if(object.buttons.length > 0) {
      this.dispatch('insert', {
        detail: {
          ...object,
          element: this.activeItem.cloneNode(true)
        }
      })
    }

    this.cancel()
  }

  insertByEnter(e) {
    if(this.isInvisible(this.menuTarget)) return

    e.preventDefault()
    e.stopPropagation()

    this.insert()
  }

  cancel() {
    this.removeActiveClassOnTriggerButton()
    this.element.setAttribute('data-dropdown-open-value', false)

    this.dispatch('canceled')

    this.listTargets.forEach(this.resetList)
    this.radioTargets.find(radio => radio.dataset.type === 'quick_reply').checked = true
  }

  closeByEscape() {
    if(this.isInvisible(this.menuTarget)) return

    this.cancelButtonTarget.click()
    this.radioTargets.find(radio => radio.value === 'quick_reply').checked = true
  }

  focus({ currentTarget }) {
    this.addClass(this.triggerButtonTarget, 'icon-button--compose--tiger')
    const type = currentTarget.dataset.type || 'quick_reply'

    this.listTargets.forEach(list => {
      if(list.dataset.type === type) {
        this.show(list)
        this.dispatch('focus', {
          target: list
        })
      } else {
        this.hide(list)
        this.resetList(list)
      }
    })
  }

  resetList(list) {
    this.dispatch('reset', {
      target: list
    })
  }

  removeActiveClassOnTriggerButton() {
    this.removeClass(this.triggerButtonTarget, 'icon-button--compose--tiger')
  }

  enable() {
    super.enable(this.triggerButtonTarget)

    this.dispatch('enable', {
      target: this.element.closest('[data-controller*="compose--template"]')
    })
  }

  disable() {
    super.disable(this.triggerButtonTarget)

    this.dispatch('disable', {
      target: this.element.closest('[data-controller*="compose--template"]')
    })
  }

  get activeItem() {
    return this.listTargets.find(list => this.isVisible(list))
  }

  get menuTarget() {
    return this.element.querySelector('[popover]')
  }
}
