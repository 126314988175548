import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["closingBrace", "content"]

  initialize() {
    this.composeParent = this.element.closest("[data-controller*='compose']")
  }

  connect() {
    if(!this.element.previousSibling) {
      this.element.insertAdjacentHTML("beforebegin", "&nbsp;")
    }

    if(!this.element.nextSibling) {
      this.element.insertAdjacentHTML("afterend", "&nbsp;")
    }
  }

  // private

  closingBraceTargetDisconnected() {
    const template = document.createElement("template")
    template.innerHTML = `${this.contentTarget.innerHTML.trim()}`.trim()

    this.element.replaceWith(template.content.textContent)

    this.composeParent.dispatchEvent(
      new CustomEvent("tag:removed", {
        detail: this.contentTarget.innerText.trim(),
      })
    )
  }
}
