import PaginationController from "../pagination_controller"

export default class extends PaginationController {
  initialize() {
    super.initialize()
    this.initialRender = true
  }

  get paginationUrl() {
    let url

    if (this.initialRender) {
      url = new URL(this.urlValue)
    } else {
      url = new URL(this.urlValue.split("?")[0])
    }

    const query = new URLSearchParams(window.location.search)

    if(query.has("filter")) {
      url.searchParams.set("filter", query.get("filter"))
    }

    if (query.getAll("member[]").length === 0) {
      if (!this.initialRender) {
        url.searchParams.set("team", "1")
      }
    } else {
      new Set([...query.getAll("member[]")])
        .forEach((memberId) => {
        url.searchParams.append("member[]", memberId)
      })
    }

    query.getAll("label[]").forEach((labelId) => {
      url.searchParams.append("label[]", labelId)
    })

    url.searchParams.set("page", this.pageValue)

    this.initialRender = false
    return url.toString()
  }
}
