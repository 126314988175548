import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    windows: Array,
    activeWindow: Object,
    technologies: Array,
  }

  static targets = [
    'destinationMenu',
    'sourceOption',
    'destinationOption',
    'destinationMenuTrigger',
    'windowUpdate',
    'refreshedDestinationMenu',
    'templatesMenu',
    'inputContainer',
    'disableNotice'
  ]

  onClickOutside({target}) {
    if (this.isInvisible(this.destinationMenuTarget) || this.destinationMenuTarget.contains(target) || this.destinationMenuTriggerTarget.contains(target)) return
    this.element.setAttribute('data-custom-dropdown-open-value', false)
  }

  closeByEscape() {
    if (this.isInvisible(this.destinationMenuTarget)) return

    this.element.setAttribute('data-custom-dropdown-open-value', false)
    this.dispatch('focus', {
      target: this.element,
    })
  }

  coordinateDestinationToSource({detail}) {
    if (this.coordinatingFromSourceToDestination) return

    if (detail.identifier === 'default') {
      return this.coordinateDestinationToDefaultSource()
    }

    const window = this.windowsValue.find(window => window.channel_id === detail.channelId.split(':')[1])

    this.coordinatingFromDestinationToSource = true

    if (window && window.open) {
      const destinationOption = this.destinationOptionTargets.find(option => option.dataset.identity === window.destination_id)
      destinationOption.click()
    } else {
      const destinationOption = this.destinationOptionTargets.find(option => option.dataset.technology === detail.technology)
        || this.destinationOptionTargets.find(option => detail.technology === 'whatsapp' && ['sms', 'whatsapp'].includes(option.dataset.technology))

      destinationOption?.click()
    }

    this.nextTick(() => this.coordinatingFromDestinationToSource = false, 100)

    this.syncCompose({
      window,
      technology: window?.technology || this.technologiesValue.find(t => t.name === detail.technology),
    })

    this.activeWindowValue = window
  }

  coordinateDestinationToDefaultSource() {
    const openWindow = this.openWindow
    this.coordinatingFromDestinationToSource = true

    if (openWindow) {
      const destinationOption = this.destinationOptionTargets.find(option => option.dataset.technology === openWindow.technology.name)
      destinationOption.click()
    } else {
      const destinationOption = this.destinationOptionTargets.find(option => option.dataset.technology === 'sms')
      destinationOption?.click()
    }

    this.nextTick(() => this.coordinatingFromDestinationToSource = false, 100)

    this.syncCompose({
      window: openWindow,
      technology: openWindow?.technology || this.technologiesValue.find(t => t.name === 'sms'),
    })

    this.activeWindowValue = openWindow
  }

  coordinateSourceToDestination({currentTarget}) {
    if (this.coordinatingFromDestinationToSource) return

    const {technology} = currentTarget.dataset
    const window = this.windowsValue.find(window => window.technology.name === technology && window.destination_id === currentTarget.dataset.identity)

    this.coordinatingFromSourceToDestination = true

    const identifier = window && window.open ? `${window.technology.name}:${window.channel_id}` : technology

    const sourceOption = this.sourceOptionTargets.find(option => option.dataset.optionValue === identifier)
      || this.sourceOptionTargets.find(option => option.dataset.identifier === technology)
      || this.sourceOptionTargets.find(option => option.dataset.identifier === 'default')

    // Messages sent to SMS technology of a WhatsApp identity will be nil
    sourceOption.click()

    this.nextTick(() => this.coordinatingFromSourceToDestinatino = false, 100)

    this.syncCompose({
      window,
      technology: window?.technology || this.technologiesValue.find(t => t.name === technology),
    })

    if (technology === 'mercadolibre') {
      this.displayMercadolibreNotice()
    } else {
      this.hideMercadolibreNotice()
    }

    this.element.setAttribute('data-custom-dropdown-open-value', false)
    this.activeWindowValue = window
  }

  syncCompose({window, technology}) {
    this.dispatch('technology:change', {
      target: this.element,
      detail: {
        window,
        technology,
      }
    })

    this.dispatch('technology:change', {
      target: this.templatesMenuTarget,
      detail: {
        window,
        technology
      }
    })
  }

  displayMercadolibreNotice() {
    document.querySelector("mercadolibre-notice").classList.remove("hidden")
  }

  hideMercadolibreNotice() {
    if (document.querySelector("mercadolibre-notice")) {
      document.querySelector("mercadolibre-notice").classList.add("hidden")
    }
  }

  windowUpdateTargetConnected() {
    if (this.compose.hasContent) {
      return this.windowUpdateTarget.remove()
    }

    const update = JSON.parse(this.windowUpdateTarget.dataset.payload)

    this.windowsValue = [
      ...this.windowsValue.filter(window => window.id !== update.id),
      update,
    ]

    this.syncCompose({
      window: update,
      technology: update.technology,
    })

    if (this.activeWindowValue?.id === update.id) {
      return this.windowUpdateTarget.remove()
    }

    this.activeWindowValue = update

    this.destinationOptionTargets
      .find(({dataset}) => dataset.identity === update.destination_id && dataset.technology === update.technology.name)
      .click()

    this.windowUpdateTarget.remove()
  }

  refreshedDestinationMenuTargetConnected() {
    const activeOption = Array
      .from(this.destinationMenuTarget.querySelectorAll('[data-compose--coordinator-target="destinationOption"]'))
      .find(option => option.hasAttribute('data-active'))

    const optionInNewMenu = Array
      .from(this.refreshedDestinationMenuTarget.querySelectorAll('[data-compose--coordinator-target="destinationOption"]'))
      .find(option => option.dataset.optionValue === activeOption?.dataset?.optionValue)

    if (optionInNewMenu && !optionInNewMenu.hasAttribute('data-active')) {
      this.coordinatingFromSourceToDestination = true
      optionInNewMenu.click()

      this.nextTick(() => this.coordinatingFromSourceToDestination = false, 100)
    }

    this.destinationMenuTarget.replaceWith(this.refreshedDestinationMenuTarget)
    this.refreshedDestinationMenuTarget.setAttribute('data-compose--coordinator-target', 'destinationMenu')

    this.nextTick(() => {
      if(this.destinationMenuTarget.hasAttribute('data-multiple')) {
        if(!optionInNewMenu) {
          this.destinationOptionTargets[0].click()
        }

        return this.show(this.destinationMenuTriggerTarget)
      }

      if (this.destinationOptionTargets.length === 1) {
        this.hide(this.destinationMenuTriggerTarget)
        this.element.setAttribute('data-custom-dropdown-open-value', false)
      } else {
        this.hide(this.inputContainerTarget)
        this.disableNoticeTarget.firstElementChild.textContent = this.translations.compose.unreachable
        this.show(this.disabledNoticeTarget)
      }
    })
  }

  // private

  get compose() {
    return this.application.getControllerForElementAndIdentifier(this.element, 'input')
  }

  get openWindow() {
    return this.findOpenWindowByTechnology('whatsapp')
      || this.findOpenWindowByTechnology('instagram')
      || this.findOpenWindowByTechnology('sms')
      || this.findOpenWindowByTechnology('mercadolibre')
  }

  findOpenWindowByTechnology(technology) {
    return this.windowsValue.find(window => window.technology.name === technology && window.open)
  }
}
