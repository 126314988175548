import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ["button", "menu"]

  onTagClick({ currentTarget }) {
    this.dispatch('insertTag', {
      detail: currentTarget.dataset.tag
    })
  }

  enable() {
    super.enable(this.buttonTarget)
  }

  disable() {
    super.disable(this.buttonTarget)
  }

  onDropdownOpen() {
    this.dispatch('focus')
  }

  onDropdownHide() {
    this.dispatch('blur')
  }

  closeByEscape(event) {
    if(this.isInvisible(this.menuTarget)) return
    this.buttonTarget.click()
  }
}
