import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submit"]

  updateUrl(e) {
    this.element.action = e.target.dataset.url
    this.submitTarget.click()
  }
}
