import ApplicationController from '../application_controller'

import { useDraggable } from "../mixins/useDraggable"

export default class extends ApplicationController {
  static values = {
    placeholder: String,
    label: String,
    focus: Boolean,
    required: { type: Boolean, default: true },
  }

  static targets = [
    'input',
    'placeholder',
    'placeholderInput',
    'requiredInput',
    'footer',
    'reorderButton',
    'label',
    'labelInput',
    'trigger',
    'inputTemplate',
    'switch',
  ]

  static classes = ['drag']

  initialize() {
    super.initialize()

    const optionsToHide = [
      ...(window.selectedInputTypes || []),
      this.element.dataset.inputType
    ]

    this.inputTemplateTargets
      .filter(template => optionsToHide.includes(template.dataset.kind) || optionsToHide.includes(template.dataset.optionValue))
      .forEach(template => template.classList.add('hidden'))
  }

  connect() {
    super.connect()

    if(this.focusValue) {
      this.focus()
    }

    useDraggable(this)
  }

  focus() {
    this.element.setAttribute('data-active', true)
    super.focus(this.labelInputTarget, { moveCursorToEnd: true })
  }

  save() {
    if(this.placeholderInputTarget.value) {
      this.placeholderTarget.innerText = this.placeholderValue = this.placeholderInputTarget.value
    } else {
      this.placeholderTarget.innerText = this.placeholderInputTarget.value = this.placeholderValue
    }

    if(this.labelInputTarget.value) {
      this.labelTarget.innerText = this.labelValue = this.labelInputTarget.value
    } else {
      this.labelTarget.innerText = this.labelInputTarget.value = this.labelValue
    }

    this.element.removeAttribute('data-active')
  }

  restore() {
    this.placeholderInputTarget.value = this.placeholderValue
    this.labelInputTarget.value = this.labelValue

    this.triggerTarget.click()

    this.element.removeAttribute('data-active')
  }

  require() {
    this.requiredInputTarget.checked = true
    this.requiredInputTarget.disabled = true

    this.reorderButtonTarget.classList.remove('group-hover/input:flex')
    this.footerTarget.classList.add('hidden')

    this.requiredValue = true
  }

  enable() {
    this.requiredInputTarget.disabled = false

    this.reorderButtonTarget.classList.add('group-hover/input:flex')
    this.footerTarget.classList.remove('hidden')

    this.requiredValue = false
  }

  remove() {
    this.element.remove()
  }

  // private

  requiredValueChanged() {
    if(this.requiredValue) {
      this.switchTarget.setAttribute('data-disabled', true)
    } else {
      this.switchTarget.removeAttribute('data-disabled')
    }
  }

  afterDragEnabled() {
    this.element.classList.remove("not-draggable")
  }

  afterDragDisabled() {
    this.element.classList.add("not-draggable")
  }
}
