import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [
    'showMoreButton',
    'list'
  ]

  expand() {
    this.showMoreButtonTarget.remove()

    Array.from(this.listTarget.children).forEach((item) => {
      item.classList.add('!flex')
    })
  }

  listTargetConnected() {
    if (this.listTarget.children.length >= 7 && this.hasShowMoreButtonTarget) {
      this.showMoreButtonTarget.querySelector('span').innerText = this.translations.vcards.vcard.show_more.replace('%{n}', this.listTarget.children.length - 6)
      this.show(this.showMoreButtonTarget)
    }
  }
}
