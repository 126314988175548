import Application_controller from '@/controllers/application_controller'

export default class extends Application_controller {
  static targets = [
    'approveButton',
    'rejectButton',
    'rejectionReason',
    'rejectionReasonTemplate',
    'rejectionReasonsList',
    'rejectionReasonItem',
    'rejectionReasonPopover',
    'orLabel',
  ]

  addRejectionReason({ currentTarget }) {
    const { kind } = currentTarget.dataset

    const reason = this.rejectionReasonTemplateTarget.cloneNode(true)

    reason.setAttribute('data-admin--campaign-target', 'rejectionReason')
    reason.setAttribute('data-kind', kind)

    reason.querySelector('[data-title]').innerText = t.admin.campaign.rejection_reasons.rejection_reason[kind].title
    reason.querySelector('[data-description]').innerText = t.admin.campaign.rejection_reasons.rejection_reason[kind].description

    reason.classList.remove('hidden')
    reason.querySelectorAll('input, textarea').forEach(this.enable)

    this.rejectionReasonsListTarget.appendChild(reason)

    this.hide(currentTarget)

    if(this.rejectionReasonItemTargets.every(item => item.classList.contains('hidden'))) {
      this.hide(this.rejectionReasonPopoverTarget)
      this.hide(this.orLabelTarget)
    } else {
      this.show(this.rejectionReasonPopoverTarget)
      this.show(this.orLabelTarget)
    }
  }

  rejectionReasonTargetConnected() {
    this.hide(this.approveButtonTarget)
    this.show(this.rejectButtonTarget)

    this.rejectionReasonPopoverTarget.querySelector('[data-ui--popover-target="trigger"]').textContent = t.admin.campaigns.campaign.add_another_reason
  }

  rejectionReasonTargetDisconnected(target) {
    if(this.rejectionReasonTargets.length === 0) {
      this.show(this.approveButtonTarget)
      this.hide(this.rejectButtonTarget)

      this.rejectionReasonPopoverTarget.querySelector('[data-ui--popover-target="trigger"]').textContent = t.admin.campaigns.campaign.choose_rejection_reason
    }

    this.rejectionReasonItemTargets.find(item => item.dataset.kind === target.dataset.kind).classList.remove('hidden')

    this.show(this.rejectionReasonPopoverTarget)
    this.show(this.orLabelTarget)
  }
}
