import ApplicationController from '../../application_controller'

import { Current } from '../../models/current'

export default class extends ApplicationController {
  static values = {
    credits: Number
  }

  static targets = ['mobileLabel', 'desktopLabel', 'list']

  connect() {
    super.connect()

    this.originalCreditsValue = this.creditsValue
    this.distribution = 'even'
    this.country = JSON.parse(this.valueOfMetaTag('country_json'))

    this.calculateEstimates()
  }

  onCreditBundleChange({ detail: creditBundle }) {
    const bundle = Number.parseInt(creditBundle)
    this.creditsValue = this.originalCreditsValue + bundle

    this.desktopLabelTarget.innerText = this.translations.static.pricing.features.credit.available.replace(
      '%{count}',
      this.numberWithDelimiter(this.creditsValue)
    )
    this.mobileLabelTarget.innerText =  this.numberWithDelimiter(this.creditsValue)

    this.calculateEstimates()
  }

  distributeCredit({ detail: distribution }) {
    this.distribution = distribution
    this.calculateEstimates(this.creditsValue)
  }

  // private

  numberWithDelimiter(number, delimiter = ',') {
    number = number.toString();

    const split = number.split('.');
    split[0] = split[0].replace(
      /(\d)(?=(\d\d\d)+(?!\d))/g,
      '$1' + delimiter
    );
    return split.join('.');
  }

  createListItem(text) {
    const listItem = document.createElement('li')
    const paragraph = document.createElement('p')

    paragraph.innerText = text

    listItem.appendChild(paragraph)

    return listItem
  }

  calculateEstimates(credits = this.creditsValue, distribution = this.distribution) {
    this.listTarget.innerHTML = ''

    if(distribution !== 'whatsapp') {
      this.calculateSMSCredits(credits)
    }

    if(distribution !== 'sms') {
      this.calculateWhatsAppConversationCredits(credits)
    }
  }

  // private

  calculateSMSCredits(credits) {
    const smsCredits = Math.ceil(credits / this.smsCreditCost * this.smsCreditMultiplier)
    this.updateSMSCredit(smsCredits)
  }

  calculateWhatsAppConversationCredits(credits) {
    const whatsappCredits = Math.ceil(
      credits / this.whatsappConversationCreditCost * this.whatsappConversationMultiplier
    )

    this.updateWhatsAppConversationsCredit(whatsappCredits)
  }

  updateSMSCredit(count) {
    const label =  this.translations.static.pricing.features.credit.distribution.sms
      .replace('%{count}', this.numberWithDelimiter(count))
      .replace('%{country}', Current.country.name)

    this.listTarget.appendChild(
      this.createListItem(label)
    )
  }


  updateWhatsAppConversationsCredit(count) {
    this.listTarget.appendChild(
      this.createListItem(
        this.translations.static.pricing.features.credit.distribution.whatsapp_conversations.replace(
          '%{count}',
          this.numberWithDelimiter(count)
        )
      )
    )
  }

  get whatsappConversationMultiplier() {
    const constants = {
      sms: 0,
      whatsapp: 1,
      even: 0.286
    }


    return constants[this.distribution] || constants.even
  }

  get smsCreditMultiplier() {
    const constants = {
      sms: 1,
      whatsapp: 0,
      even: 0.714
    }

    return constants[this.distribution] || constants.even
  }

  get smsCreditCost() {
    const feature = this.country.features.find(feature => feature.identifier === 'credit.sms')
    return feature.cost
  }

  get whatsappConversationCreditCost() {
    const feature = this.country.features.find(feature => feature.identifier === 'credit.whatsapp') || {}
    return feature.cost || 2
  }
}
