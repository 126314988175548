import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    reach: {type: Number, default: 500},
    time: {type: Number, default: 1},
    recommendedPackageId: String
  }

  static targets = ['recommendedLabel', 'row', 'expandableCell', 'agreementCheckbox', 'package', 'creditBundleCell']

  initialize() {
    super.initialize();

    this.country = JSON.parse(this.valueOfMetaTag('country_json'))
    this.recommendedPackageIdValueChanged(this.recommendedPackageIdValue, null)
  }

  switchToMonthlyAgreement() {
    this.agreementCheckboxTarget.checked = false

    this.packageTargets.forEach((target) => {
      this.dispatch('agreement:change', {
        target,
        detail: 'monthly'
      })
    })
  }

  switchToYearlyAgreement() {
    this.agreementCheckboxTarget.checked = true

    this.packageTargets.forEach((target) => {
      this.dispatch('agreement:change', {
        target,
        detail: 'yearly'
      })
    })
  }

  toggleAgreement() {
    if (this.agreementCheckboxTarget.checked) {
      this.switchToYearlyAgreement()
    } else {
      this.switchToMonthlyAgreement()
    }
  }

  onCreditBundleChange({currentTarget}) {
    const {packageId, optionValue} = currentTarget.dataset

    this.element.querySelectorAll("[data-cell-id='credit']").forEach(cell => {
      if (cell.dataset.packageId === packageId) {
        this.dispatch(
          'credit-bundle:change',
          {
            target: cell,
            detail: optionValue
          }
        )

        this.dispatch('credit-bundle:change', {
          target: this.findPackage(packageId),
          detail: optionValue
        })
      }
    })
  }

  updateReach({target}) {
    this.reachValue = target.value
    this.calculateImpact()
  }

  updateTime({target}) {
    this.timeValue = target.value
    this.calculateImpact()
  }

  onPackagePriceChange({ detail }) {
    const recommendedPackage = this.findPackage(this.recommendedPackageIdValue)

    if (detail.packageId === recommendedPackage.dataset.packageId) {
      this.delayed(() => {
        this.packageTargets.forEach((packageTarget, index) => {
          if(packageTarget === recommendedPackage || index === 2) return

          const recommendedPackagePrice = parseInt(recommendedPackage.dataset.total)
          const packagePrice = parseInt(packageTarget.dataset.total)
          const difference = Math.abs(recommendedPackagePrice - packagePrice)

          const hundredDollars = 100000

          if(difference <= hundredDollars) {
            this.selectPackage(packageTarget.dataset.packageId)
            this.deselectPackage(recommendedPackage.dataset.packageId)
          } else {
            this.selectPackage(recommendedPackage.dataset.packageId)
            this.deselectPackage(packageTarget.dataset.packageId)
          }
        })
      }, 300)
    }
  }

  calculateImpact() {
    const impact = Math.ceil(this.reachValue * this.timeValue)
    const credits = impact * this.smsCreditCost

    let cell, bundle;

    this.creditBundleCellTargets.forEach((cellElement) => {
      if (cell) {
        this.dispatch('package:enable', {
          target: this.packageTargets.find(
            (target) => target.dataset.packageId === cell.dataset.packageId
          ),
        })

        return
      }

      if(!cellElement.hasAttribute('data-bundles')) return

      const bundles = cellElement.dataset.bundles.split(',').map(Number)
      const packageCredit = parseInt(
        this.element
          .querySelector(`[data-cell-id='credit'][data-package-id='${cellElement.dataset.packageId}']`)
          .dataset
          .credits
      )

      if (packageCredit >= credits) {
        cell = cellElement
        bundle = 0
      } else if (bundles.find((bundle) => bundle + packageCredit >= credits)) {
        cell = cellElement
        bundle = bundles.find((bundle) => bundle + packageCredit >= credits)
      } else if (!cellElement.hasAttribute('data-contact-required')) {
        bundle = bundles[bundles.length - 1]
        cellElement.querySelector(`[data-option-value='${bundle}']`).click()

        this.dispatch('package:disable', {
          target: this.packageTargets.find(
            (target) => target.dataset.packageId === cellElement.dataset.packageId
          ),
        })
      }
    })

    // if no cell was found, this means that the credits have surpassed 20,000
    // automatically select the the Scale option in that case.
    if (!cell) {
      cell = this.creditBundleCellTargets[this.creditBundleCellTargets.length - 1]
    }

    this.recommendedPackageIdValue = cell.dataset.packageId

    if (!cell.hasAttribute('data-contact-required')) {
      cell.querySelector(`[data-option-value='${bundle || 0}']`).click()
    }
  }

  recommendedPackageIdValueChanged(value, previousValue) {
    if (value === previousValue) return

    const packageIndex = this.findPackageIndex(this.recommendedPackageIdValue)

    if (previousValue) {
      this.disablePackage(previousValue)
    }

    if (packageIndex === 3) {
      this.disableAllPackages()
    } else if (packageIndex === 1) {
      this.enableAllPackages()
    }

    this.selectPackage(this.recommendedPackageIdValue)
  }

  selectPackage(packageId) {
    const packageIndex = this.findPackageIndex(packageId)

    this.rowTargets.forEach((row, index) => {
      const element = row.children[packageIndex]

      element.setAttribute('data-recommended', true)

      if (index === 0 && !element.contains(this.recommendedLabelTarget)) {
        element.querySelector('header').prepend(this.recommendedLabelTarget)
      }

      if (row.dataset.variant === 'dark') {
        this.removeClass(element, 'bg-night-10')
        this.addClass(element, 'bg-lavender', 'border-x-lavender')

        // when the selected option is the middle cell, we need to change the border color of it's siblings
        // such that the cell looks like it's expanding the full width of the table
        if (packageIndex === 2) {
          this.addClass(element.previousElementSibling, 'border-r-lavender')
          this.addClass(element.nextElementSibling, 'border-l-lavender')
        }
      } else {
        this.removeClass(element, 'bg-night-5')

        // We don't want to add the lavender to the row that allows the table to be expanded
        // Because it has a white background on mobile and we don't want to override it
        if(row.hasAttribute('data-expansion')) {
          row.querySelectorAll('ul').forEach((ul, index) => {
            if (index === packageIndex - 1) {
                this.removeClass(ul, 'lg:hidden')
                this.addClass(ul, 'block')
              } else {
                this.removeClass(ul, 'block')
                this.addClass(ul, 'lg:hidden')
              }
            }
          )
          this.addClass(element, 'lg:bg-lavender-light')
        } else {
          this.addClass(element, 'bg-lavender-light')
        }
      }
    })
  }

  deselectPackage(packageId) {
    const packageIndex = this.findPackageIndex(packageId)

    this.rowTargets.forEach((row, index) => {
      const element = row.children[packageIndex]

      element.removeAttribute('data-recommended')

      if (row.dataset.variant === 'dark') {
        this.removeClass(element, 'bg-lavender', 'border-x-lavender')

        // when the selected option is the middle cell, we need to change the border color
        // such that the cell looks like it's expanding the full width of the table
        if (packageIndex === 2) {
          this.removeClass(element.previousElementSibling, 'border-r-lavender')
          this.removeClass(element.nextElementSibling, 'border-l-lavender')
        }
      } else {
        this.removeClass(element, 'bg-lavender-light', 'lg:bg-lavender-light')
      }
    })
  }

  enablePackage(packageId) {
    const packageIndex = this.findPackageIndex(packageId)

    this.rowTargets.forEach((row) => {
      const element = row.children[packageIndex]

      if (row.classList.contains('bg-night-5')) {
        this.removeClass(element, 'bg-night-10')
      } else {
        this.removeClass(element, 'bg-night-5', 'md:bg-night-5')
      }
    })
  }

  disablePackage(packageId) {
    const previousPackageIndex = this.findPackageIndex(packageId)
    const packageIndex = this.findPackageIndex(this.recommendedPackageIdValue)

    this.rowTargets.forEach((row) => {
      const element = row.children[previousPackageIndex]

      element.removeAttribute('data-recommended')

      if (row.dataset.variant === 'dark') {
        if (packageIndex > previousPackageIndex) {
          this.addClass(element, 'bg-night-10')
        }

        this.removeClass(element, 'bg-lavender', 'border-x-lavender')

        if (previousPackageIndex === 2) {
          this.removeClass(element.previousElementSibling, 'border-r-lavender')
          this.removeClass(element.nextElementSibling, 'border-l-lavender')
        }
      } else {
        if (packageIndex > previousPackageIndex) {
          if(row.hasAttribute('data-expansion')) {
            this.addClass(element, 'md:bg-night-5')
          } else {
            this.addClass(element, 'bg-night-5')
          }
        }

        this.removeClass(element, 'bg-lavender-light', 'lg:bg-lavender-light')

        if (previousPackageIndex === 2) {
          this.removeClass(element.previousElementSibling, 'border-r-transparent')
          this.removeClass(element.nextElementSibling, 'border-l-transparent')
        }
      }
    })
  }

  enableAllPackages() {
    this.packageTargets.forEach((target) => {
      this.dispatch('package:enable', {target})
      this.enablePackage(target.dataset.packageId)
    })
  }

  disableAllPackages() {
    this.packageTargets.filter((target) => target.dataset.packageId !== this.recommendedPackageIdValue)
      .forEach((target) => {
        this.dispatch('package:disable', {target})
        this.disablePackage(target.dataset.packageId)
      })
  }

  findPackage(packageId) {
    return this.packageTargets.find((target) => target.dataset.packageId === packageId)
  }

  findPackageIndex(packageId) {
    return parseInt(
      this.rowTargets[0]
        .querySelector(`[data-package-id='${packageId}']`)
        .dataset
        .position
    )
  }

  get smsCreditCost() {
    const feature = this.country.features.find(feature => feature.identifier === 'credit.sms')
    return feature.cost
  }
}
