import LineController from "../journeys/line_controller"
import MessageController from "./message_controller"

export default class extends MessageController {
  static targets = ["trigger"]

  initialize() {
    super.initialize()
    this.currentValue = this.containerTarget.innerText
    this.kind = "wait"
  }

  sync({ detail }) {
    if (
      detail.stepId === this.element.id &&
      detail.period?.length !== 0 &&
      detail.kind
    ) {
      const period = parseInt(detail.period)
      const kind = detail.kind.toLowerCase()

      if(period === 1) {
        this.containerTarget.innerText = `${period} ${this.translations.filter.sentence.periodicity[kind].one}`
      } else {
        this.containerTarget.innerText = this.translations.filter.sentence.periodicity[kind].other.replace("%{other}", period)
      }

      if (detail.commit === true) {
        this.triggerTarget.classList.remove("halo--active")
        this.currentValue = this.containerTarget.innerText

        if(!this.persistedValue) {
          this.dispatch("saved", {
            target: document.documentElement
          })
        }

        this.persistedValue = true
      }
    }
  }
}
