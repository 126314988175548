import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["submitButton", "date", "hour", "scheduleDatePicker", "scheduledAt", "nightHoursConflict"]
  static values = {
    scheduledLabel: String,
    sendNowLabel: String,
    nightHoursConfigured: { type: Boolean, default: false },
    nightHoursStart: String,
    nightHoursEnd: String,
  }

  initialize() {
    this.showSendNowLabel()

    // wait until flatpickr is initialized before setting the date
    setTimeout(() => this.setHourForDate(), 10)
  }

  showSendNowLabel() {
    this.submitButtonTarget.innerText = this.sendNowLabelValue
  }

  showScheduledLabel() {
    this.submitButtonTarget.innerText = this.scheduledLabelValue
  }

  enableSubmitButton() {
    this.enable(this.submitButtonTarget)
  }

  sendNow() {
    this.showSendNowLabel()
    this.enableSubmitButton()
    this.scheduleDatePickerTarget.classList.add("hidden")

    this.disable(this.scheduledAtTarget)
    this.disable(this.hourTarget)
  }

  schedule() {
    this.showScheduledLabel()

    this.submitButtonTarget.disabled = !this.filledInputs
    this.removeClass(this.scheduleDatePickerTarget, "hidden")

    this.enable(this.scheduledAtTarget)
    this.enable(this.hourTarget)
  }

  disableSubmitTillInputsAreFilled() {
    this.submitButtonTarget.disabled = !this.filledInputs
    this.setHourForDate()
  }

  setHourForDate(event) {
    const [hour, minute] = (event?.detail || this.hourTarget.value).split(":")

    this.scheduledAtTarget.value = new Date(Date.parse(`${this.dateTarget.value} ${hour}:${minute}:00`))

    if(this.hasNightHoursTarget) {
      if(this.nightHoursConfiguredValue && this.withinNightHours(hour, minute)) {
        this.removeClass(this.nightHoursConflictTarget, "hidden")
      } else {
        this.addClass(this.nightHoursConflictTarget, "hidden")
      }
    }

  }

  // private

  withinNightHours(hour, minute) {
    const time = new Date(0, 0, 0, hour, minute || 0)
    return time >= this.startsAt && time <= this.endsAt
  }

  get startsAt() {
    const [startsAtHour, startsAtMinute] = this.nightHoursStartValue.split(":").map((value) => parseInt(value))
    return new Date(0, 0, 0, startsAtHour, startsAtMinute)
  }

  get endsAt() {
    const [endsAtHour, endsAtMinute] = this.nightHoursEndValue.split(":").map((value) => parseInt(value))
    return new Date(0, 0, this.spansTwoDays ? 1 : 0, endsAtHour, endsAtMinute)
  }

  get spansTwoDays() {
    const [startsAtHour, _] = this.nightHoursStartValue.split(":").map((value) => parseInt(value))
    const [endsAtHour, __] = this.nightHoursEndValue.split(":").map((value) => parseInt(value))

    return startsAtHour > endsAtHour
  }

  get filledInputs() {
    return [this.dateTarget, this.hourTarget].every(
      (input) => input.value.trim().length > 0
    )
  }
}
