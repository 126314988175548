import iro from "@jaames/iro"

export const useColorPickerVisibilityControls = (
  controller,
  {
    pickerTarget,
    beforeColorPickerShow = () => {},
    afterColorPickerShow = () => {},
    onColorPickerHide = () => {},
  }
) => {
  Object.assign(controller, {
    toggleColorPickerControls(e) {
      if (
        pickerTarget.contains(e.target) === false &&
        pickerTarget.classList.contains("hidden") === false
      ) {
        pickerTarget.classList.add("hidden")
        onColorPickerHide()
        return
      }

      if (pickerTarget.classList.contains("hidden")) {
        beforeColorPickerShow()
        pickerTarget.classList.remove("hidden")
        afterColorPickerShow()
      }
    },
    hideColorPicker({ target }) {
      if (
        this.element.contains(target) === false &&
        pickerTarget.classList.contains("hidden") === false
      ) {
        pickerTarget.classList.add("hidden")
        onColorPickerHide()
      }
    },
  })
}
