import { Controller } from "@hotwired/stimulus"

import {useMoveCursorToEndOfInput} from "./mixins/useMoveCursorToEndOfInput"

export default class extends Controller {
  static targets = [
    "industriesSelect",
    "customIndustry",
    "customIndustryInput",
    "customIndustryButton",
    "resetIndustrySelectButton",
    "utmInput",
  ]

  connect() {
    useMoveCursorToEndOfInput(this)
    super.connect()

    this.setUTMFields()
  }

  showCustomIndustryField() {
    this.industriesSelectTarget.classList.add("hidden")

    this.customIndustryTarget.classList.remove("hidden")
    this.customIndustryInputTarget.disabled = false
    this.moveCursorToEndOfInput(this.customIndustryInputTarget, { focus: true })

    this.resetIndustrySelectButtonTarget.click()
  }

  hideCustomIndustryField() {
    this.industriesSelectTarget.classList.remove("hidden")

    this.customIndustryTarget.classList.add("hidden")
    this.customIndustryInputTarget.disabled = true
    this.customIndustryInputTarget.value = ""
  }

  setUTMFields() {
    const cookie = document.cookie.replace(/(?:(?:^|.*;\s*)utm_params\s*=\s*([^;]*).*$)|^.*$/, "$1")

    if (!cookie) {
      return
    }

    const storedUTMValue = JSON.parse(cookie)

    Object.entries(storedUTMValue).forEach(([key, value]) => {
      if(this.utmInputTargets.find(input => input.dataset.kind === key)) {
        this.utmInputTargets.find(input => input.dataset.kind === key).value = value
      }
    })
  }
}
