import PaneController from "../../journeys/pane_controller"

export default class extends PaneController {
  static values = {
    periodKind: { type: String, default: "Minutes" },
    persistedNames: { type: Array, default: [] },
    activeJourneysCount: Number,
  }

  static targets = ["journey", "journeysList", "addButton", "template", "submit"]

  initialize() {
    super.initialize()
    this.journeyNames = this.persistedNamesValue
    this.persistedNames = this.persistedNamesValue
  }

  sync(commit = false) {
    this.dispatch("sync", {
      detail: {
        commit,
        stepId: this.stepIdValue,
        journeyNames: this.persistedNames
      },
      target: document.documentElement,
    })
  }

  saveAndHide() {
    this.persistedNames = this.journeyNames
    this.clearQueue()
    this.sync(true)
    this._hide()

    this.element.setAttribute("data-saved", "true")

    this.journeysMarkedToRemove.forEach(journey => {
      this.dispatch("delete", {target: journey})
    })

    if(this.activeJourneys.length === 1) {
      this.dispatch("prevent-remove", {
        target: this.activeJourneys[0]
      })
    }

    setTimeout(() => {
      this.activeJourneys.forEach(journey => journey.setAttribute("data-saved", ""))
    }, 10)
  }

  cancel() {
    this._abort()
    this._hide()

    this.journeyNames = this.persistedNames

    setTimeout(this.clearQueue, 1000)
  }

  syncValues() {
    if (this.hasUnsavedChanges) {
      window.paneId = this.element.id
    }
  }

  _abort() {
    this.dispatch("sync:abort", {
      detail: this.stepIdValue,
      target: document.documentElement,
    })

    this.journeysMarkedToRemove.forEach(journey => {
      this.dispatch("save", {target: journey})
    })

    setTimeout(() => {
      this.activeJourneys.forEach(journey => {
        this.dispatch("remove-not-persisted", {target: journey})
      })

      this.activeJourneys
        .filter(journey => !journey.hasAttribute("data-saved"))
        .forEach(journey => journey.remove())
    }, 1)

    setTimeout(() => {
      if(this.activeJourneys.length === 1) {
        this.dispatch("prevent-remove", {
          target: this.activeJourneys[0]
        })
      } else {
        this.activeJourneys.forEach(journey => {
          this.dispatch("allow-remove", {target: journey})
        })
      }
    }, 10)

    super._abort()
  }

  hidePane(e) {
    if (
      this.element.contains(e.target) ||
      Array.from(document.querySelectorAll("#toast")).some((toast) =>
        toast.contains(e.target)
      ) ||
      this.invisible ||
      e.target.id.split("_trigger")[0] === this.stepIdValue
    )
      return

    if(this.element.hasAttribute('data-saved')) {
      super.hidePane(e)
    } else if(this.activeJourneysCountValue === 0) {
      this.saveAndHide()
    } else {
      this.openModalButtonTarget.click()
    }
  }

  onJourneySelected({ currentTarget }) {
    this.addButtonTarget.classList.remove("hidden")
    this.submitTarget.disabled = false

    this.journeyNames = [...new Set([...this.journeyNames, currentTarget.dataset.name])]
  }

  addJourney() {
    const cloned = this.element.querySelector("[data-template]").cloneNode(true)
    cloned.classList.remove("hidden")
    cloned.removeAttribute("data-template")
    cloned.setAttribute("data-step--stop--pane-target", "journey")

    this.journeysListTarget.appendChild(cloned)

    this.dispatch("allow-remove", {
      target: this.defaultJourney
    })
  }

  markJourneyForRemoval({ detail: name }) {
    this.journeyNames = this.journeyNames.filter(journeyName => journeyName !== name)

    setTimeout(() => {
      if(this.activeJourneys.length === 1) {
        this.dispatch("prevent-remove", {
          target: this.activeJourneys[0]
        })
      }
    }, 1)
  }

  syncJourneys() {
    if(this.activeJourneys.length === 1) {
      this.dispatch("prevent-remove", {
        target: this.activeJourneys[0]
      })
    }
  }

  get defaultJourney() {
    return this.journeyTargets[0]
  }

  get hasUnsavedChanges() {
    return this.persistedNames.sort().toString() !== this.journeyNames.sort().toString()
  }

  get journeysMarkedToRemove() {
    return this.journeyTargets.filter(journey => journey.hasAttribute("data-marked-for-remove"))
  }

  get activeJourneys() {
    return this.journeyTargets.filter(journey => !journey.hasAttribute("data-marked-for-remove"))
  }
}
