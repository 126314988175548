import CopyController from '../copy_to_clipboard_controller'

export default class extends CopyController {
  static targets = ['container', 'duplicate', 'duplicateTrigger', 'duplicatePopover']

  onDuplicatePopoverToggle({ detail: isOpen }) {
    if(isOpen) {
      this.containerTarget.classList.remove('group-hover/copy:flex')
    } else {
      this.containerTarget.classList.add('group-hover/copy:flex')
    }
  }

  removeDuplicate({ currentTarget }) {
    this.nextTick(() => {
      currentTarget.closest('[data-audience--attribute-copy-target="duplicate"]')?.remove()
    })
  }

  duplicateTargetDisconnected() {
    this.duplicateTriggerTarget.querySelector('[data-text]').textContent = this.duplicateTargets.length

    if(this.duplicateTargets.length === 0) {
      this.duplicateTriggerTarget.click()
      this.duplicateTriggerTarget.classList.add('hidden')
      this.duplicatePopoverTarget.remove()
    }
  }
}
