import {Picker, init} from 'emoji-mart'
import data from '@emoji-mart/data'

import ApplicationController from '../../application_controller'
import {Current} from '../../models/current'

export default class extends ApplicationController {
  static values = {
    size: { type: Number, default: 24 },
    perLine: { type: Number, default: 9 }
  }

  static targets = [
    'menu',
    'button'
  ]

  initialize() {
    this.onEmojiSelect = this.onEmojiSelect.bind(this)

    super.initialize()
  }

  connect() {
    init({data})
    this.buildAndAppendPicker()

    super.connect()
  }

  onDropdownOpen() {
    this.focus(this.pickerElement.querySelector('input'), {moveCursorToEnd: true})
    this.dispatch('opened')
  }

  onDropdownHide() {
    // this.menuTarget.innerHTML = ''
    // this.buildAndAppendPicker()
  }

  onEmojiSelect(emoji) {
    this.dispatch('selected', {
      detail: emoji
    })

    this.buttonTarget.click()
  }

  enable() {
    super.enable(this.buttonTarget)
  }

  disable() {
    super.disable(this.buttonTarget)
  }

  hideIfOpen() {
    if(this.isInvisible(this.menuTarget)) return

    this.dispatch('hidden')
    this.buttonTarget.click()
  }

  // private

  buildAndAppendPicker() {
    this.menuTarget.appendChild(this.pickerObject)
    this.overridePickerDefaultStylesFromShadowRoot()
    this.setupEscapeListener()
  }


  overridePickerDefaultStylesFromShadowRoot() {
    setTimeout(() => {
      const style = document.createElement('style')
      style.innerHTML = this.styleOverrides

      this.pickerElement.appendChild(style)

      this.pickerElement.querySelectorAll('.category').forEach(category => {
        category.firstElementChild.classList.add('caption')
      })
    }, 200)
  }

  // Emoji-mart stops the propagation of Escape keydown event
  // Which prevents us from closing the component when the user presses the Escape key
  // When the input blurs as a result of clicking inside the picker
  // We don't want to close the picker, only when the user presses the Escape key
  setupEscapeListener() {
    this.pickerElement.addEventListener('click', () => {
      this.clickedInside = true
      this.nextTick(() => this.clickedInside = false, 100)
    })

    // this.nextTick(() => {
    //   this.pickerElement.querySelector('input').addEventListener('blur', () => {
    //     this.nextTick(() => {
    //       if (!this.clickedInside) {
    //         this.dispatch('hidden')
    //
    //         if(this.isVisible(this.menuTarget)) {
    //           this.buttonTarget.click()
    //         }
    //       }
    //     }, 100)
    //   })
    // }, 200)
  }

  get pickerElement() {
    return this.element.querySelector("em-emoji-picker").shadowRoot
  }

  get pickerObject() {
    return new Picker({
      onEmojiSelect: this.onEmojiSelect,
      theme: 'light',
      dynamicWidth: true,
      locale: Current.locale,
      previewPosition: 'none',
      skinTonePosition: 'none',
      emojiSize: this.sizeValue,
      perLine: this.perLineValue
    })
  }

  get styleOverrides() {
    return  `
      .caption {
        font-size: 0.875rem;
        line-height: 1.25rem; 
        letter-spacing: 0.025em; 
        text-transform: uppercase; 
        color: var(--night-40)
      }
      
      input {
        border-radius: 0.75rem !important; 
        border: 1px !important; 
        border-color: var(--lavender-light) !important;
        outline-style: none !important; 
        font-size: 1rem !important;
        line-height: 1.5rem !important; 
        transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 300ms; 
        transition-duration: 100ms;
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important; 
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;  
      }
      
      input:focus {
        outline: none; /* focus:outline-none */
        border-color: var(--indigo-60) !important; /* focus:border-indigo-60 */
        box-shadow: 0 0 0 4px var(--lavender-light) !important; /* focus:ring-4 focus:ring-lavender-light */
      }
      
      input:hover {
       border-color: var(--night-20) !important; /* hover:border-night-20 */
      }
      
      span.icon { display: none !important; }
      
      #nav .bar {
      height: 1px !important;
      border-radius: 0 !important;
      background-color: var(--tiger) !important;
     }
      `
  }
}
