import ButtonController from './button_controller'

export default class extends ButtonController {
  static targets = ['titleInput', 'numberInput']

  connect() {
    super.connect()
  }

  validate() {
    if(this.isValid && !this.dispatchedEvent) {
      this.element.setAttribute('data-valid', '')

      this.dispatchedEvent = true
      this.dispatch('valid')

      this.enable(
        this.element.querySelector('[data-button="remove"]')
      )
    } else if(this.isInvalid && this.titleInputTarget.value.trim().length === 0 && this.numberInputTarget.value.trim().length === 0 ) {
      this.dispatchedEvent = false
      this.dispatch('invalid')
    }
  }

  focus() {
    if(this.titleInputTarget.value.trim().length === 0) {
      super.focus(this.titleInputTarget)
    } else {
      super.focus(this.numberInputTarget)
    }
  }

  focusNumberInput() {
    super.focus(this.numberInputTarget)
  }

  reset() {
    this.titleInputTarget.value = ''
    this.numberInputTarget.value = ''

    this.dispatchedEvent = false
  }

  toObject() {
    return {
      type: 'phone',
      text: this.titleInputTarget.value.trim(),
      title: this.titleInputTarget.value.trim(),
      number: this.numberInputTarget.value.trim(),
      index: Array.from(this.element.parentElement.children).indexOf(this.element)
    }
  }

  get isValid() {
    return this.titleInputTarget.value.trim().length > 0 && this.numberInputTarget.value.trim().length > 0
  }
}
