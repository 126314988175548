import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { limit: Number }
  static targets = [ "input", "counter" ]

  connect() {
    this.updateCounter()
  }

  updateCounter() {
    this.inputTarget.value = this.inputTarget.value.substring(0, this.limitValue)
    this.counterTarget.textContent = this.limitValue - this.inputTarget.value.length
  }
}
