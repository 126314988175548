import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["requirementCheckbox", "continueButton", "link"]

  requirementFulfilled() {
    if(this.requirementCheckboxTargets.every(checkbox => checkbox.checked)) {
      this.continueButtonTarget.disabled = false
    } else {
      this.continueButtonTarget.disabled = true
    }
  }

  continue() {
    this.linkTarget.click()
  }
}
