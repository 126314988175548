import Flatpickr from "stimulus-flatpickr"

import { Spanish } from "flatpickr/dist/l10n/es.js";
import { english } from "flatpickr/dist/l10n/default.js";

export default class extends Flatpickr {
  locales = {
    es: Spanish,
    en: english
  };

  static classes = ["calendar"]

  connect() {
    this.config = {
      prevArrow:
        "<svg class='rotate-90 stroke-night-60 mx-1.5' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'><path  stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/></svg>",
      nextArrow:
        "<svg class='-rotate-90 stroke-night-60 mx-1.5' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'><path  stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/></svg>",
      appendTo: this.element.parentElement,
      positionElement: this.element.parentElement,
      dateFormat: "Y-m-d",
      altInput: true,
      altFormat: "d M Y",
      locale: this.userLocale,
    }

    super.connect()
    this.calendarContainerTarget.removeAttribute("style")
  }

  open() {
    setTimeout(() => {
      this.calendarContainerTarget.removeAttribute("style")
      this.calendarContainerTarget.classList.add(...this.calendarClasses)
    }, 0)
  }

  // private

  get userLocale() {
    return document.querySelector('meta[name="user-locale"]').getAttribute("value")
  }
}
