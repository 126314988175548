import ApplicationController from '../application_controller'

import { Current } from '../models/current'
import { useUpdatableTimestamp } from "../mixins/useUpdatableTimestamp"
import { useLineNotifiers } from "../mixins/useLineNotifiers"

export default class extends ApplicationController {
  static values = {
    id: String,
    timestamp: String,
    url: String,
    groupId: String,
    groupSize: { type: Number, default: 1 },
  }

  static targets = [
    'timestamp',
    'shareButton',
    'deleteButton',
    'menu',
  ]

  connect() {
    super.connect()

    if (this.element.getAttribute('data-dropdown-open-value') === 'true') {
      this.element.scrollIntoView({
        behaviour: 'smooth',
        block: 'nearest',
      })
    }

    if(Current.user.role.agent) {
      this.element.deleteButtonTargets.forEach(element => element.remove())
    }

    useUpdatableTimestamp(this)
    useLineNotifiers(this)

    this.updateTimestampEveryMinute()
  }

  disconnect() {
    clearInterval(this.timestampInterval)

    this.dispatch('hide', {
      target: document.getElementById('track-event-modal'),
      detail: this.idValue,
    })
  }

  updateTimestampEveryMinute() {
    this.timestampInterval = setInterval(() => {
      this.timestampTarget.innerHTML = this.timeAgoInWordsWithParsing(this.timestampValue)
    }, 30000)
  }

  moveInboxToSavepoint() {
    const inbox = document.getElementById('chat')

    if (inbox) {
      return this.dispatch('scroll-to-view', {
        target: document.getElementById('chat'),
        detail: this.element.id,
      })
    }

    Turbo.visit(this.urlValue)
  }

  share({ currentTarget }) {
    currentTarget.blur()

    const url = new URL(document.location.href)
    url.searchParams.set('activity', this.element.id.split('_').pop())

    navigator.clipboard.writeText(url.toString())

    clearTimeout(this.timeout)
    this.shareButtonTarget.innerText = this.translations.track.events.event.options.shared

    this.timeout = this.nextTick(() => {
      this.shareButtonTarget.innerText = this.translations.track.events.event.options.share
    }, 3000)
  }

  showModal() {
    this.dispatch('show', {
      target: document.getElementById('track-event-modal'),
      detail: {
        id: this.idValue,
        size: this.groupSizeValue,
        groupId: this.groupIdValue,
      }
    })
  }

  showLine() {
    this.element.setAttribute('data-line-active', true)
    this.moveLineToThisElement()
  }

  resetTabs({ detail }) {
    this.element.setAttribute('data-tabs-index-value', 0)
    this.element.removeAttribute('data-line-active')
  }
}
