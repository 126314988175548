import { Controller } from "@hotwired/stimulus"

import { useColorPickerVisibilityControls } from "../../mixins/useColorPickerVisibilityControls"
import {
  useCenterAlignment,
  useLeftAlignment,
  useRightAlignment,
} from "../../mixins/tools/useAlignment"
import { useMoveCursorToEndOfInput } from "../../mixins/useMoveCursorToEndOfInput"
import { useURLValidations } from "../../mixins/useURLValidations"

export default class extends Controller {
  static values = {
    bold: { type: Boolean, default: false },
    italic: { type: Boolean, default: false },
    defaultColor: { type: String, default: "#000000" },
    color: { type: String, default: "#000000" },
    fontSizes: Object,
    alignment: { type: String, default: "center" },
    content: String,
  }

  static classes = ["active", "inactive", "linkError"]
  static targets = [
    "container",
    "activeAlignment",
    "menu",
    "alignmentButton",
    "alignmentInput",
    "preview",
    "colorPickerControls",
    "colorInput",
    "boldButton",
    "italicButton",
    "linkButton",
    "addLinkButton",
    "editLinkContainer",
    "linkInput",
    "newLinkContainer",
    "colorButton",
  ]

  initialize() {
    this.allowSync = true
    this.linkHighlighted = false
    this.colorHighlighted = false

    this.element.classList.add(...this.inactiveClasses)
    this.element.classList.remove(...this.activeClasses)

    this.linkInputListener = this.linkInputListener.bind(this)
    // Trix takes some time to load custom configuration
    // Replace content To enforce styles
    setTimeout(() => (this.trix.value = this.contentValue), 0)

    this.setupTrix()

    this.trixEditor.activateAttribute("foregroundColor")
  }

  linkInputListener(e) {
    if (!this.linkHighlighted) {
      this.highlightSelectionAndSaveSelectionRange()

      this.linkHighlighted = true
      this.linkInputTarget.focus()
    }
  }

  changeColor({ detail }) {
    this.colorValue = detail
    this.trix.focus()
  }

  connect() {
    this.linkInputTarget.addEventListener("focus", this.linkInputListener)
    this.colorButtonTarget
      .querySelector("input")
      .addEventListener("focus", () => {
        if (!this.colorHighlighted) {
          this.highlightSelectionAndSaveSelectionRange()
          this.colorButtonTarget.querySelector("input").focus()

          this.colorHighlighted = true
        }
      })

    useMoveCursorToEndOfInput(this)
    useURLValidations(this)

    useColorPickerVisibilityControls(this, {
      pickerTarget: this.colorPickerControlsTarget,
      beforeColorPickerShow:
        this.highlightSelectionAndSaveSelectionRange.bind(this),
      onColorPickerHide: this.removeHighlightFromSelection.bind(this),
    })

    useLeftAlignment(this, {
      on: this.trix,
      applyClasses: ["text-left"],
      removeClasses: ["text-center", "text-right"],
      afterAlignment: () => this.syncAlignmentTo("left"),
    })

    useCenterAlignment(this, {
      on: this.trix,
      applyClasses: ["text-center"],
      removeClasses: ["text-left", "text-right"],
      afterAlignment: () => this.syncAlignmentTo("center"),
    })

    useRightAlignment(this, {
      on: this.trix,
      applyClasses: ["text-right"],
      removeClasses: ["text-center", "text-left"],
      afterAlignment: () => this.syncAlignmentTo("right"),
    })

    this.syncAlignment()

    this.element.classList.add(...this.inactiveClasses)
    this.element.classList.remove(...this.activeClasses)
  }

  saveLinkOnEnter(e) {
    this.addingLink = true

    Promise.resolve()
      .then(() => {
        if (e.key === "Enter" && this.linkInputTarget.value.trim() !== "0") {
          if (this.addLinkButtonTarget.classList.contains("hidden")) {
            this.saveChangesToSelectedLink()
          } else {
            this.addLink()
          }
        }
      })
      .then(() => {
        // trix also listens for Enter keypress that will enter a new line
        // which results in removing the newly linked to a url since it's within the selection
        setTimeout(() => (this.addingLink = false), 3000)
      })
  }

  toggleBold() {
    this.boldValue = !this.boldValue
    if (this.trixEditor.attributeIsActive("bold")) {
      this.trixEditor.deactivateAttribute("bold")
    } else {
      this.trixEditor.activateAttribute("bold")
    }
  }

  toggleItalic() {
    if (this.trixEditor.attributeIsActive("italic")) {
      this.trixEditor.deactivateAttribute("italic")
    } else {
      this.trixEditor.activateAttribute("italic")
    }
  }

  trixKeypressListener(e) {
    this.removeHighlightFromSelection()
    this.syncBoldButtonWithCursorLocationAttribute()
    this.syncItalicButtonWithCursorLocationAttribute()
    this.syncLinkButtonWithCursorLocationAttribute()
    this.syncColorPickerWithCursorLocationAttribute()

    if (this.colorPickerControlsTarget.classList.contains("hidden") === false) {
      this.colorPickerControlsTarget.classList.add("hidden")

      this.removeHighlightFromSelection()
    }

    // skip this pass if the link has not yet been added to the editor
    if (e.key === "Enter" && e.type === "keyup" && !this.addingLink) {
      this.trixEditor.recordUndoEntry("newLine")
      this.trixEditor.insertLineBreak()
    }

    const defaultLabel = this.fontSizesValue.p.label

    const activeFontSizeFilter = Object.values(this.fontSizesValue).find(
      (valueObject) => this.trix.editor.attributeIsActive(valueObject.label)
    )

    this.fontSizeDropdownLabelContainer.innerText = activeFontSizeFilter
      ? activeFontSizeFilter.label.split("_").join(" ")
      : defaultLabel
  }

  syncSelection() {
    const range = this.trixEditor.getSelectedRange()

    // the OR is for cases when the cursor is within a href but has not selected anything yet.
    // example, the text "hellotext" is linked to www.hellotext.com and the cursor is within the text
    // i.e hell|otext. In this case the link_attachment button needs to be enabled so that business
    // can update or remove the link

    if (
      this.trixEditorDocument.getStringAtRange(range).toString().length > 0 ||
      this.trix.editor.attributeIsActive("href")
    ) {
      this.linkButtonTarget.classList.replace(
        "text-night-40",
        "hover:text-tiger"
      )
      this.linkButtonTarget.disabled = false

      this.selectionRange = range
    } else {
      this.linkButtonTarget.classList.replace(
        "hover:text-tiger",
        "text-night-40"
      )
      this.linkButtonTarget.disabled = true
    }
  }

  toggleLinkContainer() {
    const piece = this.trixEditorDocument.getPieceAtPosition(
      this.trixEditor.getPosition()
    )

    if (piece.attributes.has("href")) {
      this.linkInputTarget.value = piece.attributes.get("href")
      this.addLinkButtonTarget.classList.add("hidden")
      this.editLinkContainerTarget.classList.remove("hidden")

      const indexOfPiece = this.trixEditorDocument
        .toString()
        .indexOf(piece.toString())
      const textRange = [indexOfPiece, indexOfPiece + piece.length]

      this.trixEditor.setSelectedRange(textRange)
      this.trixEditor.activateAttribute("frozen")

      this.selectionRange = textRange
    } else {
      this.addLinkButtonTarget.classList.remove("hidden")
      this.editLinkContainerTarget.classList.add("hidden")
    }

    this.newLinkContainerTarget.classList.toggle("hidden")

    if (this.newLinkContainerTarget.classList.contains("hidden") === false) {
      this.moveCursorToEndOfInput(this.linkInputTarget, { focus: true })
    }
  }

  saveChangesToSelectedLink() {
    this.removeOldLink(true)
  }

  unlinkText() {
    this.removeOldLink()
  }

  changeFontSizeForElements({ currentTarget }) {
    Object.values(this.fontSizesValue).forEach((objectValue) =>
      this.trixEditor.deactivateAttribute(objectValue.label)
    )

    this.trixEditor.activateAttribute(
      this.fontSizesValue[currentTarget.dataset.entry].label
    )
  }

  replaceWithSynced({ detail }) {
    this.allowSync = false

    Promise.resolve()
      .then(() => {
        this.dispatch("color:change", {
          target: this.colorPickerControlsTarget,
          detail: detail.color,
        })

        this.alignmentValue = detail.alignment
        this.syncAlignment(false)

        console.log(detail.trix.value)
        this.trix.value = detail.trix.value
      })
      .then(() => (this.allowSync = true))
  }

  syncStylesWithDropdownState() {
    setTimeout(() => {
      if (this.menuTarget.classList.contains("hidden")) {
        this.element.classList.add(...this.inactiveClasses)
        this.element.classList.remove(...this.activeClasses)
      } else {
        this.element.classList.remove(...this.inactiveClasses)
        this.element.classList.add(...this.activeClasses)
      }
    }, 10)
  }

  toggleToolbar(e) {
    if (
      this.menuTarget.classList.contains("hidden") === false &&
      this.element.contains(e.target)
    ) {
      return
    }

    this.menuTarget.classList.toggle("hidden")
    this.syncStylesWithDropdownState()
  }

  hideToolbar(e) {
    if (
      this.element.contains(e.target.parentElement) === false &&
      this.menuTarget.contains(e.target.parentElement) === false &&
      this.menuTarget.classList.contains("hidden") === false &&
      document.activeElement.dataset.trixId === this.trix.dataset.trixId
    ) {
      this.menuTarget.classList.add("hidden")
      this.syncStylesWithDropdownState()

      this.allowSync = true
      this.sync()
      this.resetLinkModalContent()
      if (this.selectionRange) {
        this.removeHighlightFromSelection()
      }
    }
  }

  hidePicker() {
    this.colorPickerControlsTarget.classList.add("hidden")
  }

  addLink() {
    if (this.invalidURL(this.linkInputTarget.value)) {
      this.linkInputTarget.classList.add(...this.linkErrorClasses)
      return
    } else {
      this.linkInputTarget.classList.remove(...this.linkErrorClasses)
    }

    if (this.selectionRange) {
      this.trixEditor.setSelectedRange(this.selectionRange)
      this.trixEditor.activateAttribute("href", this.linkInputTarget.value)
      this.removeHighlightFromSelection()
    }

    this.linkHighlighted = false
    this.newLinkContainerTarget.classList.add("hidden")
    this.linkInputTarget.value = "https://www."
    this.sync()
  }

  // private

  sync() {
    if (!this.allowSync) return

    this.dispatch("sync", {
      target: Array.from(
        document.querySelectorAll(
          `[data-hero-id='${this.element.dataset.heroId}']`
        )
      ).find((layoutSection) => layoutSection !== this.element),
      detail: this.currentState,
    })
  }

  syncAlignmentTo(alignment) {
    this.changeAlignmentActiveClassTo(
      this.element.querySelector(`[data-align='${alignment}']`)
    )
    this.alignmentInputTarget.value = alignment
    this.sync()
  }

  changeAlignmentActiveClassTo(button) {
    this.alignmentButtonTargets.forEach((alignmentButton) => {
      if (alignmentButton === button) {
        alignmentButton.classList.add("text-tiger")
      } else {
        alignmentButton.classList.remove("text-tiger")
      }
    })
  }

  colorValueChanged() {
    this.colorInputTarget.value = this.colorValue

    this.previewTargets.forEach((previewTarget) => {
      previewTarget.style.backgroundColor = this.colorValue
    })

    if (this.trix) {
      this.trixEditor.activateAttribute("foregroundColor", this.colorValue)
    }
  }

  highlightSelectionAndSaveSelectionRange() {
    const range = this.trixEditor.getSelectedRange()

    if (this.trixEditorDocument.getStringAtRange(range).toString().length > 0) {
      this.trixEditor.setSelectedRange(range)
      this.trixEditor.activateAttribute("highlight")
      this.selectionRange = range
    } else {
      // when there is no selection in the input. create a collapsed selection in the current cursor position
      // that will be used with the selected color from the color wheel
      const position = this.trixEditor.getPosition()
      this.trixEditor.setSelectedRange([position - 1, position + 1])
      this.selectionRange = range
    }
  }

  removeHighlightFromSelection() {
    if (!this.selectionRange) return

    this.trixEditor.setSelectedRange(this.selectionRange)
    this.trixEditor.deactivateAttribute("highlight")

    this.selectionRange = undefined
  }

  setupTrix() {
    this.addFontSizeControlsToTrix(window.popupControlsz.font.base)
    this.setupForegroundColorExtension()

    this.trix = this.element.querySelector("trix-editor")
    this.trix.classList.add("text-center")
  }

  addFontSizeControlsToTrix(base) {
    Object.entries(this.fontSizesValue).forEach(([tagName, valueObject]) => {
      const computedFontSize = base * valueObject.size
      Trix.config.textAttributes[valueObject.label] = {
        tagName: tagName,
        parser: (element) => element.style.fontSize === `${computedFontSize}px`,
        inheritable: true,
      }
    })
  }

  syncBoldButtonWithCursorLocationAttribute() {
    if (this.trixEditor.attributeIsActive("bold")) {
      this.boldButtonTarget.classList.add("text-tiger")
    } else {
      this.boldButtonTarget.classList.remove("text-tiger")
    }
  }

  syncItalicButtonWithCursorLocationAttribute() {
    if (this.trixEditor.attributeIsActive("italic")) {
      this.italicButtonTarget.classList.add("text-tiger")
    } else {
      this.italicButtonTarget.classList.remove("text-tiger")
    }
  }

  syncLinkButtonWithCursorLocationAttribute() {
    if (this.trixEditor.attributeIsActive("href")) {
      this.linkButtonTarget.classList.add("text-tiger")
      this.linkButtonTarget.classList.replace(
        "text-night-40",
        "hover:text-tiger"
      )
      this.linkButtonTarget.disabled = false
    } else {
      this.linkButtonTarget.classList.remove("text-tiger")
    }
  }

  syncColorPickerWithCursorLocationAttribute() {
    // because the color picker is displayed above the trix editor
    // we need to check that the color picker is hidden in order to update the preview targets
    // displayed under the A color icon when the user moves the cursor in inside trix.

    if (this.colorPickerControlsTarget.classList.contains("hidden")) {
      const piece = this.trixEditorDocument.getPieceAtPosition(
        this.trixEditor.getPosition()
      )
      let color = this.defaultColorValue

      if (piece.attributes.has("foregroundColor")) {
        color = piece.attributes.get("foregroundColor")
        this.trixEditor.activateAttribute("foregroundColor", color)
      }

      this.dispatch("color:change", {
        target: this.colorPickerControlsTarget,
        detail: color,
      })

      this.previewTarget.style.backgroundColor = color
    }
  }

  removeOldLink(replaceWithNewLink = false) {
    this.trixEditor.setSelectedRange(this.selectionRange)
    this.trixEditor.deactivateAttribute("frozen")
    this.trixEditor.deactivateAttribute("href")

    if (replaceWithNewLink) {
      this.trixEditor.activateAttribute("href", this.linkInputTarget.value)
    }

    this.selectionRange = undefined
    this.linkInputTarget.value = ""
    this.linkHighlighted = false

    this.newLinkContainerTarget.classList.add("hidden")

    this.addLinkButtonTarget.classList.remove("hidden")
    this.editLinkContainerTarget.classList.add("hidden")

    this.sync()
  }

  resetLinkModalContent() {
    this.addLinkButtonTarget.classList.remove("hidden")
    this.editLinkContainerTarget.classList.add("hidden")
    this.linkInputTarget.value = "https://www."
    this.linkInputTarget.classList.remove(...this.linkErrorClasses)
    this.newLinkContainerTarget.classList.add("hidden")
  }

  get trixEditor() {
    return this.trix.editor
  }

  get trixEditorDocument() {
    return this.trix.editor.getDocument()
  }

  get fontSizeDropdownLabelContainer() {
    return this.element.querySelector(
      '[data-custom-dropdown-target="placeholderText"]'
    )
  }

  syncTrix() {
    this.sync()
  }

  get currentState() {
    return {
      bold: this.boldValue,
      italic: this.italicValue,
      alignment: this.alignmentInputTarget.value,
      color: this.colorValue,
      trix: this.trix,
    }
  }

  // contains configuration option for foregroundColor
  setupForegroundColorExtension() {
    Trix.config.textAttributes.foregroundColor = {
      styleProperty: "color",
      inheritable: 1,
    }
  }

  syncAlignment(enableSyncAfterAlignment = true) {
    this.allowSync = false

    Promise.resolve()
      .then(() => {
        switch (this.alignmentValue) {
          case "left":
            this.alignToLeft({
              currentTarget: this.element.querySelector("[data-align='left']"),
            })
            break
          case "center":
            this.alignToCenter({
              currentTarget: this.element.querySelector(
                "[data-align='center']"
              ),
            })
            break
          case "right":
            this.alignToRight({
              currentTarget: this.element.querySelector("[data-align='right']"),
            })
            break
          case "full_width":
            this.alignToFullWidth({
              currentTarget: this.element.querySelector(
                "[data-align='full_width']"
              ),
            })
            break
        }
      })
      .then(() => (this.allowSync = enableSyncAfterAlignment))
  }
}
