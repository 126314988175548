import ApplicationController from '../../application_controller'

import { MoneyFormatter } from '../../models/money_formatter'

export default class extends ApplicationController {
  static values = {
    basePrice: String,
    yearlyPrice: Object,
    monthlyPrice: Object,
    metadata: Object,
    total: Number,
    info: Object
  }

  static targets = ['pricingLabel', 'getDemoButton', 'limitOverflowButton', 'pricingContainer', 'limitOverflowMessage']

  initialize() {
    super.initialize();

    this.countryCurrency = JSON.parse(this.valueOfMetaTag('country_currency'))
    this.moneyFormatter = new MoneyFormatter(
      this.countryCurrency.code,
    )

    this.features = { ...this.infoValue.features }

    this.resetCreditBundleAvailableAmount()
    this.calculateTotalValue()
  }

  displayMonthlyPrice() {
    this.agreementType = 'monthly'
    this.calculateTotalValue()
  }

  displayAnnualPrice() {
    this.agreementType = 'yearly'
    this.calculateTotalValue()
  }

  updatePrice({ detail: agreementType }) {
    if (agreementType === 'monthly') {
      this.displayMonthlyPrice()
    } else {
      this.displayAnnualPrice()
    }
  }

  enable() {
    if(this.needsToContactUs) return

    this.show(this.getDemoButtonTarget)
    this.removeClass(this.pricingContainerTarget, 'invisible')

    this.hide(this.limitOverflowButtonTarget)
    this.hide(this.limitOverflowMessageTarget)
  }

  disable() {
    if(this.needsToContactUs) return

    this.hide(this.getDemoButtonTarget)
    this.addClass(this.pricingContainerTarget, 'invisible')

    this.show(this.limitOverflowButtonTarget)
    this.show(this.limitOverflowMessageTarget)
  }

  addCreditBundleToTotal({ detail: creditBundle }) {
    this.features.credit_bundle.available = Number.parseInt(creditBundle)
    this.calculateTotalValue()
  }

  addFeatureToTotalPrice({ detail: { type: feature, ...detail } }) {
    if(this.needsToContactUs) return

    this.setFeature(feature, detail)
    this.calculateTotalValue()

    this.updateMetadataValue(feature, detail.available)
  }

  removeFeatureFromTotalPrice({ detail: { type: feature, ...detail } }) {
    if(this.needsToContactUs) return

    this.setFeature(feature, detail)
    this.calculateTotalValue()

    if(detail.available === 1) {
      this.removeMetadataValue(feature)
    } else {
      this.updateMetadataValue(feature, detail.available)
    }
  }

  updateMetadataValue(feature, available) {
    const url = new URL(this.getDemoButtonTarget.href)
    url.searchParams.set(`addon[${feature}]`, available)

    this.getDemoButtonTarget.href = url.toString()
  }

  removeMetadataValue(feature) {
    const url = new URL(this.getDemoButtonTarget.href)
    url.searchParams.delete(`addon[${feature}]`)

    this.getDemoButtonTarget.href = url.toString()
  }

  totalValueChanged() {
    this.element.dataset.total = this.totalValue

    this.dispatch('price:change', {
      detail: {
        packageId: this.element.dataset.packageId,
        total: this.totalValue
      }
    })

    if(this.needsToContactUs) return
    this.pricingLabelTarget.textContent = this.moneyFormatter.humanizeWithSymbol(this.totalValue * this.countryCurrency.multiplier)
  }

  setFeature(feature, value) {
    this.features[feature].added = value.available
  }

  calculateTotalValue() {
    if(this.metadataValue.action === 'contact_us') return

    this.totalValue = Object.entries(this.features).map(([key, feature]) => {
      if(key === 'credit_bundle') {
        const subunits = this.monthlyAgreement ? feature.monthly_base_price : feature.yearly_base_price
        return subunits * feature.available
      }

      const subunits = (this.monthlyAgreement ? feature.total_monthly_price.subunits : feature.total_yearly_price.subunits) || 0
      let extraSubunits = 0

      if(feature.added) {
        const addedItems = feature.added - feature.available
        extraSubunits = (this.monthlyAgreement ? feature.monthly_base_price : feature.yearly_base_price) * addedItems
      }

      return subunits + extraSubunits
    }).reduce((a, b) => a + b, 0)
  }

  // private

  resetCreditBundleAvailableAmount() {
    // Initially, each package has a specific amount of available credits in their bundle.
    // On the first paint, we don't want the price of credit bundle to be aggregated to the total price.
    // We want the price of the credit bundle to be aggregated to the total price only when the user adds more credits to their bundle.

    this.features.credit_bundle = {
      ...this.features.credit_bundle,
      available: 0
    }
  }

  get needsToContactUs() {
    return this.metadataValue.action === 'contact_us'
  }

  get monthlyAgreement() {
    return this.agreementType === 'monthly'
  }
}
