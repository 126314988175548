import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

import { useRemove } from "../mixins/useRemove";

export default class extends Controller {
  static values = {url: String, updateUrl: Boolean }
  static targets = ["link", "radio"]

  initialize() {
    this.popStateListener = this.popStateListener.bind(this)
  }

  connect() {
    window.addEventListener("popstate", this.popStateListener)

    if(this.updateUrlValue) {
      const url = new URL(this.urlValue)
      history.pushState({}, null, url.toString())

      this.radioTarget.checked = true
      this.dispatch("uncheck", { detail: this.element.dataset.id })
    }

    useRemove(this)
  }

  disconnect() {
    window.removeEventListener("popstate", this.popStateListener)
  }

  popStateListener() {
    const parts = window.location.href.split("/")
    const id = parts[parts.length - 1]

    if(id === this.element.dataset.id) {
      this.linkTarget.click()
    } else if(this.hasRadioTarget) {
      this.radioTarget.checked = false
    }
  }

  changeBrowserUrl() {
    if(window.location.href === this.urlValue) return

    const url = new URL(this.urlValue)
    history.pushState({}, null, url.toString())
  }

  async getSegmentForm(e) {
    await get(e.currentTarget.dataset.url, {
      responseKind: "turbo-stream",
    })

    let event = new CustomEvent(e.currentTarget.dataset.event, {
      bubbles: true,
      cancelable: true,
    })

    window.dispatchEvent(event)
  }

  removeFromPageWithFadeOutTransition({ dataset }) {
    if(dataset.id !== this.element.dataset.id) return

    this.element.classList.add("opacity-0")

    setTimeout(() => {
      history.back()
      this.remove()
    }, 550)
  }

  uncheck() {
    this.radioTarget.checked = false
  }

  uncheckSelf({ dataset }) {
    if(dataset.id !== this.element.dataset.id) {
      this.uncheck()
    }
  }
}
