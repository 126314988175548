import { Controller } from "@hotwired/stimulus"
import Scalable from "scalable"

export default class extends Controller {
  connect() {
    // Main
    if (document.querySelector(".main-hero-scalable")) {
      var mainHeroScalable = new Scalable(".main-hero-scalable", {
        align: "right",
        verticalAlign: "bottom",
        containerHeight: "fixed",
        minScale: 0.8,
        maxScale: 1,
      })
    }
    if (document.querySelector(".main-feature-grow")) {
      var mainFeatureGrow = new Scalable(".main-feature-grow", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "auto",
        maxScale: 1,
      })
    }
    if (document.querySelector(".main-feature-reach")) {
      var mainFeatureReach = new Scalable(".main-feature-reach", {
        align: "center",
        verticalAlign: "bottom",
        maxScale: 1,
      })
    }
    if (document.querySelector(".main-feature-engage")) {
      var mainFeatureEngage = new Scalable(".main-feature-engage", {
        align: "right",
        verticalAlign: "center",
      })
    }
    if (document.querySelector(".main-feature-learn")) {
      var mainFeatureLearn = new Scalable(".main-feature-learn", {
        align: "center",
        verticalAlign: "center",
      })
    }
    if (document.querySelector(".footer-cta-scalable")) {
      var footerCtaScalable = new Scalable(".footer-cta-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "auto",
        maxScale: 1,
        maxWidth: 550,
      })
    }

    // Product
    if (document.querySelector(".product-hero-features")) {
      var productHeroFeatures = new Scalable(".product-hero-features", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "auto",
        maxScale: 1,
      })
    }

    if (document.querySelector(".product-grow")) {
      var productGrow = new Scalable(".product-grow", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "auto",
        maxScale: 1,
      })
    }

    if (document.querySelector(".product-reach")) {
      var productReach = new Scalable(".product-reach", {
        align: "center",
        verticalAlign: "bottom",
        containerHeight: "fixed",
      })
    }

    if (document.querySelector(".product-engage")) {
      var productEngage = new Scalable(".product-engage", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "auto",
        maxScale: 1,
      })
    }

    if (document.querySelector(".product-measure")) {
      var productMeasure = new Scalable(".product-measure", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".product-channels")) {
      var productChannels = new Scalable(".product-channels", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".footer-learn")) {
      var footerLearn = new Scalable(".footer-learn", {
        align: "center",
        containerHeight: "auto",
        maxScale: 1,
      })
    }

    // Grow
    if (document.querySelector(".grow-hero-scalable")) {
      var growHeroScalable = new Scalable(".grow-hero-scalable", {
        align: "right",
        verticalAlign: "bottom",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".grow-capture-popup-scalable")) {
      var growCapturePopupScalable = new Scalable(
        ".grow-capture-popup-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "auto",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".popup-grow-capture-popup-scalable")) {
      var growPopupCapturePopupScalable = new Scalable(
        ".popup-grow-capture-popup-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "auto",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".grow-capture-checkout-scalable")) {
      var growCaptureCheckoutScalable = new Scalable(
        ".grow-capture-checkout-scalable",
        {
          align: "right",
          verticalAlign: "bottom",
          containerHeight: "auto",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".popup-grow-capture-checkout-scalable")) {
      var growPopupCaptureCheckoutScalable = new Scalable(
        ".popup-grow-capture-checkout-scalable",
        {
          align: "right",
          verticalAlign: "bottom",
          containerHeight: "auto",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".grow-capture-social-scalable")) {
      var growCaptureSocialScalable = new Scalable(
        ".grow-capture-social-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "auto",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".popup-grow-capture-social-scalable")) {
      var growPopupCaptureSocialScalable = new Scalable(
        ".popup-grow-capture-social-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "auto",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".grow-capture-qr-scalable")) {
      var growCaptureQRScalable = new Scalable(".grow-capture-qr-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "auto",
        maxScale: 1,
      })
    }

    if (document.querySelector(".popup-grow-capture-qr-scalable")) {
      var growPopupCaptureQRScalable = new Scalable(
        ".popup-grow-capture-qr-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "auto",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".grow-capture-keywords-scalable")) {
      var growCaptureKeywordsScalable = new Scalable(
        ".grow-capture-keywords-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "auto",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".popup-grow-capture-keywords-scalable")) {
      var growPopupCaptureKeywordsScalable = new Scalable(
        ".popup-grow-capture-keywords-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "auto",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".grow-design-scalable")) {
      var growDesignScalable = new Scalable(".grow-design-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "auto",
        maxScale: 1,
      })
    }

    if (document.querySelector(".grow-incentive-scalable")) {
      var growIncentiveScalable = new Scalable(".grow-incentive-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "auto",
        maxScale: 1,
      })
    }

    if (document.querySelector(".grow-fields-scalable")) {
      var growFieldsScalable = new Scalable(".grow-fields-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".grow-steps-scalable")) {
      var growStepsScalable = new Scalable(".grow-steps-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".grow-rules-scalable")) {
      var growRulesScalable = new Scalable(".grow-rules-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "auto",
        maxScale: 1,
      })
    }

    if (document.querySelector(".grow-build-scalable")) {
      var growBuildScalable = new Scalable(".grow-build-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".reach-multiday-scalable")) {
      var reachMultidayScalable = new Scalable(".reach-multiday-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".reach-flash-scalable")) {
      var reachFlashScalable = new Scalable(".reach-flash-scalable", {
        align: "center",
        verticalAlign: "bottom",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".reach-launches-scalable")) {
      var reachLaunchesScalable = new Scalable(".reach-launches-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".reach-sales-scalable")) {
      var reachSalesScalable = new Scalable(".reach-sales-scalable", {
        align: "center",
        verticalAlign: "top",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".reach-shortenlinks-scalable")) {
      var reachShortenlinksScalable = new Scalable(
        ".reach-shortenlinks-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".reach-tags-scalable")) {
      var reachTagsScalable = new Scalable(".reach-tags-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".reach-coupons-scalable")) {
      var reachCouponsScalable = new Scalable(".reach-coupons-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".reach-journeys-cart-scalable")) {
      var reachJourneysCartScalable = new Scalable(
        ".reach-journeys-cart-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".popup-reach-journeys-cart-scalable")) {
      var reachPopupJourneysCartScalable = new Scalable(
        ".popup-reach-journeys-cart-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".reach-journeys-shipping-scalable")) {
      var reachJourneysShippingScalable = new Scalable(
        ".reach-journeys-shipping-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".popup-reach-journeys-shipping-scalable")) {
      var reachPopupJourneysShippingScalable = new Scalable(
        ".popup-reach-journeys-shipping-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".reach-journeys-replenishment-scalable")) {
      var reachJourneysReplenishmentScalable = new Scalable(
        ".reach-journeys-replenishment-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (
      document.querySelector(".popup-reach-journeys-replenishment-scalable")
    ) {
      var reachPopupJourneysReplenishmentScalable = new Scalable(
        ".popup-reach-journeys-replenishment-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".reach-journeys-backinstock-scalable")) {
      var reachJourneysBackInStockScalable = new Scalable(
        ".reach-journeys-backinstock-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".popup-reach-journeys-backinstock-scalable")) {
      var reachPopupJourneysBackInStockScalable = new Scalable(
        ".popup-reach-journeys-backinstock-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".reach-journeys-byoj-scalable")) {
      var reachJourneysBYOJScalable = new Scalable(
        ".reach-journeys-byoj-scalable",
        {
          align: "center",
          verticalAlign: "top",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".popup-reach-journeys-byoj-scalable")) {
      var reachPopupJourneysBYOJScalable = new Scalable(
        ".popup-reach-journeys-byoj-scalable",
        {
          align: "center",
          verticalAlign: "top",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".engage-hero-scalable")) {
      var engageHeroScalable = new Scalable(".engage-hero-scalable", {
        align: "center",
        verticalAlign: "top",
        containerHeight: "auto",
        maxScale: 1,
      })
    }

    if (document.querySelector(".engage-turn-scalable")) {
      var engageTurnScalable = new Scalable(".engage-turn-scalable", {
        align: "center",
        verticalAlign: "top",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".engage-encourage-scalable")) {
      var engageEncourageScalable = new Scalable(".engage-encourage-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "auto",
        maxScale: 1,
      })
    }

    if (document.querySelector(".engage-inbox-scalable")) {
      var engageInboxScalable = new Scalable(".engage-inbox-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".engage-notes-scalable")) {
      var engageNotesScalable = new Scalable(".engage-notes-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".measure-hero-scalable")) {
      var measureHeroScalable = new Scalable(".measure-hero-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".measure-campaigns-scalable")) {
      var measureCampaignsScalable = new Scalable(
        ".measure-campaigns-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".measure-products-scalable")) {
      var measureProductsScalable = new Scalable(".measure-products-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".measure-activity-scalable")) {
      var measureActivityScalable = new Scalable(".measure-activity-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".measure-profile-scalable")) {
      var measureProfileScalable = new Scalable(".measure-profile-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".measure-team-scalable")) {
      var measureTeamScalable = new Scalable(".measure-team-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".channels-hero-scalable")) {
      var channelsHeroScalable = new Scalable(".channels-hero-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".channels-whatsapp-scalable")) {
      var channelsWhatsappScalable = new Scalable(
        ".channels-whatsapp-scalable",
        {
          align: "center",
          verticalAlign: "bottom",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".channels-acquire-scalable")) {
      var channelsAcquireScalable = new Scalable(".channels-acquire-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".channels-migrate-scalable")) {
      var channelsMigrateScalable = new Scalable(".channels-migrate-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "auto",
        maxScale: 1,
      })
    }

    if (document.querySelector(".channels-keywords-scalable")) {
      var channelsKeywordsScalable = new Scalable(
        ".channels-keywords-scalable",
        {
          align: "left",
          verticalAlign: "center",
          containerHeight: "auto",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".integrations-api-scalable")) {
      var integrationsAPIScalable = new Scalable(".integrations-api-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".ecommerce-hero-apparel-scalable")) {
      var ecommerceHeroApparelScalable = new Scalable(
        ".ecommerce-hero-apparel-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".ecommerce-hero-home-scalable")) {
      var ecommerceHeroHomeScalable = new Scalable(
        ".ecommerce-hero-home-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".ecommerce-hero-toys-scalable")) {
      var ecommerceHeroToysScalable = new Scalable(
        ".ecommerce-hero-toys-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".ecommerce-hero-beauty-scalable")) {
      var ecommerceHeroBeautyScalable = new Scalable(
        ".ecommerce-hero-beauty-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".ecommerce-hero-food-scalable")) {
      var ecommerceHeroFoodScalable = new Scalable(
        ".ecommerce-hero-food-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".reach-segments-scalable")) {
      var reachSegmentsScalable = new Scalable(".reach-segments-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".retail-hero-tag-scalable")) {
      var retailHeroTagScalable = new Scalable(".retail-hero-tag-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".retail-hero-phone-scalable")) {
      var retailHeroPhoneScalable = new Scalable(
        ".retail-hero-phone-scalable",
        {
          align: "center",
          verticalAlign: "bottom",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".retail-encourage-scalable")) {
      var retailEncourageScalable = new Scalable(".retail-encourage-scalable", {
        align: "right",
        verticalAlign: "bottom",
        containerHeight: "auto",
        maxScale: 1,
      })
    }

    if (document.querySelector(".retail-discounts-scalable")) {
      var retailDiscountsScalable = new Scalable(".retail-discounts-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "auto",
        maxScale: 1,
      })
    }

    if (document.querySelector(".retail-sales-scalable")) {
      var retailSalesScalable = new Scalable(".retail-sales-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "fixed",
        maxScale: 1,
      })
    }

    if (document.querySelector(".packaged-hero-track-scalable")) {
      var packagedHeroTrackScalable = new Scalable(
        ".packaged-hero-track-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".packaged-reward-scalable")) {
      var packagedRewardScalable = new Scalable(".packaged-reward-scalable", {
        align: "center",
        verticalAlign: "center",
        containerHeight: "auto",
        maxScale: 1,
      })
    }

    if (document.querySelector(".entertainment-tickets-scalable")) {
      var entertainmentTicketsScalable = new Scalable(
        ".entertainment-tickets-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "auto",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".entertainment-earlybird-scalable")) {
      var entertainmentEarlyBirdScalable = new Scalable(
        ".entertainment-earlybird-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".nonprofit-hero-bag-scalable")) {
      var nonProfitHeroBagScalable = new Scalable(
        ".nonprofit-hero-bag-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".nonprofit-giveaways-scalable")) {
      var nonProfitGiveawaysScalable = new Scalable(
        ".nonprofit-giveaways-scalable",
        {
          align: "center",
          verticalAlign: "center",
          containerHeight: "fixed",
          maxScale: 1,
        }
      )
    }

    if (document.querySelector(".demo-phone-scalable")) {
      var demoPhoneScalable = new Scalable(".demo-phone-scalable", {
        align: "right",
        verticalAlign: "top",
        containerHeight: "auto",
        maxScale: 1,
      })
    }
  }
}
