import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "output"]

  populate() {
    const value = this.inputTarget.value.trim()
    const regex = new RegExp(/[\W_\s]+/, "g")
    this.outputTarget.value = value.trim().replaceAll(regex, "-")
  }
}
