import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "form", "label", "edit", "modal", "deleteButton"]
  static classes = ["hover"]

  initialize() {
    this.currentValue = this.inputTarget.value
  }

  focus() {
    this.element.classList.remove(...this.hoverClasses)
    this.inputTarget.selectionStart = this.inputTarget.selectionEnd = 1000

    this.labelTarget.classList.add("hidden")
    this.formTarget.classList.remove("hidden")

    setTimeout(() => {
      this.inputTarget.focus()
    })
  }

  blur() {
    this.element.classList.add(...this.hoverClasses)
    this.labelTarget.classList.remove("hidden")
    this.formTarget.classList.add("hidden")
  }

  restore() {
    this.inputTarget.value = this.currentValue
    this.blur()
  }

  save(e) {
    const modalContainer = document.querySelector("#modal [data-labels--modal-target='dialog']")

    if(this.formTarget.classList.contains("hidden") || this.formTarget.contains(e.target) || modalContainer.contains(e.target)) return

    this.formTarget.requestSubmit()
  }

  showEdit() {
    this.editTarget.classList.remove("invisible")
  }

  hideEdit() {
    this.editTarget.classList.add("invisible")
  }

  setModalVisibility({ currentTarget }) {
    this.inputTarget.blur()
    currentTarget.blur()

    this.dispatch("modal:open", {
      target: document.getElementById("modal"),
      detail: this.element.id,
    })
  }

  onFormSubmit({ detail: { formSubmission }}) {
    if(formSubmission.result.success) return

    this.inputTarget.classList.add("error")
  }

  destroy() {
    this.deleteButtonTarget.click()
  }
}
