import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['cell', 'distributionRange', 'smsButton', 'evenlyButton', 'whatsappButton']

  connect() {
    super.connect();
  }

  distributeFullyToSMS(notifyMobile = true) {
    this.distributionRangeTarget.value = this.distributionRangeTarget.min
    this.disableSMSButton()

    if(this.cellTargets.length === 0) {
      this.notifyDesktopRow('sms')
      return
    }

    if(notifyMobile) {
      this.notifyMobileRow('sms')
    }

    this.cellTargets.forEach(cell => {
      this.dispatch('distribution:change', {
        target: cell,
        detail: 'sms'
      })
    })
  }

  distributeFullyToWhatsApp(notifyMobile = true) {
    this.distributionRangeTarget.value = this.distributionRangeTarget.max
    this.disableWhatsAppButton()

    if(this.cellTargets.length === 0) {
      this.notifyDesktopRow('whatsapp')

      return
    }

    if(notifyMobile) {
      this.notifyMobileRow('whatsapp')
    }

    this.cellTargets.forEach(cell => {
      this.dispatch('distribution:change', {
        target: cell,
        detail: 'whatsapp'
      })
    })
  }

  distributeEvenly(notifyMobile = true) {
    this.distributionRangeTarget.value = 0
    this.disableEvenlyButton()

    if(this.cellTargets.length === 0) {
      this.notifyDesktopRow('even')

      return
    }

    if(notifyMobile) {
      this.notifyMobileRow('even')
    }

    this.cellTargets.forEach(cell => {
      this.dispatch('distribution:change', {
        target: cell,
        detail: 'even'
      })
    })
  }

  notifyMobileRow(distribution) {
    this.dispatch('desktop:distribution:change', {
      target: document.documentElement,
      detail: distribution
    })
  }

  notifyDesktopRow(distribution) {
    this.dispatch('mobile:distribution:change', {
      target: document.documentElement,
      detail: distribution
    })
  }

  onDesktopDistributionChange({ detail: distribution }) {
    if(this.cellTargets.length !== 0) return

    if(distribution === 'sms') {
      this.distributionRangeTarget.value = this.distributionRangeTarget.min
      this.disableSMSButton()
    } else if(distribution === 'whatsapp') {
      this.distributionRangeTarget.value = this.distributionRangeTarget.max
      this.disableWhatsAppButton()
    } else {
      this.distributionRangeTarget.value = 0
      this.disableEvenlyButton()
    }
  }

  onMobileDistributionChange({ detail: distribution }) {
    if(this.cellTargets.length === 0) return

    if(distribution === 'sms') {
      this.distributeFullyToSMS(false)
    } else if(distribution === 'whatsapp') {
      this.distributeFullyToWhatsApp(false)
    } else {
      this.distributeEvenly(false)
    }
  }

  onRangeChange(e) {
    const value = e.target.value

    if(value <= -100) {
      this.distributeFullyToSMS()
    } else if(value >= 100) {
      this.distributeFullyToWhatsApp()
    } else if(value > -100 && value < 100) {
      this.distributeEvenly()
    }
  }

  disableSMSButton() {
    this.addClass(this.smsButtonTarget, '-z-10')
    this.removeClass(this.whatsappButtonTarget, '-z-10')
    this.removeClass(this.evenlyButtonTarget, '-z-10')
  }

  disableWhatsAppButton() {
    this.addClass(this.whatsappButtonTarget, '-z-10')
    this.removeClass(this.smsButtonTarget, '-z-10')
    this.removeClass(this.evenlyButtonTarget, '-z-10')
  }

  disableEvenlyButton() {
    this.addClass(this.evenlyButtonTarget, '-z-10')
    this.removeClass(this.smsButtonTarget, '-z-10')
    this.removeClass(this.whatsappButtonTarget, '-z-10')
  }
}
