import { Controller } from "@hotwired/stimulus"
import { setToArray } from "@jsplumb/util"

export default class extends Controller {
  static values = {
    step: String,
  }

  static targets = ["step"]

  progress(event) {
    const { detail: stepId } = event

    const currentStep = this.stepTargets.find(
      (step) => step.dataset.id === this.stepValue
    )
    const nextStep = this.stepTargets.find((step) => step.dataset.id === stepId)

    if (currentStep.previousElementSibling === nextStep) {
      this.back(event)
    } else {
      this.advance(event)
    }
  }

  advance({ detail: stepId }) {
    const nextStep = this.stepTargets.find((step) => step.dataset.id === stepId)
    const currentStep = this.stepTargets.find(
      (step) => step.dataset.id === this.stepValue
    )

    if (nextStep === currentStep) return

    this.stepValue = stepId

    currentStep.querySelector("x-head").classList.add("bg-tiger-40")
    currentStep.querySelector("x-head").classList.remove("!border-transparent")
    currentStep
      .querySelector("x-progress")
      .classList.replace("bg-tiger", "bg-tiger-40")

    currentStep.classList.replace("text-white", "text-black")
    currentStep.classList.add("z-50")

    nextStep.querySelector("x-progress").style.width = "calc(100% + 48px)"

    if (nextStep.querySelector("x-head")) {
      nextStep.querySelector("x-head").classList.add("!border-transparent")
    }

    nextStep.classList.replace("text-black", "text-white")
  }

  back({ detail: stepId }) {
    const currentStep = this.stepTargets.find(
      (step) => step.dataset.id === this.stepValue
    )
    const previousStep = stepId
      ? this.stepTargets.find((step) => step.dataset.id === stepId)
      : currentStep.previousElementSibling

    if (previousStep === currentStep) return

    this.stepValue = previousStep.dataset.id

    currentStep.querySelector("x-progress").style.width = "0%"
    currentStep.classList.replace("text-white", "text-black")

    if (!currentStep.hasAttribute("data-last")) {
      currentStep
        .querySelector("x-head")
        .classList.remove("!border-transparent")
    }

    previousStep.classList.remove("z-50")
    previousStep
      .querySelector("x-head")
      .classList.replace("bg-tiger-40", "bg-tiger")
    previousStep.querySelector("x-head").classList.add("!border-transparent")
    previousStep
      .querySelector("x-progress")
      .classList.replace("bg-tiger-40", "bg-tiger")
    previousStep.classList.replace("text-black", "text-white")
  }
}
