import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    url: String,
    viewed: String,
  }

  static targets = [
    'input',
    'searchTurboFrame',
    'business',
    'skeleton',
  ]

  initialize() {
    this.search = this.search.bind(this)
    super.initialize()
  }

  debounceSearch(event) {
    clearTimeout(this.searchTimeout)
    this.searchTimeout = this.delayed(this.search, 500)
  }

  search() {
    const url = new URL(this.urlValue)
    url.searchParams.append('search', this.inputTarget.value)

    if(this.viewedValue) {
      url.searchParams.append('viewed', this.viewedValue)
    } else {
      url.searchParams.delete('viewed')
    }

    this.businessTargets.forEach((element) => {
      const skeleton = this.skeletonTarget.cloneNode(true)
      skeleton.classList.remove('hidden')

      element.replaceWith(skeleton)
    })

    this.searchTurboFrameTarget.src = url
  }

  markAsViewed({ currentTarget }) {
    this.viewedValue = currentTarget.dataset.handle
  }
}
