import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    inbox: Boolean,
    url: String,
    conversationUrl: String,
    domId: String,
  }

  async focus({ currentTarget }) {
    if(this.inboxValue) {
      if(document.querySelector(`[data-contact-id="${this.domIdValue}"]`)) {
        document.querySelector(`[data-contact-id="${this.domIdValue}"]`).click()
      } else {
        const response = await get(this.conversationUrlValue, {
          responseKind: "turbo-stream",
          query: { update_url: true} }
        )

        console.log(response)
      }
    } else {
      if(document.getElementById(this.domIdValue)) {
        document.getElementById(this.domIdValue).click()
      } else {
        get(this.urlValue, {
          responseKind: "turbo-stream",
          query: { update_url: true} }
        )

        const url = new URL(this.urlValue)
        history.pushState({}, null, url.toString())
      }
    }
  }
}
