import {get, post, destroy} from "@rails/request.js";

import PropertyController from "../contact/property_controller"

import { useBatchUpdateNotifiers } from '../mixins/useBatchUpdateNotifiers'

export default class extends PropertyController {
  static values = {
    labelingUrl: String,
    url: String,
    autofocus: Boolean,
    searchUrl: String,
    key: { type: String, default: "label" }
  }

  static targets = ["persisted", "item", "itemPill", "research", "deleted", "menu", "button"]

  initialize() {
    this.placeholderElement = this.element.querySelector("x-placeholder")?.cloneNode(true)
    super.initialize();
  }

  connect() {
    super.connect();

    if (this.autofocusValue) {
      this.inputTarget.focus()
    }

    useBatchUpdateNotifiers(this, {
      onBatchUpdateCancellation: () => {
        if(this.blockedAnchor) {
          return this.blockedAnchor.click()
        }

        this.inputTarget.focus()
      },
      onBatchUpdateAbortion: () => this.clearAndHide()
    })
  }

  saveChanges(e) {
    if(e.target.nodeName === 'DIALOG' || e.target.getAttribute('data-audience--contact-target') === 'checkbox' || this.isBlockedAnchor(e)) return

    const modalContainer = document.querySelector("[data-controller='labels--modal'] [data-labels--modal-target='container']")

    if (modalContainer) {
      if (this.withinBoundaries(e) || (this.withinBoundaries(e) && modalContainer?.contains(e.target))) return

      if (this.containerTarget.contains(e.target) === false && !this.menuTarget.contains(e.target) && !modalContainer.contains(e.target)) {
        this.onClickOutside(e)
      }
    } else {
      if (this.withinBoundaries(e)) return

      if (this.containerTarget.contains(e.target) === false) {
        this.onClickOutside(e)
      }
    }
  }

  search() {
    this.clearDebounce()

    if (this.inputTarget.value.trim().length === 0) {
      this.menuTarget.classList.add("hidden")
    } else {
      this.debounce(async () => {
        await get(this.searchUrlValue, {
          responseKind: "turbo-stream",
          query: {
            search: this.inputTarget.value
          }
        })
      }, 300)
    }
  }

  save({currentTarget: label}) {
    const options = {
      responseKind: "turbo-stream",
      body: {
        label_id: label.dataset.value,
        mode: this.getMode(),
        label: { name: label.innerText }
      }
    }

    const url = this.persistedValue ? this.labelingUrlValue : this.urlValue

    post(url, options)
  }

  saveNewTag({currentTarget: newTag}) {
    const options = {
      responseKind: "turbo-stream",
      body: {
        label: {name: newTag.dataset.value},
        mode: this.getMode()
      }
    }

    const url = this.persistedValue ? this.labelingUrlValue : this.urlValue

    post(url, options)
  }

  persistedTargetConnected() {
    this.persistedValue = true
    this.labelingUrlValue = this.persistedTarget.dataset.url

    this.element.querySelector('[data-placeholder]').classList.remove(...this.unsetClasses)

    this.persistedTarget.remove()
  }

  submitOnEnter() {
    if(this.inputTarget.value.length === 0) {
      if(this.itemsChanged && this.inBatchUpdateMode()) {
        this.requestBatchUpdateConfirmation()
      } else {
        this.clearAndHide()
      }

      return
    }

    if (this.element.querySelector("[data-new]")) {
      this.element.querySelector("[data-new]").click()
      return
    } else if (this.element.querySelectorAll("[data-existing]").length > 0) {
      this.element.querySelectorAll("[data-existing]")[0].click()
      return
    }

    this.clearDebounce()
    this.onInputEnter()
  }

  removeItem({ currentTarget }, identifier = '[data-property--tags-target="item"]') {
    const item = currentTarget.closest(identifier)

    if(item.hasAttribute('data-added')) {
      return item.remove()
    }

    if(item.tagName === 'FORM' && !this.inBatchUpdateMode()) {
      item.requestSubmit()
    }

    if(this.inBatchUpdateMode()) {
      item.toggleAttribute('data-removed')
      this.focus()
    } else {
      this.itemPillTargets.find(pill => pill.dataset.id === item.dataset.id)?.remove()
      item.remove()
    }
  }

  setLabelToDestroy({currentTarget}) {
    this.elementId = currentTarget.dataset.id
  }

  deletedTargetConnected() {
    this.persistedValue = false
    this.element.querySelector('[data-placeholder]').classList.add(...this.unsetClasses)

    if(this.hasDeleteTarget) {
      this.deletedTarget.remove()
    }
  }

  researchTargetConnected() {
    if(this.containerTarget.classList.contains("hidden")) return

    if(
      this.element.querySelectorAll("[data-new]").length === 0
      || this.element.querySelectorAll("[data-existing]").length === 0
    ) {
      this.focus()
      this.search()
    }
  }

  onClickOutside(e) {
    if(this.inBatchUpdateMode() && this.itemsChanged) {
      if(e.target.tagName === 'A' || e.target.closest('a')) {
        e.preventDefault()
        e.stopPropagation()

        this.blockedAnchor = e.target.closest('a') || e.target
      }

      return this.requestBatchUpdateConfirmation()
    }

    this.buttonTarget.classList.remove("hidden")
    this.containerTarget.classList.add("hidden")
    this.inputTarget.value = ""

    if(this.suggestions.length === 0) {
      this.menuTarget.classList.add("hidden")
    }
  }

  clearAndHide() {
    if(this.inBatchUpdateMode()) {
      this.itemTargets.filter(item => item.hasAttribute('data-added')).forEach(item => item.remove())
      this.itemTargets.filter(item => item.hasAttribute('data-removed')).forEach(item => item.removeAttribute('data-removed'))
    }

    this.clearDebounce()
    this.inputTarget.value = ""

    this.containerTarget.classList.add("hidden")
    this.menuTarget.classList.add("hidden")

    this.buttonTarget.classList.remove("hidden")

    this.blurLine()
  }

  async onInputEnter() {
    const options = {
      responseKind: "turbo-stream",
      body: {
        label: { name:  this.inputTarget.value },
        mode: this.getMode()
      }
    }

    const url = this.persistedValue ? this.labelingUrlValue : this.urlValue

    await post(url, options)
    this.refocus()
  }

  sync() {
    if(this.inputTarget.value.length === 0) {
      clearTimeout(this.searchTimeout)

      this.menuTarget.classList.add("hidden")
      document.querySelector("[data-controller='labels--modal'] [data-labels--modal-target='container']")?.remove()
    }
  }

  itemTargetConnected() {
    if(this.isVisible(this.menuTarget)) {
      this.refocus()
    }

    this.element.querySelector('[data-placeholder]').classList.remove(...this.unsetClasses)
  }

  refocus() {
    this.inputTarget.value = ""
    this.inputTarget.focus()

    this.search()

    this.menuTarget.classList.add('hidden')
  }

  itemTargetDisconnected() {
    this.inputTarget.focus()
    this.menuTarget.classList.add("hidden")

    if(this.itemTargets.length === 0) {
      this.deletedTargetConnected()
    }
  }

  get itemsChanged() {
    return this.itemTargets.filter(item => item.hasAttribute('data-removed') || item.hasAttribute('data-added')).length > 0
  }

  get suggestions() {
    return this.element.querySelectorAll("[data-suggestion='true']")
  }

  get invisibleClass() {
    return "hidden"
  }

  getValueForBatch() {
    return {
      add_to: this.itemTargets.filter(item => item.hasAttribute('data-added')).map(item => item.innerText.trim()).join(', '),
      remove_from: this.itemTargets.filter(item => item.hasAttribute('data-removed')).map(item => item.innerText.trim()).join(', '),
    }
  }
}
