const placeTypes = {
  country: 'country',
  administrative_area_level_1: 'state',
  locality: 'city',
  neighborhood: 'neighborhood',
  route: 'street',
  establishment: 'establishment'
}

class PlaceAutocomplete {
  constructor(response) {
    this.response = response
  }

  get type() {
    if(this.isCountry) {
      return placeTypes.country
    } else if (this.isState) {
      return placeTypes.administrative_area_level_1
    } else if (this.isCity) {
      return placeTypes.locality
    } else if (this.isNeighborhood) {
      return placeTypes.neighborhood
    } else if (this.isStreet) {
      return placeTypes.route
    } else if (this.isEstablishment) {
      return placeTypes.establishment
    }
  }

  get isCountry() {
    return this.response.types.includes('country')
  }

  get isState() {
    return this.response.types.includes('administrative_area_level_1')
  }

  get isCity() {
    return this.response.types.includes('locality')
  }

  get isNeighborhood() {
    return this.response.types.includes('neighborhood')
  }

  get isStreet() {
    return this.response.types.includes('route') || this.response.types.includes('street_address')
  }

  get isEstablishment() {
    return this.response.types.includes('establishment')
  }
}

export { PlaceAutocomplete }
