import MessageController from "./message_controller"

export default class extends MessageController {
  static targets = ["trigger", "method", "url", "initialContent"]

  initialize() {
    super.initialize()
    this.kind = "request"
  }

  sync({ detail }) {
    if (detail.stepId === this.element.id ) {
      if (detail.commit === true) {
        this.triggerTarget.classList.remove("halo--active")
        this.containerTarget.innerText = detail.method + " " + detail.url

        if(!this.persistedValue) {
          this.dispatch("saved", {
            target: document.documentElement
          })
        }

        this.persistedValue = true
      }
    }
  }
}
