import CampaignPreviewController from '../campaign/preview_controller'
import {Piece} from '../compose/models/piece'
import {Loader} from "@googlemaps/js-api-loader";

export default class extends CampaignPreviewController {
  static values = {
    googleMapsApiKey: String,
    latitude: String,
    longitude: String,
  }

  static targets = ['header', 'footer', 'buttonContainer', 'buttonTemplate', 'map']

  connect() {
    super.connect()

    this.loader = new Loader({
      apiKey: window.googleMapsApiKey,
      version: "weekly",
      libraries: ["places"]
    });

    this.mapTarget.classList.add('hidden')
    this.loadMap()
  }

  loadMap() {
    this.loader.load().then((google) => {
      this.map = new google.maps.Map(this.mapTarget, this.mapOptions)
      this.marker = new google.maps.Marker({ map: this.map })

      if (this.latitudeValue && this.longitudeValue) {
        this.map.panTo({
          lat: parseFloat(this.latitudeValue),
          lng: parseFloat(this.longitudeValue)
        })

        this.marker.setPosition({
          lat: parseFloat(this.latitudeValue),
          lng: parseFloat(this.longitudeValue)
        })

        this.show(this.mapTarget)
      }
    });

  }

  previewAttachment({detail: attachmentElement}) {
    this.headerTarget.innerHTML = ''

    if (!attachmentElement) {
      return this.hide(this.headerTarget)
    }

    if (attachmentElement.querySelector('img')) {
      const img = attachmentElement.querySelector('img').cloneNode(true)
      this.addClass(img, 'w-full')
      this.headerTarget.appendChild(img)
    } else {
      const content = attachmentElement.querySelector('main').cloneNode(true)
      this.replaceClass(content, 'max-w-[11rem]', 'w-full')
      this.headerTarget.appendChild(content)
    }

    this.show(this.headerTarget)
  }

  previewBody({detail}) {
    if (detail.location) {
      this.map.panTo(detail.location)
      this.marker.setPosition(detail.location);
      this.show(this.mapTarget)
    } else {
      this.hide(this.mapTarget)
    }

    super.updatePreview({detail})
  }

  previewFooter({detail: body}) {
    this.footerTarget.innerHTML = body

    if (body) {
      this.show(this.footerTarget)
    } else {
      this.hide(this.footerTarget)
    }
  }

  previewButtons({detail: groups}) {
    if(!groups) {
      return this.buttonContainerTarget.classList.add('hidden')
    }

    this.buttonContainerTarget.innerHTML = ''

    const buttons = Object.values(groups).map(({buttons}) => buttons).flat()

    if (buttons.length >= 3) {
      this.addClass(this.buttonContainerTarget, 'flex-col', 'space-y-2')
      this.removeClass(this.buttonContainerTarget, 'gap-x-2')

      this.addClass(this.buttonTemplateTarget, 'w-full')
    } else {
      this.removeClass(this.buttonContainerTarget, 'flex-col', 'space-y-2')

      this.addClass(this.buttonContainerTarget, 'gap-x-2')
      this.removeClass(this.buttonTemplateTarget, 'w-full')
    }

    buttons.forEach(button => {
      const element = this.buttonTemplateTarget.cloneNode(true)
      element.innerText = button.text
      this.show(element)

      this.buttonContainerTarget.appendChild(element)
    })

    this.show(this.buttonContainerTarget)
  }

  get mapOptions() {
    return {
      center: {
        lat: 0,
        lng: 0
      },
      zoom: 15,
      disableDefaultUI: false,
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      zoomControl: false,
      scaleControl: false,
      rotateControl: false,
      clickableIcons: false,
      draggable: false,
      scrollwheel: false,
      disableDoubleClickZoom: true,
    }
  }
}
