import { Piece } from '../piece'

import { Character } from '../character'
import { Token, Stack, Contact, Coupon } from './index'

class Parser {
  constructor(compose) {
    this.compose = compose

    this.contactWhitelistedTags = this.compose.allowedProfileTagsValue
    this.couponWhitelistedTags = this.compose.allowedCouponTagsValue
  }

  get tokens() {
    const tokens = []
    const document = this.compose.trixDocument

    Contact.whitelistedTags = this.contactWhitelistedTags.map(tag => tag.toLowerCase())
    Coupon.whitelistedTags = this.couponWhitelistedTags

    document.getPieces().forEach((piece, pieceIndex) => {
      if (piece.attachment) return

      if (Piece.wrap(piece).isTag) {
        return tokens.push(this.tokenFromPiece(piece, pieceIndex))
      }

      const content = piece.string
      const stack = new Stack()

      content.split('').forEach((character, index) => {
        const token = new Character(character)

        if(token.isAstrix) {
          stack.push({token, index})

          const [firstToken, lastToken] = stack.borderlineObjects

          if(firstToken?.token.isAstrix && lastToken?.token.isAstrix) {
            console.log('should mark as bold')
          }
        } else if (token.isOpeningBrace) {
          if (stack.head?.token?.isOpeningBrace) {
            stack.clear()
          }

          stack.push({token, index})
        } else if (token.isClosingBrace && stack.head?.token?.isOpeningBrace) {
          const lastToken = tokens[tokens.length - 1]
          const newLastToken = stack
            .push({token, index})
            .tokenize(this.compose.contentLengthBeforePiece(pieceIndex))

          tokens.push(newLastToken)

          if(lastToken?.end === newLastToken.start) {
            newLastToken.addWhitespace = true
          }

          stack.clear()
        } else {
          const [firstToken, lastToken] = stack.borderlineObjects

          if (firstToken?.token.isOpeningBrace && !lastToken?.token?.isClosingBrace) {
            stack.push({token, index})
          }

          if(firstToken?.token?.isAstrix && !lastToken?.token.isAstrix) {
            stack.push({token, index})
          }
        }
      })
    })

    return tokens
  }

  // private

  tokenFromPiece(piece, pieceIndex) {
    const startsAt = this.compose.contentLengthBeforePiece(pieceIndex)

    return new Token(piece.string, {
      start: startsAt,
      end: startsAt + piece.string.length,
      isValidTagName: Contact.isValidTag(piece.string) || Coupon.isValidTag(piece.string),
      piece: Piece.wrap(piece),
    })
  }
}

export { Parser }
