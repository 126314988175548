import { AttachmentManager } from './attachment_manager'

class LinksManager extends AttachmentManager {
  insert(event) {
    const attachment = this.constructAttachmentElementForLink(event.detail.linkPartial)
    super.insert(attachment)
  }

  replace({ detail }) {
    const { id, linkPartial } = detail

    this.editor.setSelectedRange(this.oldPosition)

    this.trixDocument.getPieces().forEach((piece, index) => {
      if(!piece.attachment) return

      const elementId = new DOMParser()
        .parseFromString(piece.attachment.attributes.values.content, 'text/html')
        .querySelector('[data-id]')
        .dataset
        .id

      if(id === elementId) {
        const contentLengthBeforeReplacedPiece = this.contentLengthBeforePiece(index)
        this.editor.setSelectedRange([contentLengthBeforeReplacedPiece, contentLengthBeforeReplacedPiece + 1])

        this.editor.deleteInDirection('forward')
        this.insert({ detail: { linkPartial } })

        this.inputController.afterAttachmentReplacement(id)
      }
    })
  }

  constructAttachmentElementForLink(linkPartial) {
    const htmlFromPartial = new DOMParser().parseFromString(linkPartial, 'text/html')

    const element = htmlFromPartial.querySelector('[data-sgid]')
    const { destination, link, sgid, id } = element.dataset

    return new Trix.Attachment({
      content: linkPartial,
      contentType: 'shortlink',
      sgid,
      id,
      destination,
      link
    })
  }
}

export { LinksManager }
