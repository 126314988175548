import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [
    'submit',
    'requiredFilter',
  ]

  validateFilter(event) {
    const { detail: isValid } = event
    this.submitTarget.disabled = !isValid
  }

  requiredFilterTargetDisconnected() {
    this.submitTarget.disabled = this.requiredFilterTargets.some(target => target.dataset.valid === 'false')
  }
}
