import ApplicationController from '../application_controller'

import { MoneyFormatter } from "../models/money_formatter";
import { useNumberHelpers } from '../mixins/useNumberHelpers';

export default class extends ApplicationController {
  static values = {
    info: Object,
    monthlyAmount: Number,
    yearlyAmount: Number,
    active: Boolean,
    selected: Boolean,
    contactUs: Boolean
  }

  static targets = ['creditsContainer', 'yearlyAgreementPrice', 'monthlyAgreementPrice', 'creditBundleDropdownContainer']

  initialize() {
    super.initialize();
    this.moneyFormatter = new MoneyFormatter();
    this.agreementType = 'yearly'

    this.originalAvailableCredits = this.availableCredits
  }

  connect() {
    super.connect()

    useNumberHelpers(this)

    if(this.hasMonthlyAgreementPriceTarget) {
      this.monthlyAgreementPriceTarget.innerText = this.translations.products.packages.package.monthly_price.replace(
        '%{price}',
        this.moneyFormatter.humanizeWithSymbol(this.monthlyAmountValue),
      )
    }

    if(this.hasYearlyAgreementPriceTarget) {
      this.yearlyAgreementPriceTarget.innerText = this.translations.products.packages.package.monthly_price.replace(
        '%{price}',
        this.moneyFormatter.humanizeWithSymbol(this.yearlyAmountValue),
      )
    }
  }

  onAgreementTypeChange({ detail: agreementType }) {
    if(this.contactUsValue) return

    this.agreementType = agreementType

    if(agreementType === 'monthly') {
      if(this.hasYearlyAgreementPriceTarget) {
        this.hide(this.yearlyAgreementPriceTarget)
      }

      this.show(this.monthlyAgreementPriceTarget)
    } else {
      this.hide(this.monthlyAgreementPriceTarget)
      this.show(this.yearlyAgreementPriceTarget)
    }
  }

  onSelectionChange({ target }) {
    this.activeValue = target.checked

    if(target.checked) {
      this.dispatch('change', {
        detail: this.infoValue
      })
    }

    if(!this.hasCreditBundleDropdownContainerTarget) return

    if(target.checked) {
      this.show(this.creditBundleDropdownContainerTarget)
    } else {
      this.hide(this.creditBundleDropdownContainerTarget)
    }
  }

  onCreditBundleChange({ target }) {
    const value = parseInt(target.value) || 0
    const total = this.originalAvailableCredits + value

    if(this.activeValue) {
      this.infoValue = {
        ...this.infoValue,
        credit_bundle: target.value,
        features: this.infoValue.features.map(feature => {
          if(feature.identifier === 'credit') {
            return {
              ...feature,
              available: total
            }
          } else {
            return feature
          }
        })
      }

      this.dispatch('change', {
        detail: this.infoValue
      })
    }

    this.creditsContainerTarget.innerText = this.translations.products.packages.package.credits.replace(
      '%{available}',
      this.numberWithDelimiter(total)
    )

    if(this.hasMonthlyAgreementPriceTarget) {
      this.monthlyAgreementPriceTarget.innerText = this.translations.products.packages.package.monthly_price.replace(
        '%{price}',
        this.moneyFormatter.humanizeWithSymbol(this.monthlyAmountValue + this.totalPriceForCreditBundle(value))
      )
    }

    if(this.hasYearlyAgreementPriceTarget) {
      this.yearlyAgreementPriceTarget.innerText = this.translations.products.packages.package.monthly_price.replace(
        '%{price}',
        this.moneyFormatter.humanizeWithSymbol(this.yearlyAmountValue + this.totalPriceForCreditBundle(value))
      )
    }
  }

  totalPriceForCreditBundle(creditBundle) {
    let total;

    if(this.agreementType === 'monthly') {
      total = creditBundle * .07 * 1000
    } else {
      total = creditBundle * .05 * 1000
    }

    return total
  }

  activeValueChanged() {
    if(this.element.querySelector('select')) {
      this.element.querySelector('select').disabled = !this.activeValue
    }
  }

  get availableCredits() {
    return parseInt(this.infoValue.features.find(feature => feature.identifier === 'credit').available)
  }
}
