import { Controller } from '@hotwired/stimulus'

const REGEXP = '^.*[a-zA-Z][a-zA-Z0-9-_]*\.myshopify\.com.*$'

export default class extends Controller {
  static targets = ['domainInput', 'submitButton']

  onDomainChange() {
    this.submitButtonTarget.disabled = !new RegExp(REGEXP)
      .test(this.domainInputTarget.value)
  }
}


