import ApplicationController from './application_controller'

import { useLineNotifiers } from './mixins/useLineNotifiers'

export default class extends ApplicationController {
  static values = {
    persisted: { type: Boolean, default: false },
    focus: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    originalName: String,
    profileScoped: { type: Boolean, default: false },
  }

  static targets = [
    "main",
    "inputContainer",
    "input",
    "form",
    "tooltip",
    "toggleUniquenessButton",
    "uniquenessLabel",
    "focus",
  ]

  connect() {
    super.connect();

    if(this.hasInputTarget) {
      this.nameValue = this.inputTarget.value
    }

    this.parentElement = this.element.parentElement

    if(this.focusValue) {
      this.focus()
    }

    useLineNotifiers(this, {
      element: this.element.closest('li')
    })
  }

  disconnect() {
    if(document.getElementById('property--modal')) {
      this.dispatch('hide', {
        target: document.getElementById('property--modal')
      })
    }

    super.disconnect()
  }

  focus() {
    this.mainTarget.classList.add("hidden")
    this.inputContainerTarget.classList.remove("hidden")

    if(this.hasInputTarget) {
      super.focus(this.inputTarget, { moveCursorToEnd: true })
    }

    this.nextTick(() => this.moveLineToThisElement())
  }

  saveChanges(e) {
    if(
      this.inputContainerTarget.classList.contains("hidden")
      || this.inputContainerTarget.contains(e.target)
      || this.mainTarget.contains(e.target)
      || this.inputContainerTarget.contains(e.target)
      || this.togglingUniqueness
    ) return

    if(this.hasInputTarget && this.nameValue !== this.inputTarget.value) {
      this.element.requestSubmit()
    }

    this.blur()
    this.element.closest('li').removeAttribute('data-line-active')
  }

  blur() {
    this.element.removeAttribute('data-line-active')

    this.mainTarget.classList.remove("hidden")
    this.inputContainerTarget.classList.add("hidden")

    if(this.errorValue) {
      this.inputTarget.value = this.originalNameValue
      this.inputContainerTarget.classList.remove("error")
      this.errorValue = false

      setTimeout(() => {
        this.element.dataset.controller = this.element.dataset.controller.replace("tooltip", "")
        this.element.dataset.action = this.element.dataset.action.replace(this.element.dataset.tooltipActions, "")
        this.tooltipTarget.classList.add("hidden")
      }, 300)
    }
  }

  blurField() {
    if(this.hasInputTarget) {
      this.inputContainerTarget.blur()
    }
  }

  requestDeletion() {
    this.dispatch('show', {
      target: document.getElementById('property--modal'),
      detail: {
        url: this.element.action,
        element: this.element,
        profileScoped: this.profileScopedValue,
      },
    })

    setTimeout(() => {
      this.focus()

      if(this.hasInputTarget) {
        this.inputTarget.blur()
      }
    })
  }

  onModalClosed() {
   setTimeout(() => {
     this.mainTarget.click()
   })
  }

  save() {
    this.element.requestSubmit()
  }

  restore() {
    if(this.hasInputTarget) {
      this.inputTarget.value = this.originalNameValue
    }

    this.blur()
    this.blurLine()
  }

  toggleUniqueness() {
    this.togglingUniqueness = true
    this.toggleUniquenessButtonTarget.click()
  }

  onSubmitEnd({ detail }) {
    if(detail.success && this.togglingUniqueness) {
      this.focus()

      this.togglingUniqueness = false
      this.uniquenessLabelTarget.classList.toggle('hidden')
    }
  }
}
