import { Controller } from "@hotwired/stimulus"

// there is a bug within turbo that doesn't let you navigate
// from a frame more than once, to fix this you have to reset the frame
// src and all works. this is a monkey patch. to use it
// place it directly on a frame, data-controller="reset-frame-src"
// data-action="turbo:submit-end->reset-frame-src#reset"

export default class extends Controller {
  reset() {
    this.element.src = ""
  }
}
