import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  onInboxActiveStateChange({ detail }) {
    this.inboxState = detail.state
  }

  moveConversationToTop({ dataset }) {
    const conversation = this.element.querySelector(`#conversation_${dataset.id}`)

    if(conversation) {
      const clonedNode = conversation.cloneNode(true)
      conversation.remove()

      this.element.querySelector("#conversations").prepend(clonedNode)
    }
  }

  onNewConversationAppended({ detail: conversation }) {
    if(this.inboxState === "opened" && ["assigned", "unassigned"].includes(conversation.state)) {
      conversation.element.classList.remove("hidden")
    } else if(conversation.state === this.inboxState) {
      conversation.element.classList.remove("hidden")
    } else {
      conversation.element.remove()
    }
  }

  onConversationClick({ currentTarget }) {
    get(currentTarget.dataset.url, { responseKind: "turbo-stream" })
  }
}
