import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  changeAction({currentTarget}) {
    this.element.action = currentTarget.dataset.actionUrl

    if (currentTarget.dataset.method) {
      this.element.method = currentTarget.dataset.method

      if (this.csrfInput) {
        this.csrfInput.disabled = currentTarget.dataset.method.toLowerCase() === "get";
      }
    }
  }

  get csrfInput() {
    return this.element.querySelector("input[type='hidden'][name='authenticity_token']")
  }
}
