import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    current: String
  }

  static targets = ["option"]

  choose({ currentTarget }) {
    if(this.chosenOption) {
      this.chosenOption.classList.remove("bg-lavender-light")
    }

    this.chosenOption = currentTarget
    this.currentValue = currentTarget.innerText
    this.chosenOption.classList.add("bg-lavender-light")

    this.dispatch("value-changed", {
      detail: this.currentValue
    })
  }

  sync({ target }) {
    this.currentValue = target.value

    console.log(
      this.dispatch("value-changed", {
        detail: this.currentValue
      })
    )

    this.activateChosenOption()
  }

  activateChosenOption() {
    const chosenOption = this.optionTargets.find((option) => option.innerText === this.currentValue)

    if(chosenOption && !this.chosenOption) {
      this.chosenOption = chosenOption
      chosenOption.classList.add("bg-lavender-light")
    }
  }
}
