import { get } from '@rails/request.js'

import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    url: String,
  }

  static targets = ['dropdown', 'details', 'summary', 'summarySkeletonList', 'header', 'headerSkeleton']
  showDropdown() {
    this.show(this.dropdownTarget)
  }

  hideDropdown() {
    this.hide(this.dropdownTarget)
  }

  toggleDropdown() {
    this.nextTick(() => {
      if(this.detailsTarget.open) {
        this.showDropdown()
      } else {
        this.hideDropdown()
      }
    })
  }

  onQuotaChange({ target }) {
    this.show(this.summarySkeletonListTarget)
    this.show(this.headerSkeletonTarget)

    this.hide(this.headerTarget)
    this.hide(this.summaryTarget)

    const url = new URL(this.urlValue.replace(':id', target.value))

    get(url.toString(), {responseKind: 'turbo-stream'})
  }
}