import { Application } from "@hotwired/stimulus"
import AccordionController from "@kanety/stimulus-accordion"

const application = Application.start()
application.register("accordion", AccordionController)

export default class extends AccordionController {
  static values = {
    expanded: Boolean,
  }

  connect() {
    super.connect()

    if (this.expandedValue) {
      this.open(this.togglers[0])
      this.toggleNext(this.togglers[0])
    }
  }

  open(toggler) {
    let content = this.findContent(toggler)
    this.show(toggler, content, false)
    this.store.save()
    this.dispatch("opened", { detail: { toggler: toggler, content: content } })
  }

  close(toggler) {
    let content = this.findContent(toggler)
    this.hide(toggler, content, false)
    this.store.save()
    this.dispatch("closed", { detail: { toggler: toggler, content: content } })
  }

  toggle(e) {
    clearInterval(this.intervalId)
    this.togglers.forEach((toggler) => {
      if (toggler.contains(e.target)) {
        if (this.isOpened(toggler)) {
          if (!this.expandedValue) {
            this.close(toggler)
          }
        } else {
          this.open(toggler)
        }
      } else if (this.isOpened(toggler)) {
        this.close(toggler)
      }
    })

    e.preventDefault()
  }

  next(element) {
    let currentPosition = this.togglers.indexOf(element)
    let nextPosition = (currentPosition + 1) % this.togglers.length
    return this.togglers[nextPosition]
  }

  toggleNext(element) {
    let controller = this
    clearInterval(this.intervalId)
    this.intervalId = window.setInterval(function () {
      controller.close(element)
      controller.open(controller.next(element))
      controller.toggleNext(controller.next(element))
    }, 7500)
  }
}
