import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['backButton', 'submit', 'form', 'compose']

  updateBackButtonHref(event) {
    const { params } = event
    document.cookie = `preview=${params.type};path=/`
  }

  submit(e) {
    if(this.composeSnapshot.template && ['low', 'medium'].includes(this.composeSnapshot.template.quality) && !this.composeSnapshot.templateChanged) {
      this.dispatch('show', {
        target: this.element,
        detail: this.composeSnapshot.template.quality,
      })

      e.preventDefault()
      e.stopPropagation()
    }
  }

  sync({ detail: composeSnapshot }) {
    this.submitTarget.disabled = composeSnapshot.string.length === 0 || composeSnapshot.overflow

    if(composeSnapshot.overflow) {
      this.showToast({
        message: t.compose.submit.blocked_by_overflow.replace('%{max_length}', composeSnapshot.technology.max_message_length),
        type: 'error',
      })
    }

    this.composeSnapshot = composeSnapshot
  }

  submitAnyway() {
    this.formTarget.requestSubmit()
  }

  editTemplate() {
    this.nextTick(() => {
      this.dispatch('focus', {
        target: this.composeTarget
      })
    })
  }
}
