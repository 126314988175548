import {Turbo} from "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"

import "trix"
import "./stylesheets/application.scss"
import "../controllers"
import "../channels"

import "@rails/actiontext"
import TurboPower from "turbo_power"

import './application/register_service_worker'

TurboPower.initialize(Turbo.StreamActions)
window.TurboPower = TurboPower
window.StreamActions = Turbo.StreamActions

ActiveStorage.start()

Turbo.StreamActions.prepend_unless_present = function () {
  if (
    document.querySelector(this.target) &&
    !document
      .querySelector(this.target)
      .querySelector(`[id='${this.dataset.item}']`)
  ) {
    document.querySelector(this.target).prepend(this.templateContent)
  }
}

Turbo.StreamActions.append_unless_present = function () {
  if (
    document.querySelector(this.target) &&
    !document
      .querySelector(this.target)
      .querySelector(`[id='${this.dataset.item}']`)
  ) {
    document.querySelector(this.target).append(this.templateContent)
  }
}

//
// import { createApp } from 'vue/dist/vue.esm-bundler';
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = createApp()
//
//   // register components here
//
//
//   app.mount('body')
// })


let scrollPositions = {};

addEventListener("turbo:before-render", function () {
  document?.querySelectorAll("[data-turbo-permanent]").forEach(function (element) {
    scrollPositions[element.id] = element.scrollTop;
  });

  document?.querySelectorAll('[data-controller="dropdown"]').forEach((el) => {
    el.setAttribute('data-dropdown-open-value', false)
  })
});

addEventListener("turbo:render", function () {
  document?.querySelectorAll("[data-turbo-permanent]").forEach(function (element) {
    element.scrollTop = scrollPositions[element.id];
  });
});
