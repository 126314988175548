import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submit"]

  initialize() {
    this.onSubmit = this.onSubmit.bind(this)
  }

  connect() {
    this.element.addEventListener("submit", this.onSubmit)
  }

  disconnect() {
    this.element.removeEventListener("submit", this.onSubmit)
  }

  onSubmit() {
    this.element.querySelectorAll("input").forEach((input) => {
      input.readOnly = true
    })
  }

  onSubmitEnd() {
    this.element.querySelectorAll("input").forEach((input) => {
      input.readOnly = false
    })
  }

  submit() {
    if(this.hasSubmitTarget) {
      this.submitTarget.click()
    } else {
      this.element.requestSubmit()
    }
  }

  submitOnEnter(e) {
    if(e.key === "Enter") {
      e.preventDefault()
      e.stopPropagation()

      this.element.requestSubmit()
    }
  }
}
