import Browser from "./browser";
class Role {
  static SiteAdmin = "site_admin"
  static Owner = "owner"
  static Administrator = "administrator"
  static Manager = "manager"
  static Agent = "agent"

  constructor(role) {
    this.role = role
  }

  get agent() {
    return this.role === Role.Agent
  }

  get manager() {
    return this.role === Role.Manager
  }

  get administrator() {
    return this.role === Role.Administrator
  }

  get owner() {
    return this.role === Role.Owner
  }

  get siteAdmin() {
    return this.role === Role.SiteAdmin
  }
}

class User {
  static get id() {
    return document.querySelector('meta[name="current.id"]').getAttribute("value")
  }

  static get locale() {
    return document.querySelector('meta[name="user-locale"]').getAttribute("value")
  }

  static get siteAdmin() {
    return document.querySelector('meta[name="current.site_admin"]').getAttribute("value") === "true"
  }

  static get privilege() {
    return document.querySelector('meta[name="user-privilege"]')?.getAttribute("value")
  }

  static get teamMember() {
    return document.querySelector('meta[name="user-privilege"]') !== null
  }

  static get role() {
    return new Role(document.querySelector('meta[name="user-role"]')?.getAttribute("value"))
  }
}

class Business {
  static get country() {
    return JSON.parse(
      document.querySelector('meta[name="current.business.country"]').getAttribute("value")
    )
  }
}

class Current {
  static get user() { return User }

  static get locale() {
    return document.querySelector('meta[name="site-locale"]').getAttribute('value')
  }

  static get country() {
    if(document.querySelector('meta[name="site-country"]').getAttribute("value")) {
      return JSON.parse(
        document.querySelector('meta[name="site-country"]').getAttribute("value")
      )
    }

    return {}
  }

  static get browser() { return new Browser() }
  static get business() { return Business }
}

export { Current }
