import { post } from '@rails/request.js'

import ApplicationController from '../application_controller'

import { useNumberHelpers } from '../mixins/useNumberHelpers'

export default class extends ApplicationController {
  static values = {
    id: String,
    contactId: String,
    checked: Array,
    count: Number,
    exportUrl: String,
    batchUrl: String,
  }

  static targets = [
    'checkbox',
    'checkAll',
    'checkAllContainer',
    'exportButton',
    'selectionContainer',
    'selectAllButton',
    'deselectAllButton',
  ]

  connect() {
    useNumberHelpers(this)
    super.connect()
  }

  async export() {
    await post(this.exportUrlValue, {
      responseKind: 'turbo-stream',
      body: {
        export: {
          contact_ids_to_operate_on: this.selectAllValue ? [] : this.checkedValue,
          order: (new URLSearchParams(window.location.search)).get('order')
        }
      }
    })

    this.reset()
  }

  activeAudienceChanged({ currentTarget }) {
    console.log(currentTarget.dataset)
    this.countValue = parseInt(currentTarget.dataset.count)
    this.reset()
  }

  reset() {
    this.uncheckAll()
    this.syncSelectionContainer()
    this.updateBatchButtonLabelsTo('all')
  }

  onContactCheckboxChange({currentTarget}) {
    if (currentTarget.checked) {
      this.checkedValue = Array.from(new Set([...this.checkedValue, currentTarget.value]))
    } else {
      this.checkedValue = this.checkedValue.filter(value => value !== currentTarget.value)
    }

    this.checkAllTarget.checked = this.checkedValue.length === this.countValue

    this.syncSelectionContainer()
  }

  checkAll({currentTarget}) {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = currentTarget.checked

      if (checkbox.checked) {
        this.checkedValue = Array.from(new Set([...this.checkedValue, checkbox.value]))
      } else {
        this.checkedValue = this.checkedValue.filter(value => value !== checkbox.value)
      }
    })

    this.syncSelectionContainer()
  }

  uncheckAll() {
    this.checkboxTargets.forEach(checkbox => checkbox.checked = false)
    this.checkedValue = []

    this.checkAllTarget.checked = false
  }

  removeAll() {
    this.dispatch('show', {
      target: document.getElementById('audience--radio-modal'),
      detail: {
        id: this.idValue,
        ids: this.checkedValue,
        size: this.countValue,
        contactId: this.contactIdValue,
        element: this.element,
        all: this.checkAllTarget.checked,
      }
    })
  }

  checkedValueChanged() {
    this.syncSelectionContainer()

    if (this.checkedValue.length === this.countValue || this.checkedValue.length === 0) {
      this.updateBatchButtonLabelsTo('all')
    } else {
      this.updateBatchButtonLabelsTo('selected')
    }
  }

  syncSelectionContainer() {
    if (this.checkAllTarget.checked && this.checkedValue.length !== this.countValue) {
      this.selectionContainerTarget.querySelector('p').innerHTML = this.translations.audience.selection.limited.replace('%{count}', this.numberWithDelimiter(this.checkedValue.length))
      this.show(this.selectionContainerTarget)
    } else {
      this.show(this.selectionContainerTarget.firstElementChild)
      this.hide(this.selectionContainerTarget.lastElementChild)
      this.hide(this.selectionContainerTarget)
    }
  }

  selectAll() {
    this.selectAllValue = true
    this.updateBatchButtonLabelsTo('all')
  }

  deselectAll() {
    this.selectAllValue = false
    this.updateBatchButtonLabelsTo('selected')
  }

  updateBatchButtonLabelsTo(type) {
    if(!this.hasExportButtonTarget) return

    this.exportButtonTarget.innerText = this.translations.audience.export.label[type]

    this.dispatch('selection:change', {
      target: this.element,
      detail: {
        count: type === 'all' && this.selectAllValue ? this.countValue : this.checkedValue.length,
        contactIdsToOperateOn: this.selectAllValue ? [] : this.checkedValue,
      },
    })
  }

  checkboxTargetConnected(element) {
    if (this.checkAllTarget.checked) {
      element.checked = true
      this.checkedValue = [...this.checkedValue, element.value]
    }
  }

  countValueChanged() {
    if(!this.numberWithDelimiter) {
      useNumberHelpers(this)
    }

    this.selectAllButtonTarget.querySelector('[data-text]').innerHTML = t.audience.selection.all.select.replace('%{count}', this.numberWithDelimiter(this.countValue))
    this.deselectAllButtonTarget.querySelector('[data-text]').innerHTML = t.audience.selection.all.deselect.replace('%{count}', this.numberWithDelimiter(this.countValue))
  }
}
