import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    subscriptionUrl: String,
    deleteUrl: String,
  }

  static targets = [
    'unsubscription',
    'delete',
  ]

  showSubscriptionModal({ detail: { id } }) {
    this.titleElement.innerText = this.translations.contact.subscriptions.modal.do_you_have_consent
    this.subtitleElement.innerText = ''

    this.form.action = this.subscriptionUrlValue.replace(':contact', id)
    const submitButton = this.form.querySelector('button[type="submit"]')

    submitButton.setAttribute('value', 'subscribe')
    submitButton.innerText = this.translations.contact.subscriptions.button.subscribe

    this.dispatch('open', {
      target: this.unsubscriptionTarget
    })
  }

  showUnsubscriptionModal({ detail: { id, name } }) {
    this.titleElement.innerText = this.translations.contact.subscriptions.modal.are_you_sure.replace('%{contactable}', name)
    this.subtitleElement.innerText = this.translations.contact.subscriptions.modal.this_will_cause_the_contactable_to_unsubscribe_from_every_channel

    this.form.action = this.subscriptionUrlValue.replace(':contact', id)
    const submitButton = this.form.querySelector('button[type="submit"]')

    submitButton.setAttribute('value', 'unsubscribe')
    submitButton.innerText = this.translations.contact.subscriptions.button.unsubscribe

    this.open(this.unsubscriptionTarget)
  }

  showDeleteModal({ detail: { id }}) {
    const form = this.deleteTarget.querySelector('[data-contact--modal-target="form"]')
    form.action = this.deleteUrlValue.replace(':contact', id)

    this.open(this.deleteTarget)
  }

  // private

  open(modal) {
    this.dispatch('open', {
      target: modal
    })
  }

  get titleElement() {
    return this.unsubscriptionTarget.querySelector('[data-modal-target="title"]')
  }

  get subtitleElement() {
    return this.unsubscriptionTarget.querySelector('[data-modal-target="subtitle"]')
  }

  get form() {
    return this.unsubscriptionTarget.querySelector('[data-contact--modal-target="form"]')
  }
}
