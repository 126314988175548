import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  remove({ target }) {
    if(this.element.contains(target) || document.querySelector("[data-profile-draft]").contains(target)) return

    document.querySelector("[data-profile-draft]").remove()
    this.element.remove()
  }
}
