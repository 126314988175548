import { Current } from './current.js'

export default class Audio {
  constructor(chunks) {
    this.chunks = chunks;
  }

  get objectURL() {
    return URL.createObjectURL(this.blob);
  }

  get blob() {
    return Current.browser.isSafari ? this.blobAsMP3() : this.blobAsOGG();
  }

  blobAsOGG() {
    return new Blob(this.chunks, { type: "audio/ogg; codecs=opus" });
  }

  blobAsMP3() {
    return new Blob(this.chunks, { type: "audio/mp4" });
  }
}
