import { Dropdown } from "tailwindcss-stimulus-components"

export default class extends Dropdown {
  static targets = [
    "placeholder",
    "placeholderText",
    "mobilePlaceholderText",
    "searchInput",
    "emptyResultLabel",
    "select",
    "newOption",
    "newOptionButton",
    "input"
  ]
  static values = {
    fuzzySearch: { type: Boolean, default: false },
    placeholder: String
  }

  static classes = ["selected"]

  initialize() {
    this.fuzzySearchFilter = this.fuzzySearchFilter.bind(this)
    this.exactMatchFilter = this.exactMatchFilter.bind(this)
    this.activeIndex = -1 // in the case where user presses Arrow up/down for the first time, ensure first item is selected

    if(this.activeOption && !this.activeOption.hasAttribute('data-noplaceholder')) {
      this.changePlaceholder({ currentTarget: this.activeOption })
    }
  }

  onInputChange() {
    if(this.inputTarget.value.trim().length > 0) {
      this.show()
    } else {
      super._hide()
      this.removeSearchFilter()
    }
  }

  onInputBlur() {

  }

  openValueChanged() {
    if (this.openValue === false && this.hasSearchInputTarget) {
      setTimeout(() => {this.removeSearchFilter()}, 2)
    }

    if (this.openValue && this.hasSearchInputTarget) {
      setTimeout(() => this.searchInputTarget.focus(), 1)
    }

    this.dispatch("toggle", {
      detail: this.openValue
    })

    if(this.openValue) {
      this.menuTarget.classList.remove("hidden")
      this.menuTarget.setAttribute('aria-expanded', 'true')

      this.dispatch("opened", {
        detail: this.openValue
      })
    } else {
      this.menuTarget.classList.add("hidden")
      this.menuTarget.removeAttribute('aria-expanded')

      this.dispatch("hidden", {
        detail: this.openValue
      })
    }
  }

  hideDropdown() {
    console.log('hiding')
    this.openValue = false
  }

  toggle(e) {
    if (this.menuTarget.contains(e.currentTarget)) {
      if(!e.currentTarget.hasAttribute("data-noplaceholder")) {
        this.changePlaceholder(e)
      }

      this.applyActiveClassOn(e.currentTarget)

      if(this.hasInputTarget) {
        this.inputTarget.value = e.currentTarget.innerText
      }
    }

    super.toggle()
  }

  changePlaceholder({ currentTarget }) {
    if(this.hasPlaceholderTextTarget &&( currentTarget.dataset.placeholder?.trim().length > 0 || currentTarget.innerText?.trim().length > 0)) {

      const placeholderContent = currentTarget.querySelector("x-placeholder-content")?.cloneNode(true)

      if(placeholderContent) {
        placeholderContent.removeAttribute("hidden")
        placeholderContent.classList.remove("hidden")
      }


      this.placeholderTextTarget.innerHTML = placeholderContent?.outerHTML || currentTarget.dataset.placeholder || currentTarget.innerHTML

      if(this.hasMobilePlaceholderTextTarget) {
        this.mobilePlaceholderTextTarget.innerHTML = currentTarget.dataset.mobilePlaceholder || currentTarget.dataset.placeholder || currentTarget.innerHTML
      }
    }
  }

  setLabel({ detail }) {
    const option = Array.from(
      this.element.querySelectorAll("[data-option-value]")
    ).find(
      (optionElement) =>
        optionElement.dataset.optionValue.toLowerCase() === detail.toLowerCase()
    )

    if (option) {
      option.click()
    } else {
      this.placeholderTextTarget = detail
    }
  }

  clear() {
    this.selectTarget.value = ""
    this.placeholderTextTarget.innerHTML = this.placeholderValue

    if(this.activeOption) {
      this.activeOption.classList.remove(...this.activeElementClass)
    }
  }

  onKeydown(e) {
    if(this.openValue === false) return

    if (e.key === "Enter") {
      e.preventDefault()

      if(this.filteredElements[this.activeIndex || 0]) {
        this.filteredElements[this.activeIndex || 0]?.click()
      } else if(this.hasNewOptionTarget && this.newOptionTarget.classList.contains("hidden") === false) {
        this.newOptionButtonTarget.click()
      }
    } else if(e.key === "ArrowUp") {
      if(this.activeIndex !== 0) {
        this.activeIndex--
        this.mimicFocusBehaviourOnActiveIndex()
        e.preventDefault();
      }
    } else if(e.key === "ArrowDown") {
      if(this.activeIndex !== this.filteredElements.length - 1) {
        this.activeIndex++
        this.mimicFocusBehaviourOnActiveIndex()
        e.preventDefault();
      }
    }
  }

  disable({ detail: optionToSelect }) {
    this.selectTarget.disabled = true

    if(optionToSelect) {
      this.items.find(element => element.dataset.optionValue.toLowerCase() === optionToSelect.toLowerCase())
        ?.click()
    }
  }

  enable() {
    this.selectTarget.disabled = false
  }

  filter({ target }) {
    if (this.hasSearchInputTarget && target === this.searchInputTarget && target.value.trim().length === 0) {
      this.removeSearchFilter(null, true)

      return
    }

    if(this.fuzzySearchValue) {
      this.searchableElements.forEach((element) => {
        this.fuzzySearchFilter(element, target.value)
      })
    } else {
      this.searchableElements.forEach((element) => {
        this.exactMatchFilter(element, target.value)
      })
    }

    if (this.filteredElements.length === 0) {
      if(this.hasNewOptionTarget) {
        this.dispatch("new-option", { detail: this.searchInputTarget.value })
      } else if(this.hasEmptyResultLabelTarget){
        this.emptyResultLabelTarget.classList.remove("hidden")
      } else {
        this._hide()
      }

      if(this.hasNewOptionTarget && this.newOptionTarget.classList.contains("hidden") && this.hasEmptyResultLabelTarget) {
        this.emptyResultLabelTarget.classList.remove("hidden")
      }

      return
    } else {
      this.show()
    }

    if(this.hasEmptyResultLabelTarget) {
      this.emptyResultLabelTarget.classList.add("hidden")
    }

    this.activeIndex = 0

    this.filteredElements.forEach((filteredElement, index) => {
      if(index === this.activeIndex) {
        filteredElement.classList.add(...this.activeElementClass)
      } else {
        filteredElement.classList.remove(...this.activeElementClass)
      }
    })
  }

  checkOptionValidity({ detail: valid }) {
    if(valid) {
      this.dispatch("show", {
        target: this.newOptionTarget,
        detail: this.searchInputTarget.value
      })

      this.emptyResultLabelTarget.classList.add("hidden")
    } else {
      this.newOptionTarget.classList.add("hidden")
      this.emptyResultLabelTarget.classList.remove("hidden")
    }
  }

  removeSearchFilter(e, focusInput = true) {
    if(this.hasSearchInputTarget) {
      this.searchInputTarget.value = ""
    }

    this.searchableElements.forEach((element) => {
      element.classList.remove("hidden")

      if(!element.hasAttribute("data-active")) {
        element.classList.remove(...this.activeElementClass)
      }
    })

    if(this.hasEmptyResultLabelTarget) {
      this.emptyResultLabelTarget.classList.add("hidden")
    }

    if (focusInput && this.hasSearchInputTarget) {
      this.searchInputTarget.focus()
    }

    if(this.hasNewOptionTarget) {
      this.newOptionTarget.classList.add("hidden")
    }

    this.activeIndex = -1
  }

  // private

  mimicFocusBehaviourOnActiveIndex() {
    this.filteredElements.forEach((filteredElement, index) => {
      if(index === this.activeIndex) {
        filteredElement.classList.add(...this.activeElementClass)
      } else {
        filteredElement.classList.remove(...this.activeElementClass)
      }
    })
  }

  fuzzySearchFilter(element, value) {
    const searchableTerm = element.dataset.searchable.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")

    if (searchableTerm.includes(value.toLowerCase())) {
      element.classList.remove("hidden")
    } else {
      element.classList.add("hidden")
    }
  }

  exactMatchFilter(element, value) {
    const searchableTerm = element.dataset.searchable.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")

    if (searchableTerm.startsWith(value.toLowerCase())) {
      element.classList.remove("hidden")
    } else {
      element.classList.add("hidden")
    }
  }

  applyActiveClassOn(option) {
    if(this.hasNewOptionTarget && option === this.newOptionButtonTarget) return

    this.items.forEach((element) => {
      if(element !== option) {
        element.classList.remove(...this.activeElementClass)
        element.removeAttribute("data-active")
      }
    })

    const listItem = option.tagName === "LI" ? option : option.closest("li")
    listItem.classList.add(...this.activeElementClass)

    setTimeout(() => listItem.setAttribute("data-active", ""), 1)
  }

  get items() {
    return Array.from(this.menuTarget.querySelectorAll("li"))
  }

  get searchableElements() {
    return Array.from(this.menuTarget.querySelectorAll("[data-searchable]"))
  }

  get filteredElements() {
    return this.searchableElements.filter(
      (element) => element.classList.contains("hidden") === false
    )
  }

  get activeOption() {
    return this.items.find((element) => element.hasAttribute("data-active"))
  }

  get emptyResultMatchingQuery() {
    return !this.searchableElements.find((element) =>
      element.dataset.searchable
        .toLowerCase()
        .includes(this.searchInputTarget.value.toLowerCase())
    )
  }

  get activeElementClass() {
    return this.hasSelectedClass ? this.selectedClasses : ["bg-lavender-light"]
  }
}
