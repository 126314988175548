import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [
    'submit',
    'item',
  ]

  handleMapItemChange(event) {
    this.disableButton()

    let atLeastOneChecked = false

    const enable = this.itemTargets.every(element => {
      const isChecked = element.dataset['import-MapItemChecked'] === 'true'
      const isSelected = element.dataset['import-MapItemSelected'] === 'true'

      atLeastOneChecked = atLeastOneChecked || isChecked

      return isChecked && isSelected
      || !isChecked && !isSelected
      || !isChecked && isSelected
    })

    if (enable && atLeastOneChecked) this.enableButton()
  }

  disableButton() {
    this.submitTarget.disabled = true
  }

  enableButton() {
    this.submitTarget.disabled = false
  }

  itemTargetConnected() {
    this.itemTargets.forEach(item => {
      item.addEventListener('import-map-item:change', this.handleMapItemChange.bind(this))
    })
  }
}
