export const useDraggable = (controller) => {
  Object.assign(controller, {
    enableDragging() {
      this.draggable.classList.add(...this.dragClasses)
      this.dragging = true

      this.afterDragEnabled()
    },
    disableDragging(e) {
      if (this.element.contains(e.target) === false && this.dragging) {
        this.dragging = false
        this.draggable.classList.remove(...this.dragClasses)
        this.afterDragDisabled()
      }
    },
  })
}
