import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import { useDispatch } from "stimulus-use"

// attaches an infinite-scroll listener on either a specific scrollable element or
// the entire window(<body>)

// if you want to attach the listener to the window, you can do this
// data-pagination-attach-to-body-value="true", then the listener will be attached on the window
// rather than the element.

// If you omit this option, the controller will attach the event listener-
// on the element that has the declaration
//<element data-controller="pagination">

// make sure to return a <element data-pagination-target="lastPage" /> in the turbo stream response
// to indicate that there are no more pages. Thus, no more requests being sent.

export default class extends Controller {
  static targets = ["lastPage", "loadMoreContainer", "skeleton", "scrollable", "fetchNewPage"]

  static values = {
    threshold: Number,
    attachToBody: Boolean,
    url: String,
    page: {
      type: Number,
      default: 1,
    },
    hasNextPage: Boolean,
    scroll: Boolean,
    lazy: Boolean,
    eventPrefix: String,
  }

  initialize() {
    this.bodyScrollListener = this.bodyScrollListener.bind(this)
    this.scroll = this.scroll.bind(this)
    this.pageValue = this.pageValue || 1
    this.scrollValue = this.scrollValue || false
  }

  fetchNewPageTargetConnected() {
    this.urlValue = this.fetchNewPageTarget.dataset.url
    this.pageValue = this.fetchNewPageTarget.dataset.page || 1

    this._fetchNewPage()

    this.fetchNewPageTarget.remove()
  }

  connect() {
    useDispatch(this, { eventPrefix: this.eventPrefixValue || false })

    if (this.lazyValue) {
      this._fetchNewPage()
    }
    if (!this.scrollValue) return

    if (this.attachToBodyValue) {
      document.addEventListener("scroll", this.bodyScrollListener)
    } else if (this.hasScrollableTarget) {
      this.scrollableTarget.addEventListener("scroll", this.scroll)
    } else {
      this.element.addEventListener("scroll", this.scroll)
    }
  }

  scroll() {
    if (this.hasLastPageTarget) {
      this.dispatch("last-page")
    }

    if (this.scrollReachedEnd && !this.hasLastPageTarget && !this.fetching) {
      this._fetchNewPage()
    }
  }

  resetPage(e) {
    this.pageValue = e?.dataset?.page || 1
    this.pageValue = e?.detail?.page || 1

    if (this.hasLastPageTarget) {
      this.lastPageTarget.remove()
    }
  }

  lastPageTargetConnected() {
    this.element.querySelector(`[data-action="click->pagination#paginate"]`)
      ?.remove()
  }

  disconnect() {
    document.removeEventListener("scroll", this.bodyScrollListener)
    this.element.removeEventListener("scroll", this.scroll)

    super.disconnect()
  }

  async bodyScrollListener() {
    if (this.reachedEndOfBody && this.hasMorePagesLeft && !this.fetching) {
      this._fetchNewPage()
    }
  }

  async paginate(e) {
    if (this.hasMorePagesLeft) {
      await this._fetchNewPage()
      e.target.blur()
    } else {
      e.target.remove()
    }
  }

  async _fetchNewPage() {
    this.fetching = true
    this.dispatch("fetching")

    this._showSkeletonLoader()
    await this._performRequest()

    this.pageValue += 1

    this.dispatch("fetched")
    this.fetching = false

    this._hideSkeletonLoader()
  }

  _showSkeletonLoader() {
    if (this.hasSkeletonTarget) {
      this.skeletonTargets.forEach((el) => {
        el.classList.remove("hidden")
      })
    }
  }

  _hideSkeletonLoader() {
    setTimeout(() => {
      if (this.hasSkeletonTarget) {
        this.skeletonTargets.forEach((el) => {
          el.classList.add("hidden")
        })
      }
    }, 0)
  }

  async _performRequest() {
    await get(this.paginationUrl, {
      responseKind: "turbo-stream",
    })
  }

  get paginationUrl() {
    return this.urlValue.concat(
      `${
        this.urlValue.includes("?")
          ? `&page=${this.pageValue}`
          : `?page=${this.pageValue}`
      }`
    )
  }

  get reachedEndOfBody() {
    return (
      document.documentElement.scrollTop >=
      document.documentElement.scrollHeight - document.body.offsetHeight - 100
    )
  }

  get scrollReachedEnd() {
    if (this.hasScrollableTarget) {
      return (
        this.scrollableTarget.scrollTop >=
        this.scrollableTarget.scrollHeight -
          this.scrollableTarget.offsetHeight -
          100
      )
    } else {
      return (
        this.element.scrollTop >=
        this.element.scrollHeight - this.element.offsetHeight - 100
      )
    }
  }

  get hasMorePagesLeft() {
    return !this.hasLastPageTarget
  }
}
