import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static values = {
    stripePublicKey: String
  }

  static targets = [
    "cardholderName",
    "errorsContainer",
    "incompleteCardNumber",
    "incompleteCardExpiry",
    "incompleteCardCvc",
    "incompleteCardholderName",
    "cardExpYear",
    "cardExpMonth",
    "cardLast4",
    "cardToken",
    "cardBrand",
    "submit"
  ]

  initialize() {
    this.applyActiveClasses = this.applyActiveClasses.bind(this)
    this.removeActiveClasses = this.removeActiveClasses.bind(this)
    this.onStripeElementChange = this.onStripeElementChange.bind(this)
    this.onSubmitByEnter = this.onSubmitByEnter.bind(this)

    this.state = {
      cardNumberValid: false,
      cardExpiryValid: false,
      cardCvcValid: false,
      cardholderNameValid: false,
    }
  }

  connect() {
    this.element.addEventListener("submit", this.onSubmitByEnter)

    this.stripe = Stripe(this.stripePublicKeyValue, { locale: this.userLocale });
    const elements = this.stripe.elements({});

    const style = {
      base: {
        fontFamily: '"GT Walsheim", "system-ui", -apple-system, Helvetica, Arial, sans-serif',
      },
      invalid: {
        color: '#E25950',
      },
    };

    this.cardNumberElement = elements.create('cardNumber', {
      style
    });

    this.cardExpiryElement = elements.create("cardExpiry", {
      style
    })

    this.cardCvcElement = elements.create("cardCvc", {
      style
    })

    this.cardNumberElement.on("focus", this.applyActiveClasses)
    this.cardNumberElement.on("blur", this.removeActiveClasses)
    this.cardNumberElement.on("change", this.onStripeElementChange)

    this.cardExpiryElement.on("focus", this.applyActiveClasses)
    this.cardExpiryElement.on("blur", this.removeActiveClasses)
    this.cardExpiryElement.on("change", this.onStripeElementChange)

    this.cardCvcElement.on("focus", this.applyActiveClasses)
    this.cardCvcElement.on("blur", this.removeActiveClasses)
    this.cardCvcElement.on("change", this.onStripeElementChange)

    this.cardNumberElement.mount("card-number")
    this.cardExpiryElement.mount("card-expiry")
    this.cardCvcElement.mount("card-cvc")
  }

  async attemptSubmit() {
    if(this.cardholderNameTarget.value.trim().length === 0) {
      this.show(this.incompleteCardholderNameTarget)
      this.addClass(this.cardholderNameTarget, "error")
      return
    }

    this.disable(this.submitTarget)
    this.submitTarget.innerText = this.submitTarget.dataset.disableWith

    const {paymentMethod, error} = await this.stripe.createPaymentMethod({
      type: "card",
      card: this.cardNumberElement,
      billing_details: {
        name: this.cardholderNameTarget.value,
      },
    })

    if(paymentMethod) {
      document.getElementById("default__input_group").classList.remove("error")

      this.cardLast4Target.value = paymentMethod.card.last4
      this.cardExpMonthTarget.value = paymentMethod.card.exp_month
      this.cardExpYearTarget.value = paymentMethod.card.exp_year
      this.cardBrandTarget.value = paymentMethod.card.brand

      this.cardTokenTarget.value = paymentMethod.id

      this.element.removeEventListener("submit", this.onSubmitByEnter)
      this.submit()
    }

    if(error) {
      this.enable(this.submitTarget)
      this.submitTarget.innerText = this.submitTarget.dataset.label

      document.getElementById("default__input_group").classList.add("error")

      if(error.code === "incomplete_number") {
        this.showErrorFor(this.incompleteCardNumberTarget)
      } else if(error.code === "incomplete_expiry") {
        this.showErrorFor(this.incompleteCardExpiryTarget)
      } else {
        this.showErrorFor(this.incompleteCardCvcTarget)
      }
    }
  }

  onCardholderNameChange() {
    if(this.cardholderNameTarget.value.trim().length === 0) {
      this.show(this.incompleteCardholderNameTarget)
      this.addClass(this.cardholderNameTarget, "error")
    } else {
      this.hide(this.incompleteCardholderNameTarget)
      this.removeClass(this.cardholderNameTarget, "error")
    }

    this.state.cardholderNameValid = this.cardholderNameTarget.value.trim().length > 0
    this.syncSubmitButton()
  }

  submitOnEnter(e) {
    if(e.key === 'Enter' && Object.values(this.state).every(Boolean)) {
      this.attemptSubmit()
    }
  }

  // private

  syncSubmitButton() {
    this.submitTarget.disabled = !Object.values(this.state).every(Boolean)
  }

  onStripeElementChange(event) {
    if (event.complete) {
      this.removeClass(document.getElementById("default__input_group"), "error")
    } else {
      this.addClass(document.getElementById("default__input_group"), "error")
    }

    this.state[`${event.elementType}Valid`] = event.complete
    this.syncSubmitButton()
  }

  applyActiveClasses() {
    this.addClass(document.getElementById("default__input_group"), "ring-4", "border-indigo-60")
  }

  removeActiveClasses() {
    document.getElementById("default__input_group").classList.remove("ring-4", "border-indigo-60")
  }

  showErrorFor(target) {
    target.classList.remove("hidden")
    this.errorLabels.filter(label => label !== target)
      .forEach(label => label.classList.add("hidden"))
  }

  onSubmitByEnter(e) {
    e.preventDefault()
    this.attemptSubmit()
  }

  submit() {
    this.element.requestSubmit()
  }

  get errorLabels() {
    return Array.from(this.errorsContainerTarget.children)
  }
}
