import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    searchUrl: String
  }

  static targets = ["contactsList", "clearButton", "searchInput"]

  showContactsList() {
    this.contactsListTarget.classList.remove("hidden")
  }

  search() {
    get(this.searchUrlValue, {
      query: { search: this.searchInputTarget.value },
      responseKind: "turbo-stream"
    })

    if(this.searchInputTarget.value.trim().length > 0) {
      this.clearButtonTarget.classList.replace("opacity-0", "opacity-100")
    } else {
      this.clearButtonTarget.classList.replace("opacity-100", "opacity-0")
    }
  }

  clearSearch() {
    this.searchInputTarget.value = ""
    this.search()
  }
}
