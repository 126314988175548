import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hidden"]

  initialize() {
    this.shown = false
  }

  toggleHiddenElements() {
    if(this.shown) {
      this.hideElements()
    } else {
      this.shown = true

      this.hiddenTargets.forEach(element => {
        element.style.top = element.dataset.style

        setTimeout(() => {
          element.classList.remove("-z-50")
        }, 300) // wait for transition to finish
      })
    }
  }

  onWindowClick({ target }) {
    if(this.element.contains(target) === false) {
      this.hideElements()
    }
  }

  // private

  hideElements() {
    this.shown = false

    this.hiddenTargets.forEach(element => {
      element.style.top = "0"
      element.classList.add("-z-50")
    })
  }
}
