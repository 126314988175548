import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    qrCode: String,
    filename: String,
  }

  downloadAsSVG() {
    const a = document.createElement("a")
    const blob = new Blob([this.qrCodeValue], { type: "image/svg+xml" })
    const url = URL.createObjectURL(blob)
    a.setAttribute("href", url)
    a.setAttribute("download", this.filenameValue)
    a.click()
  }
}
