import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  setActiveStep({ detail }) {
    this.activeStepIdValue = detail
    window.activePopupStepId = this.activeStepIdValue
  }

  change(e) {
    this.changeEveryLayoutOfSteps(e)
    window.mobileLayout = e.currentTarget.dataset.kind
  }

  changeDesktopLayout(e) {
    this.changeEveryLayoutOfSteps(e)
    window.desktopLayout = e.currentTarget.dataset.kind
  }

  setButtonStyles({ detail: { state } }) {
    this.buttonStyles = state
  }

  // private

  changeEveryLayoutOfSteps({ currentTarget }) {
    const overlayBackgroundColor = document.querySelector(
      "input[name='popup[overlay][background_color]']"
    ).value
    Object.entries(window.popupSteps).forEach(([k, _]) => {
      const hero = document
        .getElementById(k)
        .querySelector("[data-controller*='popup--step--hero'] trix-editor")
      const notice = document
        .getElementById(k)
        .querySelector("[data-controller*='popup--step--notice'] trix-editor")
      get(currentTarget.dataset.url, {
        query: {
          within: k,
          fields: JSON.stringify(window.popupSteps[k].fields),
          button: JSON.stringify(this.buttonStyles),
          hero: hero.value,
          notice: notice.value,
          overlayBackgroundColor,
        },
        responseKind: "turbo-stream",
      })
    })
  }
}
