export const useToastNotifiers = (controller) => {
  Object.assign(controller, {
    showToastErrorMessage(message, { autoHide = false } = {}) {
      window.dispatchEvent(
        new CustomEvent("toast:show", {
          detail: {
            message,
            type: "error",
            autoHide
          },
        })
      )
    },
    showToastInformationMessage(message, { autoHide = false } = {}) {
      window.dispatchEvent(
        new CustomEvent("toast:show", {
          detail: {
            message,
            type: "information"
          },
        })
      )
    }
  })
}
