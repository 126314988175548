import { Current } from "./current";
import {dinero, toDecimal} from "dinero.js";
import { USD } from "@dinero.js/currencies";

class MoneyFormatter {
  constructor(currency = 'USD', symbol = '$') {
    this.options = {
      style: 'currency',
      currency: currency || 'USD',
      minimumFractionDigits: 0, // Set minimumFractionDigits to 0 to remove trailing zeros
    }

    this.symbol = symbol
  }

  // Returns a formatted amount that acts like humanized_money_with_symbol on the server
  humanizeWithSymbol(cents) {
    const amount = Math.round(cents / 10000)

    const formattedAmount = this.formatter.formatToParts(amount).reduce((acc, part) => {
      if (part.type !== 'currency') {
        return acc + part.value;
      }
      return acc;
    }, '');

    if(Current.locale === 'es') {
      return `${this.symbol}${formattedAmount}${this.options.currency}`
    } else {
      return `${this.symbol}${formattedAmount} ${this.options.currency}`
    }
  }

  transformer({ value, currency }) {
    return Number(value).toLocaleString(Current.locale, {
      ...this.options,
      style: 'currency',
      currency: currency.code,
    });
  };

  get formatter() {
    return new Intl.NumberFormat(Current.locale, this.options);
  }
}

export { MoneyFormatter }
