import PaneController from "../pane_controller"
import { destroy } from "@rails/request.js"

export default class extends PaneController {
  static values = {
    url: String,
    selectedList: String,
  }

  static targets = ["input", "information", "form"]

  async destroyResource(e) {
    const response = await destroy(e.target.dataset.url, {
      responseKind: "turbo-stream",
    })

    if (response.ok) {
      this.hide()
    }
  }

  checkFormStatus(e) {
    if (e.detail.success) {
      this.hide()
    }
  }

  reset() {
    this.inputTarget.value = ""
  }

  hide() {
    if (window.location.href.includes("campaigns") && this.hasFormTarget) {
      if (!this.formTarget.classList.contains("hidden")) {
        this.informationTarget.classList.remove("hidden")
        this.formTarget.classList.add("hidden")

        return
      }
    }

    super.hide()
  }
}
