import Application_controller from '@/controllers/application_controller'

export default class extends Application_controller {
  static values = {
    persisted: { type: Boolean, default: false }
  }

  static targets = [
    'addCommentButton',
    'commentContainer',
    'commentInput',
    'kindInput',
    'idInput',
    'destroyInput',
  ]

  connect() {
    this.kindInputTarget.value = this.element.dataset.kind
    this.disable(this.destroyInputTarget)

    super.connect()
  }

  addComment() {
    this.hide(this.addCommentButtonTarget)

    this.show(this.commentContainerTarget)

    this.enable(this.commentInputTarget)
    this.focus(this.commentInputTarget, { moveCursorToEnd: true })
  }

  removeComment() {
    this.show(this.addCommentButtonTarget)

    this.hide(this.commentContainerTarget)
    this.disable(this.commentInputTarget)
  }

  remove() {
    if(this.persistedValue) {
      const newElement = document.createElement('div')

      newElement.classList.add('hidden')
      this.enable(this.destroyInputTarget)

      this.element.querySelectorAll('input, textarea').forEach(input => {
        newElement.appendChild(input.cloneNode(true))
      })

      this.element.replaceWith(newElement)
    } else {
      this.element.remove()
    }
  }
}
