import NotificationEventsController from '../notification/events_controller'

export default class extends NotificationEventsController {
  static values = {
    state: String,
  }

  static targets = ['importAll', 'autoImport', 'autoImportButton']

  connect() {
    super.connect()

    this.updateSubmitButtonLabel()
  }

  autoImportValueChanged() {
    this.updateSubmitButtonLabel()
  }

  updateSubmitButtonLabel() {
    if(this.autoImportTarget.checked || (this.hasImportAllTarget && this.importAllTarget.checked) || this.checkboxTargets.some(checkbox => checkbox.checked)) {
      this.show(this.submitTarget)
      this.hide(this.skipTarget)
    } else {
      this.hide(this.submitTarget)
      this.show(this.skipTarget)
    }
  }

  syncSubmitButton() {
  }

  get draft() {
    return this.stateValue === 'draft'
  }
}
