import CloneableController from '../../cloneable_controller'

export default class extends CloneableController {
  static values = {
    limit: Number,
    edit: {type: Boolean, default: false}
  }

  static targets = ['item', 'template']

  itemTargetConnected(item) {
    if (this.itemTargets.length === 1) {
      this.hideRemoveButtonFor(item)
    } else if (this.editValue) {
      this.itemTargets.forEach((item) => this.showRemoveButtonFor(item))
    } else {
      this.itemTargets
        .filter((item, index) => index !== this.itemTargets.length - 1)
        .forEach((item) => this.showRemoveButtonFor(item))
    }
  }

  itemTargetDisconnected(item) {
    if (this.itemTargets.length === 1) {
      this.hideRemoveButtonFor(this.itemTargets[0])
    } else {
      this.itemTargets.forEach((item, index) => {
        if (index === this.itemTargets.length - 1) {
          this.hideRemoveButtonFor(item)
        } else {
          this.showRemoveButtonFor(item)
        }
      })
    }
  }

  insertEmptyRow({srcElement}) {
    if(this.limitValue === 1) return

    if (this.hasReachedLimit) {
      return this.showRemoveButtonFor(srcElement)
    }

    const clonedTemplate = this.templateTarget.cloneNode(true)
    clonedTemplate.setAttribute('data-compose--template--button-list-target', 'item')

    this.hide(clonedTemplate.querySelector('[data-button="remove"]'))

    this.show(clonedTemplate)
    this.element.appendChild(clonedTemplate)

    if (srcElement) {
      this.showRemoveButtonFor(srcElement)
    }

    clonedTemplate.scrollIntoView()
    this.hideRemoveButtonFor(clonedTemplate)
  }

  removeEmptyRow({srcElement}) {
    if (this.itemTargets.length === 1) return

    const lastItem = this.itemTargets[this.itemTargets.length - 1]
    if (lastItem.hasAttribute('data-valid')) return

    srcElement.nextElementSibling?.remove()
  }

  handleChildListChange(mutations) {
    const mutation = mutations.find(mutation => mutation.type === 'childList')
    if (!mutation) return

    if (this.itemTargets.length === 1) {
      const item = this.itemTargets[0]

      this.hideRemoveButtonFor(item)
      this.removeClass(item.querySelector('aside'), 'w-9')

      this.dispatchEventTo(item, 'focus')

      if (item.hasAttribute('data-valid')) {
        this.insertEmptyRow({srcElement: item})
      }
    }
  }

  removeItemAndFocusNextElement({srcElement}) {
    if (srcElement.nextElementSibling) {
      this.dispatchEventTo(srcElement.nextElementSibling, 'focus')
    } else if (srcElement.previousElementSibling) {
      this.dispatchEventTo(srcElement.previousElementSibling, 'focus')
    }

    srcElement.remove()

    if(!this.hasEmptyRow) {
      this.insertEmptyRow({ srcElement: this.itemTargets[this.itemTargets.length - 1] })
    }
  }

  focus({detail}) {
    const position = detail.position || 0

    this.nextTick(() => {
      this.dispatch('focus', {
        target: this.itemTargets[position]
      })
    })
  }

  focusNextElement({ srcElement }) {
    const item = srcElement.closest('[data-compose--template--button-list-target="item"]')

    if(item.nextElementSibling) {
      this.dispatchEventTo(item.nextElementSibling, 'focus')
      item.nextElementSibling.scrollIntoView()
    } else {
      this.dispatch('save')
    }
  }

  focusLastItem() {
    const item = this.itemTargets[this.itemTargets.length - 1]
    this.dispatchEventTo(item, 'focus')
  }

  toObject() {
    return {
      type: this.element.dataset.type,
      buttons: this.itemTargets
        .filter((item) => item.hasAttribute('data-valid'))
        .map((item) =>
          this.application
            .getControllerForElementAndIdentifier(item, `compose--template--${this.controllerName}-button`)
            .toObject()
        )
    }
  }

  reset() {
    this.element
      .querySelectorAll('[data-compose--template--button-manager-target="button"]:not([data-compose--template--button-list-target="template"])')
      .forEach((button, index) => {
        if (index === 0) {
          button.removeAttribute('data-valid')
          button.querySelectorAll('input').forEach(this.clearInput)
          this.dispatchEventTo(button, 'reset')
        } else {
          button.remove()
        }
      })
  }

  get controllerName() {
    return this.element.dataset.type.replace('_', '-')
  }

  showRemoveButtonFor(item) {
    this.show(item.querySelector('[data-button="remove"]'))
  }

  get hasReachedLimit() {
    return this.itemTargets.length >= this.limitValue
  }

  get isScrollable() {
    return this.element.scrollHeight > this.element.clientHeight
  }

  get hasEmptyRow() {
    const lastItem = this.itemTargets[this.itemTargets.length - 1]
    return !lastItem.hasAttribute('data-valid')
  }
}
