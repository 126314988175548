import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static values = {
    type: String,
    added: Number,
    unitMonthlyPrice: Object,
    unitYearlyPrice: Object,
  }

  static targets = ['increaseButton', 'decreaseButton', 'cell']

  initialize() {
    super.initialize();
    this.addedValue = this.minimumValue
  }

  increase() {
    this.addedValue += 1
  }

  decrease() {
    this.addedValue -= 1
  }

  onMobileValueChanged({ detail }) {
    if(detail.type !== this.typeValue) return

    this.addedValue = detail.added
  }

  onDesktopValueChanged({ detail }) {
    if(detail.type !== this.typeValue) return

    this.addedValue = detail.added
  }

  addedValueChanged(value, oldValue) {
    if (this.addedValue && this.addedValue > this.minimumValue) {
      this.enable(this.decreaseButtonTarget)
    } else {
      this.disable(this.decreaseButtonTarget)
    }

    // On mobile, the adjustable controls are disabled in another different row/element.
    // When changed, we need to change it's sibling, which is the view displayed on desktop.
    if(this.cellTargets.length === 0) {
      this.dispatch(`${this.typeValue}:mobile:change`, {
        target: document.documentElement,
        detail: {
          type: this.typeValue,
          added: this.addedValue,
        }
      })

      return
    }

    // On desktop, when the value is changed, we need to update the view on mobile.
    // This is a way to not trigger an infinite loop where both views are trying to update each other.
    this.element.previousElementSibling.setAttribute('data-static--pricing--adjustable-added-value', this.addedValue)

    this.cellTargets.forEach(cell => {
      const available = parseInt(cell.dataset.available)

      const list = cell.querySelector('ul')

      if (this.addedValue > available) {
        const added = this.addedValue - available

        if (list.children.length > 1) {
          list.removeChild(list.children[1])
        }

        const listItem = list.children[0].cloneNode(true)

        listItem.children[0].innerText = added === 1
          ? this.translations.static.pricing.features[this.typeValue].overflow.one
          : this.translations.static.pricing.features[this.typeValue].overflow.other.replace('%{count}', added)

        list.appendChild(listItem)

        const action = value < oldValue ? 'decrease' : 'increase'

        this.dispatch(`${this.typeValue}:${action}`, {
          target: document.querySelector(
            `[data-controller='static--pricing--package'][data-package-id='${cell.dataset.packageId}']`
          ),
          detail: {
            available: added + available,
            type: this.typeValue,
          }
        })
      } else if (list.children.length > 1) {
        list.removeChild(list.children[1])

        const added = Math.abs(this.addedValue - available)

        this.dispatch(`${this.typeValue}:decrease`, {
          target: document.querySelector(
            `[data-controller='static--pricing--package'][data-package-id='${cell.dataset.packageId}']`
          ),
          detail: {
            available: added + available,
            type: this.typeValue,
          }
        })
      }
    })
  }

  get recommendedCell() {
    return this.cellTargets.find(cell => cell.hasAttribute('data-recommended'))
  }

  get minimumValue() {
    if(this.cellTargets.length === 0) {
      return Array.from(this.element.nextElementSibling.querySelectorAll('td:not(:first-child)')).map(cell => parseInt(cell.dataset.available)).sort((a, b) => a - b)[0]
    } else {
      return this.cellTargets.map(cell => parseInt(cell.dataset.available)).sort((a, b) => a - b)[0]
    }
  }
}
