import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "destinationNumber", "input"]

  connect() {
    this.moveCursorToEnd()
    super.connect()
  }

  onChannelChange({ currentTarget }) {
    this.destinationNumberTarget.innerText = currentTarget.dataset.channel.split("+")[1]
  }

  onContentChange() {
    this.contentTarget.innerText = this.inputTarget.value
  }

  // private

  moveCursorToEnd() {
    this.inputTarget.selectionStart = this.inputTarget.selectionEnd = 10000
  }
}
