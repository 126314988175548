import { Controller } from "@hotwired/stimulus"
import { useColorPickerVisibilityControls } from "../mixins/useColorPickerVisibilityControls"

export default class extends Controller {
  static targets = [
    "content",
    "backgroundPicker",
    "overlay",
    "actions",
    "backgroundButton",
  ]

  connect() {
    if (this.hasBackgroundPickerTarget) {
      useColorPickerVisibilityControls(this, {
        pickerTarget: this.backgroundPickerTarget,
        afterColorPickerShow: () => {
          this.actionsTarget.classList.replace("hidden", "flex")
        },
        onColorPickerHide: () => {
          this.actionsTarget.classList.replace("flex", "hidden")
        },
      })
    }

    if (window.popup?.color) {
      this.element.style.color = window.popup?.color
    }

    if (window.popup?.backgroundColor) {
      this.element.style.backgroundColor = window.popup?.backgroundColor
    }

    if (window.popup?.fontSizeBase) {
      this.multiplyFontSizeWithBase(window.popup.fontSizeBase)
    }

    if (window.popup?.image) {
      this.element.style.backgroundImage = `url(${window.popup.image.path})`
      if (window.popup.image.fit === "fill") {
        this.element.style.backgroundSize = "cover"
      } else {
        this.element.style.backgroundRepeat = "repeat"
      }
    }

    this.dispatch("connected")
  }

  changeTextColor({ detail }) {
    this.element.style.color = detail.color
  }

  changeBackgroundColor({ detail }) {
    this.element.style.backgroundColor = detail.color
  }

  changeTextSize({ detail }) {
    this.multiplyFontSizeWithBase(detail.size)
  }

  changeBodyBackground({ detail }) {
    this.element.style.backgroundImage = `url(${detail.imagePath})`
    this.element.style.backgroundSize = "cover"
  }

  changeBodyBackgroundFitToMosaic() {
    this.element.style.backgroundSize = "contain"
    this.element.style.backgroundRepeat = "repeat"
  }

  changeBodyBackgroundFitToFill() {
    this.element.style.backgroundSize = "cover"
    this.element.style.removeProperty("background-repeat")
  }

  removeBodyBackground() {
    this.element.style.removeProperty("background-size")
    this.element.style.removeProperty("background-repeat")
    this.element.style.removeProperty("background-image")
  }

  changeOverlayBackgroundColor({ detail: rgbaString, target }) {
    if (target !== this.backgroundPickerTarget) return

    const rgba = this.getRGBValues(rgbaString)

    rgbaString = `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`
    this.overlayTarget.style.backgroundColor = rgbaString

    this.dispatch("overlay:background-color-changed", {
      target: document.documentElement,
      detail: rgbaString,
    })
  }

  hidePicker(e) {
    if (this.backgroundButtonTarget.contains(e?.target) === false) {
      this.backgroundPickerTarget.classList.add("hidden")
      this.actionsTarget.classList.replace("flex", "hidden")
    }
  }

  getRGBValues(str) {
    const vals = str.substring(str.indexOf("(") + 1, str.length - 1).split(", ")
    return {
      r: vals[0],
      g: vals[1],
      b: vals[2],
      a: 0.4,
    }
  }

  syncOverlayBackgroundImage({ detail: rgbaColor }) {
    this.overlayTarget.style.backgroundColor = rgbaColor
  }

  // private

  multiplyFontSizeWithBase(base) {
    const paragraphTags = this.element.querySelectorAll(
      "[data-kind='paragraph']"
    )
    const headerTags = this.element.querySelectorAll("[data-kind='heading']")
    const smallTags = this.element.querySelectorAll("[data-kind='small']")

    paragraphTags.forEach((element) => {
      const size =
        Number.parseFloat(element.dataset.size) * Number.parseFloat(base)
      element.style.fontSize = `${size}px`
    })

    headerTags.forEach((element) => {
      const size =
        Number.parseFloat(element.dataset.size) * Number.parseFloat(base)
      element.style.fontSize = `${size}px`
    })

    smallTags.forEach((element) => {
      const size =
        Number.parseFloat(element.dataset.size) * Number.parseFloat(base)
      element.style.fontSize = `${size}px`
    })
  }
}
