import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["newCondition", "container", "input"]
  static values = {
    stepId: String,
  }

  connect() {
    this.inputTargets.forEach((input) => {
      input.setAttribute("data-saved-state", input.value)
    })
    super.connect()
  }

  saveState({ detail }) {
    if (detail.stepId !== this.stepIdValue) return

    this.inputTargets.forEach((input) => {
      input.setAttribute("data-saved-state", input.value)
    })
  }

  restoreSavedState({ detail: stepId }) {
    if (stepId !== this.stepIdValue) return

    this.inputTargets.forEach((input) => {
      input.value = input.dataset.savedState
    })
  }

  disableSubmit() {
    if (this.hasEmptyInput) {
      this.dispatch("disable-submit")
    }
  }

  disable() {
    this.containerTarget
      .querySelectorAll("input, select")
      .forEach((element) => (element.disabled = true))
  }

  enable() {
    this.containerTarget
      .querySelectorAll("input, select")
      .forEach((element) => (element.disabled = false))
  }

  // private

  get hasEmptyInput() {
    return (
      this.inputTargets.filter(
        (input) =>
          input.type === "text" &&
          !input.disabled &&
          input.value.trim().length === 0
      ).length > 0
    )
  }
}
