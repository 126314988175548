import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [
    'form',
    'compose',
  ]

  submit({ detail: composeSnapshot }) {
    const { template, templateChanged } = composeSnapshot

    if(template && ['medium', 'low'].includes(template.quality) && !templateChanged) {
      this.dispatch('show', {
        target: this.element,
        detail: template.quality,
      })
    } else {
      this.formTarget.requestSubmit()
    }
  }

  submitAnyway() {
    this.formTarget.requestSubmit()
  }

  editTemplate() {
    this.dispatch('edit', {
      target: this.composeTarget,
    })
  }
}
