import PaneController from "../pane_controller"
import { get } from "@rails/request.js"

export default class extends PaneController {
  async openWithForm(e) {
    await get(e.target.dataset.url, { responseKind: "turbo-stream" })

    let event = new CustomEvent(e.target.dataset.event, {
      bubbles: true,
      cancelable: true,
    })

    window.dispatchEvent(event)
  }
}
