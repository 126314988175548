import { Controller } from "@hotwired/stimulus"
import lax from "lax.js"

export default class extends Controller {
  connect() {
    // window.onload = function () {
    window.lax = { presets: lax.presets }

    lax.init()

    lax.addDriver("scrollY", function () {
      // return window.scrollY
      return window.scrollY
    })

    // Add animation bindings to elements
    lax.addElements(".use-cases-row-1", {
      scrollY: {
        translateX: [
          ["elInY", "elCenterY", "elOutY"],
          [0, -200, -400],
        ],
      },
    })

    // Add animation bindings to elements
    lax.addElements(".use-cases-row-2", {
      scrollY: {
        translateX: [
          ["elInY", "elCenterY", "elOutY"],
          [-600, -400, -200],
        ],
      },
    })
  }

  disconnect() {
    delete window.lax
  }
}
