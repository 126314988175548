import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    country: String
  }

  static targets = [
    'nameInput',
    'slugInput',
    'submitButton',
  ]

  suggestSlug({ target }) {
    const value = target.value.trim().toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")
    const regex = new RegExp(/[\W_\s]+/, "g")

    this.slugInputTarget.value = value.trim().replaceAll(regex, "-")
  }

  sync() {
    this.submitButtonTarget.disabled = this.nameInputTarget.value.trim() === "" || this.slugInputTarget.value.trim() === "" || this.countryValue === ""
  }

  countryChanged({ target }) {
    this.countryValue = target.value
    this.sync()
  }
}
