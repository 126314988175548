import {patch} from "@rails/request.js"

import DragController from "../drag_controller"

export default class extends DragController {
  end(event) {
    const { draggableUrl } = event.item.dataset

    let data = {
      position: event.newIndex + 1,
    }

    patch(draggableUrl, {
      responseKind: "turbo-stream",
      body: data
    })

    this.dispatch("end", {
      target: event.item
    })
  }
}
