class Character {
  static fromPiece(piece) {
    return new Character(piece?.string?.charAt(0) || '')
  }

  static fromKeyboardEvent(event) {
    return new Character(event.key)
  }

  constructor(key) {
    this.key = key
  }

  get isWhitespace() {
    return this.key === ' '
  }

  get isEmptySpace() {
    return this.key === ''
  }

  get isZeroWidthNonJoinerSpace() {
    return this.key.charCodeAt(0) === 8204
  }

  get isLineFeed() {
    return this.key.charCodeAt(0) === 10
  }

  get isOpeningBrace() {
    return this.key === '{'
  }

  get isAstrix() {
    return this.key === '*'
  }

  get isClosingBrace() {
    return this.key === '}'
  }

  get isAtSymbol() {
    return this.key === '@'
  }

  get isForwardSlash() {
    return this.key === '\/'
  }
}

class KeyboardKey {
  static fromKeyboardEvent(event) {
    return new KeyboardKey(event)
  }

  constructor(event) {
    this.key = event.key
    this.event = event
  }

  get isHotkey() {
    return this.activateBold
      || this.activateItalic
      || this.activateLink
      || this.isShiftEnter
      || this.isEnter
      || this.isPasting
      || this.isEscape
  }

  get length() {
    return this.key.length
  }

  get isEscape() {
    return this.key === 'Escape'
  }

  get activateBold() {
    return (this.event.metaKey || this.event.ctrlKey) && this.key === 'b'
  }

  get activateItalic() {
    return (this.event.metaKey || this.event.ctrlKey) && this.key === 'i'
  }

  get activateLink() {
    return (this.event.metaKey || this.event.ctrlKey) && this.key === 'k'
  }

  get isPasting() {
    return (this.event.metaKey || this.event.ctrlKey) && this.key === 'v'
  }

  get isShiftEnter() {
    return this.isShift && this.isEnter
  }

  get isShift() {
    return this.event.shiftKey || this.key === 'Shift'
  }

  get isEnter() {
    return this.key === 'Enter'
  }

  get isArrow() {
    return ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(this.key)
  }

  get isLeftArrow() {
    return this.key === 'ArrowLeft'
  }

  get isRightArrow() {
    return this.key === 'ArrowRight'
  }

  get isVerticalArrow() {
    return this.isUpArrow || this.isDownArrow
  }

  get isUpArrow() {
    return this.key === 'ArrowUp'
  }

  get isDownArrow() {
    return this.key === 'ArrowDown'
  }

  get isWhitespace() {
    return this.key === ' '
  }

  get hasAnyModifier() {
    return this.event.metaKey || this.event.ctrlKey
  }

  get isTab() {
    return this.key === 'Tab'
  }

  get isBackspace() {
    return this.key === 'Backspace'
  }

  get isAlphanumeric() {
    if(this.isShift) {
      return this.key !== 'Shift' && !this.isPunctuation
    }

    return /^[A-Za-z0-9]$/i.test(this.key) && !this.isPunctuation && !this.isWhitespace && !this.hasAnyModifier && !this.isArrow
  }

  get isAlphabetic() {
    return /^[a-zA-Z]$/i.test(this.key)
  }

  get isNumeric() {
    return /^[0-9]$/.test(this.key) && this.length === 1
  }

  get isPunctuation() {
    return /^[.,:;!?]$/i.test(this.key)
  }
}

export { Character, KeyboardKey }
