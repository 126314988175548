import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    active: String,
    url: String,
  }

  static targets = ["anchor"]

  open() {
    const form = document.getElementById("popup_form")
    const oldAction = form.action
    form.action = this.urlValue

    // form.setAttribute("target", "_blank")
    form.submit()

    form.action = oldAction
    form.removeAttribute("target")
    //
    // const url = new URL(this.urlValue.replace(":id", this.activeValue.split("_step")[1]))
    // console.log(window.popupSteps[this.activeValue])
    // Object.entries(window.popupSteps[this.activeValue].fields)
    //   .forEach(([k, v]) => {
    //     url.searchParams.append("fields[]", JSON.stringify(v))
    //   })
    //
    // console.log(url.toString())
    // this.anchorTarget.href = url.toString()
    // this.anchorTarget.click()
  }

  close() {
    document
      .querySelectorAll(".preview-off")
      .forEach((el) => el.classList.remove("hidden"))

    document
      .querySelectorAll(".preview-on")
      .forEach((el) => el.classList.add("hidden"))
  }
}
