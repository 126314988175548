import DraggableController from "../draggable_controller"

export default class extends DraggableController {
  static targets = ["field", "show"]

  initialize() {
    super.initialize()

    this.onInputFocus = this.onInputFocus.bind(this)
    this.onInputBlur = this.onInputBlur.bind(this)
  }

  connect() {
    this.enabled = true

    this.element.querySelectorAll('input').forEach(input => {
      input.addEventListener('focus', this.onInputFocus)
      input.addEventListener('blur', this.onInputBlur)
    })
  }

  onInputFocus() {
    this.element.setAttribute('data-line-active', true)
  }

  onInputBlur() {
    this.element.removeAttribute('data-line-active')
  }

  enable() {
    super.enable()
    this.element.classList.add("group")
  }

  disableDragging() {
    super.disable()
    this.element.classList.remove("group")
  }

  showTargetConnected() {
    this.element.classList.add("group")
    this.element.classList.add("not-draggable")

    this.showTarget.remove()
  }

  onDragEnd() {
    this.element.classList.add("group")
    this.element.classList.add("not-draggable")
  }
}
