import ApplicationController from '../application_controller'
import { KeyboardKey } from "../compose/models";

export default class extends ApplicationController {
  initialize() {
    super.initialize()

    this.onKeydown = this.onKeydown.bind(this)
    this.onContentPaste = this.onContentPaste.bind(this)
  }

  connect() {
    super.connect()

    this.element.addEventListener('keydown', this.onKeydown)
    this.element.addEventListener('paste', this.onContentPaste)
  }

  disconnect() {
    super.disconnect()

    this.element.removeEventListener('keydown', this.onKeydown)
    this.element.removeEventListener('keydown', this.onContentPaste)
  }

  onKeydown(event) {
    const key = KeyboardKey.fromKeyboardEvent(event)

    if(
      key.isNumeric
      || key.isArrow
      || key.isBackspace
      || key.hasAnyModifier
      || key.isTab
      || key.isEnter
    ) {
      return
    }

    event.preventDefault()
  }

  onContentPaste(event) {
    const parsedClipboardData = parseInt(event.clipboardData.getData('text/plain'))

    if(isNaN(parsedClipboardData)) {
      event.preventDefault()
    }
  }
}
