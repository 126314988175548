import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["image", "uploadImageButton", "imageControls", "fileInput"]

  onImageSelect(e) {
    const imagePath = URL.createObjectURL(e.target.files[0])
    this.element.style.backgroundImage = `url(${imagePath})`
    this.element.style.backgroundSize = "cover"

    this.uploadImageButtonTarget.classList.add("hidden")
    this.imageControlsTarget.classList.remove("hidden")
  }

  setObjectFitToContain() {
    this.element.style.backgroundSize = "contain"
  }

  setObjectFitToCover() {
    this.element.style.backgroundSize = "cover"
  }

  remove() {
    this.element.style.removeProperty("background-image")
    this.element.style.removeProperty("background-size")

    this.uploadImageButtonTarget.classList.remove("hidden")
    this.imageControlsTarget.classList.add("hidden")

    this.fileInputTarget.files = new DataTransfer().files
  }
}
