import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    step: String
  }

  connect() {
    this.dispatch("progress", {detail: this.stepValue})
    this.element.remove()
  }
}
