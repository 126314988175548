import { Controller } from "@hotwired/stimulus"
import { useMoveCursorToEndOfInput } from "../mixins/useMoveCursorToEndOfInput"

export default class extends Controller {
  static targets = ["input", "label", "submitButton", "inputContainer", "toggle"]

  connect() {
    useMoveCursorToEndOfInput(this)
    this.moveCursorToEndOfInput(this.inputTarget, { focus: true })
  }

  focusInput() {
    this.inputTarget.focus()
  }

  saveChanges(e) {
    if (
      this.element.contains(e.target) === false &&
      this.inputContainerTarget.classList.contains("hidden") === false
    ) {
      this.submitButtonTarget.click()
    }
  }

  cancelChanges() {
    this.inputTarget.value = this.labelTarget.innerText.trim()
    this.toggleTarget.click()
  }
}
