import ModalController from '../modal_controller'

export default class extends ModalController {
  static targets = [
    'editTemplateButton',
    'submitButton',
  ]

  submitAnyway() {
    this.dispatch('submit', {
      target: this.element,
    })

    this.close()
  }

  editTemplate() {
    this.dispatch('edit', {
      target: this.element,
    })

    this.close()
  }

  show({ detail: quality }) {
    this.titleTarget.textContent = t.compose.quality_modal.title[quality]
    this.subtitleTarget.textContent = t.compose.quality_modal.subtitle[quality]

    if(quality === 'low') {
      this.submitButtonTarget.classList.add('hidden')
      this.editTemplateButtonTarget.classList.remove('hidden')
    } else {
      this.submitButtonTarget.classList.remove('hidden')
      this.editTemplateButtonTarget.classList.add('hidden')
    }

    this.open()
  }
}
