import { Controller } from "@hotwired/stimulus"
import { useRemove } from "../mixins/useRemove"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    checkConflictUrl: String,
    excluded: Boolean,
    initial: Boolean,
    selected: String,
    includeLabel: { type: String, default: "And is" },
    excludeLabel: { type: String, default: "And is not" },
  }

  static targets = [
    "conflictWarningContainer",
    "select",
    "anyChannelButton",
    "removeButton",
    "footerLabel",
    "orLabel",
    "container",
  ]

  connect() {
    useRemove(this, {
      stopEventPropagation: true,
    })

    this.dispatch("connected", {
      target: document.documentElement,
      detail: {
        id: this.element.id,
        excluded: this.excludedValue,
        included: this.included,
      },
    })
  }

  disconnect() {
    this.dispatch("removed", {
      target: document.documentElement,
    })
  }

  enableRemove({ detail: newTrigger }) {
    if (
      this.element.id === newTrigger.id ||
      !this.initialStep ||
      this.isTriggersDropdown
    )
      return

    this.initialValue = this.element.previousElementSibling !== undefined

    if (newTrigger.included) {
      console.log("allowsing remove")
      console.log(this.element)
      this.allowTriggerToBeRemoved()
      this.disableAnyChannelOption()
    }

    if (newTrigger.excluded) {
      this.footerLabelTarget.classList.remove("hidden")
    }
  }

  disableRemove() {
    if (this.isTriggersDropdown) return

    if (this.onlyTrigger) {
      this.disallowTriggerRemoval()
      this.enableAnyChannelOption()
    }

    if (!this.initialStep && !this.element.previousElementSibling) {
      this.initialValue = true

      if (this.excludedValue) {
        this.footerLabelTarget.classList.remove("hidden")
      }
    }
  }

  includeSelf({ detail }) {
    if (detail === this.element.id || this.isTriggersDropdown) return

    this.excludedValue = false
    this.initialValue = !this.multipleChannelTriggers

    this.getConflictInformationFor(this.selectedValue)

    if (!this.initialValue) {
      this.orLabelTarget.classList.remove("hidden")
    } else {
      this.orLabelTarget.classList.add("hidden")
    }
  }

  excludeSelf({ detail }) {
    if (detail === this.element.id || this.isTriggersDropdown) return

    this.excludedValue = true
    this.removeOrLabel()
    this.removeConflictContainer()
  }

  disableHasAnyChannel() {
    if (
      !this.initialStep ||
      !this.element.nextElementSibling ||
      this.isTriggersDropdown
    )
      return

    this.initialValue = this.element.previousElementSibling === undefined
    this.excludedValue = false

    this.dispatch("include", {
      target: document.documentElement,
      detail: this.element.id,
    })

    if (this.element.nextElementSibling) {
      this.allowTriggerToBeRemoved()
      this.disableAnyChannelOption()
      this.footerLabelTarget.classList.add("hidden")
    }
  }

  checkForConflict({ currentTarget }) {
    this.selectedValue =
      currentTarget.dataset.checkConflictId || currentTarget.value

    if (this.included) {
      this.getConflictInformationFor(
        currentTarget.dataset.checkConflictId || currentTarget.value
      )
    }
  }

  removeConflictContainer() {
    this.conflictWarningContainerTarget.innerHTML = ""
    this.conflictWarningContainerTarget.className = ""

    this.conflictWarningContainerTarget.removeAttribute("data-conflict")
    if (this.hasFooterLabelTarget) {
      this.footerLabelTarget.innerText = this.excludeLabelValue
    }
  }

  selectAnyOption() {
    this.anyOptionValue.selected = true
  }

  setSelectedValue() {
    this.selectedValue = "any"

    if (!this.initialValue) {
      this.orLabelTarget.classList.remove("hidden")
    } else {
      this.orLabelTarget.classList.add("hidden")
    }

    this.removeButtonTarget.classList.add("hidden")
  }

  // private

  getConflictInformationFor(channelId) {
    let query = {
      channable: channelId,
      append_to: this.element.id,
    }

    if (this.excludedValue) {
      query.exclude = "true"
    }

    get(this.checkConflictUrlValue, {
      responseKind: "turbo-stream",
      query,
    })
  }

  initialValueChanged() {
    if (!this.initialValue || this.isTriggersDropdown) return

    if (!this.selectedValue) {
      this.anyChannelButtonTarget.click()
      this.dispatch("exclude", {
        target: document.documentElement,
        detail: this.element.id,
      })

      if (this.multipleChannelTriggers) {
        this.footerLabelTarget.classList.remove("hidden")
      }
    }
  }

  allowTriggerToBeRemoved() {
    if (this.isTriggersDropdown || this.excludedValue) return

    this.removeButtonTarget.classList.remove("hidden")
  }

  disallowTriggerRemoval() {
    this.removeButtonTarget.classList.add("hidden")
    this.footerLabelTarget.classList.add("hidden")
  }

  disableAnyChannelOption() {
    this.anyChannelButtonTarget.classList.replace(
      "hover:bg-night-10",
      "text-night-40"
    )
    this.anyChannelButtonTarget.disabled = true
  }

  enableAnyChannelOption() {
    this.anyChannelButtonTarget.classList.remove("hidden")
    this.anyChannelButtonTarget.classList.replace(
      "text-night-40",
      "hover:bg-night-10"
    )
    this.anyChannelButtonTarget.disabled = false
  }

  removeOrLabel() {
    if (this.hasOrLabelTarget) {
      this.orLabelTarget.classList.add("hidden")
    }
  }

  get anyOptionValue() {
    return Array.from(this.selectTarget.options).find(
      (option) => option.value === "any"
    )
  }

  get included() {
    return !this.excludedValue
  }

  get onlyTrigger() {
    return (
      !this.element.previousElementSibling && !this.element.nextElementSibling
    )
  }

  get initialStep() {
    return this.initialValue
  }

  get multipleChannelTriggers() {
    return (
      document.querySelectorAll(
        `[data-controller="${this.element.dataset.controller}"]`
      ).length > 1
    )
  }

  get isTriggersDropdown() {
    return this.element.id === "triggers_dropdown"
  }
}
