import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static values = {
    url: String,
    title: String,
  }

  connect() {
    super.connect();
    console.log("History controller connected")
  }

  pushState({ currentTarget }) {
    console.log("pushing state")

    const url = currentTarget.dataset.url || this.urlValue
    const state = this.element.dataset.state
    const title = currentTarget.dataset.title || this.titleValue || document.title

    window.history.pushState(state, title, url)
  }

  replaceState({ currentTarget }) {
    console.log("replacing state")

    const url = currentTarget.dataset.url || this.urlValue
    const state = this.element.dataset.state
    const title = currentTarget.dataset.title || this.titleValue || document.title

    console.log(url)
    window.history.replaceState(state, title, url)
  }

  goBack() {
    window.history.back()
  }
}
