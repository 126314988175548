import TooltipController from "../../tooltip_controller"
import { useDraggable } from "../../mixins/useDraggable"
import { useRemove } from "../../mixins/useRemove"

export default class extends TooltipController {
  static targets = [
    "inputContainer",
    "input",
    "label",
    "orderInput",
    "deleteButton",
    "reorderButton",
  ]
  static classes = ["drag"]

  connect() {
    useDraggable(this)
    this.updateNextAndPreviousElementReferences()

    this.dispatch("connected", {
      target: this.element.previousElementSibling || document.documentElement,
    })

    useRemove(this, {
      afterRemove: () => {
        // drag_controller destroys and recreate the records behind the scenes
        // causing disconnect() to be invoked when we don't want to
        // we only want to dispatch these events when the Trash icon was clicked
        this.dispatch("disconnected", {
          target: this.previousElement || document.documentElement,
        })

        this.dispatch("disconnected", {
          target: this.nextElement || document.documentElement,
        })

        this.dispatch("changed")
      },
    })

    super.connect()
  }

  enableRemoveIfMoreThanOneStep(e) {
    this.updateNextAndPreviousElementReferences()
    this.deleteButtonTarget.classList.remove("hidden")
    this.reorderButtonTarget.classList.remove("hidden")
    this.tooltipTarget.classList.replace("left-3", "-left-8")
    this.tooltipTarget.classList.add("space-x-4")
  }

  disableRemoveIfOnlyStep() {
    this.updateNextAndPreviousElementReferences()
    if (!(this.nextElement || this.previousElement)) {
      this.tooltipTarget.classList.replace("-left-8", "left-3")
      this.tooltipTarget.classList.remove("space-x-4")
      this.deleteButtonTarget.classList.add("hidden")
      this.reorderButtonTarget.classList.add("hidden")
    }
  }

  edit() {
    this.inputContainerTarget.classList.remove("hidden")
    this.labelTarget.classList.add("hidden")

    this.inputTarget.selectionStart = this.inputTarget.selectionEnd =
      this.inputTarget.value.length
    this.inputTarget.focus()
    this.editing = true
    this.hideToolbar()
  }

  saveChanges(e) {
    if (this.element.contains(e.target) === false && this.editing) {
      this.labelTarget.innerText = this.inputTarget.value

      this.labelTarget.classList.remove("hidden")
      this.inputContainerTarget.classList.add("hidden")

      this.editing = false
      this.dispatch("changed")
    }
  }

  onDragEnd({ detail: newOrder }) {
    this.dispatch("changed")

    this.labelTarget.classList.remove(...this.dragClasses)
    this.dragging = false

    this.orderInputTarget.value = newOrder
    this.element.classList.add("not-draggable")

    Array.from(this.element.parentElement.children).forEach((li, index) => {
      if (li !== this.element) {
        this.dispatch("drag-end", {
          target: li,
          detail: index + 1,
        })
      }
    })
  }

  updateOrder({ detail: newOrder }) {
    this.orderInputTarget.value = newOrder
    this.updateNextAndPreviousElementReferences()
  }

  show(e) {
    if (!this.dragging && !this.editing) {
      super.show(e)
    }
  }

  showToolbar() {
    super.show()
  }

  hideToolbar() {
    this.tooltipTarget.classList.add("hidden")
  }

  // private

  get draggable() {
    return this.labelTarget
  }

  afterDragEnabled() {
    this.hideToolbar()
    this.element.classList.remove("not-draggable")
  }

  afterDragDisabled() {
    this.labelTarget.classList.remove(...this.dragClasses)
  }

  updateNextAndPreviousElementReferences() {
    this.previousElement = this.element.previousElementSibling
    this.nextElement = this.element.nextElementSibling
  }
}
