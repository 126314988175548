import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    limit: { type: Number, default: 60 }
  }

  static targets = ['input']

  sync(e) {
    const { target } = e

    if(target.value.length > this.limitValue) {
      target.value = target.value.substring(0, this.limitValue)
    }

    this.dispatch('change', {
      detail: target.value,
    })
  }

  restore({ detail: value }) {
    this.inputTarget.value = value
  }

  update({ detail: footer }) {
    if(!footer) return

    this.inputTarget.value = footer.value || ''
    this.inputTarget.dispatchEvent(new Event('input', { bubbles: true }))

    this.dispatch('change', {
      detail: this.inputTarget.value,
    })
  }

  disable() {
    super.disable(this.inputTarget)
    this.addClass(this.inputTarget, 'text-night-20')

    this.addClass(this.element, 'grayscale', 'cursor-not-allowed')

    if(!this.inputTarget.value) {
      this.hide()
    }
  }

  enable() {
    super.enable(this.inputTarget)
    this.removeClass(this.inputTarget, 'text-night-20')

    this.removeClass(this.element, 'grayscale', 'cursor-not-allowed')

    if(!this.inputTarget.value) {
      this.show()
    }
  }

  clear() {
    this.inputTarget.value = ''
    this.disable()
  }

  preventEnter(e) {
    e.preventDefault()
    e.stopPropagation()
  }
}
