import PopoverController from './popover_controller'
import {offset} from "@floating-ui/dom";

export default class extends PopoverController {
  static values = {
    grace: { type: Number, default: 100 }
  }

  hide() {
    this.hideTimeout = this.delayed(() => {
      super.hide()
    }, this.graceValue)
  }

  keep() {
    clearTimeout(this.hideTimeout)
  }

  get middlewares() {
    return [
      offset(5),
      ...super.middlewares,
    ]
  }
}
