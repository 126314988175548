import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static values = {
    subscribedToAll: Boolean,
    chooseAll: Boolean,
    disable: { type: Boolean, default: true }
  }

  static targets = ["event", "checkbox", "submit", "skip"]

  initialize() {
    if(this.subscribedToAllValue || this.chooseAllValue) {
      this.checkAndDisableAll()
    }

    if(this.disableValue) {
      this.syncSubmitButton()
    }
  }

  syncAllOptions({ target }) {
    if (target.checked) {
      this.checkAndDisableAll()
      this.enable(this.submitTarget)
    } else {
      this.uncheckAndEnableAll()
    }
  }

  checkAndDisableAll() {
    this.eventTargets.forEach((element) => {
      this.addClass(element, "bg-night-10", "cursor-not-allowed")

      const checkbox= element.querySelector("input")

      if(checkbox.checked) {
        checkbox.setAttribute("data-was-checked", "")
      }

      checkbox.checked = true
      checkbox.dispatchEvent(new Event("change"))

      this.disable(checkbox)
      this.addClass(checkbox, "!hidden")
    })
  }

  syncSubmitButton() {
    if(this.hasSubmitTarget) {
      this.submitTarget.disabled = this.checkboxTargets.every(checkbox => !checkbox.checked)
    }
  }

  uncheckAndEnableAll() {
    this.eventTargets.forEach((element) => {
      this.removeClass(element, "bg-night-10", "cursor-not-allowed")

      const checkbox = element.querySelector("input")
      checkbox.checked = checkbox.hasAttribute("data-was-checked")

      checkbox.dispatchEvent(new Event("change"))
      checkbox.removeAttribute("data-was-checked")

      this.enable(element.querySelector("input"))
      this.removeClass(element.querySelector("input"), "!hidden")
    })

    this.syncSubmitButton()
  }

  updateSubmitButtonLabel() {
    if(!this.hasSkipTarget) return

    if(this.checkboxTargets.some(checkbox => checkbox.checked)) {
      this.show(this.submitTarget)
      this.hide(this.skipTarget)
    } else {
      this.hide(this.submitTarget)
      this.show(this.skipTarget)
    }
  }
}
