export default class Browser {
  constructor() {
    this.navigator = window.navigator
  }

  get isChromeBased() {
    return navigator.userAgent.includes("Chrome")
  }

  get isFirefox() {
    return this.navigator.userAgent.includes("Firefox")
  }

  get isSafari() {
    return this.navigator.userAgent.includes("Safari")
  }
}
