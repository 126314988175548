import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    name: String,
  }

  dispatchEvent() {
    let event = new CustomEvent(this.nameValue, {
      bubbles: true,
      cancelable: true,
    })
    event.data = this.element.dataset

    window.dispatchEvent(event)
  }
}
